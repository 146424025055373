import { Component, OnInit } from '@angular/core';
import { APICallingService } from '../model/APICallingService';
import { loginDetails } from '../UserDetails';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { Cart } from '../Cart';
import * as PageStore from "../../../src/app/Store/PageStore/Page.Actions";
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { DatePipe } from '@angular/common';
import { NgxGalleryImage,NgxGalleryOptions,} from 'ngx-gallery-9';
import { AppSettings, IAppSettings } from '../model/AppSettings';
import { HttpClient } from '@angular/common/http';
import { BrowserService } from '../browser.service';
import { Navigate } from '../navigate';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css'],
  providers:[DatePipe]
})
export class OrdersComponent implements OnInit {
  loginUserDetails: any;
  lstoforders:any=[];
  DbResult:any=[];
  Todaysdate:string="";
  galleryImages: NgxGalleryImage[];
  galleryOptions: NgxGalleryOptions[];
  ImagePath:string;
  // imageUploaded : string = "";
  storeSettings:IAppSettings=
  {
      faxUserId:'',
      faxPassword:'',
      companyName:'',
      theamColorCode:'#ea0029',
      headerColorCode: '#000',
      headerimage: '../../assets/img/trending1.png',
      loginBackgroundImage:'../../assets/bg-1.jpg',
      loginImage:'../../assets/img/logo_web.png',
      dangerColorCode: '#dc3545',
      successColorCode: '#28a745',
      logo: '../../assets/img/logo_web.png',
      paymentGateway:'',
      key:'',
      selectedBranch:'',
      apiLoginId:'',
      orderEmail:'',
      adminUrl:'',
      orderUrl:'',
      viewName:'AppSettings',
      apiCallingUrl:'',
      fontFamily:'prompt,sans-serif',
      domainName:'',
      fax:'',
      projectId:'',
      keyNo:'',
      noOfBranches:0,
      currency:'Rs ',
      transactionkey:'',
  };
  OrderNo = '';
  OrderNetTotal:string="";

  constructor(private browserService: BrowserService,private store: Store<any>,private datePipe:DatePipe,private apiCall: APICallingService,private fb:FormBuilder,
    public routerref:Router,public router:Navigate,public objCart: Cart,public appSettings:AppSettings,private http: HttpClient) { 
      
      if (this.browserService.isBrowser()) {
      this.storeSettings=   this.appSettings.loadSettings();
      this.ImagePath=this.storeSettings.apiCallingUrl;
      }
  }

  ngOnInit() {
    this.loginUserDetails = new loginDetails();
    //debugger
    if (this.browserService.isBrowser()) {
    $("#preloader").show();
    }
    if (this.browserService.isBrowser()) {
  
    var result = (this.store as any).source['value']['MSECOM'].filter((x: any) => { return x.viewName == "loginDetails"; });
    if (result.length > 0) {
      this.Todaysdate=this.datePipe.transform(new Date(),'MM/dd/yyyy');
      this.loginUserDetails = (Object.assign({}, result[0]));
    }
  }
    this.vieworders('Open');
    setTimeout(hide, 3000);
    function hide(){
      if (this.browserService.isBrowser()) {
      $("#preloader").hide();
      }
    }
    // this.getProductOptionDetailsByProductID("193");
  }
 
  ActivateTab:string="Confirmed";
  lstofFilters:any=[];

 
  vieworders(type){
    //debugger
    this.lstoforders=[];
    this.apiCall.DBCalling('OrderDetailsByUserID',this.loginUserDetails.UserID,'','','').subscribe(
      (res) => {
        $("#preloader").hide();
       $("#preloader").hide();
      this.DbResult= (res);
      if( this.DbResult.tasks.length==0){
        $("#preloader").hide();
      }
      else if(this.DbResult.tasks.length>0 )
      {
        //debugger
        this.lstoforders=this.DbResult.tasks[0];
        let len=this.lstoforders.length-1;
        for(var i=0;i<this.lstoforders.length;i++){
        if(this.lstoforders[i].Details!=null && typeof(this.lstoforders[i].Details)!=undefined)
        {
          //debugger
        try{
          if(len==i){
            //debugger
            var Array=((this.lstoforders[i].Details).replace(/\n/g, "")).replace(/'/g,"\"");
            this.lstoforders[i].Details=JSON.parse(Array);
           // this.GetcountofEachTab()
            break;
          }else{
            var Array=((this.lstoforders[i].Details).replace(/\n/g, "")).replace(/'/g,"\"");
            this.lstoforders[i].Details=JSON.parse(Array);
          //  this.GetcountofEachTab()
          }
        }
        catch(error){console.log(error)}
        }
        }
        this.GetcountofEachTab()
        this.ActivateCollapse=this.lstoforders[0].OrderTrackingNumber;
        this.clickTab(type)
        $("#preloader").hide();
      }else{
        $("#loaderParent").hide();
      }
     });
  }

  GetcountofEachTab(){
    //debugger
    var count,count1,count2,count3
    count=this.lstoforders.filter(x=>x.ClientOrderStatus ==='Confirmed').length;
    count1=this.lstoforders.filter(x=>x.ClientOrderStatus ==='Delivered').length;
    count2=this.lstoforders.filter(x=>x.ClientOrderStatus ==='Open').length;
    count3=this.lstoforders.filter(x=>x.ClientOrderStatus ==='Cancelled').length;
    $("#confirmcount").html(count.toString());
    $("#delivercount").html(count1.toString());
    $("#ordercount").html(count2.toString());
    $("#cancelcount").html(count3.toString());
    // for(let i=0;i<3;i++){
    //   if(i==0){
    //     var count=;
    //     $("#confirmcount").html(count.toString());
    //   }else if(i==1){
    //     var count1=
    //     $("#delivercount").html(count1.toString());
    //   }
    //   else if(i==2){
    //     var count1=
    //     $("#ordercount").html(count1.toString());
    //   }else{
    //     var count2=this.lstoforders.filter(x=>x.ClientOrderStatus ==='Cancelled').length;
    //     $("#cancelcount").html(count2.toString());
    //   }
    //  }
  }
  
  clickTab(CurrentTab){
    //debugger
    this.ActivateTab=CurrentTab;
    this.lstofFilters=[];
    var userorderDetails=this.lstoforders.filter(x=>x.ClientOrderStatus === CurrentTab);
    if(userorderDetails.length>0){
      for(let i=0;i<userorderDetails.length;i++){
        this.lstofFilters.push(userorderDetails[i])
      }
      this.ActivateCollapse=this.lstofFilters[0].OrderTrackingNumber;
    }
  }

  ActivateCollapse:string='';

  click(ActivateAccordance){
    //debugger
    if(this.ActivateCollapse==ActivateAccordance){
      this.ActivateCollapse=''
    }else{
      this.ActivateCollapse=ActivateAccordance;
    }
  }

  mail(d){
    this.apiCall.SendEmail('','','',this.lstoforders[1]).subscribe((res)=>
    {
    
    })
  }

  comment:string="";
  OrderDetailsId:number=0;
  OrderId:number=0;
  Ratingstar:number=0;

  getratingDetails(data){

    if (this.browserService.isBrowser()) {
      const document:any = this.browserService.getDocument();
    //debugger
    document.getElementById("myForm").style.display = "block";
    if(data!=''){
      this.OrderDetailsId=data.OrderDetailsID;
      this.OrderId=data.OrderID;
    }
  }
  }


  closeForm(){
    if (this.browserService.isBrowser()) {
      const document:any = this.browserService.getDocument();
    document.getElementById("myForm").style.display = "none";
    }
  }

  ReturnforProduct(lst){
    //debugger
    this.store.dispatch(new PageStore.OpenPage({viewName:'DeliveredList',lstproductlist:Object.assign([], lst)}));
    this.routerref.navigateByUrl('/ReturnsMyProduct' );
  }

  ExchangeforProduct(d,g){
    //debugger
    this.router.navigate(['/OrderExchange'], { queryParams: {  OptionID:g.ProductOptionID,ID:g.ProductID,OrderDet:JSON.stringify(d),OrderChildDet:JSON.stringify(g)} });
  }

  validateRating : string = "";
  SaveRatingByProduct(){
    //debugger
    
    if(this.Ratingstar > 0 && this.comment!=''){
      this.validateRating = "";
      var xml1='<NewDataSet><Table1>'
    +'<OrderDetailsID>'+this.OrderDetailsId+'</OrderDetailsID>'
    +'<Rating>'+this.Ratingstar+'</Rating>'
    +'<comment>'+this.comment+'</comment>'
    +'<Ratingstatus>1</Ratingstatus>'
    +'<Image>'+this.Image+'</Image>'
    +'<Image1>'+this.Image1+'</Image1>'
    +'<Image2>'+this.Image2+'</Image2>'
    +'</Table1></NewDataSet>';
    this.apiCall.DBCalling('UpdateOrderRatingByOrderID',xml1,this.comment,this.OrderId,'').subscribe(
      (res) => {
      //debugger
      this.DbResult= (Object.assign([],res));
        if(this.DbResult.tasks[0].length>0){
          this.Image = '';
          this.Image1 = '';
          this.Image2 = '';
          var that =this;
          Swal.fire({
            position: 'center',
            icon: 'success',
            text:'Rated sucessfully. '
          }).then(function() {
            that.vieworders('Delivered') // <--- submit form programmatically
          });
        }
    });
    }else{
      this.validateRating = "Rating & review required!"
    }
  }

    Startshoping(){
      //debugger
      this.router.navigateByUrl('/home');
    }


    // cancel order functionality
    UpdateOrderConfirm(resstatus,e) {
      //debugger

      this.PhoneNo = e.PhoneNo;
      this.OrderNo = e.OrderID
      this.OrderID = e.OrderID
      $("#loaderParent").prepend("<div class='theme-loader' id='loaderDet'> <div class='preloader3 loader-block'> <div class='circ1'></div> <div class='circ2'></div> <div class='circ3'></div> <div class='circ4'></div> </div> </div>");
      $("#loaderParent").show();
      //debugger
      var xml = this.getXmlForOrdersForConfirm(resstatus);
      //  var UserId=sessionStorage.getItem('UserId');
      //debugger
      console.log(xml)
      this.apiCall.DBCalling("OrderConfirm", xml, "", "","").subscribe(
        (res: Response) => {
  
          //debugger
          $("#loaderParent").hide();
          this.DbResult = (res);
  
          if (this.DbResult.tasks[0].length > 0 && this.DbResult.tasks[0][0].DBresult > 0) {
            var DeliveryAlertContactNo = this.DbResult.tasks[0][0].DeliveryAlertContactNo;
            $("#loaderParent").hide();
            //  this.router.navigate(['/product']);
  
            if (this.PhoneNo != null && this.PhoneNo != "" && typeof (this.PhoneNo) != "undefined") {
              var SMSMsg = "";
              if (resstatus == 'ShipmentCreated') {
                this.orderConfirmsmsSend();
              }
  
              if (resstatus == 'Canceled') {
                SMSMsg = "Your Order has been Cnaceled Order Ref No:" + this.OrderNo;
              }
  
              else if (resstatus == 'Delivered') {
                SMSMsg = "Your Order has been Delivered Order Ref No:" + this.OrderNo;
              }
  
              else {
                SMSMsg = "Your Order has been Confirmed Order Ref No:" + this.OrderNo;
  
                if (DeliveryAlertContactNo != null && DeliveryAlertContactNo != "") {
                  if (typeof localStorage !== 'undefined') {
                  var MName = localStorage.getItem("MerchantName");
                  this.apiCall.SMSSend(DeliveryAlertContactNo, (" Order  Confirmed By " + MName + " Order Ref No:" + this.OrderNo)).subscribe(
                    (res: Response) => {
                    });
                  }
                }
              }
              this.apiCall.SMSSend(this.PhoneNo, (SMSMsg)).subscribe(
                (res: Response) => {
                });
            }
  
            if (resstatus == 'Deliveredd') {
              // this.router.navigate(['/Orders']);  

              
if (this.browserService.isBrowser()) {
  const window:any = this.browserService.getWindow();
              window.location.href = "#/Orders";
              window.location.reload();
}
            } else {
              // this.router.navigate(['/OrderConfirm'], { queryParams: { OrderID: this.OrderID } });
              this.vieworders('Open');
            }
  
  
          } else {
  
            if (this.DbResult.tasks[0][0].DBresult == -3) {
              (window as any).swal({
                type: 'warning',
                title: 'Exists',
                text: 'failed.!',
                confirmButtonText: 'Dismiss',
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-lg btn-warning'
              });
            } else {
              (window as any).swal({
                type: 'error',
                title: 'Error!',
                text: 'failed.!',
                confirmButtonText: 'Dismiss',
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-lg btn-danger'
              });
            }
          }
          //console.log('Sucsess');
        },
        err => {
          (window as any).swal({
            type: 'error',
            title: 'Error!',
            text: 'Network Error Please Try Again .!',
            confirmButtonText: 'Dismiss',
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-lg btn-danger'
          });
        }
      );
    }

    OrderID = 0;
    Waybill: string = "";
    public dateValue: Date = new Date();
    PhoneNo = '';

    getXmlForOrdersForConfirm(status): any {
      //debugger
      var deliveryDate = this.dateValue.getFullYear() + '/' + (((this.dateValue.getMonth() + 1) > 9 ? (this.dateValue.getMonth() + 1) : '0' + (this.dateValue.getMonth() + 1)) + '/' + (this.dateValue.getDate() > 9 ? this.dateValue.getDate() : '0' + this.dateValue.getDate()));
      //debugger
      var UserId = (typeof sessionStorage !== 'undefined') ?sessionStorage.getItem('MerchantID'):0;
      return '<NewDataSet><Table1>'
        + ' <MerchantID>' + UserId + '</MerchantID>'
        + '<DDate>' + deliveryDate + '</DDate>'
        + '<OStatus>' + status + '</OStatus>'
        + '<OrderID>' + this.OrderID + '</OrderID>'
        + '<Waybill>' + this.Waybill + '</Waybill>'
        + '</Table1></NewDataSet>';
    }

    orderConfirmsmsSend()
    {
      //debugger
      this.http.post(AppSettings.Path+'orderconfirmsms.php', { 'mobile_number': this.PhoneNo, 'order_no': this.OrderNo,
      'order_amount':this.OrderNetTotal,'tracking_no':this.Waybill }).subscribe(res => {
        //debugger
      });
    }


    fileType: any;
    extention: any = 'webp';
    selectedFile: ImageSnippet;
    Bfname: any;
    Image : string = "";
    Image1 : string = "";
    Image2 : string = "";

    BannerfileChangeListener($event,e) {
      //debugger
        var Bimage: any = new Image();
        var file: File = $event.target.files[0];
        var myReader: FileReader = new FileReader();
        var that = this;
        this.fileType = file.type;
        //this.extention = $("#fileOpenBanner").val().split('.').pop();
        myReader.readAsDataURL(file);
        myReader.onload = (_event) => {
          //debugger
          try {
            var path = myReader.result;
            this.selectedFile = new ImageSnippet(path.toString(), file);
            const formData = new FormData();
            let currDatetime: string = new Date().toString().trim();
            currDatetime = currDatetime.replace(/[^a-zA-Z0-9]/g, "");
            that.Bfname = "Product_review" + name + currDatetime + '.' + this.extention;
            formData.append('image', this.selectedFile.file);
            formData.append('imageName', that.Bfname);
            that.SaveBannerImage(formData,e);
          } catch (ex) {
            alert(ex.message);
          }
          $("#fileOpenBanner").val("");
        };
        myReader.readAsDataURL(file);
    }

    SaveBannerImage(formData,e) {
      //debugger
      this.apiCall.SaveImage(formData, this.fileType).subscribe(
        (res) => {
          //debugger
          if(e==0){
            this.Image = 'Images/' + this.Bfname;
          }
          if(e==1){
            this.Image1 = 'Images/' + this.Bfname;
          }
          if(e==2){
            this.Image2 = 'Images/' + this.Bfname;
          }
          //debugger
        },
        (err) => {
        });
    }
}

class ImageSnippet {
  constructor(public src: string, public file: File) { }
}