
<h2 class="offer">SHIPPING POLICY</h2>
<div class="container">
    <h1>Shipping Policy</h1>
    <!-- <p>Welcome to our E-commerce Website. These Shipping Policy outline the rules and regulations for the use of our website.</p> -->
    
   
    <p>To return your product, you can directly do it through our return courier</p>
    
    <p>Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund.
        Depending on where you live, the time it may take for your exchanged product to reach you, may vary.
        </p>

    <h2>Self-Ship</h2>
    <p>If your Pincode is not in the serviceable area i.e. if our delivery agents are not able to come for pick-up, the customer has to self-ship the product. </p>
    
    <h1></h1>
        <h2></h2>
        <h3></h3>
        <h4></h4>
    
</div>