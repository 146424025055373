import { ApplicationRef, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, NgZone, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { loginDetails } from '../UserDetails';
import { Cart, CartList } from '../Cart';
import { APICallingService } from '../model/APICallingService';
import { AppSettings, IAppSettings } from '../model/AppSettings';
import * as PageStore from "../../../src/app/Store/PageStore/Page.Actions";
import { AppComponent } from '../app.component';
import { MyWishList } from '../Mywishlist';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation, NgxGalleryLayout, NgxGalleryImageSize, NgxGalleryComponent } from '@kolkov/ngx-gallery';
import Swal from 'sweetalert2';
import $ from 'jquery';
import { BrowserService } from '../browser.service';
import * as _ from 'lodash';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import * as data from '../../assets/seo/meta-tags.json';
import { MetaService } from './meta-service';
import { Navigate } from '../navigate';
import { FacebookPixelService } from '../../facebook-pixel.service';
@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  
  styleUrls: ['./product-details.component.css'],
  encapsulation: ViewEncapsulation.Emulated,

  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductDetailsComponent implements OnInit {
  @ViewChild('ctrCart') ctrCartButton: ElementRef;
  @ViewChild('ctrGallery') ctrGallery: NgxGalleryComponent;
  hover = false;
  //metaDetails: any[] = (data as any).meta;
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  lstReviews: any = [];
  uniqueProducts: any = [];
  recommendedProducts: any = [];

  totalPrice: number = 0;
  salesPrice: number = 0;
  ImageAppUrl: string = "";


    storeSettings: IAppSettings =
      {
        faxUserId: '',
        faxPassword: '',
        companyName: '',
        theamColorCode: '#ea0029',
        headerColorCode: '#000',
        headerimage: '../../assets/img/trending1.png',
        loginBackgroundImage: '../../assets/bg-1.jpg',
        loginImage: '../../assets/img/logo_web.png',
        dangerColorCode: '#dc3545',
        successColorCode: '#28a745',
        logo: '../../assets/img/logo_web.png',
        paymentGateway: '',
        key: '',
        selectedBranch: '',
        apiLoginId: '',
        orderEmail: '',
        adminUrl: '',
        orderUrl: '',
        viewName: 'AppSettings',
        apiCallingUrl: '',
        fontFamily: 'prompt,sans-serif',
        domainName: '',
        fax: '',
        projectId: '',
        keyNo: '',
        noOfBranches: 0,
        currency: 'Rs ',
        transactionkey: '',
      };

  ImagePath: string;
  //private appRef: ApplicationRef,
  constructor(private fbPixelService: FacebookPixelService, private zone: NgZone,private titleService: Title,
    private metaService: MetaService,private meta: Meta,private browserService: BrowserService, 
    private cdr: ChangeDetectorRef,private el: ElementRef, private renderer: Renderer2, 
    private route: ActivatedRoute, private apiCall: APICallingService, private WishList: MyWishList, 
    private App: AppComponent, private objCart: Cart, private store: Store<any>, public routRouter: Router, public router: Navigate, public appSettings: AppSettings) {

var that=this;
 


    if (this.browserService.isBrowser()) {
      this.storeSettings = this.appSettings.loadSettings();
      this.ImagePath = this.storeSettings.apiCallingUrl;


      let storeDeliverySettings = (this.store as any).source['value']['MSECOM'].filter((x: any) => {
        return x.viewName == 'deliveryZipCode';
      });
      if (storeDeliverySettings.length > 0) {
        let deliveryZipCode = Object.assign({}, storeDeliverySettings[0]);

        $('#selectedZipCode').val(deliveryZipCode.deliveryZipCode);

        if (this.browserService.isBrowser()) {
          let cartDetails = (this.store as any).source['value']['MSECOM'].filter((x: any) => {
            return x.viewName == 'CartList';
          });
          if (cartDetails.length > 0) {
            let lstCartList = Object.assign([], cartDetails[0].lstCartList);
            this.objCart.CalcTotals(lstCartList);
          }

        }


        this.checkDeliveryAvailability((+this.objCart.OrderGrossAmount));
      }


      var lstImagepath = (this.store as any).source['value']['MSECOM'].filter((x: any) => { return x.viewName == "ImageUrl"; });
      this.ImageAppUrl = lstImagepath[0].ImageUrl;
      if (this.browserService.isBrowser()) {
        this.storeSettings = this.appSettings.loadSettings();
      }
    }
  }


  getcurrency() {
    return this.storeSettings.currency.toString();
  }

  sizepopdisplay = 'none';
  ImageClick(Image) {
    this.Imagesrc = Image;
  }

  closeLoginModal() {
    this.sizepopdisplay = 'none';
  }

  sizeView(description) {
    debugger;
    this.sizepopdisplay = 'block';
    this.SizeChartDescription = description;
  }

  loginUserDetails: any = [];
  RawImagePath = "";
  Imagesrc = "";
  getdescription(ProductShortDesc) {

    if (typeof (ProductShortDesc) != "undefined") {
      var res = ProductShortDesc.replaceAll("<p>", "<p class='white-font-color'>");
      return res;
    }
  }
  productName: string = "";
  dropdownOpen=false;
drpShareTap()
{
  this.dropdownOpen = !this.dropdownOpen;
}


  ngOnInit(): void {
    console.log('ngOnInit started');
   //this.metaService.setMetaTags();
    //this.meta.updateTag({ name: 'description', content:'dsfsdfdsfsdf'});
    // const metaData = this.route.snapshot.data['metaData'];

    // let description=  metaData.tasks[0][0].Fld2.trim();
    // let title=  metaData.tasks[0][0].Fld1.trim();
    // let tags=  metaData.tasks[0][0].Fld3.trim();
    //  this.meta.updateTag({ name: 'description', content:description});
    //  this.meta.updateTag({ name: 'keywords', content:tags});
    //  this.meta.updateTag({ name: 'title', content:title});
    
      this.route.queryParamMap.subscribe((params: any) => {
        //debugger
        const metaData = this.route.snapshot.data['metaData'];
        this.DProductID = params.params.ID;
        this.DOptionID = params.params.OptionID;
        try{
        this.viewComboProducts(this.DProductID);
        }catch(e)
        {

        }
  
try{
        // let index=this.metaDetails.findIndex(x=> x.id== this.DProductID);
        // let description=  this.metaDetails[index].description.trim();
        // let title=  this.metaDetails[index].title.trim();
        // let tags=  this.metaDetails[index].tags.trim();
        // this.meta.updateTag({ name: 'description', content:description});
        // this.meta.updateTag({ name: 'keywords', content:tags});
        // this.meta.updateTag({ name: 'title', content:title});


    //     $(document).ready(function() {
    //       //debugger;
    //  const metaDescriptionTag = document.querySelector('meta[name="description"]');
    //           if (metaDescriptionTag) {
    //             metaDescriptionTag.setAttribute('content', description);
    //           }
    //         });
       
}catch(e)
{
  
}
    
     
        //  this.RawImagePath=params.params.Img;
        //  this.SelectedOption1=params.params.option1;
        //  this.SelectedOption2=params.params.option2;
        //  this.productName=params.params.Name;
        
          
      
         
        this.loginUserDetails = new loginDetails();
        var result = (this.store as any).source['value']['MSECOM'].filter((x: any) => { return x.viewName == "loginDetails"; });
        if (result.length > 0) {
          this.loginUserDetails = (Object.assign({}, result[0]));
        }
        //debugger
        var that =this;
        $( document ).ready(function() {


          that.zone.run(() => {
          that.getProductOptionDetailsByProductID(metaData);

          });
        });

         

        
        this.ratingsReviews(this.DProductID);
      }
      );



    //   setTimeout(() => {
    //    // this.updateMetaTags();
    // }, 1000); 


      this.galleryOptions = [
        {
          width: "497px",
          height: "1100px",
          previewCloseOnClick: true,
          previewCloseOnEsc: true,
          thumbnailsColumns: 5,
          previewZoom: true,
          imageInfinityMove: true,

          imageBullets: true,

          previewRotate: true,
          fullWidth: false,
          arrowPrevIcon: 'fa fa-chevron-left',
          arrowNextIcon: 'fa fa-chevron-right',
          closeIcon: "fa fa-window-close",
          fullscreenIcon: "fa fa-arrows",
          spinnerIcon: "fa fa-refresh fa-spin fa-3x fa-fw",
          previewFullscreen: true,
          thumbnailSize: NgxGalleryImageSize.Contain,

          imageAutoPlayInterval: 3000,
          layout: NgxGalleryLayout.ThumbnailsBottom,
          // auto play section
          imageAutoPlay: false,
          imageAutoPlayPauseOnHover: true,
          previewAutoPlay: true,
          previewAutoPlayPauseOnHover: true,
          // auto play section ends
          imageAnimation: NgxGalleryAnimation.Slide,
          imageSwipe: true,
          thumbnailsSwipe: true,
          previewSwipe: true,
          imageSize: NgxGalleryImageSize.Cover,
          thumbnailsMoveSize: 1
        },

        // max-width 800
        {
          breakpoint: 800,
          width: '100%',
          height: '800px',
          imagePercent: 100,
          thumbnailsPercent: 20,
          previewFullscreen: true,
          thumbnailsMargin: 0,
          thumbnailMargin: 0,
          imageSwipe: true,
          thumbnailsSwipe: true,
          previewSwipe: true,

        },

        // max-width 400
        {
          breakpoint: 400,
          preview: true,
          previewFullscreen: true,
          imageSwipe: true,
          thumbnailsSwipe: true,
          previewSwipe: true,
        },


        { "breakpoint": 500, "width": "300px", "height": "800px", "previewFullscreen": true, "thumbnailsColumns": 5 },
        { "breakpoint": 300, "width": "100%", "height": "800px", "previewFullscreen": true, "thumbnailsColumns": 5 }

      ];


      this.galleryImages = [];


  //  }


  }


  applyStyles() {
    // const element = this.el.nativeElement.querySelector('.ngx-gallery-layout');

    // if (element) {
    //   this.renderer.setStyle(element, 'width', '100%');
    //   this.renderer.setStyle(element, 'height', '100%');
    //   this.renderer.setStyle(element, 'display', '-webkit-box');
    //   this.renderer.setStyle(element, 'display', 'block');
    //   this.renderer.setStyle(element, '-webkit-box-orient', 'vertical');
    //   this.renderer.setStyle(element, '-webkit-box-direction', 'normal');
    //   this.renderer.setStyle(element, 'flex-direction', 'column');
    // }

    // const element = this.el.nativeElement.querySelector('ngx-gallery-thumbnails');
    // if (element) {
    //       this.renderer.setStyle(element, 'height', 'calc(15% + 20px)!important');
    // } 

    // const element1 = this.el.nativeElement.querySelector('[ngx-gallery-image]');
    // if(element1){
    //   this.renderer.setStyle(element1, 'margin-bottom', '-10px');
    //  }
  }


  ratingsReviews(e) {
    //debugger
    // alert(e);
    this.apiCall.DBCalling("Ratings_Reviews", e, '', '', '').subscribe((res: any) => {
      //debugger
      this.lstReviews = [];
      let data = (res.tasks[0]);
      try {
        if (data.length > 0) {
          this.lstReviews = data;
        }
      } catch (e) {

      }
    })
  }

  zindexval: string = '-1';
  click(event) {



  }



  lstProducts = [];
  lstRelatedProducts = [];
  IsWishListItem = [];
  lstset: any = [];
  lstOp2: any = [];
  lstOp3: any = [];
  lstOp4: any = [];
  MerchantID = '0';

  SelectedOption1 = '';
  SelectedOption2 = '';
  SelectedOption3 = '';
  SelectedOption4 = '';
  DOptionID = '0';
  DProductID = '0';
  lstCurrentDet: any;

  CheckOptionB(lstData): [] {

    var lstFilterDet: any = [];
    if (lstData != null && typeof (lstData) != "undefined") {


      for (let j = 0; j < this.lstProducts.length; j++) {

        if (this.lstProducts[j].OPName1 == this.SelectedOption1) {
          for (var i = 0; i < lstData.length; i++) {

            if (lstData[i].OptionName == this.lstProducts[j].OPName2 && lstData[i].SalesPrice == this.lstProducts[j].SalesPrice) {
              if (lstFilterDet.length > 0) {
                lstFilterDet.push(lstData[i]);
              } else {
                lstFilterDet = [lstData[i]];
              }

            }
          }
        }
      }
    }

    return lstFilterDet;
  }



  CheckOptionC(lstData): [] {


    var lstFilterDet: any = [];

    if (lstData != null && typeof (lstData) != "undefined") {

      for (let j = 0; j < this.lstProducts.length; j++) {

        if (this.lstProducts[j].OPName1 == this.SelectedOption1 && this.lstProducts[j].OPName2 == this.SelectedOption2) {
          for (var i = 0; i < lstData.length; i++) {

            if (lstData[i].OptionName == this.lstProducts[j].OPName3 && lstData[i].SalesPrice == this.lstProducts[j].SalesPrice) {
              if (lstFilterDet.length > 0) {
                lstFilterDet.push(lstData[i]);
              } else {
                lstFilterDet = [lstData[i]];
              }

            }
          }
        }
      }
    }

    return lstFilterDet;
  }





  CheckOptionD(lstData): [] {

    var lstFilterDet: any = [];

    try {



      if (lstData != null && typeof (lstData) != "undefined") {

        for (let j = 0; j < this.lstProducts.length; j++) {

          if (this.lstProducts[j].OPName1 == this.SelectedOption1 && this.lstProducts[j].OPName2 == this.SelectedOption2 && this.lstProducts[j].OPName3 == this.SelectedOption3) {
            for (var i = 0; i < lstData.length; i++) {

              if (lstData[i].OptionName == this.lstProducts[j].OPName4 && lstData[i].SalesPrice == this.lstProducts[j].SalesPrice) {
                if (lstFilterDet.length > 0) {
                  lstFilterDet.push(lstData[i]);
                } else {
                  lstFilterDet = [lstData[i]];
                }

              }
            }
          }
        }

      }

    } catch (e) {

    }
    return lstFilterDet;
  }


  CheckOption2(lstData, SValue): [] {


    var lstFilterDet: any = [];
    for (var i = 0; i < lstData.length; i++) {

      if (lstData[i].OPName2 == SValue) {
        if (lstFilterDet.length > 0) {
          lstFilterDet.push(lstData[i]);
        } else {
          lstFilterDet = [lstData[i]];
        }

      }
    }

    return lstFilterDet;
  }



  CheckOption3(lstData, SValue): [] {

    var lstFilterDet: any = [];
    for (var i = 0; i < lstData.length; i++) {

      if (lstData[i].OPName3 == SValue) {
        if (lstFilterDet.length > 0) {
          lstFilterDet.push(lstData[i]);
        } else {
          lstFilterDet = [lstData[i]];
        }


      }
    }

    return lstFilterDet;
  }



  CheckOption4(lstData, SValue): [] {

    var lstFilterDet: any = [];
    for (var i = 0; i < lstData.length; i++) {

      if (lstData[i].OPName4 == SValue) {
        if (lstFilterDet.length > 0) {
          lstFilterDet.push(lstData[i]);
        } else {
          lstFilterDet = [lstData[i]];
        }


      }
    }

    return lstFilterDet;
  }

  CheckOption1(lstData, SValue): [] {
    var lstFilterDet: any = [];
    for (var i = 0; i < lstData.length; i++) {

      if (lstData[i].OPName1 == SValue) {
        if (lstFilterDet.length > 0) {
          lstFilterDet.push(lstData[i]);
        } else {
          lstFilterDet = [lstData[i]];
        }

      }
    }

    return lstFilterDet;
  }
  addDays = (date: Date, days: number): Date => {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };
  ExpectedDeliveryDays = "";
  DeliveryCharges: number = 0;
  mDeliveryCharges = 0;
  EnterPincode: string = "";
  showDeliveryStatusmsg :boolean = false;
  Deliveryerror :boolean =  false;


  checkDeliveryAvailability(price) {

    let deliveryZipCode = $("#deliveryZipCode").val();
    this.EnterPincode = deliveryZipCode.toString()
    if (typeof (deliveryZipCode) != 'undefined' && deliveryZipCode != '') {
      //debugger;
      $("#preloader").show();

      this.apiCall.DBCalling("CheckDeliveryAvailability", deliveryZipCode, price, "", "").subscribe(
        (res: any) => {
          debugger
          $("#preloader").hide();
          this.showDeliveryStatusmsg = true;
          if (res.tasks[1][0].Message != '') {
            this.DeliveryCharges = -1;
            this.Deliveryerror = true
            //debugger;
           
          } else {
            let dbResult: any = (res);
            if (dbResult.tasks.length > 0) {
              debugger
              this.Deliveryerror = false;
              this.store.dispatch(new PageStore.OpenPage({ viewName: 'selectedZipCode', deliveryZipCode }));
              let ExpectedDeliveryDays = typeof (dbResult.tasks[0][0]['ExpectedDeliveryDays']) == 'undefined' ? '' : dbResult.tasks[0][0]['ExpectedDeliveryDays'];
              this.DeliveryCharges = (typeof (dbResult.tasks[0][0]['DeliveryCharges']) == 'undefined' ? -1 : (+dbResult.tasks[0][0]['DeliveryCharges']));
              this.mDeliveryCharges = (+dbResult.tasks[0][0]['DeliveryCharges']);
              this.showDeliveryStatusmsg = this.DeliveryCharges == -1 ? false : true;
              // this.showDeliveryStatusmsg = this.DeliveryCharges > 0 ? true : false;
              this.cdr.detectChanges();
              try {
                debugger
                if (ExpectedDeliveryDays != '') {
                  const date: Date = new Date();
                  const dateResult: Date = this.addDays(date, (+ExpectedDeliveryDays));
                  //debugger;
                  this.ExpectedDeliveryDays = dateResult.toLocaleDateString('en-US', { day: 'numeric', month: 'short' });
                } else {
                  this.ExpectedDeliveryDays = null;
                }
                //debugger;
              } catch (e) {
              }
              //debugger;
              try {
                //debugger;
                let maxDiscountAmount = (+dbResult.tasks[1][0]['MaxDiscountAmount'])
                let fixedAmount = (+dbResult.tasks[1][0]['FixedAmount'])
                let percentage = (+dbResult.tasks[1][0]['Percentage'])
                //debugger;
                let perDiscount = percentage > 0 ? ((price * percentage) / 100) : 0;
                //debugger;
                let disc = perDiscount > 0 ? ((perDiscount > fixedAmount) ? fixedAmount : perDiscount) : (fixedAmount > 0 ? ((fixedAmount > maxDiscountAmount) ? maxDiscountAmount : fixedAmount) : maxDiscountAmount);
                //debugger;
                this.DeliveryCharges = this.DeliveryCharges - (disc > maxDiscountAmount ? maxDiscountAmount : disc);
                this.DeliveryCharges = this.DeliveryCharges > 0 ? this.DeliveryCharges : 0;
              } catch (e) {
              }
            } else {
              //debugger;
              this.Deliveryerror = true;
            }
          }
          this.cdr.detectChanges();
        }
      );
    } else {
      this.ExpectedDeliveryDays = '';
      this.showDeliveryStatusmsg = false;
    }

  }


  validatePinCode(e) {
    debugger

    let pincode = e.target.value
    if (pincode.length > 6) {
      //debugger;
      // pincode.splice(0,6)
      pincode = pincode.slice(0, 6)
      // document.getElementById('deliveryZipCode').value =pincode;
      const inputElement = document.getElementById('deliveryZipCode') as HTMLInputElement;
      inputElement.value = pincode;
    }


    //   if (this.value.length > 4) {
    //     this.value = this.value.slice(0,4); 
    // }

  }
  getstockstatus(OPName2) {

    var filter = this.lstProducts.filter((x) => x.OPName2 == OPName2);
    return ((filter[0].Available) != 'out of stock' ? false : true)
  }

  OptionsChangeAssign(event, target, index) {


debugger;
    var lstFilterDet: any[];
    var lstFilterDet1: any[];
    var ReslstFilterDet: any[];

    if (index == 1) {

      this.SelectedOption1 = target;


      this.SelectedOption2 = "";
      this.SelectedOption3 = "";
      this.SelectedOption4 = "";
    }

    if (index == 2) {

      this.SelectedOption2 = target;

      this.SelectedOption3 = "";
      this.SelectedOption4 = "";
    }

    if (index == 3) {
      this.SelectedOption3 = target;

      this.SelectedOption4 = "";
    }

    if (index == 4) {
      this.SelectedOption4 = target;

    }
    lstFilterDet = this.CheckOption1(this.lstProducts, this.SelectedOption1);




    if (this.SelectedOption2 != "" && (lstFilterDet.length > 1)) {

      lstFilterDet1 = this.CheckOption2(lstFilterDet, this.SelectedOption2);
      lstFilterDet = lstFilterDet1;

      //this.SelectedOption3=lstFilterDet.length==1 ? lstFilterDet[0].OPName3 : '';
    }

    if (this.SelectedOption3 != "" && (lstFilterDet.length > 1)) {

      lstFilterDet1 = this.CheckOption3(lstFilterDet, this.SelectedOption3);
      lstFilterDet = lstFilterDet1;
    }

    if (this.SelectedOption4 = "" && (lstFilterDet.length > 1)) {

      lstFilterDet1 = this.CheckOption4(lstFilterDet, this.SelectedOption4);
      lstFilterDet = lstFilterDet1;
    }





    if (lstFilterDet.length > 0) {
      var Sindex = 0;
      var MaxOptions = 0;

      for (var i = 0; i < lstFilterDet.length; i++) {
        if (lstFilterDet[i].OPName1 != "" && lstFilterDet[i].OPName2 != "" && lstFilterDet[i].OPName3 != "" && lstFilterDet[i].OPName4 != "") {
          Sindex = i;
          MaxOptions = 4;
break;

        }

        if (lstFilterDet[i].OPName1 != "" && lstFilterDet[i].OPName2 != "" && lstFilterDet[i].OPName3 != "" && MaxOptions < 4) {
          Sindex = i;
          MaxOptions = 3;
          break;
        }


        if (lstFilterDet[i].OPName1 != "" && lstFilterDet[i].OPName2 != "" && MaxOptions < 3) {
          Sindex = i;
          MaxOptions = 2;
          break;
        }


        if (lstFilterDet[i].OPName1 != "" && MaxOptions < 2) {
         Sindex = i;
          MaxOptions = 2;
          break;
        }

      }




      var resD1 = (((lstFilterDet[Sindex].SetDetails).replace(/\n/g, "")).replace(/'/g, "\""));
      var resChild1 = JSON.parse(resD1);
      this.lstset = resChild1 == '' ? [] : resChild1;

      this.lstCurrentDet = lstFilterDet[Sindex];

      this.SelectedOption1 = lstFilterDet[Sindex].OPName1;

      this.SelectedOption2 = lstFilterDet[Sindex].OPName2;


      this.SelectedOption3 = lstFilterDet[Sindex].OPName3;
      this.SelectedOption4 = lstFilterDet[Sindex].OPName4;
      try {

        var resDF = (((this.lstCurrentDet['Features']).replace(/\n/g, "")).replace(/'/g, "\""));
        var resChildF = JSON.parse(resDF);
        this.lstCurrentDet['Features'] = resChildF;
      } catch (e) {

      }

      try {


        var resD2 = (((this.lstCurrentDet['lst1Options']).replace(/\n/g, "")).replace(/'/g, "\""));
        var resChild2 = JSON.parse(resD2);
        this.lstCurrentDet['lst1Options'] = resChild2;

      } catch (e) {

      }


      {
        try {

          var resD = (((this.lstCurrentDet['lst2Options']).replace(/\n/g, "")).replace(/'/g, "\""));
          var resChild = JSON.parse(resD);
          this.lstCurrentDet['lst2Options'] = resChild;

        } catch (e) {

        }
        this.lstOp2 = this.CheckOptionB(this.lstCurrentDet['lst2Options']);
      }
      {
        try {
          var resD = (((this.lstCurrentDet['lst3Options']).replace(/\n/g, "")).replace(/'/g, "\""));
          var resChild = JSON.parse(resD);
          this.lstCurrentDet['lst3Options'] = resChild;
        } catch (e) {

        }
        this.lstOp3 = this.CheckOptionC(this.lstCurrentDet['lst3Options']);
      }

      {
        try {
          var resD = (((this.lstCurrentDet['lst4Options']).replace(/\n/g, "")).replace(/'/g, "\""));
          var resChild = JSON.parse(resD);
          this.lstCurrentDet['lst4Options'] = resChild;
        } catch (e) {

        }
        this.lstOp4 = this.CheckOptionD(this.lstCurrentDet['lst4Options']);
      }



    }

    this.Imagesrc = this.lstCurrentDet.Image1;

    this.lstCurrentDet.Price = Math.trunc(this.lstCurrentDet.Price)


    this.galleryImages = [];

    if (this.lstCurrentDet.Image1 != '' && this.lstCurrentDet.Image1 != '0') {
      this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image1, medium: this.ImagePath + this.lstCurrentDet.Image1, big: this.ImagePath + this.lstCurrentDet.Image1 ,description: 'Buy online -'+this.productName,label: 'Buy online -'+this.productName})
    }
    if (this.lstCurrentDet.Image2 != '' && this.lstCurrentDet.Image2 != '0') {
      this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image2, medium: this.ImagePath + this.lstCurrentDet.Image2, big: this.ImagePath + this.lstCurrentDet.Image2,description: 'Buy online -'+this.productName ,label: 'Buy online -'+this.productName})
    }

    if (this.lstCurrentDet.Image3 != '' && this.lstCurrentDet.Image3 != '0') {
      this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image3, medium: this.ImagePath + this.lstCurrentDet.Image3, big: this.ImagePath + this.lstCurrentDet.Image3,description: 'Buy online -'+this.productName ,label: 'Buy online -'+this.productName})
    }
    if (this.lstCurrentDet.Image4 != '' && this.lstCurrentDet.Image4 != '0') {
      this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image4, medium: this.ImagePath + this.lstCurrentDet.Image4, big: this.ImagePath + this.lstCurrentDet.Image4 ,description: 'Buy online -'+this.productName,label: 'Buy online -'+this.productName})
    }
    if (this.lstCurrentDet.Image5 != '' && this.lstCurrentDet.Image5 != '0') {
      this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image5, medium: this.ImagePath + this.lstCurrentDet.Image5, big: this.ImagePath + this.lstCurrentDet.Image5 ,description: 'Buy online -'+this.productName,label: 'Buy online -'+this.productName})
    }
    if (this.lstCurrentDet.Image6 != '' && this.lstCurrentDet.Image6 != '0') {
      this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image6, medium: this.ImagePath + this.lstCurrentDet.Image6, big: this.ImagePath + this.lstCurrentDet.Image6 ,description: 'Buy online -'+this.productName,label: 'Buy online -'+this.productName})
    }

    this.galleryOptions[0].thumbnailsColumns = this.galleryImages.length;

    var keyNamecolor = "";
    let NotslectedProdcutslst = [];

    function getKeyByValue(object, value) {
      return Object.keys(object).find(key =>
        object[key] === value);
    }
    keyNamecolor = getKeyByValue(this.lstProducts[0], 'Color');
    if (keyNamecolor == 'undefined' || keyNamecolor == undefined) {
      keyNamecolor = getKeyByValue(this.lstProducts[0], 'color');
    }
    if (keyNamecolor == 'OPGName1') {
      NotslectedProdcutslst = this.lstProducts.filter((x) => {
        return x.OPName1 !== this.SelectedOption1
      })

    } else if (keyNamecolor == 'OPGName2') {
      NotslectedProdcutslst = this.lstProducts.filter((x) => {
        return x.OPName2 !== this.SelectedOption2
      })

    } else if (keyNamecolor == 'OPGName3') {
      NotslectedProdcutslst = this.lstProducts.filter((x) => {
        return x.OPName3 !== this.SelectedOption3
      })

    } else if (keyNamecolor == 'OPGName4') {
      NotslectedProdcutslst = this.lstProducts.filter((x) => {
        return x.OPName4 !== this.SelectedOption4
      })

    } else {

    }


    if (NotslectedProdcutslst.length > 0) {

      for (let d of NotslectedProdcutslst) {

        this.lstRelatedProducts.unshift(Object.assign({}, d))
      }
    }
  }

selectedOptionIndex=0;

selectedOption='';
  OptionsChange(event, target, index) {
debugger;
//alert(JSON.stringify( event));

let isColor=false;

// switch(index)
// {
// case 1:
// {
// if( event.OPGName1.toLowerCase() =='color')
// {
//   isColor=true;
// }
//   break;
// }


// case 2:
// {
//   if( event.OPGName2.toLowerCase() =='color')
//     {
//       isColor=true;
//     }
//   break;
// }
// case 3:
// {
//   if( event.OPGName3.toLowerCase() =='color')
//     {
//       isColor=true;
//     }
//   break;
// }
// case 4:
// {
//   if( event.OPGName4.toLowerCase() =='color')
//     {
//       isColor=true;
//     }
//   break;
// }


// }
if(event.OptionGroup.toLowerCase() =='color')
{
  debugger;
  if(event.ProductID!= this.DProductID)
  {
    
    localStorage.setItem("SelectedOption",JSON.stringify( {target:this.selectedOption, index:this.selectedOptionIndex,ProductID:event.ProductID,ProductName:event.ProductName}));
  


    const urlFriendlyProduct = _.kebabCase(event.ProductName);
    this.router.navigate(['/product', urlFriendlyProduct], { queryParams: {  ID: event.ProductID } });
  }
  
}else{

this.selectedOptionIndex=index;
this.selectedOption=target;
  this.OptionsChangeAssign(event, target, index) ;
}



  }
  SizeChartDescription: string = "";


  DProductName = "";
  someHtmlCode = "<p>new <strong class='ql-font-monospace'>china assadadasdas<strong></p>"
  AddtoCartClick(Qty, check) {
    //debugger;

    let lstCart = (this.store as any).source['value']['MSECOM'].filter((x: any) => {
      return x.viewName == 'CartList';
    });
    let product;

    if (lstCart.length > 0) {
      product = lstCart[0].lstCartList.filter((x: any) => x.ProductID == this.lstCurrentDet.ProductID && x.ProductOptionID == this.lstCurrentDet.ProductOptionID)
    } //debugger;



    if (product != undefined && product.length > 0 && product[0].Qty >= (+product[0].ProductQty)) {
      //debugger;
      // alert('Out of Stock')
      Swal.fire({
        title: `Out Of Stock`,
        timer: 2000,
      });

    } else {

     

      this.store.dispatch(new PageStore.OpenPage({ viewName: 'Buylst', lstCartList: [] }));
      //debugger;
      var keyNamecolor, KeyNamesize, ValueColorKey, ValuesizeKey, ValidationMsge;
      //debugger;
      function getKeyByValue(object, value) {
        return Object.keys(object).find(key =>
          object[key] === value);
      }
      keyNamecolor = getKeyByValue(this.lstCurrentDet, 'Color');
      KeyNamesize = getKeyByValue(this.lstCurrentDet, 'Size');

      if (keyNamecolor == 'undefined' || keyNamecolor == undefined) {
        keyNamecolor = getKeyByValue(this.lstCurrentDet, 'color');
      }
      if (KeyNamesize == 'undefined' || KeyNamesize == undefined) {
        KeyNamesize = getKeyByValue(this.lstCurrentDet, 'size');
      }

      //debugger;

      ValueColorKey = keyNamecolor === 'OPGName1' ? this.lstCurrentDet.OPName1 : keyNamecolor === 'OPGName2' ? this.lstCurrentDet.OPName2 : keyNamecolor === 'OPGName3' ? this.lstCurrentDet.OPName3 : keyNamecolor === 'OPGName4' ? this.lstCurrentDet.OPName4 : '';
      ValuesizeKey = KeyNamesize === 'OPGName1' ? this.lstCurrentDet.OPName1 : KeyNamesize === 'OPGName2' ? this.lstCurrentDet.OPName2 : KeyNamesize === 'OPGName3' ? this.lstCurrentDet.OPName3 : KeyNamesize === 'OPGName4' ? this.lstCurrentDet.OPName4 : '';
      if (ValueColorKey != '' && typeof (ValueColorKey) != 'undefined' && ValuesizeKey !== '' && typeof (ValuesizeKey) != 'undefined' && ValuesizeKey != null && ValueColorKey != null) {
        //debugger;
        var lstofProduct = this.lstCurrentDet;
        var valid = true;
        try {
          valid = true;
          let obj = new CartList();
          obj.MerchantID = this.MerchantID;
          obj.ProductName = lstofProduct.ProductName;
          obj.ProductCode = lstofProduct.ProductCode;
          obj.ProductQty = this.getproductAvailableqty();
          obj.ProductLongDesc = lstofProduct.ProductLongDesc;
          obj.ProductImage = this.ImagePath + this.Imagesrc;
          obj.Price = lstofProduct.Price;
          obj.SalesPrice = lstofProduct.SalesPrice;
          obj.IsWishListItem = this.IsWishListItem.toString();
          obj.OPGName1 = lstofProduct.OPGName1;
          obj.OPGName2 = lstofProduct.OPGName2;
          obj.OPGName3 = lstofProduct.OPGName3;
       //   obj.AvailableQty=lstofProduct.AvailableQty;
          obj.OPGName4 = lstofProduct.OPGName4;
          obj.ProductID = lstofProduct.ProductID;
          obj.ProductOptionID = lstofProduct.ProductOptionID;
          obj.OPName1 = lstofProduct.OPName1;
          obj.OPName2 = lstofProduct.OPName2;
          obj.OPName3 = lstofProduct.OPName3;
          obj.OPName4 = lstofProduct.OPName4;
          //debugger;
          obj.ParentID = '0';

          obj.Qty = Qty;

          obj.OPName3 = lstofProduct.OPName3;
          obj.OPName4 = lstofProduct.OPName4;

          var strExtraPieces = "";
          if (this.lstSetItemList.length > 0) {
            debugger
            for (let s = 0; s < this.lstSetItemList.length; s++) {
              //debugger;


             
              let obj1 = new CartList();
              obj1.MerchantID = this.MerchantID;
              obj1.ProductName = this.lstSetItemList[s].ProductName;
              obj1.ProductCode = this.lstSetItemList[s].ProductCode;
              obj1.ProductLongDesc = this.lstSetItemList[s].ProductLongDesc;
              obj1.ProductImage = this.ImagePath + this.lstSetItemList[s].OptionItem.Image;
              obj1.Price = this.lstSetItemList[s].Price;
              obj1.SalesPrice = this.lstSetItemList[s].SalesPrice;
              obj1.OPGName1 = this.lstSetItemList[s].OPGName1;
              obj.Price = (+obj.Price) + (+this.lstSetItemList[s].Price);
              obj.SalesPrice = (+obj.SalesPrice) + (+this.lstSetItemList[s].SalesPrice);
              obj1.OPGName2 = this.lstSetItemList[s].OPGName2;
              obj.IsWishListItem = this.lstSetItemList[s].IsWishListItem.toString();
              obj1.OPGName3 = this.lstSetItemList[s].OPGName3;
              obj1.OPGName4 = this.lstSetItemList[s].OPGName4;
              obj1.ProductID = this.lstSetItemList[s].ProductID;
              obj1.ProductOptionID = this.lstSetItemList[s].ProductOptionID;
              obj1.OPName1 = this.lstSetItemList[s].OPName1;
              obj1.OPName2 = this.lstSetItemList[s].OPName2;
              obj1.OPName3 = this.lstSetItemList[s].OPName3;
              obj1.OPName4 = this.lstSetItemList[s].OPName4;
              obj1.ParentID = this.lstCurrentDet.ProductOptionID;
              obj1.Qty = Qty;
              obj1.OPName3 = this.lstSetItemList[s].OPName3;
              obj1.OPName4 = this.lstSetItemList[s].OPName4;
              obj1.CalcTotal();
              obj.lstSet.push(obj1)
              if (s == 0) {
                strExtraPieces = this.lstSetItemList[s].ProductName;

              } else {
                strExtraPieces = strExtraPieces + ',' + this.lstSetItemList[s].ProductName;
                //debugger;
              }
             // this.fbPixelService.track( 'add to cart', {value: obj.SalesPrice, currency: 'INR'});
            }

            obj.ExtraPieces = strExtraPieces;
          }
          obj.CalcTotal();
          //debugger;


          this.lstSetItemList = [];
          this.objCart.AddToCart(obj);
          this.fbPixelService.track( 'AddToCart', {product_name:obj.ProductName, value: obj.SalesPrice, currency: 'INR'});
          
          this.store.dispatch(new PageStore.OpenPage({ viewName: 'CartList', lstCartList: Object.assign([], this.objCart.lstCartList) }));
          if (check == 'cart') {
            //debugger

            //debugger;
            //  const buttonElement: HTMLElement = this.ctrCartButton.nativeElement;

            // Perform a click on the element
            // this.renderer.selectRootElement(buttonElement).click();
            //  $("#ctrCart").click();
            //  $('#ctrCart').click();

            //$(".minicart__open--btn").click();

            ($('#ctrCart') as any).click();
            // Swal.fire({
            //   title: "Product has been added to your cart",
            //   timer: 2000,
            // });
          }

          //debugger;
          this.DProductName = obj.ProductName;



          this.CQty = 1;
        } catch (e) {
          console.log(e)
        }
      } else {
        debugger
        if (ValueColorKey == '' || typeof (ValueColorKey) == 'undefined' || typeof (ValueColorKey) == undefined || ValueColorKey == null && ValuesizeKey == '' || typeof (ValuesizeKey) == 'undefined' || typeof (ValuesizeKey) == undefined || ValuesizeKey == null) {
          ValidationMsge = 'Color && Size'
        } else if (ValueColorKey == '' || typeof (ValueColorKey) == 'undefined' || typeof (ValueColorKey) == undefined || ValueColorKey == null) {
          ValidationMsge = 'Color'
        } else if (ValuesizeKey == '' || typeof (ValuesizeKey) == 'undefined' || typeof (ValuesizeKey) == undefined || ValuesizeKey == null) {
          ValidationMsge = 'Size'
        }


        //debugger;

        Swal.fire({
          title: `Please select ${ValidationMsge} to your cart`,
          timer: 2000,
        });
      }


    }
  }

  BuyNowProduct() {


    var keyNamecolor, KeyNamesize, ValueColorKey, ValuesizeKey, ValidationMsge;

    function getKeyByValue(object, value) {
      return Object.keys(object).find(key =>
        object[key] === value);
    }
    keyNamecolor = getKeyByValue(this.lstCurrentDet, 'Color');
    KeyNamesize = getKeyByValue(this.lstCurrentDet, 'Size');

    if (keyNamecolor == 'undefined' || keyNamecolor == undefined) {
      keyNamecolor = getKeyByValue(this.lstCurrentDet, 'color');
    }
    if (KeyNamesize == 'undefined' || KeyNamesize == undefined) {
      KeyNamesize = getKeyByValue(this.lstCurrentDet, 'size');
    }

    ValueColorKey = keyNamecolor === 'OPGName1' ? this.lstCurrentDet.OPName1 : keyNamecolor === 'OPGName2' ? this.lstCurrentDet.OPName2 : keyNamecolor === 'OPGName3' ? this.lstCurrentDet.OPName3 : keyNamecolor === 'OPGName4' ? this.lstCurrentDet.OPName4 : '';
    ValuesizeKey = KeyNamesize === 'OPGName1' ? this.lstCurrentDet.OPName1 : KeyNamesize === 'OPGName2' ? this.lstCurrentDet.OPName2 : KeyNamesize === 'OPGName3' ? this.lstCurrentDet.OPName3 : KeyNamesize === 'OPGName4' ? this.lstCurrentDet.OPName4 : '';

    if (ValueColorKey != '' && typeof (ValueColorKey) != 'undefined' && ValuesizeKey !== '' && typeof (ValuesizeKey) != 'undefined' && ValuesizeKey != null && ValueColorKey != null) {
      var lstofProduct = this.lstCurrentDet;
      var lst: any = [];

      let obj = new CartList();
      obj.MerchantID = this.MerchantID;
      obj.ProductName = lstofProduct.ProductName;
      obj.ProductCode = lstofProduct.ProductCode;
      obj.ProductQty = this.getproductAvailableqty();
      obj.ProductLongDesc = lstofProduct.ProductLongDesc;
      obj.ProductImage = this.ImagePath + this.Imagesrc;
      obj.Price = lstofProduct.Price;

      obj.SalesPrice = lstofProduct.SalesPrice;
      obj.OPGName1 = lstofProduct.OPGName1;
      obj.IsWishListItem = this.IsWishListItem.toString();
      obj.OPGName2 = lstofProduct.OPGName2;

      obj.OPGName3 = lstofProduct.OPGName3;
      obj.OPGName4 = lstofProduct.OPGName4;
      obj.ProductID = lstofProduct.ProductID;
      obj.ProductOptionID = lstofProduct.ProductOptionID;
      obj.OPName1 = lstofProduct.OPName1;
      obj.OPName2 = lstofProduct.OPName2;
      obj.OPName3 = lstofProduct.OPName3;
      obj.OPName4 = lstofProduct.OPName4;

      obj.ParentID = '0';

      obj.Qty = 1;

      obj.OPName3 = lstofProduct.OPName3;
      obj.OPName4 = lstofProduct.OPName4;
      obj.CalcTotal();
      lst.push(Object.assign({}, obj))
      this.store.dispatch(new PageStore.OpenPage({ viewName: 'Buylst', lstCartList: Object.assign([], lst) }));



      if (this.loginUserDetails.UserID == 0) {


        this.App.loginClick('checkout')
      } else {


        this.router.navigate(['/checkout'], { queryParams: { navigateFrom: 'BuyNow' } });


      }
    } else {
      // //debugger
      if (ValueColorKey == '' || typeof (ValueColorKey) == 'undefined' || typeof (ValueColorKey) == undefined || ValueColorKey == null && ValuesizeKey == '' || typeof (ValuesizeKey) == 'undefined' || typeof (ValuesizeKey) == undefined || ValuesizeKey == null) {
        ValidationMsge = 'Color && Size'
      } else if (ValueColorKey == '' || typeof (ValueColorKey) == 'undefined' || typeof (ValueColorKey) == undefined || ValueColorKey == null) {
        ValidationMsge = 'Color'
      } else if (ValuesizeKey == '' || typeof (ValuesizeKey) == 'undefined' || typeof (ValuesizeKey) == undefined || ValuesizeKey == null) {
        ValidationMsge = 'Size'
      }


      Swal.fire({
        title: `Please select ${ValidationMsge} to your cart`,
        timer: 2000,
      });
    }


  }

  getproductAvailableqty() {

    return this.lstCurrentDet.Availableqty;
  }

  GetPurchaseQty(PurchaseProductList) {


    return typeof (this.objCart.getPurchaseqtyfromcart(PurchaseProductList)) == 'undefined' ? '' : this.objCart.getPurchaseqtyfromcart(PurchaseProductList);
  }
  CQty = 1;
  UserID = '0';
  lstSetItemList: any = [];
  Addedcart() {


  }


  AddWishlist() {


    this.UserID = this.loginUserDetails.UserID == 0 ? this.UserID : this.loginUserDetails.UserID;


    var ExItems = "";
    var strExtraPieces = "";
    var productName='';
    if (this.lstSetItemList.length > 0) {
      for (let s = 0; s < this.lstSetItemList.length; s++) {
        productName=this.lstSetItemList[s].ProductName;
        ExItems = ExItems + "<Table1>"

          + '<UserID>' + this.UserID + '</UserID>'
          + '<ProductOptionID>' + this.lstSetItemList[s].ProductOptionID + '</ProductOptionID>'
          + '<ParentID>' + this.lstCurrentDet.ProductOptionID + '</ParentID>'
          + '<ExtraPieces></ExtraPieces>'
          + "</Table1>"
        if (s == 0) {
          strExtraPieces = this.lstSetItemList[s].ProductName;

        } else {
          strExtraPieces = strExtraPieces + ',' + this.lstSetItemList[s].ProductName;

        }

      }
    }

    var rows = "<Table1>"

      + '<UserID>' + this.UserID + '</UserID>'
      + '<ProductOptionID>' + this.lstCurrentDet.ProductOptionID + '</ProductOptionID>'
      + '<ParentID>' + 0 + '</ParentID>'
      + '<ExtraPieces>' + strExtraPieces + '</ExtraPieces>'
      + "</Table1>"
      ;
    rows = '<NewDataSet>' + rows + ExItems + '</NewDataSet>';

    this.apiCall.DBCalling("SaveWishList", rows, "", "", "").subscribe(
      (res) => {
        this.fbPixelService.track( 'AddToWishlist', { product_name: productName});
   
        let DbResult: any = (res);

        if (DbResult.tasks.length > 0) {

          for (let i = 0; i < DbResult.tasks.length; i++) {

            if (DbResult.tasks[i].length > 0) {

              if (DbResult.tasks[i][0].DBresult > 0) {

                $("#ctrlWishList").css("background-position", "-2800px 0");
                $("#ctrlWishList").css("transition", "background 1s steps(28)");

              }
            }
          }
          Swal.fire({
            title: "Product has been added to your Wishlist",
            timer: 2000,
          });
          this.WishList.GetDetailsfromWishList(this.UserID)
        }

      });

  }
  async productMouseOverCall(productId) {


    await this.apiCall.DBCalling("GetProductOptionsShortDetByProductId", productId, "", "", "").subscribe(
      async (res) => {

        let opResult: any = (res);
        var resD = (((opResult.tasks[0][0].Result).replace(/\n/g, "")).replace(/'/g, "\""));
        var resChild = JSON.parse(resD)
        if (resChild.length > 0) {
          await this.prepareOptionsString(resChild);

        }

        if (this.browserService.isBrowser()) {
          const document: any = this.browserService.getDocument();
          document.getElementById("prodshortdesc").style.color = '#d00'
        }
      }
    );
  }

  ProductClick(ProductOptionID, ProductID, ProductImage, data, option2, option3) {
  debugger
    var keyNamecolor = "";
    function getKeyByValue(object, value) {
      return Object.keys(object).find(key =>
        object[key] === value);
    }
    if (data.ProductName == this.lstCurrentDet.ProductName && data.ProductID == this.lstCurrentDet.ProductID) {
      keyNamecolor = getKeyByValue(data, 'Color');
      if (keyNamecolor == 'OPGName1') {
        this.SelectedOption1 = data.OPName1;
        this.OptionsChangeAssign('', '', 0)
      } else if (keyNamecolor == 'OPGName2') {
        this.SelectedOption2 = data.OPName2
        this.OptionsChangeAssign('', '', 0)
      } else if (keyNamecolor == 'OPGName3') {
        this.SelectedOption3 = data.OPName3
        this.OptionsChangeAssign('', '', 0)
      } else if (keyNamecolor == 'OPGName4') {
        this.SelectedOption4 = data.OPName4
        this.OptionsChangeAssign('', '', 0)
      } else {

      }
    }
    if (this.browserService.isBrowser()) {
      const window: any = this.browserService.getWindow();
      window.scrollTo(0, 0)
    }

    const urlFriendlyProduct = _.kebabCase(data.ProductName);
    this.router.navigate(['/product', urlFriendlyProduct], { queryParams: {  ID: ProductID } });
  }
  selectedProductOptions = []
  prepareOptionsString(selectedProductOptions) {
    this.selectedProductOptions = [];
    //
    let Options = [];


    for (let d = 0; d < selectedProductOptions.length; d++) {

      let extind = Options.findIndex(x => x == selectedProductOptions[d].OptionGroupName);
      if (extind == -1) {
        Options.push(selectedProductOptions[d].OptionGroupName);

      }
    }

    for (let i = 0; i < Options.length; i++) {
      //
      const array = selectedProductOptions.map(item => (item.OptionGroupName == Options[i] ? item.OptionName : null));
      const filteredArray = array.filter(value => value !== null && value !== undefined);
      const result = filteredArray.join(',');
      if (result != '') {
        this.selectedProductOptions.push({ 'Name': Options[i], 'Options': result });
      }
    }




  }

  ArryLimit = 4;
  RelatedProductslength = 0;
  getRelatedProducts1() {
    var keyNamecolor = "";
    let relatedProdlst = [];
    function getKeyByValue(object, value) {
      return Object.keys(object).find(key =>
        object[key] === value);
    }

    keyNamecolor = getKeyByValue(this.lstCurrentDet, 'Color');
    if (keyNamecolor == 'undefined' || keyNamecolor == undefined) {
      keyNamecolor = getKeyByValue(this.lstProducts[0], 'color');
    }
    if (keyNamecolor == 'OPGName1') {
      var filterprod = this.lstRelatedProducts.filter(x => x.OPName1 != this.SelectedOption1);
      relatedProdlst = this.getFilterByProducts(filterprod, 'OPName1', this.SelectedOption1)
    } else if (keyNamecolor == 'OPGName2') {


    } else if (keyNamecolor == 'OPGName3') {


    } else if (keyNamecolor == 'OPGName4') {


    } else {

    }
    //  let mymap = new Map();

    //     let unique = this.lstRelatedProducts.filter(el => {
    //         const val = mymap.get(el[keyNamecolor]);
    //         if (val) {
    //             if (el.id < val) {
    //                 mymap.delete(el.name);
    //                 mymap.set(el.name, el.id);
    //                 return true;
    //             } else {
    //                 return false;
    //             }
    //         }
    //         mymap.set(el.name, el.id);
    //         return true;
    //     });

    return relatedProdlst;
  }
  getFilterByProducts(Products, OptionName, selectedoption) {

    var existproducts = [];

    for (let d of Products) {
      var fillen = existproducts.filter(x => x[OptionName] == selectedoption);
      if (fillen.length == 0) {
        existproducts.unshift(Object.assign({}, d))
      }
    }

    return existproducts
  }
  getRelatedProducts() {

    try {


      var res = this.lstRelatedProducts.filter(x => x.ProductID != this.DProductID);
      var uniqueProducts = this.lstRelatedProducts.reduce((result, product) => {
        // Use a Set to keep track of unique product IDs
        var resty = result.map(p => p.ProductID && p.OPName1);
        const uniquePairs = [];
        result.forEach(p => {
          const pair = { ProductID: p.ProductID, OPName1: p.OPName1 };
          const existingPair = uniquePairs.find(item => item.ProductID === pair.ProductID && item.OPName1 === pair.OPName1);

          if (!existingPair) {
            uniquePairs.push(pair);
          }
        });


        // var idsSet = new Set(result.map(p => p.ProductID && p.OPName1));

        // If the product's ID is not in the Set, add it to the result array

        var uindex = uniquePairs.findIndex(x => x.ProductID == product.ProductID && x.OPName1 == product.OPName1);

        if (uindex == -1) {
          result.push(product);
        }

        //  if (!idsSet.has(product.ProductID )) {

        //  }

        // Return the intermediate result for the next iteration
        return result;
      }, []);
      var keyNamecolor = "";
      function getKeyByValue(object, value) {
        return Object.keys(object).find(key =>
          object[key] === value);
      }

      keyNamecolor = getKeyByValue(this.lstCurrentDet, 'Color');
      if (keyNamecolor == 'undefined' || keyNamecolor == undefined) {
        keyNamecolor = getKeyByValue(this.lstProducts[0], 'color');
      }
      if (keyNamecolor == 'OPGName1') {
        var index = uniqueProducts.findIndex((x) => x.OPName1 == this.SelectedOption1);
        uniqueProducts.splice(index, 1)
        var filterprod = this.lstProducts.filter(x => x.OPName1 != this.SelectedOption1);
        for (let d of filterprod) {
          var existrecord = uniqueProducts.filter(x => x.OPName1 != this.SelectedOption1);
          if (existrecord.length == 0) {
            uniqueProducts.unshift(Object.assign({}, d))
          } else {

          }
        }


      } else if (keyNamecolor == 'OPGName2') {
        var index = uniqueProducts.findIndex((x) => x.OPName2 == this.SelectedOption2);
        uniqueProducts.splice(index, 1)

      } else if (keyNamecolor == 'OPGName3') {
        var index = uniqueProducts.findIndex((x) => x.OPName3 == this.SelectedOption3);
        uniqueProducts.splice(index, 1)

      } else if (keyNamecolor == 'OPGName4') {
        var index = uniqueProducts.findIndex((x) => x.OPName4 == this.SelectedOption4);
        uniqueProducts.splice(index, 1)

      } else {

      }






      //  var res= this.lstRelatedProducts;
      //   const uniqueProducts = this.lstRelatedProducts.reduce((result, product) => {
      //     // Use a Set to keep track of unique product IDs
      //    const idsSet = new Set(result.map(p => p.OPName2!=this.SelectedOption2));

      //     // If the product's ID is not in the Set, add it to the result array
      //  //  if (!idsSet.has(product.OPName2)) {
      //      result.push(product);
      //  //  }

      //     // Return the intermediate result for the next iteration
      //    return result;
      //  }, []);
    //  this.RelatedProductslength = res.length;
 this.uniqueProducts = uniqueProducts;
   //   let productDetails = this.lstRelatedProducts.filter(x => x.ProductID == this.DProductID);
      //debugger;
   //   this.trainAndRecommend(productDetails[0]);

      return uniqueProducts;
    } catch (e) {

    }




  }
  ServerPath = "";
  SharePath = "";
  mobile: boolean = false;
  @HostListener('window:resize', [])
  onOrientationChange(event) {

    var width = window.innerWidth;
    if (width <= 768) {

      this.mobile = true;
      // window.scrollTo(0,0)
    } else {
      this.mobile = false;
    }
  }
  share(type) {

    if (this.browserService.isBrowser()) {
      const window: any = this.browserService.getWindow();

      const document: any = this.browserService.getDocument();
      switch (type) {
        case 'Twitter':
          {
            window.open("https://twitter.com/share?url=" + encodeURIComponent(this.SharePath), "_blank", "");

            break;
          }
        case 'Whatsapp':
          {
            //  sending url to be like this to whatsapp 

            // https://www.blackdenim.in/#/ProductDetails?OptionID=1065&ID=145&Img=Images%2FProductID_Image145WedAug022023150321GMT0530IndiaStandardTime.JPG&option2=M&option3=

            window.open("https://web.whatsapp.com/send?text=" + encodeURIComponent(window.location.href), " _blank", "");

            break;
          }

        case 'Facebook':
          {
            window.open("https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(this.SharePath), "_blank", "");


            break;
          }

        case 'mbWhatsapp':
          {

            // window.open("whatsapp://send?text="+encodeURIComponent("https://www.blackdenim.in/#/ProductDetails?OptionID=1065&ID=145&Img=Images%2FProductID_Image145WedAug022023150321GMT0530IndiaStandardTime.JPG&option2=M&option3=") ,"_blank" ,"");
            window.open("whatsapp://send?text=" + encodeURIComponent(window.location.href), "_blank", "");


            break;
          }

        case 'Instagram':
          {
            window.open("https://www.instagram.com/Blackdenim", "_blank", "");


            break;
          }
        case 'Copy link':
          {
            let url = document.location.href
            navigator.clipboard.writeText(url).then(function () {

            }, function () {

            });


            break;
          }
      }
    }
  }
  clickonView() {

  }
  ngDoCheck() {
    try {
      const galleryThumbnails = this.el.nativeElement.querySelector('ngx-gallery-thumbnails');
      this.renderer.setStyle(galleryThumbnails, 'height', '12%');
      this.renderer.setStyle(galleryThumbnails, 'background-size', 'cover');


      const galleryThumbnails1 = this.el.nativeElement.querySelector('ngx-gallery-image');
      this.renderer.setStyle(galleryThumbnails1, 'margin-bottom', '10px');


      const imgScroll = this.el.nativeElement.querySelector('.ngx-gallery-image-wrapper');
      this.renderer.setStyle(imgScroll, 'overflow-x', 'scroll');

      const imgScroll1 = this.el.nativeElement.querySelector('.ngx-gallery-thumbnails-wrapper');
      this.renderer.setStyle(imgScroll1, 'overflow-x', 'scroll');
      // //debugger;
      // const galleryThumbnail =$('.ngx-gallery-thumbnail');

      // for(let i=0;i<galleryThumbnail.length;i++)
      // {
      //   galleryThumbnail[i].setStyle(galleryThumbnail, 'border', 'none');


      // }

      $('.ngx-gallery-thumbnail').each(function () {
        // Use $(this) to refer to the current element in the loop
        $(this).css('border', 'none');
      });

    } catch (e) {

    }
  }

  ngAfterViewInit() {
    debugger

    // /$("#ctpcontainer").scrollTop(100);


    // // window.scrollTo(0,0);
    //  const galleryThumbnails = this.el.nativeElement.querySelector('ngx-gallery-thumbnails');
    //   this.renderer.setStyle(galleryThumbnails, 'height', 'calc(35% + 20px)');

    //   var that=this;

    //  $(document).ready(function() {
    //   //debugger
    //   // Find ngx-gallery-thumbnails by tag name
    //   // var $galleryThumbnails = $('ngx-gallery-thumbnails');

    //   // $galleryThumbnails[0].style.height="calc(35% + 20px) !important"
    //   const galleryThumbnails = that.el.nativeElement.querySelector('ngx-gallery-thumbnails');
    //   that.renderer.setStyle(galleryThumbnails, 'height', 'calc(35% + 20px)');

    // });

    this.applyStyles();
    this.sizepopdisplay = 'none';
  }
//   updateMetaTags(productTags)

// {
//   //debugger;
// // this.meta.updateTag({ property: 'og:description', content: 'sdaasdsad' }, "property='og:description'");
//   try{
// //alert( productTags.Fld2.toString());


//     this.meta.updateTag({ name: 'description', content: productTags.Fld2.toString() });
//   this.meta.updateTag({ name: 'keywords', content: productTags.toString()});
//   //  if(productTags[0].Fld2!='')
//   //  {
//       //debugger;
//     // this.meta.addTags([
//     //   { name: 'description', content:this.lstProducts[0].Fld2 },
//     //   { name: 'keywords', content: this.lstProducts[0].Fld3}
//     // ]);

//         // this.meta.updateTag( { name: 'description', content:productTags[0].Fld2 });
//         //  this.meta.updateTag({ property: 'og:image', content:productTags[0].Fld2 }, "property='og:image'");
//         //  this.meta.updateTag({ property: 'og:title', content: productTags[0].Fld2 }, "property='og:title'");
//         //  this.meta.updateTag({ property: 'og:description', content: productTags[0].Fld2}, "property='og:description'");
//   //  }
//   //  if(productTags[0].Fld1!='')
//    // {
//       this.titleService.setTitle(productTags.Fld1);
  
//     //}
  
  
//   }catch(e)
//   {
  
//   }
   
// }
  GetstocksizefromProduct() {
    // //debugger
    var filter = this.lstProducts.filter((x) => x.Available == 'In stock');
    return filter[0].OPName2;
  }

// // }catch(e)
getProduct(d:any){
  //debugger;
  // // {
      // //debugger;
      // let data = this.lstProducts.filter((x:any)=>x.OPName1 == d.OptionName);
      // if(data.length > 0){
      //   debugger
      //   this.DProductID = data[0].ProductID;
      // }
      
      //this.getProductOptionDetailsByProductID();
    }
  

  dataLoaded=false;

//   getProductOptionDetailsByProductID(res) {
//     //debugger;
//     //  this.apiCall.DBCalling("OnlineOrderProductOptionDetailsByProductID",ID,UserID,"","")
//     // //debugger
    
//     this.dataLoaded=false;
 
//         let dbResult: any = (res);
//         this.lstProducts = [];

//         if (dbResult.tasks.length > 0 && dbResult.tasks[0].length > 0) {

//           this.lstProducts = dbResult.tasks[0];

//           this.lstRelatedProducts = dbResult.tasks[1];
//           this.MerchantID = this.lstProducts[0]["MerchantID"];
          
         
// // try{

// //   if(this.lstProducts[0].Fld2!='')
// //   {
// //     //debugger;
// //   this.meta.addTags([
// //     { name: 'description', content:this.lstProducts[0].Fld2 },
// //     { name: 'keywords', content: this.lstProducts[0].Fld3}
// //   ]);
 
// //   //this.meta.updateTag({ property: 'og:description', content:this.lstProducts[0].Fld2 }, "property='og:description'");
// //   }
// //   if(this.lstProducts[0].Fld1!='')
// //   {
// //     this.titleService.setTitle(this.lstProducts[0].Fld1);

// //   }


// // }catch(e)
// // {

// // }
 
//           for (var i = 0; i < this.lstProducts.length; i++) {
//             try {
//               var resD = (((this.lstProducts[i]["lst1Options"]).replace(/\n/g, "")).replace(/'/g, "\""));
//               var resChild = JSON.parse(resD);



//               this.lstProducts[i]["lst1Options"] = resChild

//               this.lstset = resChild[0].SetDetails == '' ? [] : resChild[0].SetDetails;

//               this.SelectedOption1 = resChild[0].OptionName


//             } catch (e) {

//             }
//             try {



//               var resD = (((this.lstProducts[i]["lst2Options"]).replace(/\n/g, "")).replace(/'/g, "\""));
//               var resChild = JSON.parse(resD);



//               this.lstProducts[i]["lst2Options"] = resChild



//               this.SelectedOption2 = this.SelectedOption2 == '' ? resChild[0].OptionName : this.GetstocksizefromProduct();

//               this.OptionsChange('', this.SelectedOption2, 2)

//             } catch (e) {

//             }


//             try {
//               var resD = (((this.lstProducts[i]["lst3Options"]).replace(/\n/g, "")).replace(/'/g, "\""));
//               var resChild = JSON.parse(resD);



//               this.lstProducts[i]["lst3Options"] = resChild
//               this.SelectedOption3 = this.SelectedOption3 == '' ? resChild[0].OptionName : this.SelectedOption3;


//             } catch (e) {

//             }

//             try {

//               var resD = (((this.lstProducts[i]["lst4Options"]).replace(/\n/g, "")).replace(/'/g, "\""));
//               var resChild = JSON.parse(resD);

//               this.lstProducts[i]["lst4Options"] = resChild
//               this.SelectedOption4 = resChild[0].OptionName;

//             } catch (e) {

//             }



//             try {

//               var resDF = (((this.lstProducts['Features']).replace(/\n/g, "")).replace(/'/g, "\""));
//               var resChildF = JSON.parse(resDF);
//               this.lstProducts['Features'] = resChildF;
//             } catch (e) {

//             }






//             if (this.lstProducts[i].ProductOptionID == this.DOptionID) {
//               this.IsWishListItem = this.lstProducts[i].IsWishListItem;
//               this.lstCurrentDet = this.lstProducts[i];

//               this.Imagesrc = this.lstCurrentDet.Image1;
//               this.lstOp2 = this.CheckOptionB(this.lstCurrentDet['lst2Options'])
//               this.lstOp3 = this.CheckOptionC(this.lstCurrentDet['lst3Options'])
//               this.lstOp4 = this.CheckOptionD(this.lstCurrentDet['lst4Options'])
//             }

//             if (this.galleryImages.length == 0) {


//               if (this.lstCurrentDet.Image1 != '' && this.lstCurrentDet.Image1 != '0') {
//                 this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image1, medium: this.ImagePath + this.lstCurrentDet.Image1, big: this.ImagePath + this.lstCurrentDet.Image1 })
//               }
//               if (this.lstCurrentDet.Image2 != '' && this.lstCurrentDet.Image2 != '0') {
//                 this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image2, medium: this.ImagePath + this.lstCurrentDet.Image2, big: this.ImagePath + this.lstCurrentDet.Image2 })
//               }

//               if (this.lstCurrentDet.Image3 != '' && this.lstCurrentDet.Image3 != '0') {
//                 this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image3, medium: this.ImagePath + this.lstCurrentDet.Image3, big: this.ImagePath + this.lstCurrentDet.Image3 })
//               }
//               if (this.lstCurrentDet.Image4 != '' && this.lstCurrentDet.Image4 != '0') {
//                 this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image4, medium: this.ImagePath + this.lstCurrentDet.Image4, big: this.ImagePath + this.lstCurrentDet.Image4 })
//               }
//               if (this.lstCurrentDet.Image5 != '' && this.lstCurrentDet.Image5 != '0') {
//                 this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image5, medium: this.ImagePath + this.lstCurrentDet.Image5, big: this.ImagePath + this.lstCurrentDet.Image5 })
//               }
//               if (this.lstCurrentDet.Image6 != '' && this.lstCurrentDet.Image6 != '0') {
//                 this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image6, medium: this.ImagePath + this.lstCurrentDet.Image6, big: this.ImagePath + this.lstCurrentDet.Image6 })
//               }
//             }
//           }
          
//           // //debugger
//           if (typeof (this.lstRelatedProducts) != undefined && typeof (this.lstRelatedProducts) != 'undefined' && this.lstRelatedProducts.length > 0) {
//             for (let i = 0; i < this.lstRelatedProducts.length; i++) {
//               if (this.lstRelatedProducts[i].ProductRating === "5.0000") {
//                 this.lstRelatedProducts[i].ProductRating = "5";
//               }
//             }
//           }
//           // //debugger
//           let NotslectedProdcutslst;
//           if (this.SelectedOption2 != '' && this.SelectedOption1 != '') {
//             NotslectedProdcutslst = this.lstProducts.filter((x) => {
//               return x.OPName1 != this.SelectedOption1 && x.OPName2 != this.SelectedOption2
//             })
//           } else {
//             NotslectedProdcutslst = this.lstProducts.filter((x) => {
//               return x.OPName1 != this.SelectedOption1
//             })
//           }

//           // //debugger
//           if (NotslectedProdcutslst.length > 0) {
//             this.lstRelatedProducts.unshift(Object.assign({}, NotslectedProdcutslst[0]))
//           }
//           // //debugger

//         }
//         if (this.browserService.isBrowser()) {
//           const document: any = this.browserService.getDocument();
//           this.ServerPath = document.location.origin;

//           const urlFriendlyProduct = _.kebabCase(this.productName);
//           if (document.location.hostname == 'localhost') {
//             this.SharePath = this.ServerPath + "/Product/" + urlFriendlyProduct + "?ID=" + this.DProductID + "&OptionID=" + this.DOptionID + "&Img" + this.RawImagePath;
//           } else {
//             this.SharePath = this.ServerPath + "Blackdenim/web/Product/" + urlFriendlyProduct + "?ID=" + this.DProductID + "&OptionID=" + this.DOptionID + "&Img" + this.RawImagePath;
//           }
//         }



//         // // window.scrollTo(0,0);

//         //debugger;
//       //  this.appRef.tick();
//      //   this.dataLoaded=true;

      
   
// }
 shuffleArray<T>(array: T[]): T[] {
  return array
    .map((item) => ({ item, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ item }) => item);
}
 getRandomProductsByCategoryID(products , totalProducts: number) {
  // Group products by categoryID
  debugger;
  const productsByCategory: { [CategoryID: number]: any[] } = products.reduce((acc, product) => {
    if (!acc[product.CategoryID]) {
      acc[product.CategoryID] = [];
    }
    acc[product.CategoryID].push(product);
    return acc;
  }, {} as { [CategoryID: number]: any[] });

  // Array to hold the selected random products
  const selectedProducts: any[] = [];

  // Loop through each category and select random products
  Object.keys(productsByCategory).forEach((CategoryID) => {
    const shuffledProducts = this.shuffleArray(productsByCategory[Number(CategoryID)]);
    selectedProducts.push(...shuffledProducts.slice(0, 1)); // Get one product from each categoryID
  });

  // If less than totalProducts, fill the rest from shuffled products
  while (selectedProducts.length < totalProducts) {
    const remainingProducts = this.shuffleArray(products);
    const extraProducts = remainingProducts.filter(p => !selectedProducts.includes(p));
    selectedProducts.push(...extraProducts.slice(0, totalProducts - selectedProducts.length));
  }

  return selectedProducts.slice(0, totalProducts);
}
prepareStylingGuide()
{
  debugger;
  let lstcatProducts=[];
for(let i=0;i<this.lstStylingGuidCategories.length;i++)
{
//let res=this.lstRelatedProducts.map(x=>x.CategoryID==this.lstStylingGuidCategories[i].CategoryID)
let productDetails = this.lstRelatedProducts.filter(x => x.CategoryID == this.lstStylingGuidCategories[i].RelatedCategoryID);
if(productDetails.length>0)
{

for(let j=0;j<productDetails.length;j++)
{
let ind=  lstcatProducts.findIndex(x=>x.ProductID==productDetails[j].ProductID);
if(ind==-1)
{
  lstcatProducts.push(productDetails[j]);

}

}
}
}

  this.recommendedProducts = this.getRandomProductsByCategoryID(lstcatProducts, 6);
  this.cdr.detectChanges();
}


lstStylingGuidCategories=[];
async getProductsByCategory(productCategory)
{
 await this.apiCall.DBCalling("ViewProductDetByType1","Category",productCategory,"","").subscribe(
    (res) => {
      debugger;
      let dbResult: any = (res);
      if (dbResult.tasks.length > 0 && dbResult.tasks[0].length > 0) {
        this.lstRelatedProducts = dbResult.tasks[0];
        
        if (typeof (this.lstRelatedProducts) != undefined && typeof (this.lstRelatedProducts) != 'undefined' && this.lstRelatedProducts.length > 0) {
          for (let i = 0; i < this.lstRelatedProducts.length; i++) {
            if (this.lstRelatedProducts[i].ProductRating === "5.0000") {
              this.lstRelatedProducts[i].ProductRating = "5";
            }
          }
        }
        let NotslectedProdcutslst;
        if (this.SelectedOption2 != '' && this.SelectedOption1 != '') {
          NotslectedProdcutslst = this.lstProducts.filter((x) => {
            return x.OPName1 != this.SelectedOption1 && x.OPName2 != this.SelectedOption2
          })
        } else {
          NotslectedProdcutslst = this.lstProducts.filter((x) => {
            return x.OPName1 != this.SelectedOption1
          })
        }

        // //debugger
        if (NotslectedProdcutslst.length > 0) {
          this.lstRelatedProducts.unshift(Object.assign({}, NotslectedProdcutslst[0]))
        }
        this.cdr.detectChanges();
        try{
          this.lstStylingGuidCategories= dbResult.tasks[1];
          this.zone.run(() => {
          this.prepareStylingGuide();
          });
        }catch(e)
        {

        }
      }
    });

}
  getProductOptionDetailsByProductID(data) {
    //  this.apiCall.DBCalling("OnlineOrderProductOptionDetailsByProductID",ID,UserID,"","")
    // //debugger
    if (this.browserService.isBrowser()) {
    $("#preloader").show();
    }
    this.dataLoaded=false;


  
   // this.apiCall.DBCalling("OnlineOrderProductOptionDetailsByProductID", this.DProductID, 0, "", "").subscribe(
    //  (res) => {
        debugger
        let dbResult: any = (data);
        this.lstProducts = [];

        if (dbResult.tasks.length > 0 && dbResult.tasks[0].length > 0) {

          this.lstProducts = dbResult.tasks[0];
          
          try{
//this.productCategory= this.lstProducts[0]["CategoryID"];
this.zone.run(() => {
this.getProductsByCategory(this.lstProducts[0]["CategoryID"]);
});
          }catch(e)
          {

          }

         // this.lstRelatedProducts = dbResult.tasks[1];
          this.MerchantID = this.lstProducts[0]["MerchantID"];
          // try {
          //   if (this.lstProducts[0].Fld2 != '') {
          //     const metaDescriptionTag = document.querySelector('meta[name="description"]');
          //     if (metaDescriptionTag) {
          //       metaDescriptionTag.setAttribute('content', this.lstProducts[0].Fld2);
          //     }
          //   }
          //   if (this.lstProducts[0].Fld3 != '') {
          //     const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
          //     if (metaKeywordsTag) {
          //       metaKeywordsTag.setAttribute('content', this.lstProducts[0].Fld3);
          //     }
          //   }
          //   if (this.lstProducts[0].Fld1 != '') {
          //     document.title = this.lstProducts[0].Fld1;
          //   }
          // } catch (e) {
          //   console.error("Error updating meta tags:", e);
          // }

         
// try{

//   if(this.lstProducts[0].Fld2!='')
//   {
//     //debugger;
//   // this.meta.addTags([
//   //   { name: 'description', content:this.lstProducts[0].Fld2 },
//   //   { name: 'keywords', content: this.lstProducts[0].Fld3}
//   // ]);
//   this.meta.updateTag(
//        { name: 'description', content:this.lstProducts[0].Fld2 });

  
//   }
//   if(this.lstProducts[0].Fld1!='')
//   {
//     this.titleService.setTitle(this.lstProducts[0].Fld1);

//   }


// }catch(e)
// {

// }
 

let data = this.lstProducts.map((x:any)=>x.lst1Options).filter((value,index,current_value)=>current_value.indexOf(value)===index);
//debugger;
let options = [];

for(let j=0;j<data.length;j++){
  //debugger;
  var resD = (((data[j]).replace(/\n/g, "")).replace(/'/g, "\""));
  var resChild = JSON.parse(resD);
  //debugger;
  for(let k=0;k<resChild.length;k++){
    //debugger;
    options.push(resChild[k])
  }
}
//debugger;
for (var k = 0; k < this.lstProducts.length; k++) {
  //debugger;
  try {
    //debugger;
    var resD = (((this.lstProducts[k]["lst1Options"]).replace(/\n/g, "")).replace(/'/g, "\""));
    var resChild = JSON.parse(resD);
    this.lstProducts[k]["lst1Options"] = options;
    //debugger;
    this.lstset = resChild[0].SetDetails == '' ? [] : resChild[0].SetDetails;
    this.SelectedOption1 = options[0].OptionName
  } catch (e) {
  }
}


          for (var i = 0; i < this.lstProducts.length; i++) {
            // try {
            //   var resD = (((this.lstProducts[i]["lst1Options"]).replace(/\n/g, "")).replace(/'/g, "\""));
            //   var resChild = JSON.parse(resD);



            //   this.lstProducts[i]["lst1Options"] = resChild

            //   this.lstset = resChild[0].SetDetails == '' ? [] : resChild[0].SetDetails;

            //   this.SelectedOption1 = resChild[0].OptionName


            // } catch (e) {

            // }
            try {



              var resD = (((this.lstProducts[i]["lst2Options"]).replace(/\n/g, "")).replace(/'/g, "\""));
              var resChild = JSON.parse(resD);



              this.lstProducts[i]["lst2Options"] = resChild



              this.SelectedOption2 = this.SelectedOption2 == '' ? resChild[0].OptionName : this.GetstocksizefromProduct();

              

            } catch (e) {

            }


            try {
              var resD = (((this.lstProducts[i]["lst3Options"]).replace(/\n/g, "")).replace(/'/g, "\""));
              var resChild = JSON.parse(resD);



              this.lstProducts[i]["lst3Options"] = resChild
              this.SelectedOption3 = this.SelectedOption3 == '' ? resChild[0].OptionName : this.SelectedOption3;


            } catch (e) {

            }

            try {

              var resD = (((this.lstProducts[i]["lst4Options"]).replace(/\n/g, "")).replace(/'/g, "\""));
              var resChild = JSON.parse(resD);

              this.lstProducts[i]["lst4Options"] = resChild
              this.SelectedOption4 = resChild[0].OptionName;

            } catch (e) {

            }



            try {

              var resDF = (((this.lstProducts['Features']).replace(/\n/g, "")).replace(/'/g, "\""));
              var resChildF = JSON.parse(resDF);
              this.lstProducts['Features'] = resChildF;
            } catch (e) {

            }


try{
//debugger;

 let selectedOption= JSON.parse( localStorage.getItem("SelectedOption"));
 //this.OptionsChangeAssign('', this.SelectedOption2, 2)

 if(selectedOption.ProductID==this.DProductID)
 {
  this.OptionsChangeAssign('', selectedOption.target, selectedOption.index);

 }else{
  this.OptionsChangeAssign('', this.SelectedOption2, 2)

 }
}catch(e)
{
  this.OptionsChangeAssign('', this.SelectedOption2, 2)
}
         


            if (this.lstProducts[i].ProductOptionID == this.DOptionID) {
              this.IsWishListItem = this.lstProducts[i].IsWishListItem;
              this.lstCurrentDet = this.lstProducts[i];

              this.Imagesrc = this.lstCurrentDet.Image1;
              this.lstOp2 = this.CheckOptionB(this.lstCurrentDet['lst2Options'])
              this.lstOp3 = this.CheckOptionC(this.lstCurrentDet['lst3Options'])
              this.lstOp4 = this.CheckOptionD(this.lstCurrentDet['lst4Options'])
            }

            if (this.galleryImages.length == 0) {


              if (this.lstCurrentDet.Image1 != '' && this.lstCurrentDet.Image1 != '0') {
                this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image1, medium: this.ImagePath + this.lstCurrentDet.Image1, big: this.ImagePath + this.lstCurrentDet.Image1 })
              }
              if (this.lstCurrentDet.Image2 != '' && this.lstCurrentDet.Image2 != '0') {
                this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image2, medium: this.ImagePath + this.lstCurrentDet.Image2, big: this.ImagePath + this.lstCurrentDet.Image2 })
              }

              if (this.lstCurrentDet.Image3 != '' && this.lstCurrentDet.Image3 != '0') {
                this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image3, medium: this.ImagePath + this.lstCurrentDet.Image3, big: this.ImagePath + this.lstCurrentDet.Image3 })
              }
              if (this.lstCurrentDet.Image4 != '' && this.lstCurrentDet.Image4 != '0') {
                this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image4, medium: this.ImagePath + this.lstCurrentDet.Image4, big: this.ImagePath + this.lstCurrentDet.Image4 })
              }
              if (this.lstCurrentDet.Image5 != '' && this.lstCurrentDet.Image5 != '0') {
                this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image5, medium: this.ImagePath + this.lstCurrentDet.Image5, big: this.ImagePath + this.lstCurrentDet.Image5 })
              }
              if (this.lstCurrentDet.Image6 != '' && this.lstCurrentDet.Image6 != '0') {
                this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image6, medium: this.ImagePath + this.lstCurrentDet.Image6, big: this.ImagePath + this.lstCurrentDet.Image6 })
              }
            }
          }
          if (this.browserService.isBrowser()) {
          $("#preloader").hide();
          }
          // //debugger
          // if (typeof (this.lstRelatedProducts) != undefined && typeof (this.lstRelatedProducts) != 'undefined' && this.lstRelatedProducts.length > 0) {
          //   for (let i = 0; i < this.lstRelatedProducts.length; i++) {
          //     if (this.lstRelatedProducts[i].ProductRating === "5.0000") {
          //       this.lstRelatedProducts[i].ProductRating = "5";
          //     }
          //   }
          // }
          // //debugger
          // let NotslectedProdcutslst;
          // if (this.SelectedOption2 != '' && this.SelectedOption1 != '') {
          //   NotslectedProdcutslst = this.lstProducts.filter((x) => {
          //     return x.OPName1 != this.SelectedOption1 && x.OPName2 != this.SelectedOption2
          //   })
          // } else {
          //   NotslectedProdcutslst = this.lstProducts.filter((x) => {
          //     return x.OPName1 != this.SelectedOption1
          //   })
          // }

          // // //debugger
          // if (NotslectedProdcutslst.length > 0) {
          //   this.lstRelatedProducts.unshift(Object.assign({}, NotslectedProdcutslst[0]))
          // }
          // //debugger

        }
        if (this.browserService.isBrowser()) {
          const document: any = this.browserService.getDocument();
          this.ServerPath = document.location.origin;

          const urlFriendlyProduct = _.kebabCase(this.productName);
          if (document.location.hostname == 'localhost') {
            this.SharePath = this.ServerPath + "/Product/" + urlFriendlyProduct + "?ID=" + this.DProductID + "&OptionID=" + this.DOptionID + "&Img" + this.RawImagePath;
          } else {
            this.SharePath = this.ServerPath + "Blackdenim/web/Product/" + urlFriendlyProduct + "?ID=" + this.DProductID + "&OptionID=" + this.DOptionID + "&Img" + this.RawImagePath;
          }
        }



        // // window.scrollTo(0,0);

        //debugger;
      //  this.appRef.tick();
     //   this.dataLoaded=true;

    //  });
   
}


  home() {
    this.router.navigateByUrl('home')
  }

  products() {
    //this.router.navigateByUrl('ProductSearchAndFilterByCategory')

    this.router.navigateByUrl(
     '/collection'
      //,Img:''
    );

  }


  viewAll() {
    //debugger

    if (this.browserService.isBrowser()) {
      const window: any = this.browserService.getWindow();

      var NewUrl = (window.location.href.split('#')[0])
      NewUrl = this.getBaseUrl(NewUrl);

      this.router.navigate(['/product-review'], {
        queryParams: {
          ID: this.DProductID, Name: this.productName
        }
      })
     // window.open(NewUrl + url, "_blank", ""); //'#'+
    }
  }
  lstCombos: any = [];
  lstTempCombos: any = [];
  viewComboProducts(prodctid) {
    //debugger;
    this.apiCall.DBCalling('ViewComboProducts', prodctid, '', '', "").subscribe(
      (res: any) => {
        //debugger;
        $("#loaderParent").hide();
        let DbResult = (res);
        this.lstCombos = [];
        if (   DbResult.tasks.length>0  &&  DbResult.tasks[0].length > 0) {
          //debugger;
          this.lstCombos = Object.assign([], DbResult.tasks[0]);
          // this.lstTempCombos = this.lstTempCombos;
          for (let i = 0; i < this.lstCombos.length; i++) {
            if (this.lstTempCombos.length == 0) {
              this.lstTempCombos.push(this.lstCombos[i]);
            }
            else {
              let exist = this.lstTempCombos.filter((x: any) => x.ProductID == this.lstCombos[i].ProductID);
              if (exist.length == 0) {
                this.lstTempCombos.push(this.lstCombos[i]);
              } else {
                continue;
              }
            }
          }
          for (let j = 0; j < this.lstTempCombos.length; j++) {
            //debugger;
            this.lstTempCombos[j].ProductOptions = [];
            let options = this.lstCombos.filter((x: any) => x.ProductID == this.lstTempCombos[j].ProductID);
            //debugger;
            this.lstTempCombos[j].Selected = true;
            // debugger;
            for (let k = 0; k < options.length; k++) {
              //debugger;
              this.lstTempCombos[j].ProductOptions.push({
                'OPGName1': options[k].OPGName1, 'OPGName2': options[k].OPGName2,
                'OPName1': options[k].OPName1, 'OPName2': options[k].OPName2,
                'ProductOptionID': options[k].ProductOptionID, 'ProductID': options[k].ProductID
              });
            }
            this.totalPrice += (+this.lstTempCombos[j].Price);
            this.salesPrice += (+this.lstTempCombos[j].SalesPrice);
          }
        }
        this.cdr.detectChanges();
      })
  }


  getBaseUrl(url: string): string {
    //debugger
    const parsedUrl = new URL(url);
    return `${parsedUrl.origin}`;
  }

  // async trainAndRecommend(d: any) {
  //   //debugger;
  //   const selectedProduct = d;
  //   const recommendedProducts = this.recommendProducts(selectedProduct).slice(0, 6);
  //   //debugger;
  //   this.recommendedProducts = recommendedProducts
  // }
  // recommendProducts(selectedProduct: any) {
  //   //debugger;
  //   const recommendations = this.uniqueProducts.map(product => ({
  //     product,
  //     score: this.calculateSimilarity(selectedProduct, product)
  //   }))
  //     // .map(item => item.product.ProductID)
  //     .filter(item => item.product.ProductID != selectedProduct.ProductID)
  //     .sort((a, b) => b.score - a.score);
  //   //debugger;
  //   return recommendations.map(item => item.product);
  // }
  // calculateSimilarity(productA: any, productB: any) {
  //   // Simple similarity based on category and price
  //   let score = 0;
  //   if (productA.BrandName != productB.BrandName) score += 1;
  //   if (productA.Categoryname != productB.Categoryname) score += 1;
  //   return score;
  // }
  selectProduct(d, i) {
    //debugger;
    this.totalPrice = 0;
    this.salesPrice = 0;
    let value = d.target.value;
    if (this.lstTempCombos[i].Selected == '') {
      this.lstTempCombos[i].Selected = true;
    } else {
      this.lstTempCombos[i].Selected = false;
    }
    for (let j = 0; j < this.lstTempCombos.length; j++) {
      //debugger;
      if (this.lstTempCombos[j].Selected == true) {
        this.totalPrice += (+this.lstTempCombos[j].Price);
        this.salesPrice += (+this.lstTempCombos[j].SalesPrice);
      }
    }

    let validateproduct = this.lstTempCombos.filter((x:any)=> x.Selected == true);
    if(validateproduct.length > 0){
      this.productValidate = false;
    }

    this.cdr.detectChanges();
  }
  selectProductOptions(d, i, e) {
    //debugger;
    this.lstTempCombos[i].SelectedProduct = (+e.target.value);
    this.cdr.detectChanges();
  }
  submit : boolean = false;
  productValidate : boolean = false;
  addToCart() {

    this.cdr.detectChanges();
    debugger;
    // let validate = this.lstTempCombos.filter((x:any)=>x.Selected == true && x.SelectedProduct == '' || x.SelectedProduct == 0 || x.Selected == false && x.SelectedProduct != '');

    let validate : any = [];

    // for(let i=0;i<this.lstTempCombos.length;i++){
    //   if(this.lstTempCombos[i].Selected == true && this.lstTempCombos[i].SelectedProduct == '' || this.lstTempCombos[i].SelectedProduct == 0){

    //   }
    // }

    let isSelected = this.lstTempCombos.filter((x:any)=>x.Selected == true);
    let selectProducts = this.lstTempCombos.filter((x:any)=>x.SelectedProduct != '' || x.SelectedProduct != 0);
    debugger;
    if(isSelected.length > 0 && selectProducts.length > 0){
      debugger;
      if((isSelected.length) == (selectProducts.length)){
        validate = []
      }else{
        validate.push({'action':'require'});
      }
    }else {
      debugger;
      validate.push({'action':'require'});
    }
    

    debugger;
    if(validate.length == 0){
      debugger;

      // let comboProducts = this.objCart.lstCartList.findIndex((x:any)=> x.comboID == this.lstTempCombos[0].ComboID);
      let comboIDToMatch = this.lstTempCombos[0].ComboID;
      
      for (let i = this.objCart.lstCartList.length - 1; i >= 0; i--) {
        debugger;
        if (this.objCart.lstCartList[i].comboID == comboIDToMatch) {
          debugger;
          this.objCart.lstCartList.splice(i, 1);
        }
      }
      this.store.dispatch(new PageStore.OpenPage({ viewName: 'CartList', lstCartList: Object.assign([], this.objCart.lstCartList) }));
      debugger;

      this.submit = false;
      this.productValidate = false;
      for (let i = 0; i < this.lstTempCombos.length; i++) {
        debugger;
        if(this.lstTempCombos[i].Selected == true && this.lstTempCombos[i].SelectedProduct != ''){
          let lstCart = (this.store as any).source['value']['MSECOM'].filter((x: any) => {
            return x.viewName == 'CartList';
          });
          let product;
          debugger;
          if (lstCart.length > 0) {
            product = lstCart[0].lstCartList.filter((x: any) => x.ProductID == this.lstTempCombos[i].ProductID && x.ProductOptionID == this.lstTempCombos[i].ProductOptionID)
          } 
          debugger;
    
          if (product != undefined && product.length > 0 && product[0].Qty >= (+product[0].ProductQty)) {
            debugger;
            Swal.fire({
              title: `Out Of Stock`,
              timer: 2000,
            });
    
          } else {
            debugger;
            var valid = true;
            try {
              //debugger;
              valid = true;
              let obj = new CartList();
              obj.MerchantID = this.lstTempCombos[i].MerchantID;
              obj.ProductName = this.lstTempCombos[i].ProductName;
              obj.ProductCode = this.lstTempCombos[i].ProductCode;
              obj.ProductLongDesc = this.lstTempCombos[i].ProductLongDesc;
              obj.ProductImage = this.ImageAppUrl + this.lstTempCombos[i].Image1;
              obj.Price = this.lstTempCombos[i].Price;
              // obj.SalesPrice = this.lstTempCombos[i].SalesPrice;
              // obj.ProductQty = this.lstTempCombos[i].AvailableQty;
              obj.SalesPrice = this.lstTempCombos[i].SalesPrice;
              obj.ProductQty = this.lstTempCombos[i].AvailableQty;
              obj.OPGName1 = this.lstTempCombos[i].OPGName1;
              // obj.IsWishListItem = this.lstTempCombos[i].IsWishListItem.toString();
              obj.OPGName2 = this.lstTempCombos[i].OPGName2;
              obj.OPGName3 = this.lstTempCombos[i].OPGName3;
              obj.OPGName4 = this.lstTempCombos[i].OPGName4;
              obj.ProductID = this.lstTempCombos[i].ProductID;
              obj.ProductOptionID = this.lstTempCombos[i].ProductOptionID;
              obj.OPName1 = this.lstTempCombos[i].OPName1;
              obj.OPName2 = this.lstTempCombos[i].OPName2;
              obj.OPName3 = this.lstTempCombos[i].OPName3;
              obj.OPName4 = this.lstTempCombos[i].OPName4;
              obj.ParentID = '0';
              obj.Qty = 1;
              obj.OPName3 = this.lstTempCombos[i].OPName3;
              obj.OPName4 = this.lstTempCombos[i].OPName4;
              obj.isCombo = 1;
              obj.comboID = this.lstTempCombos[i].ComboID;
              obj.CalcTotal();
              this.lstSetItemList = [];
    
              this.objCart.AddToCart(obj);
            
              // Swal.fire({
              //   title: "Product has been added to your cart",
              //   timer: 2000,
              // });
              this.store.dispatch(new PageStore.OpenPage({ viewName: 'CartList', lstCartList: Object.assign([], this.objCart.lstCartList) }));
              ($('#ctrCart') as any).click();
            } catch (e) {
              console.log(e)
            }
          }
        }
      }
    }else{
      debugger;
      let validate = this.lstTempCombos.filter((x:any)=>x.Selected == false);
      if(validate.length == this.lstTempCombos.length){
        this.productValidate = true;
      }else{
        this.submit = true;
      }

    }
    this.cdr.detectChanges();
  }

  isProductsSelected(){
    debugger;
    // let validate = this.lstTempCombos.filter((x:any)=>x.Selected == true);

  }

  getSalesPrice(salesPrice){
    debugger;
    return (+salesPrice.toFixed(2));
  }

}
