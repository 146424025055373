<section class="">
    <div class="container-fluid p-4">
        
        <div class="row">
            <div class="col-md-12 mb-5">
                <p class="site-map text-end">
                    <span (click)="home()" role="button">Home</span> <i class="fa fa-chevron-right mx-3" aria-hidden="true"></i> 
                    <span  role="button">Products</span>
                    
                </p>
            </div>
            

            <div class="col-lg-2 ">
                <div class="block product-price-range mt_18">
                    <div class="sidebar-bar-title">
                        <h3>Price</h3>
                    </div>
                    <div class="block-content">
                        <div class="content">
                            <div class="slider-container">
                                <input type="range" min="0" max="{{slidermaxvalue}}" step="10" value="{{MinValue}}">
                                <input type="range" min="0" max="{{slidermaxvalue}}" step="10" value="{{MaxValue}}">
                                <span class="amount-range-price mt-5"></span>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="block-product-price-range ps-0 pe-0 mt_18">
                        <div class="sidebar-bar-title">
                            <h3>Main Categories</h3>
                        </div>
                        <div class="block-content">
                            <div class="slider-range">
                                <div class="slider-price">
                                    <ul class="check-box-list">
                                        <li *ngFor="let d of lstofResultMainCategories;let i=index">
                                            <div *ngIf="d.MnCategoryname!=''">
                                                <input type="checkbox" id="{{d.MnCategoryname}}" disabled [attr.checked]="d.show==true?'checked':null" name="{{d.MnCategoryname}}" value="{{d.MnCategoryID}}" (change)="MainCategoryChange($event.target,d,i)" style="display: none;">
                                                <label for="{{d.MnCategoryname}}"><span class="button"></span>{{d.MnCategoryname}}</label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="block-product-price-range ps-0 pe-0 mt_18">
                        <div class="sidebar-bar-title">
                            <h3>Categories</h3>
                        </div>
                        <div class="block-content">
                            <div class="slider-range">
                                <div class="slider-price">
                                    <ul class="check-box-list">
                                        <li *ngFor="let d of lstResultCategories">
                                            <input type="checkbox" id="{{d.Categoryname}}" name="{{d.Categoryname}}" value="{{d.CategoryID}}" (change)="CategoriesChange($event.target,d)">
                                            <label for="{{d.Categoryname}}"><span class="button"></span>{{d.Categoryname}}</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="block-product-price-range ps-0 pe-0 mt_18">
                        <div class="sidebar-bar-title">
                            <h3>Brands</h3>
                        </div>
                        <div class="block-content">
                            <div class="slider-range">
                                <div class="slider-price">
                                    <ul class="check-box-list">
                                        <li *ngFor="let d of lstResultBrands">
                                            <input type="checkbox" id="{{d.BrandName}}" name="{{d.BrandName}}" value="{{d.BrandID}}" (change)="BrandChange($event.target,d)">
                                            <label for="{{d.BrandName}}"><span class="button"></span>{{d.BrandName}}</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="block-product-price-range ps-0 pe-0 mt_18" *ngFor="let d of lstResultOptionGroups| UniqueOptionGroups">
                        <div class="sidebar-bar-title">
                            <h3>{{d.OptionGroup}}</h3>
                        </div>
                        <div class="block-content">
                            <div class="slider-range">
                                <div class="slider-price">
                                    <ul class="check-box-list" *ngFor="let dChild of lstResultOptions| UniqueOptions">
                                        <li *ngIf="dChild.OptionGroupID==d.OptionGroupID">
                                            <input type="checkbox" id="{{dChild.OptionName}}" name="{{dChild.OptionName}}" value="{{dChild.OptionID}}" (change)="OptionsChange($event.target,dChild,d.OptionGroupID)">
                                            <label for="{{dChild.OptionName}}"><span class="button"></span>{{dChild.OptionName}}</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <br>
                    </div>
                </div>
            </div>
            <div class="col-lg-10 ">
                <div class="shop__product--wrapper mt-19">
                    <div class="tab_content">
                        <div class="row name">
                            <div class="col-md-7 col-4">
                                <div _ngcontent-rqg-c2="" class="page-title">
                                    <h3 class="mt-8">Products</h3>
                                </div>
                            </div>
                            <div class="col-md-5 col-8">
                                <div class="toolbar">
                                    <div class="sorter">
                                        <div class="short-by">
                                            <label>Sort By:</label>
                                            <select (change)="SortChange($event.target)">
                                                <option selected="selected">Default</option>
                                                <option>Price Low to High</option>
                                                <option>Price High to Low</option>
                                                <option>Name (A-Z)</option>
                                                <option>Name (Z-A)</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="product_grid" class="tab_pane active show">
                            <div class="product__section--inner product__grid--inner">
                                <div class="row row-cols-xl-5 row-cols-lg-12 row-col-md-12 mb-5">
                                    <div class="col-md-12 text-center" *ngIf="lstFilteredProducts.length==0">
                                        <h1 class="clamp_text">PRODUCTS WILL COMING SOON!</h1>
                                    </div>
                                    <div class="col-6 col-md-3 p-0 d-table_cell" (mouseover)="hover=true;productMouseOverCall(d.ProductID)" (mouseleave)="hover=false" *ngFor="let d of lstFilteredProducts">
                                        <div class="product__items user-profile">
                                            <div class="product__items--thumbnail figure">
                                                <a class="product__items--link" (click)="ProductClick(d.ProductOptionID,d.ProductID,d.Image1,d.OPName2,d.OPName3,d.ProductName)">
                                                    <img class="product__items--img product__primary--img" src="{{ImagePath}}{{d.Image1}}" alt="Buy online - {{d.ProductName}}">
                                                    <img class="product__items--img product__secondary--img" src="{{ImagePath}}{{d.Image1}}" alt="Buy online - {{d.ProductName}}">
                                                </a>
                                            </div>
                                            <div class="css-popover bg__black d-none d-lg-block" [style.z-index]="hover==true?9:0">
                                                <div class="row">
                                                    <span *ngFor="let o of selectedProductOptions">
                                                        <div class="col-md-12">
                                                            <ul class="text-white">
                                                                <li><strong class="text-white">{{o.Name}}:</strong>{{o.Options}}</li>
                                                            </ul>
                                                        </div>
                                                    </span>
                                                    <div class="col-md-12 mt-4">
                                                        <h4 class="text-white" (click)="ProductClick(d.ProductOptionID,d.ProductID,d.Image1,d.OPName2,d.OPName3,d.ProductName)"><a>QUICK VIEW</a></h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="product__items--content text-center p-0">
                                                <strong class="text-muted" (click)="ProductClick(d.ProductOptionID,d.ProductID,d.Image1,d.OPName2,d.OPName3,d.ProductName)">{{d.ProductName}}</strong>
                                                <div class="product__items--price mb-5">
                                                    <span class="current__price mx-2" *ngIf="d.SalesPrice!=''"><b class="rupee">{{storeSettings.currency}}</b>{{(+d.SalesPrice).toFixed(0)}}</span>
                                                    <span class="text-danger" *ngIf="d.Price!=''&&((+d.SalesPrice)<(+d.Price))">(<b class="rupee"><del>{{storeSettings.currency}}{{(+d.Price).toFixed(0)}}</del></b><b class="mx-2">{{d.Discount}}</b></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
           

            <div class="col-md-8  mx-auto">
                <div class="row">
                    <div class="col-md-8">
                        <p><b>Buy {{lstofResultMainCategories[0].MnCategoryname}}</b></p>
                        <div [innerHtml]="getDescription(lstofResultMainCategories[0].MnCategoryname)"></div>
                    </div>
                    <div class="col-md-4">
                        <p><b>{{lstofResultMainCategories[0].MnCategoryname}} Price List</b></p>

                        <div class="row price-list-products" *ngFor="let d of buyProducts;let i = index">
                            <div class="col-md-9 col-9" (click)="ProductClick(d.ProductOptionID,d.ProductID,d.Image1,d.OPName2,d.OPName3,d.ProductName)">
                                <p class="m-0" style="text-align: left;">{{d.ProductName}}</p>
                            </div>
                            <div class="col-md-3 col-3">
                                Rs. {{d.SalesPrice}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
          
        
            
        </div>
    </div>
    <h1></h1>
        <h2></h2>
        <h3></h3>
        <h4></h4>
</section>