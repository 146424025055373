<h2 class="offer"><span><i class="fa fa-map-marker f-200 mx-3"></i></span>Store Locators</h2>
<div class="container">


    <div class="row">
        <div class="col-md-4">
            <div class="address mb-4s" *ngFor="let d of lstStores;let i=index;">
                <div class="row" (click)="selectLocation(d.Iframe)">
                    <div class="col-md-1">
                        <i aria-hidden="true" class="fa fa-map-marker f-200 f-20"></i>
                    </div>
                    <div class="col-md-11" >
                        <h3>{{d.Name}}</h3>
                        <p class="address_description">{{d.Address}}</p>
                        <p class="address_description"><a href="/" target="_blank">{{d.Website}}</a></p>
                      
                    </div>
                    <div class="col-md-6">
                        <img src="{{ImagePath}}{{d.Image1}}" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <!-- <button class="btn btn-primary" (click)="viewStores()">viewStores</button> -->
            <!-- <span [innerHtml]="currentMap"></span> -->
            <!-- <div id="map_canvas" style="width:100%; height:450px; border: 2px solid #3872ac;"></div> -->

            

            <!-- <div *ngFor="let d of lstStores;let i=index;">
                <iframe [src]="getMap(d.Iframe) | safeUrl"  width="100%" height="450" style="border:0;" 
                allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ></iframe>
              </div> -->

              <div *ngIf="currentMap!=''">
                <iframe [src]="currentMap | safeUrl"  width="100%" height="450" style="border:0;" 
                allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ></iframe>
              </div>  
        </div>
    </div>

    <!-- <div class="row">
        <div class="col-md-4 mb-4 mb-2">

            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.511048029618!2d78.41033977597021!3d17.483101500039126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb913c2fbd1eb1%3A0x9f6278a717cc0658!2sBlackdenim.in!5e0!3m2!1sen!2sin!4v1686919250897!5m2!1sen!2sin"
                width="370" height="300" class="border-0" allowfullscreen="" loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
            <h5 class="card-title text-center locators "><a href="https://goo.gl/maps/3aBosnEyL8xMao7D7" target="_blank"><strong>Kukatpally</strong></a></h5>
        </div>
        <div class="col-md-4 mb-4">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.6123227112575!2d78.44660787596943!3d17.43038280156355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9172ebcf4ec9%3A0x1d8d68da16a19cc0!2sBLACK%20DENIM%20BRAND%20FASHION!5e0!3m2!1sen!2sin!4v1686919307910!5m2!1sen!2sin"
                width="370" height="300" class="border-0" allowfullscreen="" loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
            <h5 class="card-title text-center locators"><a href="https://goo.gl/maps/nNWUQ3G78Q7gnDiJ7" target="_blank"><strong>PanjaGutta</strong></a></h5>
        </div>
        <div class="col-md-4 mb-4">


            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.443097291222!2d78.44717627597025!3d17.48634929994503!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb91988bdda215%3A0xc370ba11d8038f5d!2sBLACK%20DENIM%20BRAND%20FASHION!5e0!3m2!1sen!2sin!4v1686919355179!5m2!1sen!2sin"
                width="370" height="300" class="border-0" allowfullscreen="" loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
            <h5 class="card-title text-center locators"><a href="https://goo.gl/maps/gKNt2ESXkinNAorm6" target="_blank" class="text-white"><strong>IDPL</strong></a></h5>
        </div>
        <div class="col-md-4 mb-4">


            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.7427868507443!2d78.41747557596865!3d17.376105603128515!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb97ebdbdf1aad%3A0x18edcde7b0d44e5!2sBlack%20Denim!5e0!3m2!1sen!2sin!4v1686919422879!5m2!1sen!2sin"
                width="370" height="300" class="border-0" allowfullscreen="" loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
            <h5 class="card-title text-center locators"><a href="https://goo.gl/maps/8JPSZF5U1Wcaaf697" target="_blank" class="text-white"><strong>Attapur</strong></a></h5>
        </div>
        <div class="col-md-4 mb-4">



            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.280470876512!2d78.44142007596898!3d17.398322702488393!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb97fda21a5483%3A0x3f400d83c67799e2!2sBLACK%20DENIM%20BRAND%20FASHION!5e0!3m2!1sen!2sin!4v1686919465885!5m2!1sen!2sin"
                width="370" height="300" class="border-0" allowfullscreen="" loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>

            <h5 class="card-title text-center locators"><a href="https://goo.gl/maps/ZpTsYfwufJwX55Bg7" target="_blank" class="text-white"><strong>Medhipatnam</strong></a></h5>



        </div>
        <div class="col-md-4 mb-4">

            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.125617586194!2d78.40191237596913!3d17.405758202273987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb971981fd3095%3A0x91502959aba19332!2sBLACK%20DENIM%20BRAND%20FASHION!5e0!3m2!1sen!2sin!4v1686919573811!5m2!1sen!2sin"
                width="370" height="300" class="border-0" allowfullscreen="" loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
            <h5 class="card-title text-center locators"><a href="https://goo.gl/maps/ANPHQP62VuJEqF6c8" target="_blank" class="text-white"><strong>Tolichowki</strong></a></h5>



        </div>
    </div> -->

    <h1></h1>
    <h2></h2>
    <h3></h3>
    <h4></h4>

</div>