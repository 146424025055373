import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { APICallingService } from '../model/APICallingService';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.css'
})
export class FaqComponent {

  lstFaq : any = [];
  active : number = 0;
  constructor( private apiCall: APICallingService,private router:Router) { }

  ngOnInit(){
    debugger;
    this.view();
  }

  view(){
    debugger;
    var xml1 = '<NewDataSet><Table1>'
    + '<view>' + 'Website' + '</view>'
    + '</Table1></NewDataSet>';
    this.apiCall.DBCalling('ViewFaq', xml1, '', '', '').subscribe(
      (res:any) => {
        debugger;

        let result = res;
        let lstfaq = result.tasks[0];
        let i = 0;
        debugger;
        while(i < lstfaq.length ){
          debugger;
         let chldfaq = this.getChildFaq(lstfaq[i].childFaqs);
         debugger;
         lstfaq[i].childFaqs = chldfaq;
          let j = 0;
          debugger;
          while(j < lstfaq[i].childFaqs.length){
            debugger;
            lstfaq[i].childFaqs[j].Description = this.decodeContent(lstfaq[i].childFaqs[j].Description );
            j++
          }
          debugger;
          i++
        }

        this.lstFaq = lstfaq;

        // this.resetForm();
      })
  }


  getChildFaq(d:any){
    debugger;
    let resDF =  ((d.replace(/\n/g, "")).replace(/'/g, "\""));
    return JSON.parse(resDF);
  }

  decodeContent(d){
    debugger;
    return decodeURIComponent(d);
  }

  viewdetails(d){
    if(this.active == d){
      this.active = -1;
    }else{
      this.active = d;
    }
  }
}
