<div id="preloader">
  <div id="ctn-preloader" class="ctn-preloader">
      <div class="animation-preloader">
          <div class="spinner"></div>
          <div class="txt-loading">
              <span data-text-preloader="L" class="letters-loading">
                  _
              </span>

              <span data-text-preloader="O" class="letters-loading">
                  _
              </span>

              <span data-text-preloader="A" class="letters-loading">
                  _
              </span>

              <span data-text-preloader="D" class="letters-loading">
                  _
              </span>

              <span data-text-preloader="I" class="letters-loading">
                  _
              </span>

              <span data-text-preloader="N" class="letters-loading">
                  _
              </span>

              <span data-text-preloader="G" class="letters-loading">
                  _
              </span>
          </div>
      </div>
      <div class="loader-section section-left"></div>
      <div class="loader-section section-right"></div>
  </div>
</div>


<header class="header__section">
  <div class="row" *ngIf="OffersDescription!=''">
      <div class="col-md-12" style="background-color: #393939;">
          <marquee width="100%" style="color:white;margin-top: 10px;">
              <div class="mobilviewoffers">
                  <p class="offertext" style="color: white;font-size: 15px;" [innerHtml]="OffersDescription"></p>
              </div>
          </marquee>
      </div>
  </div>


  <div class="header__topbar bg__secondary">
      <div class="container-fluid">
          <div class="header__topbar--inner d-flex align-items-center justify-content-between">
              <div class="header__shipping">

                  <ul class="header__shipping--wrapper d-flex">
                      <li class="header__shipping--text text-white d-sm-2-none"> <i class="fa fa-phone"
                              aria-hidden="true"></i> Customer Care +91 8977500750 Timings :11 AM -7 PM (Mon -Sat)</li>
                      <li class="header__shipping--text text-white " (click)="myOrders()"><img
                              class="header__shipping--text__icon" src="assets/img/icon/bus.png" alt="bus-icon"> Track
                          Your Order </li>
                      <li class="header__shipping--text text-white d-sm-2-none"><img
                              class="header__shipping--text__icon" src="assets/img/icon/email.png" alt="email-icon">
                          <a class="header__shipping--text__link"
                          href="mailto:info&#64;blackdenim.in">info&#64;blackdenim.in</a>
                      </li>
                  </ul>
              </div>
              <!-- <div class="language__currency d-none d-lg-block"> -->
              <div class="language__currency d-lg-block">
                  <ul class="d-flex align-items-center">

                      <li class="language__currency--list">
                          <a href="storelocators"><i class="fa fa-map-marker f-200" aria-hidden="true"></i></a>
                      </li>
                      <li class="language__currency--list" (click)="redirectto('Facebook')">
                          <i class="fa fa-facebook" aria-hidden="true"></i>
                      </li>

                      <li class="language__currency--list" (click)="redirectto('Twitter')">
                          <i class="fa fa-twitter" aria-hidden="true"></i>
                      </li>

                      <li class="language__currency--list" (click)="redirectto('Instagram')">
                          <i class="fa fa-instagram" aria-hidden="true"></i>
                      </li>

                      <li class="language__currency--list" (click)="redirectto('Youtube')">
                          <i class="fa fa-youtube"></i>
                      </li>

                  </ul>
              </div>
          </div>
      </div>
  </div>
  <div class="main__header header__sticky">
      <div class="container-fluid">
          <div class="main__header--inner position__relative d-flex justify-content-between align-items-center">
              <div class="offcanvas__header--menu__open ">
                  <a class="offcanvas__header--menu__open--btn" href="javascript:void(0)" data-offcanvas=""
                      (click)="mobileheader=true">
                      <svg xmlns="http://www.w3.org/2000/svg" class="ionicon offcanvas__header--menu__open--svg"
                          viewBox="0 0 512 512">
                          <path fill="currentColor" stroke="currentColor" stroke-linecap="round"
                              stroke-miterlimit="10" stroke-width="32" d="M80 160h352M80 256h352M80 352h352"></path>
                      </svg>
                      <span class="visually-hidden">Menu Open </span>
                  </a>
              </div>
              <div class="main__logo">
                  <h1 class="main__logo--title">
                      <a class="main__logo--link" href="#">
                          <img class="main__logo--img" src="assets/img/logo/bdlogo1.png" alt="logo-img">

                      </a>
                  </h1>
              </div>
              <!-- <div class="header__search--widget header__sticky--none d-none d-lg-block">
                  <form class="d-flex header__search--form">
                       <div class="header__select--categories select" style="border-right: 1px solid #d7d7d7;">
                          <select class="header__select--inner" (change)="CategoryEvent(($event.target as HTMLInputElement).value)">
                              <option selected value="0">Select</option>
                              <option [value]="d.CategoryID" *ngFor="let d of lstCategories">{{d.Categoryname}}
                              </option>
                          </select>
                      </div> 
                      <div class="header__search--box">
                          <select class="js-example-basic-single w-100" name="dropdown" data-width="100%"> </select>
                          <button class="header__search--button bg__secondary text-white" type="submit"
                              aria-label="search button" (click)="SearchProductByCategory()">
                              <i class="fa fa-search" aria-hidden="true" (click)="SearchProductByCategory()"></i></button>
                      </div> 
                  </form>
              </div> -->
              <div class="header__account header__sticky--none">
                  <ul class="d-flex">
                      <li class="header__menu--items d-sm-none  d-md-block" >
                          <a class="header__menu--link header__account--btn  " 
                           (click)="searchClick()" data-bs-toggle="modal" id="popup" data-bs-target="#modal1"
                              style="padding: 0 0px;line-height:0px !important;margin-top:8px">
                              <svg class="header__search--button__svg" xmlns="http://www.w3.org/2000/svg" width="27.51"  
                              height="26.443" viewBox="0 0 512 512"><path d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"></path><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M338.29 338.29L448 448"></path></svg>
                          </a>
                         
                          
                      </li>
                      <li class="header__menu--items d-md-none d-lg-none" style="margin-right:1rem;" >
                          <a class="header__menu--link header__account--btn  " (click)="searchClick()" data-bs-toggle="modal" id="popup" data-bs-target="#modal1"
                          style="padding: 0 0px;line-height:0px !important;">
                          <svg class="header__search--button__svg" xmlns="http://www.w3.org/2000/svg" width="27.51"  
                          height="26.443" viewBox="0 0 512 512"><path d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"></path><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M338.29 338.29L448 448"></path></svg>
                      </a>
                      </li>

                    
                      <li class="header__menu--items d-sm-none d-md-none d-lg-block">
                          <a class="header__menu--link header__account--btn"
                              style="padding: 0 0px;line-height:0px !important;">
                              <svg xmlns="http://www.w3.org/2000/svg" width="26.51" height="23.443"
                                  viewBox="0 0 512 512">
                                  <path
                                      d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z"
                                      fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="32"></path>
                                  <path
                                      d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z"
                                      fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32">
                                  </path>
                              </svg>
                              <span class="header__account--btn__text">{{userName}} &nbsp;<i class="fa fa-angle-down"
                                      aria-hidden="true"></i></span>
                          </a>

                          <ul class="header__mega--menu d-flex "
                              style="width: 150px;display: flex !important;padding: 0;z-index: 2;margin-left: -51px;">

                              <li class="header__mega--menu__li" style="width: auto;">
                                  <ul class="header__mega--sub__menu">
                                      <li class="header__mega--sub__menu_li"><a class="header__mega--sub__menu--title"
                                              *ngIf="loginUserDetails.UserID>0" (click)="myAccountClick()"> Edit
                                              Profile</a></li>
                                      <li class="header__mega--sub__menu_li"><a class="header__mega--sub__menu--title"
                                              (click)="myOrders()" *ngIf="loginUserDetails.UserID>0">My Orders</a>
                                      </li>

                                      <li class="header__mega--sub__menu_li"><a class="header__mega--sub__menu--title"
                                              *ngIf="loginUserDetails.UserID==0" (click)="loginClick('')">Login</a></li>
                                      <li class="header__mega--sub__menu_li"><a class="header__mega--sub__menu--title"
                                              *ngIf="loginUserDetails.UserID>0" (click)="logoutClick()">Logout</a>
                                      </li>

                                  </ul>
                              </li>
                          </ul>
                      </li>
                      <li class="header__menu--items d-lg-none" style="margin-right:1rem;" >
                          <a class="header__menu--link header__account--btn"
                              style="padding: 0 0px;line-height:0px !important;">
                              <svg xmlns="http://www.w3.org/2000/svg" width="26.51" height="23.443"
                                  viewBox="0 0 512 512">
                                  <path
                                      d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z"
                                      fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="32"></path>
                                  <path
                                      d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z"
                                      fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32">
                                  </path>
                              </svg>
                              <span class="header__account--btn__text">{{userName}} &nbsp;<i class="fa fa-angle-down"
                                      aria-hidden="true"></i></span>
                          </a>

                          <ul class="header__mega--menu d-flex "
                              style="width: 150px;display: flex !important;padding: 0;z-index: 2;margin-left: -51px;">

                              <li class="header__mega--menu__li" style="width: auto;">
                                  <ul class="header__mega--sub__menu">
                                      <li class="header__mega--sub__menu_li"><a class="header__mega--sub__menu--title"
                                              *ngIf="loginUserDetails.UserID>0" (click)="myAccountClick()"> Edit
                                              Profile</a></li>
                                      <li class="header__mega--sub__menu_li"><a class="header__mega--sub__menu--title"
                                              (click)="myOrders()" *ngIf="loginUserDetails.UserID>0">My Orders</a>
                                      </li>

                                      <li class="header__mega--sub__menu_li"><a class="header__mega--sub__menu--title"
                                              *ngIf="loginUserDetails.UserID==0" (click)="loginClick('')">Login</a></li>
                                      <li class="header__mega--sub__menu_li"><a class="header__mega--sub__menu--title"
                                              *ngIf="loginUserDetails.UserID>0" (click)="logoutClick()">Logout</a>
                                      </li>

                                  </ul>
                              </li>
                          </ul>
                      </li>
                      <li class="header__account--items header__account2--items" (click)="Mywishlist()">
                          <a class="header__account--btn">
                              <svg xmlns="http://www.w3.org/2000/svg" width="28.51" height="23.443"
                                  viewBox="0 0 512 512">
                                  <path
                                      d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z"
                                      fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="32"></path>
                              </svg>
                              <span class="header__account--btn__text"> Wishlist </span>
                              <span class="items__count" id="Mywishlistprdcount">0 </span>
                          </a>
                      </li>
<button id="ctrCart" style="display: none;" (click)="cartClick=true;cartDetails()"></button>
                      <li class="header__account--items">
                          <a class="header__account--btn minicart__open--btn" id="ctrCart"
                              data-offcanvas="" (click)="cartClick=true;cartDetails()">
                              <svg xmlns="http://www.w3.org/2000/svg" width="26.51" height="23.443"
                                  viewBox="0 0 14.706 13.534">
                                  <g transform="translate(0 0)">
                                      <g>
                                          <path data-name="Path 16787"
                                              d="M4.738,472.271h7.814a.434.434,0,0,0,.414-.328l1.723-6.316a.466.466,0,0,0-.071-.4.424.424,0,0,0-.344-.179H3.745L3.437,463.6a.435.435,0,0,0-.421-.353H.431a.451.451,0,0,0,0,.9h2.24c.054.257,1.474,6.946,1.555,7.33a1.36,1.36,0,0,0-.779,1.242,1.326,1.326,0,0,0,1.293,1.354h7.812a.452.452,0,0,0,0-.9H4.74a.451.451,0,0,1,0-.9Zm8.966-6.317-1.477,5.414H5.085l-1.149-5.414Z"
                                              transform="translate(0 -463.248)" fill="currentColor"></path>
                                          <path data-name="Path 16788"
                                              d="M5.5,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,5.5,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,6.793,478.352Z"
                                              transform="translate(-1.191 -466.622)" fill="currentColor"></path>
                                          <path data-name="Path 16789"
                                              d="M13.273,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,13.273,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,14.566,478.352Z"
                                              transform="translate(-2.875 -466.622)" fill="currentColor"></path>
                                      </g>
                                  </g>
                              </svg>
                              <span class="header__account--btn__text"> My cart </span>
                              <span class="items__count" id="spnCartQty">0</span>
                              
                              
                          </a>
                      </li>
                     
                  

                  </ul>
              </div>
              <div class="header__menu d-none header__sticky--block d-lg-block">
                  <nav class="header__menu--navigation">
                      <ul class="d-flex">
                          <li class="header__menu--items">
                              <a class="header__menu--link" href="index.html">Home</a>
                          </li>


                          <li class="header__menu--items">
                              <a class="header__menu--link" href="#">New Arrivals</a>
                          </li>
                          <li class="header__menu--items">
                              <a class="header__menu--link" href="#">Best Sellers</a>
                          </li>

                      </ul>
                  </nav>
              </div>
              <div class="header__account header__account2 header__sticky--block">
                  <ul class="d-flex">
                      <li
                          class="header__account--items header__account2--items  header__account--search__items d-none d-lg-block">
                          <a class="header__account--btn search__open--btn" href="javascript:void(0)"
                              data-offcanvas="">
                              <svg class="header__search--button__svg" xmlns="http://www.w3.org/2000/svg"
                                  width="26.51" height="23.443" viewBox="0 0 512 512">
                                  <path d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"
                                      fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32">
                                  </path>
                                  <path fill="none" stroke="currentColor" stroke-linecap="round"
                                      stroke-miterlimit="10" stroke-width="32" d="M338.29 338.29L448 448"></path>
                              </svg>
                              <span class="visually-hidden">Search </span>
                          </a>
                      </li>
                      <li class="header__account--items header__account2--items">
                          <a class="header__account--btn" href="#">
                              <svg xmlns="http://www.w3.org/2000/svg" width="26.51" height="23.443"
                                  viewBox="0 0 512 512">
                                  <path
                                      d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z"
                                      fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="32"></path>
                                  <path
                                      d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z"
                                      fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32">
                                  </path>
                              </svg>
                              <span class="visually-hidden">{{userName}}</span>
                          </a>
                      </li>
                      <li class="header__account--items header__account2--items d-none d-lg-block">
                          <a class="header__account--btn" href="wishlist.html">
                              <svg xmlns="http://www.w3.org/2000/svg" width="28.51" height="23.443"
                                  viewBox="0 0 512 512">
                                  <path
                                      d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z"
                                      fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="32"></path>
                              </svg>
                              <span class="items__count  wishlist style2">02 </span>
                          </a>
                      </li>
                      <li class="header__account--items header__account2--items">
                          <a class="header__account--btn minicart__open--btn" href="javascript:void(0)"
                              data-offcanvas="">
                              <svg xmlns="http://www.w3.org/2000/svg" width="26.51" height="23.443"
                                  viewBox="0 0 14.706 13.534">
                                  <g transform="translate(0 0)">
                                      <g>
                                          <path data-name="Path 16787"
                                              d="M4.738,472.271h7.814a.434.434,0,0,0,.414-.328l1.723-6.316a.466.466,0,0,0-.071-.4.424.424,0,0,0-.344-.179H3.745L3.437,463.6a.435.435,0,0,0-.421-.353H.431a.451.451,0,0,0,0,.9h2.24c.054.257,1.474,6.946,1.555,7.33a1.36,1.36,0,0,0-.779,1.242,1.326,1.326,0,0,0,1.293,1.354h7.812a.452.452,0,0,0,0-.9H4.74a.451.451,0,0,1,0-.9Zm8.966-6.317-1.477,5.414H5.085l-1.149-5.414Z"
                                              transform="translate(0 -463.248)" fill="currentColor"></path>
                                          <path data-name="Path 16788"
                                              d="M5.5,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,5.5,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,6.793,478.352Z"
                                              transform="translate(-1.191 -466.622)" fill="currentColor"></path>
                                          <path data-name="Path 16789"
                                              d="M13.273,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,13.273,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,14.566,478.352Z"
                                              transform="translate(-2.875 -466.622)" fill="currentColor"></path>
                                      </g>
                                  </g>
                              </svg>
                              <span class="items__count style2">02 </span>
                          </a>
                      </li>
                  </ul>
              </div>
          </div>


      </div>
  </div>
  <!-- <div class="header__search--widget header__sticky--none   d-block d-lg-none">
      <form class="d-flex header__search--form">
          <div class="header__select--categories select" style="border-right: 1px solid #d7d7d7;width: 50%;">
              <select class="header__select--inner" (change)="CategoryEvent(($event.target as HTMLInputElement).value)"
                  style="padding-right: 0px;">
                  <option [value]="d.CategoryID" *ngFor="let d of lstCategories">{{d.Categoryname}}</option>

              </select>
          </div> 
      <div class="header__search--box" style="width: 100%;">
              <select class="js-example-basic-single" name="dropdown" style="width: 50%;"> </select>


              <button class="header__search--button bg__secondary text-white" type="submit" aria-label="search button"
                  (click)="SearchProductByCategory()"><i class="fa fa-search" aria-hidden="true" (click)="SearchProductByCategory()"></i></button>
                  
          </div>
      </form>
  </div> -->
  <!-- Web header -->
  <div class="header__bottom">
      <div class="container-fluid">
          <div
              class="header__bottom--inner position__relative d-none d-lg-flex justify-content-between align-items-center">
              <div class="header__menu">
                  <nav class="header__menu--navigation">
                      <ul class="d-flex">


                          <li class="header__menu--items mega__menu--items" *ngFor="let d of lstParentCategory">
                              <a class="header__menu--link" 
                                  (mouseover)='callMenuByparent(d.CategoryID)' (click)="CategoryClickByproduct(d.CategoryID,d.Categoryname)" >{{d.Categoryname}}
                                  <svg class="menu__arrowdown--icon" xmlns="http://www.w3.org/2000/svg" width="12"
                                      height="7.41" viewBox="0 0 12 7.41">
                                      <path d="M16.59,8.59,12,13.17,7.41,8.59,6,10l6,6,6-6Z"
                                          transform="translate(-6 -8.59)" fill="currentColor" opacity="0.7"></path>
                                  </svg>
                              </a>

                              <ul class="header__mega--menu d-flex " style="z-index: 9999;">
                                  <li class="header__mega--menu__li" *ngFor="let e of lstBrandsDet">
                                      <span class="header__mega--subtitle">{{e.BrandName}}</span>
                                      <ul class="header__mega--sub__menu mb-5">
                                          <li class="header__menu--items mega__menu--items"
                                              *ngFor="let d of getcategory(e.BrandID,d.CategoryID)">
                                              <a class="header__mega--sub__menu--title"
                                                  (click)="categoryClick(d,e)">{{d.Categoryname}}</a>
                                      </ul>
                                  </li>

                              </ul>
                          </li>

                          <!-- <li class="header__menu--items mega__menu--items" (click)="blog()">
                              <a class="header__menu--link" >Blog</a>
                          </li> -->


                      </ul>
                  </nav>
              </div>

          </div>
      </div>
  </div>

  <!-- Mobile header menu -->


  <div [class]="mobileheader?'offcanvas__header open d-lg-none d-sm-block':'offcanvas__header  '">
      <div class="offcanvas__inner">
          <div class="offcanvas__logo">
              <a class="offcanvas__logo_link" href="index.html">
                  <img src="assets/img/logo/bdlogo1.png" alt="Grocee Logo" width="158" height="36">
              </a>
              <button class="offcanvas__close--btn" data-offcanvas="" (click)="mobileheader=false">close </button>
          </div>
          <nav class="offcanvas__menu">
              <ul class="offcanvas__menu_ul">
                  <!-- previous menu binding with category with brand -->
                  <!-- <ng-container *ngFor="let d of lstParentCategory;let i=index">
                  
                      <li   [class]="category ? 'offcanvas__menu_li ':'offcanvas__menu_li'">
                          <a class="offcanvas__menu_item" (mouseover)='callMenuByparent(d.CategoryID)' (click)="Menuclick2(d.Categoryname,d.Categoryname+i+1)">{{d.Categoryname}}</a>
                          <ul id="{{d.Categoryname}}" class="offcanvas__sub_menu"  >
                           
                          <div  *ngFor="let e of GetBranddetails(d.CategoryID);let i=index">
                             <li  class="offcanvas__sub_menu_li"  >
                                
                                 <a   class="offcanvas__sub_menu_item" (click)="CallCategoryNamesByBrand2(e.Id2,e.Id)">{{e.BrandName}}</a>
                                 <ul id="{{e.Id2}}" [class]="submenu?'offcanvas__sub_menu_li active':'offcanvas__sub_menu_li'"  style="box-sizing:border-box;display:none">
                                  
                                
                                  <div *ngFor="let d of getcategory(e.BrandID,d.CategoryID)">
                                     <li class="offcanvas__sub_menu_li" >
                                      <a class="offcanvas__sub_menu_item" (click)="categoryClick(d,e)">{{d.Categoryname}}</a></li>
                                  </div>
                                 
                                 </ul>
                             <button   id ="{{e.Id}}" class="offcanvas__sub_menu_toggle"  (click)="CallCategoryNamesByBrand(e.Id2,$event)" #BrandSelection></button>
                          </li>
                            
                          </div>
                            
  
                            
                          </ul>
                      <button  id ="{{d.Categoryname+i+1}}" class="offcanvas__sub_menu_toggle"   (click)="menuclick(d.Categoryname,d.Categoryname+i+1)" ></button></li>
                      
                  </ng-container> -->
                  <ng-container *ngFor="let d of lstParentCategory;let i=index">

                      <li [class]="category ? 'offcanvas__menu_li ':'offcanvas__menu_li'" >
                          <!-- (click)="CategoryClickMobileByproduct(d.CategoryID)" -->
                          
                          <a class="offcanvas__menu_item" (mouseover)='callMenuByParentMobile(d.CategoryID)'
                              (click)="Menuclick2(d.CategoryID,d.CategoryID+i+1,d.Categoryname)">{{d.Categoryname}}</a>
                          <ul id="{{d.CategoryID}}" class="offcanvas__sub_menu">

                              <div *ngFor="let e of getMobilecategory(d.CategoryID);let i=index">
                                  <li class="offcanvas__sub_menu_li">

                                      <a class="offcanvas__sub_menu_item"
                                          (click)="categoryClick(e,e)">{{e.Categoryname}}</a>
                                      <ul id="{{e.Id2}}"
                                          [class]="submenu?'offcanvas__sub_menu_li active':'offcanvas__sub_menu_li'"
                                          style="box-sizing:border-box;display:none">

                                          <!-- <div *ngFor="let d of getcategory(e.BrandID,d.CategoryID)">
                                 <li class="offcanvas__sub_menu_li" >
                                  <a class="offcanvas__sub_menu_item" (click)="categoryClick(d,e)">{{d.Categoryname}}</a></li>
                              </div> -->

                                      </ul>
                                      <!-- <button   id ="{{e.Id}}" class="offcanvas__sub_menu_toggle"  (click)="CallCategoryNamesByBrand(e.Id2,$event)" #BrandSelection></button> -->
                                  </li>

                              </div>



                          </ul>
                          <button id="{{d.CategoryID+i+1}}" class="offcanvas__sub_menu_toggle"
                              (click)="menuclick(d.CategoryID,d.CategoryID+i+1)"></button>
                      </li>

                  </ng-container>
              </ul>

          </nav>
      </div>
  </div>

  <div [class]="cartClick?'offCanvas__minicart active':'offCanvas__minicart'" (mouseout)="cartClick=false"
      (mouseover)="cartClick=true">
      <div class="minicart__header ">
          <div class="minicart__header--top d-flex justify-content-between align-items-center">
              <h2 class="minicart__title h3"> Shopping Cart </h2>
              <button class="minicart__close--btn" aria-label="minicart close button" (click)="cartClick=false"
                  data-offcanvas="">
                  <svg class="minicart__close--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="32" d="M368 368L144 144M368 144L144 368"></path>
                  </svg>
              </button>
          </div>
          <p class="minicart__header--desc">Selected Products</p>
      </div>
      <div class="minicart__product">
          <div class="minicart__product--items d-flex" *ngFor="let crt of lstCartList ;let ind =index">
              <div class="minicart__thumb">
                  <a href="#"><img src={{crt.ProductImage}} alt="prduct-img"></a>
              </div>
              <div class="minicart__text">
                  <h3 class="minicart__subtitle h4"><a>{{crt.ProductName}}</a></h3>
                  <span class="color__variant" *ngIf="crt.OPGName1!=''"><b>{{crt.OPGName1}}: </b> {{crt.OPName1}}
                  </span>
                  <span class="color__variant" *ngIf="crt.OPGName2!=''"><b>,{{crt.OPGName2}}: </b> {{crt.OPName2}}
                  </span>
                  <span class="color__variant" *ngIf="crt.OPGName3!=''"><b>,{{crt.OPGName3}}: </b> {{crt.OPName3}}
                  </span>
                  <span class="color__variant" *ngIf="crt.OPGName4!=''"><b>,{{crt.OPGName4}}: </b> {{crt.OPName4}}
                  </span>
                  <div class="minicart__price">
                      <span class="current__price ">
                          <b class="rupee">{{storeSettings.currency}}</b>{{(+crt.SalesPrice).toFixed(0)}}
                          <del *ngIf="(+crt.SalesPrice)<(+crt.Price)" style="color: red;">({{ (+crt.Price).toFixed(0)}})</del> 
                      </span>


                  </div>
                  <div class="minicart__text--footer d-flex align-items-center">
                    <!-- *ngIf="crt.isCombo != 1" -->
                      <div class="quantity__box minicart__quantity" >
                          <button type="button" class="quantity__value decrease" aria-label="quantity value"
                              (click)="decreaseClick(crt,ind)" value="Decrease Value">- </button>
                          <label>
                              <input type="number" class="quantity__number" value={{crt.Qty}} data-counter=""
                                  disabled>
                          </label>
                          <button type="button" class="quantity__value increase" value="Increase Value"
                              (click)="increaseClick(crt,ind)">+ </button>
                      </div>
                      <button class="minicart__product--remove" (click)="removeClick(crt,ind)">Remove </button>
                  </div>
                  <div class="minicart__text--footer d-flex align-items-center" *ngIf="crt.qtyValidation">

                      <label style="color:red"><strong>out of stock Qty</strong></label>
                  </div>
              </div>
          </div>




      </div>
      <div class="minicart__amount">
          <div class="minicart__amount_list d-flex justify-content-between">
              <span>Total: </span>

              <span><b class="rupee">{{storeSettings.currency}}{{(+objCart.OrderGrossAmount).toFixed(0)}}</b></span>
              <!-- |currency:storeSettings.currency -->
          </div>
      </div>
      <div class="minicart__conditions text-center">

      </div>
      <div class="minicart__button d-flex justify-content-right">
          <!-- <a class="primary__btn minicart__button--link" [hidden]="showButtons" (click)="viewCart()">View cart </a> -->
          <a class="primary__btn minicart__button--link " [hidden]="showButtons" 
              (click)="checkoutClick('CartCheckout','checkout')">Checkout
          </a>
      </div>
  </div>
  <!-- End offCanvas minicart -->

  <!-- Start serch box area -->
  <div class="predictive__search--box ">
      <div class="predictive__search--box__inner">
          <h2 class="predictive__search--title">Search Products </h2>
          <form class="predictive__search--form" action="#">
              <label>
                  <input class="predictive__search--input" placeholder="Search Here" type="text">
              </label>
              <button class="predictive__search--button" aria-label="search button" type="submit"><svg
                      class="header__search--button__svg" xmlns="http://www.w3.org/2000/svg" width="30.51"
                      height="25.443" viewBox="0 0 512 512">
                      <path d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z" fill="none"
                          stroke="currentColor" stroke-miterlimit="10" stroke-width="32"></path>
                      <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10"
                          stroke-width="32" d="M338.29 338.29L448 448"></path>
                  </svg> </button>
          </form>
      </div>
      <button class="predictive__search--close__btn" aria-label="search close button" data-offcanvas="">
          <svg class="predictive__search--close__icon" width="40.51" height="30.443" viewBox="0 0 512 512">
              <path fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                  stroke-width="32" d="M368 368L144 144M368 144L144 368"></path>
          </svg>
      </button>
  </div>
  <!-- End serch box area -->

</header>
<!-- End header area -->

<main class="main__content_wrapper" >
  
  <router-outlet ></router-outlet>

  
  <div class="scroll-to-top" [ngClass]="{'show-scrollTop': windowScrolled}">
      <button type="button" class="btn active" data-toggle="button" aria-pressed="true" (click)="scrollToTop()" 
      style="background: red;color: white;">
          <i class="fas fa-chevron-up"  ></i>
      </button>
    </div>


</main>


<div class="container-fluid mt-5 mb-5">
    <div class="row">
        <div class="col-md-12">
            <ul class="widget__categories--menu">
                <li [ngClass]="navigationLinks == 'active' ? 'widget__categories--menu__list active':'widget__categories--menu__list '">
                    <label class="widget__categories--menu__label d-flex align-items-center p-4" (click)="openNavigationLinks()">
                        <span class="widget__categories--menu__text p-0"><b class="text-uppercase">Navigation Links</b></span>
                        <svg class="widget__categories--menu__arrowdown--icon" xmlns="http://www.w3.org/2000/svg" width="12.355" height="8.394">
                            <path d="M15.138,8.59l-3.961,3.952L7.217,8.59,6,9.807l5.178,5.178,5.178-5.178Z" transform="translate(-6 -8.59)" fill="currentColor"></path>
                        </svg>
                    </label>
                    <!-- class="widget__categories--sub__menu p-3" -->
                    <div [ngClass]="navigationLinks == 'active' ? 'widget__categories--sub__menu p-4 show' : 'widget__categories--sub__menu p-4 hide'" >
                        <div class="row">

                            <div class="col-md-12 mb-5 navigation_options" *ngFor="let d of lstParentCategory">
                                <b class="text-danger mb-3" (click)="CategoryClickByproduct(d.CategoryID,d.Categoryname)">{{d.Categoryname}}</b>
                                <p *ngFor="let e of lstBrandsDet">
                                    <span *ngFor="let d of getcategory(e.BrandID,d.CategoryID)" (click)="categoryClick(d,e)"> {{d.Categoryname}} 
                                        <span class="stepper">|</span> 
                                    </span>
                                </p>
                            </div>

                           
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>


<div style="
background-color:rgb(68 68 64);
width: 100%;
text-align: center;
"><img src="../assets/img/fs.png"  ></div>

<footer class="footer__section bg__black">
  <div class="container m-auto p-7">
      <div class="row">
          <div class="col-6 col-md-4">
              <h2 class="footer__widget--title footer-heading  h3 ">Orders </h2>
              <ul class="footer__widget--menu footer__widget--inner">
                  <li class="footer__widget--menu__list text-ofwhite"><a
                          class="footer__widget--menu__text text-ofwhite" (click)="myOrders()">My Orders </a></li>
                  <li class="footer__widget--menu__list text-ofwhite mb-3"><a
                          class="footer__widget--menu__text text-ofwhite" href="#ReturnRefundpolicy">Return /
                          Refunds</a></li>
                  <li class="footer__widget--menu__list text-ofwhite mb-3"><a
                          class="footer__widget--menu__text text-ofwhite" href="#Order&Payment">Order & Payment</a>
                  </li>
                  <h2 class="footer__widget--title footer-heading  h3">Personal Info </h2>

                  <li class="footer__widget--menu__list text-ofwhite"><a
                          class="footer__widget--menu__text text-ofwhite" (click)="myAccountClick()">Addresses</a>
                  </li>
                  <li class="footer__widget--menu__list text-ofwhite"><a
                          class="footer__widget--menu__text text-ofwhite" href="#PersonalInfo"> Personal Info</a></li>




              </ul>
          </div>


          <div class="col-6 col-md-4">
              <h2 class="footer__widget--title footer-heading  h3">Policies </h2>
              <ul class="footer__widget--menu footer__widget--inner">

                  <li class="footer__widget--menu__list text-ofwhite"><a
                          class="footer__widget--menu__text text-ofwhite" (click)="return()">Returns & Exchange Policy
                      </a></li>
                  <li class="footer__widget--menu__list text-ofwhite"><a
                          class="footer__widget--menu__text text-ofwhite" (click)="ReturnPolicy()">Returns &
                          Cancellation Policy</a></li>

                  <li class="footer__widget--menu__list text-ofwhite"><a
                          class="footer__widget--menu__text text-ofwhite"  (click)="shippingPolicy()">Shipping Policy</a></li>
                  <li class="footer__widget--menu__list text-ofwhite"><a
                          class="footer__widget--menu__text text-ofwhite" (click)="Termscondition()">Terms & Conditions of
                          Use</a></li>

                  <li class="footer__widget--menu__list text-ofwhite"><a
                          class="footer__widget--menu__text text-ofwhite" (click)="Offerterms()">Offer Terms &
                          Conditions</a></li>
                  <li class="footer__widget--menu__list text-ofwhite"><a
                          class="footer__widget--menu__text text-ofwhite" (click)="privacyPolicy()">Privacy Policy</a>
                  </li>

                  <li class="footer__widget--menu__list text-ofwhite"><a
                      class="footer__widget--menu__text text-ofwhite" (click)="blog()">Blog</a>
              </li>

                  <li class="footer__widget--menu__list text-ofwhite"><a
                      class="footer__widget--menu__text text-ofwhite" (click)="faq()">Faq</a>
              </li>


              </ul>
          </div>


          <div class="col-6 col-md-4">
              <h2 class="footer__widget--title footer-heading  h3">Contact Us </h2>
              <ul class="footer__widget--menu footer__widget--inner">
                  <li class="footer__widget--menu__list text-ofwhite"><a
                          class="footer__widget--menu__text text-ofwhite" (click)="storelocators()">Store Locators </a>
                  </li>
                  <li class="footer__widget--menu__list text-ofwhite"><a
                          class="footer__widget--menu__text text-ofwhite" (click)="contactus()">Contact Details </a></li>

              </ul>
              <br>
              <h2 class="footer__widget--title footer-heading h3"><a (click)="aboutus()">About Us</a></h2>
          </div>
      </div>


  </div>
</footer>
<div class="footer__bottom text-center justify-content-between align-items-center bg__secondary p-4">
    <p class="copyright__content text-ofwhite m-0">Copyright © {{currentYear()}} <a class="copyright__content--link" href="#">Black
            Denim, </a> All Rights Reserved.</p>
  
  </div>
  
 


<!-- modal popup start of Login and registration -->
<div id="id01" class="w3-modal" [ngStyle]="{'display':loginmodaldisplay}">
  <div class="w3-modal-content">


      <div class="w3-container" style="padding: 20px;">
          <div class="row">
              <div class="col-md-12" style="
                   
                  float: right;
              ">
              <span (click)="closeLoginModal()" class="w3-button w3-display-topright" style="float: right;">×</span>
              </div>
              </div>

          <!-- <span class="w3-button w3-display-topright" (click)="closeLoginModal()" style="float: right;">&times;</span> -->
          <h2 class="section__header--title text-center mb-5" style="font-size: 2.5rem;" *ngIf="login == true">Login
          </h2>
          <h2 class="section__header--title text-center mb-5" style="font-size: 2.5rem;" *ngIf="registrationshow == true">{{popupTitle}}</h2>

          <div class="row">
              <div class="col-md-12" style="background: #eee;">
                  <img src="./assets/img/logo/bdlogo1.png" alt="logo-img" *ngIf="login == true" class="main__logo--img" style="text-align: center; margin: auto; display: block;" />
                  <fieldset>
                      <!--login  start-->
                      <div class="row" *ngIf="login == true">
                          <div class="col-md-12 mb-3">
                              <div class="form-group">
                                  <label for="Name" class="control-label ml-2">Email*</label>
                                  <div class="col-md-12"><input id="Name" name="email" type="text" placeholder="Email" autocomplete="off" [(ngModel)]="email" class="checkout__input--field border-radius-5" /></div>
                                  <div *ngIf="submited && email==''"><label class="validate-error">Email required*</label></div>
                                  <div *ngIf="submited && email!='' && !validateEmail(email)"><label class="validate-error">Invalid Email</label></div>
                              </div>
                          </div>
                      </div>
                      <div class="row" *ngIf="login == true ">
                          <div class="col-md-12 mx-auto">
                              <div class="form-group">
                                  <label for="Name" class="control-label">Password*</label>
                                  <div class="col-md-12">
                                      <input id="Name" name="password" type="password" placeholder="Password" autocomplete="off" [(ngModel)]="password" class="checkout__input--field border-radius-5" />
                                      <div *ngIf="submited && password==''"><label class="validate-error">password required*</label></div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <!-- login -->
                      <!-- registration  start-->
                      <div class="row" *ngIf="registrationshow == true">
                          <div class="col-md-6 mb-2">
                              <div class="form-group">
                                  <label for="Name" class="control-label">First Name</label>
                                  <div class="col-md-12">
                                      <input id="firstName" name="UserFirstName" type="text" [(ngModel)]="registration.UserFirstName" placeholder="First Name" autocomplete="off" class="checkout__input--field border-radius-5" />
                                  </div>
                                  <div *ngIf="submitted && registration.UserFirstName==''"><label class="validate-error">FirstName required*</label></div>
                              </div>
                          </div>
                          <div class="col-md-6 mb-2">
                              <div class="form-group">
                                  <label for="Name" class="control-label">Last Name</label>
                                  <div class="col-md-12"><input id="lastName" name="UserLastName" type="text" [(ngModel)]="registration.UserLastName" placeholder="Last Name" autocomplete="off" class="checkout__input--field border-radius-5" /></div>
                                  <div *ngIf="submitted && registration.UserLastName==''"><label class="validate-error">LastName required*</label></div>
                              </div>
                          </div>
                      </div>
                      <div class="row" *ngIf="registrationshow == true">
                          <div class="col-md-6 mb-2">
                              <div class="form-group">
                                  <label for="Name" class="control-label">Email</label>
                                  <div class="col-md-12"><input id="email" name="UserEmail" type="text" [(ngModel)]="registration.UserEmail" placeholder="Email" autocomplete="off" class="checkout__input--field border-radius-5" /></div>
                                  <div *ngIf="submitted && registration.UserEmail==''"><label class="validate-error">Email required*</label></div>
                                  <div *ngIf="submitted && registration.UserEmail!='' && !validateEmail(registration.UserEmail)"><label class="validate-error">Invalid Email</label></div>
                              </div>
                          </div>
                          <div class="col-md-6 mb-2">
                              <div class="form-group">
                                  <label for="mobile" class="control-label">Mobile Number</label>
                                  <div class="col-md-12">
                                      <input id="UserMobile" name="UserMobile" type="number" placeholder="Mobile Number" autocomplete="off" 
                                      [(ngModel)]="registration.UserMobile"
                                      
                                      autocomplete="off" class="checkout__input--field border-radius-5"
                                      
                                      pattern="/[^a-zA-Z0-9]/g" 
                                      (keypress)="onKeyPress($event)"
                                      (input)="validateSignupNumber($event)"
                                      />
                                      <!-- maxlength="10"  pattern="[1-9]{1}[0-9]{9}"   minlength="10" -->
                                  </div>
                                  <!-- <div *ngIf="submitted && !validateTenDigitNumber(registration.UserMobile) && registration.UserMobile!=''"><label class="validate-error" style="font-size: 11px;">Please, Enter 10 digit Mobile Number*</label></div> -->
                                  <div *ngIf="submitted && registration.UserMobile==''"><label class="validate-error">Mobile required*</label></div>
                              </div>
                          </div>
                      
                          <div class="col-md-6 mb-2" *ngIf="popupTitle=='Signup'">
                              <div class="form-group">
                                  <label for="Name" class="control-label">Password</label>
                                  <div class="col-md-12"><input id="password" name="password" type="password" placeholder="Password" autocomplete="off" [(ngModel)]="registration.UserPassword" class="checkout__input--field border-radius-5" /></div>
                                  <div *ngIf="submitted && registration.UserPassword==''"><label class="validate-error">Password required*</label></div>
                              </div>
                          </div>
                          <div class="col-md-6 mb-2">
                              <div class="form-group">
                                  <label for="Name" class="control-label">Country</label>
                                  <div class="col-md-12"><input id="country" name="UserCountry" type="text" [(ngModel)]="registration.UserCountry" placeholder="Country" autocomplete="off" class="checkout__input--field border-radius-5" /></div>
                                  <div *ngIf="submitted && registration.UserCountry==''"><label class="validate-error">Country required*</label></div>
                              </div>
                          </div>
                      
                          <div class="col-md-6 mb-2">
                              <div class="form-group">
                                  <label for="Name" class="control-label">State</label>
                                  <div class="col-md-12"><input id="state" name="UserState" type="text" placeholder="State" [(ngModel)]="registration.UserState" autocomplete="off" class="checkout__input--field border-radius-5" /></div>
                                  <div *ngIf="submitted && registration.UserState==''"><label class="validate-error">State required*</label></div>
                              </div>
                          </div>
                          <div class="col-md-6 mb-2">
                              <div class="form-group">
                                  <label for="Name" class="control-label">City</label>
                                  <div class="col-md-12"><input id="city" name="UserCity" type="text" [(ngModel)]="registration.UserCity" placeholder="City" autocomplete="off" class="checkout__input--field border-radius-5" /></div>
                                  <div *ngIf="submitted && registration.UserCity==''"><label class="validate-error">City required*</label></div>
                              </div>
                          </div>
                      
                          <div class="col-md-6 mb-2">
                              <div class="form-group">
                                  <label for="Name" class="control-label">Pin</label>
                                  <div class="col-md-12"><input id="pin" name="UserZip" type="text" [(ngModel)]="registration.UserZip" placeholder="Pin" autocomplete="off" class="checkout__input--field border-radius-5" /></div>
                                  <div *ngIf="submitted && registration.UserZip==''"><label class="validate-error">Zip Code required*</label></div>
                              </div>
                          </div>
                          <div class="col-md-6 mb-2">
                              <div class="form-group">
                                  <label for="Name" class="control-label">Address</label>
                                  <div class="col-md-12"><textarea class="textarea" placeholder="Address" [(ngModel)]="registration.UserAddress" autocomplete="off" id="address" name="UserAddress" rows="4" cols="5"></textarea></div>
                                  <div *ngIf="submitted && registration.UserAddress==''"><label class="validate-error">Address required*</label></div>
                              </div>
                          </div>

                          <!-- <div class="form-group" *ngIf="registrationshow == true">
                              <div class="row"> -->
                          <div class="col-md-12 mb-3">
                              <button id="button1id" name="button1id" class="continue__shipping--btn primary__btn border-radius-5" style="width: 35%;" (click)="saveUserDetails()">Proceed</button></div>
                          <div class="col-md-12">
                              <a>Already have an account? <u class="reg" (click)="Loginotp()">Login</u></a>
                          </div>
                              <!-- </div>
                          </div> -->

                      </div>
                      <div class="row" *ngIf="login == true">
                          <div class="col-md-12 mx-auto">
                              <div class="form-group checkbox-lg mt-5">
                                  <span style="display: flex; font-size: 15px; font-weight: 600;">
                                      <input type="checkbox" name="checkboxes" id="checkboxes-0" style="width: 4%; cursor: pointer;" />&nbsp;&nbsp;
                                      <label for="checkboxes" style="font-weight: 100; cursor: pointer;">Save this information for next time</label>
                                  </span>
                              </div>
                          </div>
                      
                              <div class="col-md-6 mb-3"><button id="button1id" name="button1id" class="variant__buy--now__btn primary__btn outline" style="width: 100%;" (click)="Loginotp()">Login With OTP</button></div>
                              <div class="col-md-6 mb-3"><button id="button1id" name="button1id" class="continue__shipping--btn primary__btn border-radius-5" style="width: 100%;" (click)="Login()">LOGIN</button></div>
                              <div class="col-md-12">
                                  <a>Don't have an account? <u class="reg" (click)="registrationClick()">Signup</u></a>
                              </div>
                      </div>
                      <!-- registration  start-->
                      
                      <div class="row" *ngIf="Otp == true">
                          <div class="col-md-12"><h2 class="section__header--title text-center mb-5" style="font-size: 2.5rem;" *ngIf="login == true">Login with Otp</h2></div>
                          <div class="col-md-12 mb-3" *ngIf="ShowOtp == false">
                              <label for="Name" class="control-label ml-2">Enter Phone no</label>
                              <input id="otpPhone" name="Phone" type="number" placeholder="Phone no" autocomplete="off"
                               pattern="/[^a-zA-Z0-9]/g" 
                               (keypress)="onKeyPress($event)"
                               (input)="validateNumber($event)"
                              [(ngModel)]="otpPhone" class="checkout__input--field border-radius-5" />

                              
                          </div>
                          <div class="col-md-12 mb-3 text-center" *ngIf="ShowOtp == true">
                              <h3 class="ml-2 mb-2">User Already Exists.please verify</h3>
                              <h3 class="ml-2 mb-2">Enter OTP</h3>
                              <div id="inputs" class="inputs">
                                  <input class="input" type="text" inputmode="numeric" maxlength="1" [(ngModel)]="o1" (input)="otpChange($event)" />
                                  <input class="input" type="text" inputmode="numeric" maxlength="1" [(ngModel)]="o2" (input)="otpChange($event)" />
                                  <input class="input" type="text" inputmode="numeric" maxlength="1" [(ngModel)]="o3" (input)="otpChange($event)" />
                                  <input class="input" type="text" inputmode="numeric" maxlength="1" [(ngModel)]="o4" (input)="otpChange($event)" />
                              </div>
                          </div>
                          <div class="col-md-12 mb-3" *ngIf="ShowOtp == false">
                            <button id="button1id" name="button1id" class="continue__shipping--btn primary__btn border-radius-5" style="width: 100%;" 
                            (click)="requestOTP()">Request OTP</button>
                        </div>
                          <div class="col-md-12 text-center" *ngIf="validateNo !=''">
                              <p class="text-danger">
                                  {{validateNo}} <span (click)="registrationClick()"><a>Sign Up</a></span>
                              </p>
                          </div>
                          <div class="col-md-12 text-center" *ngIf="invalidOtp !=''"><p class="text-danger">{{invalidOtp}}</p></div>
                          <div class="col-md-12">
                              <a>Login with password? <u class="reg" (click)="logindetshowClick()">Login</u></a>
                          </div>
                      </div>
                      <!-- registration  end-->
                  </fieldset>
                  <!-- </form> -->
              </div>
          </div>
          
      </div>
  </div>
</div>



<!-- Modal -->

<!-- search start -->
<div class="modal fade w3-modal"   [ngStyle]="{'display':searchModelShow}"  id="modal1"  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content  page-width">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"><i class="fa fa-search" aria-hidden="true" style="font-size: 20px;color: #8f8f8fd1;"></i></h5>
        <label style="width: 100%;">
          <input class="header__search--input" (input)="SearchChange($event)" id="txtsearchbox"  placeholder="Search here..." type="text" style="border: none;">
      </label>
        <button type="button" (click)="searchClose()" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" style="overflow-y: inherit;">
          <div class="row" *ngIf="lstsearchResult.length==0">
              <div class="col-md-12">
                  <div class="autocomplete-item-section">
              <div class="autocomplete-item-head Categories" data-index="1">
               Categories 
              </div>
          </div>
          <div class="row" style="padding: 30px 50px;">
              <div class="col-md-2" *ngFor="let d of lstParentCategory">
                  <img src={{ImagePath+d.Image}} style="border-radius: 20px;" (click)="CategoryByProduct(d.CategoryID,d)">  
                  <div class="grid-product__meta" (click)="CategoryByProduct(d.CategoryID,d)">
                      <div class="grid-product__title" >{{d.Categoryname}}</div>
                   </div>  
              </div>
              
               </div>  
              </div>
            
              </div>

          <div class="row" *ngIf="lstsearchResult.length>0"  > 
              <div class="col-md-3">
                  <ul>
                      <li class="autocomplete-item-head-main" data-index="0"> Categories</li>
                      <!-- <li *ngFor="let d of getSearchedCategories()" class="autocomplete-link">Snitch Plus <em>Shirts</em></li> -->
                      <li *ngFor="let d of getSearchedCategories()" class="autocomplete-link" (click)="SearchByCategory(d)">{{d}}</li>
                   

                  </ul>
              </div>
              <div class="col-md-9">
                  <h4>TOP PRODUCTS</h4>



                  <div class="row">
                  <div class="col-md-3" *ngFor="let d of lstsearchResult">
                   <img src={{ImagePath+d.ProductImage}} class="search-img" (click)="searchProductCluck(d)"> 
                   <div class="grid-product__meta">
                      <div class="grid-product__title">{{d.ProductName}}</div>
                      <div class="grid-product__price">
                        <span class="visually-hidden">Regular price</span>
                        <span class="grid-product__price--original" *ngIf="(+d.SalesPrice)!= (+d.Price)">Rs. {{d.Price}}</span>
                        <span class="visually-hidden">Sale price</span>
                        Rs. {{d.SalesPrice}}
                        <span class="grid-product__price--savings" *ngIf="(+d.SalesPrice)!= (+d.Price)">{{d.Discount}}</span>
                      </div>
                   </div> 
                  </div>
                 
              </div>
          </div>
      </div>

    </div>
  </div>
</div>
<!-- search end -->