import { Component, HostListener, Injectable, NgZone, OnInit } from '@angular/core';
import { loginDetails } from '../UserDetails';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { Cart } from '../Cart';
import { AppSettings, IAppSettings } from '../model/AppSettings';
import { APICallingService } from '../model/APICallingService';
import * as PageStore from "../../../src/app/Store/PageStore/Page.Actions";
import { PaymentgatwayModel } from '../../app/model/paymentgateway.model';
//import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { WindowRefService } from '../model/WindowRefService';
import $ from 'jquery';

 
// import '../../assets/js/vendor/select2/css/select2.min.css';
// import '../../assets/js/vendor/select2/js/select2.min.js';
import { BrowserService } from '../browser.service';
import { Navigate } from '../navigate';

// @Injectable({
//   providedIn: 'root'
// })



// Now TypeScript knows about the select2 method


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

  formValidation: boolean = false;
  paymentmodaldisplay = 'none';
  detailsArray: any = [];
  selectedPaymentMode : string = "Online Payment";
  public payment(response: any) {


    if (response.messages.resultCode === "Error") {

    } else {


      this.objPaymentGatway.loadPaypal(this.orderTotal);

    }

  }

  storeSettings: IAppSettings =
    {
      faxUserId: '',
      faxPassword: '',
      companyName: '',
      theamColorCode: '#ea0029',
      headerColorCode: '#000',
      headerimage: '../../assets/img/trending1.png',
      loginBackgroundImage: '../../assets/bg-1.jpg',
      loginImage: '../../assets/img/logo_web.png',
      dangerColorCode: '#dc3545',
      successColorCode: '#28a745',
      logo: '../../assets/img/logo_web.png',
      paymentGateway: '',
      key: '',
      selectedBranch: '',
      apiLoginId: '',
      orderEmail: '',
      adminUrl: '',
      orderUrl: '',
      viewName: 'AppSettings',
      apiCallingUrl: '',
      fontFamily: 'prompt,sans-serif',
      domainName: '',
      fax: '',
      projectId: '',
      keyNo: '',
      noOfBranches: 0,
      currency: '₹',
      transactionkey: '',
    };
  paymentdisplay = 'none';
  ImagePath: string = "";

  constructor(private browserService: BrowserService,private store: Store<any>, public objPaymentGatway: PaymentgatwayModel, private route: ActivatedRoute, public appSettings: AppSettings, private zone: NgZone, private LoginPage: AppComponent, private winRef: WindowRefService, private routerUrl: Router, private router: Navigate, private appCall: AppComponent, public objCart: Cart, private apiCall: APICallingService) {
   
    if (this.browserService.isBrowser()) {
    this.storeSettings = this.appSettings.loadSettings();
    this.ImagePath = this.storeSettings.apiCallingUrl;
    }
  }
  public payPalConfig?: any = this.objPaymentGatway.payPalConfig;
  public authorizenetConfig: any = this.objPaymentGatway.authorizenetConfig;


  public responseHandler(response: any) {
    //debugger
    //Visit Api Reference for API Schema
    if (response.messages.resultCode === "Error") {
      var i = 0;
      while (i < response.messages.message.length) {
        console.log(
          response.messages.message[i].code + ": " +
          response.messages.message[i].text
        );
        i = i + 1;
      };
    }
    else {
      this.objPaymentGatway.payAuthorizeDotnetPayment(response.detail.opaqueData, this.orderTotal);
    }
  }

  PrivacyPolicycheck: boolean = false;
  PrivacyPolicy(event: any) {
    //debugger
    if (event == false) {
      this.PrivacyPolicycheck = true;
    } else {
      this.PrivacyPolicycheck = false;
    }
  }

  CheckMarkerrror = '';
  lstInvalidstock: any = [];
  loginUserDetails: any;
  NavigateFrom: string = "";

  ngOnInit(): void {
    Object.preventExtensions(this.lstCartList);

    this.loginUserDetails = new loginDetails();
    if (this.browserService.isBrowser()) {
   
    var result = (this.store as any).source['value']['MSECOM'].filter((x: any) => { return x.viewName == "loginDetails"; });
    if (result.length > 0) {
      if (this.browserService.isBrowser()) {
      $('#preloader').hide();
      }
      this.loginUserDetails = (Object.assign({}, result[0]));
    }
    this.route.queryParamMap
      .subscribe((params: any) => {
        this.NavigateFrom = params.params.navigateFrom;
      })
    this.getDeliverychrg();
    }
  }


  loginClick() {
    // this.router.navigateByUrl('/userlogin');
    this.appCall.loginClick('');
  }

  PlaceOrder(PaymentMode) {
    //debugger
    if (this.loginUserDetails.UserID == 0) {
      //debugger
      this.LoginPage.loginClick('checkout');
    } else if (this.loginUserDetails.UserEmail == '' || this.loginUserDetails.UserFirstName == '' || this.loginUserDetails.UserLastName == '' ||
      this.loginUserDetails.UserAddress == '' || this.loginUserDetails.UserCity == '' || this.loginUserDetails.UserCountry == '' || this.loginUserDetails.UserState == '' || this.loginUserDetails.UserZip == '') {
      //debugger
      this.formValidation = true;
    } else {
      //debugger
      this.formValidation = false;
      this.SaveOrder(PaymentMode);
    }
  }

  loadpayment() {

    this.objPaymentGatway.loadPaymentGatway(this);
  }

  lstCartList = [];
  AppliedCouponCode: string = "";
  DbResult: any = [];

  cartDetails() {
    if (this.browserService.isBrowser()) {
    let cartDetails = (this.store as any).source['value']['MSECOM'].filter((x: any) => {
      return x.viewName == 'CartList';
    });
    if (cartDetails.length > 0 && typeof (cartDetails[0].lstCartList.length) != 'undefined' && cartDetails[0].lstCartList.length != 0) {
      this.lstCartList = Object.assign([], cartDetails[0].lstCartList);
      this.objCart.OrderDiscount = 0;
      this.objCart.CouponDiscount = 0;
      this.claculateDeliveryDiscount();
      this.objCart.CalcTotals(this.lstCartList);
      this.objCart.getCartQty(this.lstCartList);
    }
  }
  }

  BuyDetails() {

    let BuyProduct = (this.store as any).source['value']['MSECOM'].filter((x: any) => {
      return x.viewName == 'Buylst';
    });

    if (BuyProduct.length > 0) {
      this.lstCartList = Object.assign([], BuyProduct[0].lstCartList);
      this.objCart.OrderDiscount = 0;
      this.objCart.CouponDiscount = 0;
      this.objCart.BuyNowTotals(this.lstCartList);
    }
  }

  Returntocart() {

    this.router.navigateByUrl('cart')
  }

  deliveryDiscount = 0;
  claculateDeliveryDiscount() {

    let odrValue = (+(+this.objCart.OrderGrossAmount).toFixed(2));
    this.deliveryDiscount = 0;
    for (let i = 0; i < this.lstDeliveryDiscount.length; i++) {
      let discount = 0;
      if (odrValue >= (+this.lstDeliveryDiscount[i]["MaxOrders"])) {
        let maxDiscount = (+this.lstDeliveryDiscount[i]["MaxDiscountAmount"]);
        let fixedAmount = (+this.lstDeliveryDiscount[i]["FixedAmount"]);
        if (fixedAmount > 0) {
          discount = fixedAmount;
        }
        else {
          let calcDiscount = (this.objCart.DeliveryCharges * (+this.lstDeliveryDiscount[i]["Percentage"])) / 100;
          discount = (calcDiscount > maxDiscount ? maxDiscount : calcDiscount);
        }

        // this.deliveryDiscount = this.deliveryDiscount > discount ? this.deliveryDiscount : discount;


        if(this.selectedPaymentMode == 'Online Payment'){
          
          this.deliveryDiscount = this.deliveryDiscount > discount ? this.deliveryDiscount : discount;
            this.deliveryDiscount = this.deliveryDiscount > discount ? this.deliveryDiscount : discount;  
          }
          else{
            this.deliveryDiscount = 0;
          }

      }
    }
  }

  lstDeliveryCharges = [];
  lstDeliveryDiscount = [];

  getDeliverychrg() {
   
    $("#preloader").show();
  
    this.apiCall.DBCalling("GetDeliveryCharges", "", "", "", "").subscribe(
      (res) => {

        let DbResult: any = (res);
        if (DbResult.tasks.length > 0) {

          this.lstDeliveryCharges = DbResult.tasks[0];

          if (DbResult.tasks[0][0].lstDeliveryDiscount != null && typeof (DbResult.tasks[0][0].lstDeliveryDiscount) != 'undefined') {
            var resD = (((DbResult.tasks[0][0].lstDeliveryDiscount).replace(/\n/g, "")).replace(/'/g, "\""));
            var resChild = JSON.parse(resD)
            this.lstDeliveryDiscount = resChild;
          }


          var result = (this.store as any).source['value']['MSECOM'].filter((x: any) => { return x.viewName == "selectedZipCode" });
          if (result.length > 0) {
            let delivery = this.lstDeliveryCharges.filter(x => x.PinCode === this.loginUserDetails.UserZip);
            if (delivery.length > 0) {
              this.DeliveryError = '';
              this.objCart.DeliveryCharges = (+delivery[0].DeliveryCharges);
              this.ExpectedDeliveryDays = delivery[0].ExpectedDeliveryDays;

            } else {
              this.DeliveryError = 'Delivery not possible at given pincode';
            }
          }

          if (this.objCart.OrderDiscount > 0) {

            if (typeof (this.objCart.ApplyCouponCode) != "undefined") {
              this.couponstatus = true;
              
              $('#txtCouponCode').val(this.objCart.ApplyCouponCode)
              $("input[name='txtCouponCode']").val(this.objCart.ApplyCouponCode);
              
            }
          }
        
          $("#preloader").hide();
          
          this.claculateDeliveryDiscount();
          this.LoadCouponDropdown('')
        }
      });
  }


  InvTax: number = 0;
  UserID: number = 0;
  NetTotal: number = 0;
  MsgType: string = '';
  ErrorMessage: string = '';
  TotalDiscount: number = 0;
  DeliveryError: string = "";
  mDeliveryCharges: number = 0;

  Zipcode(event) {
   let value= event.target.value;
    let delivery = this.lstDeliveryCharges.filter(x => x.PinCode.trim() === value.trim());
    if (delivery.length > 0) {

      this.DeliveryError = '';

      this.objCart.DeliveryCharges = (+delivery[0].DeliveryCharges);
      this.ExpectedDeliveryDays = (+delivery[0].ExpectedDeliveryDays)

      this.loginUserDetails.PinCode = value;
      this.store.dispatch(new PageStore.OpenPage(Object.assign({}, this.loginUserDetails)));
      this.store.dispatch(new PageStore.OpenPage({ viewName: 'selectedZipCode', Pincode: value }));
    } else {
      this.DeliveryError = 'Delivery not possible at given pincode';
      this.ExpectedDeliveryDays = 0;
      this.objCart.DeliveryCharges = 0;
    }
  }

  couponstatus: boolean = false;
  couponError: string = "";

  ClearCoupon() {
 
   // $('.drpCoupon').empty();
    
    $('#txtCouponCode').val('');
    $("input[name='txtCouponCode']").val("");
    this.AppliedCouponCode='';

    this.couponstatus = false;
    this.buttonText = "Apply"
    this.lstCartList[0].ProductDiscount = 0;
    this.objCart.OrderDiscount = 0;
    this.couponError = '';
    this.couponaplymsg = '';
    this.objCart.CouponDiscount = 0;
    this.objCart.ApplyCouponCode = '';
    this.claculateDeliveryDiscount();
    if (this.NavigateFrom != 'BuyNow') {
      this.objCart.CalcTotals(this.lstCartList);
    }

  }
  couponUpdate(e)
  {
    debugger;
    this.AppliedCouponCode=e. value;
  }
  ApplyCoupon() {
    debugger
   
    let CartTotal = (+this.objCart.OrderGrossAmount);
    let CouponCode = this.AppliedCouponCode.toString();

    this.UserID = this.loginUserDetails.UserID == 0 ? this.UserID : this.loginUserDetails.UserID;
    if (this.buttonText == 'Apply') {
      if (CouponCode != "") {


        this.apiCall.DBCalling("GetDiscountFromCoupon", CouponCode, this.UserID, "", "").subscribe(
          (res) => {
            try {
              debugger;
              let Result: any = (res);
              if (Result.tasks.length > 0) {
                this.couponstatus = true;

                this.couponError = '';
                this.couponaplymsg = '';
                let DiscountType: string = Result.tasks[0][0].RuleType;
                let MaxDiscountAmount: number = Number(Result.tasks[0][0].MaxDiscountAmount);
                let FixedAmount: number = Number(Result.tasks[0][0].FixedAmount);
                let MaxOrders: number = Result.tasks[0][0].MaxOrders;
                let DiscountPer: number = Number(Result.tasks[0][0].Percentage);
                if ((+this.objCart.OrderGrossAmount) > MaxOrders) {
                  if (FixedAmount != 0) {

                    this.couponstatus = true;
                    this.buttonText = "Clear"
                    this.lstCartList[0].ProductDiscount = (+FixedAmount);
                    this.objCart.OrderDiscount = (+FixedAmount);
                    this.objCart.ApplyCouponCode = CouponCode.toString();
                    this.objCart.CouponDiscount = (+FixedAmount);
                    this.couponaplymsg = 'Applied coupon successfully !..'
                  } else if (DiscountPer != 0 && MaxDiscountAmount != 0 && DiscountType == 'NewCustomer') {

                    this.couponstatus = true;
                    this.buttonText = "Clear"
                    var ApplyDiscountAmt = (+this.objCart.OrderGrossAmount) * (+DiscountPer) / 100;
                    ApplyDiscountAmt = (+ApplyDiscountAmt) > MaxDiscountAmount ? MaxDiscountAmount : ApplyDiscountAmt;
                    // this.lstCartList[0].ProductDiscount =ApplyDiscountAmt;
                   // Object.preventExtensions(this.lstCartList[0]);


                    this.lstCartList[0]['ApplyCouponCode'] = CouponCode.toString();
                  
                   // this.lstCartList[0]['ApplyCouponCode'] = { ...this.lstCartList[0], ApplyCouponCode:ApplyDiscountAmt};
                    this.objCart.CouponDiscount = ApplyDiscountAmt;
                    //   this.objCart.OrderDiscount = ApplyDiscountAmt;
                    this.objCart.ApplyCouponCode = CouponCode.toString();
                    this.couponaplymsg = 'Applied coupon successfully !..'
                    this.objCart.CouponDiscount = ApplyDiscountAmt;
                  }
                  else if (DiscountPer != 0 && MaxDiscountAmount != 0 && DiscountType == 'Invoice') {

                    this.couponstatus = true;
                    this.buttonText = "Clear"
                    var ApplyDiscountAmt = (+this.objCart.OrderGrossAmount) * (+DiscountPer) / 100;
                    ApplyDiscountAmt = (+ApplyDiscountAmt) > MaxDiscountAmount ? MaxDiscountAmount : ApplyDiscountAmt;
                    //   this.lstCartList[0].ProductDiscount =ApplyDiscountAmt;

                    //Object.preventExtensions(this.lstCartList[0]);
                    this.lstCartList[0]['ApplyCouponCode'] = CouponCode.toString();
                   
                   
                    ///this.lstCartList[0]['ApplyCouponCode'] = { ...this.lstCartList[0], ApplyCouponCode:ApplyDiscountAmt};
                    this.objCart.CouponDiscount = ApplyDiscountAmt;
                    //    this.objCart.OrderDiscount = ApplyDiscountAmt;
                    this.objCart.ApplyCouponCode = CouponCode.toString();
                    this.couponaplymsg = 'Applied coupon successfully !..'
                    this.objCart.CouponDiscount = ApplyDiscountAmt;
                  }
                } else {
                  this.couponstatus = false;
                  this.couponError = 'Coupon not valid for your order';

                }


              } else {
                // if get empty result
                this.couponstatus = false;
                this.couponError = 'Invalid Coupon Code'
                //    this.objCart.ApplyCouponCode='';
              }
              //debugger
              this.claculateDeliveryDiscount();

              if (this.NavigateFrom != 'BuyNow') {
                this.objCart.CalcTotals( [...this.lstCartList ] )
                this.store.dispatch(new PageStore.OpenPage({ viewName: "CartList", lstCartList: Object.assign([], this.lstCartList) }));
              }

            } catch (e) {
              console.log(e)
            }
            
          });
      }
    } else {
      this.ClearCoupon()
    }

  }

  couponaplymsg: string = '';


  closeLoginModal() {
    this.paymentdisplay = 'none';
  }

  closeModalPopup() {
    this.StockValidationdisplay = false;
  }

  IsguestUser: boolean = false;
  Orderstatus: string = "Open";
  PaidStatus: number = 0;
  OrderNo: string = "";
  OrderDate: string = "";
  orderTotal: number = 0;
  ExpectedDeliveryDays: number = 0;

  stripepayment(trdata, lstdata) {

    var orderid = lstdata.DbResult.tasks[0][0].OrderID
     
    $("#loaderParent").show();
    
    //  OrderPaymentDetailsUpdate
    this.apiCall.DBCalling('OrderPaymentDetailsUpdate', orderid, trdata.id, trdata.id, trdata.type).subscribe(
      (res) => {
      
        $("#loaderParent").hide();
        
        this.DbResult = (res);
        if (this.DbResult.tasks.length > 0) {
          // this.sweetalert()

          this.router.navigateByUrl('thankyoupage');
        }
      });

  }

  confirmCOD(e: string) {
    //debugger
    // if (this.PrivacyPolicycheck) {
      try {
        //debugger
        Swal.fire({
          title: 'Are you sure',
          text: 'Do you want to place order with COD (Cash on delivery)?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }).then((result) => {
          if (result.value) {
            //debugger
            this.CheckStockByProducts(e);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              'Cancelled',
              'your record is safe',
              'error'
            )
          };
        });
      }
      catch (e) { }
    // }
    
    // else {
    //   this.CheckMarkerrror = "**";
    // }
  }

  StockValidationdisplay: boolean = false;
  CheckStockByProducts(PaymentMode) {
    //debugger
    // if (this.PrivacyPolicycheck) {
      var xml = "";
      var rows = '';
      for (let i = 0; i < this.lstCartList.length; i++) {
        rows = rows + '<Table1>'
          + '<ProductOptionID>' + (typeof (this.lstCartList[i].ProductOptionID) == 'undefined' ? '0' : this.lstCartList[i].ProductOptionID) + '</ProductOptionID>'
          + '<Qty>' + (typeof (this.lstCartList[i].Qty) == 'undefined' ? '0' : this.lstCartList[i].Qty) + '</Qty>'
          + '</Table1>'
      }
      xml = '<NewDataSet>' + rows + '</NewDataSet>';
      this.apiCall.DBCalling('checkStock', xml, '', '', '').subscribe(
        (res) => {
          debugger
          var DbResult: any = (Object.assign([], res));

          if (DbResult.tasks[0][0].stockstatus == 'Sufficient Stock') {
            this.StockValidationdisplay = false;
            this.PlaceOrder(PaymentMode);
            //debugger
          } else {
            this.lstInvalidstock = Object.assign([], DbResult.tasks[0]);
            //debugger
            //open pop-up throught boolean 

            this.StockValidationdisplay = true;
          }
        }
      )
    // }
    //  else {
    //   this.CheckMarkerrror = "**";
    // }
  }
  
  setwidthDevice: string = "369px";
  @HostListener('window:resize', [])
  onOrientationChange(event) {
    if (this.browserService.isBrowser()) {
      const window:any = this.browserService.getWindow();
    var width = window.innerWidth;
    if (width <= 768) {

      this.setwidthDevice = "309px";

      //this.LoadCouponDropdown();

     // window.scrollTo(0, 0)
    } else {
      this.setwidthDevice = "369px";
      // this.LoadCouponDropdown();
    }

  }
  }
  async SaveOrder(PaymentMode) {
    //debugger

    var UserID = this.loginUserDetails.UserID;
    var UserName = this.loginUserDetails.UserFirstName + this.loginUserDetails.UserLastName;
    this.IsguestUser = (this.loginUserDetails.UserID) === 0 ? true : false;

    var xml1 = "";
    var xml2 = "";
    var xml3 = "";

    var xml1 = '<NewDataSet><Table1>'
      + '<UserID>' + (typeof (UserID) == 'undefined' ? '0' : UserID) + '</UserID>'
      + '<OrderShipName>' + (typeof (UserName) == 'undefined' ? '0' : UserName) + '</OrderShipName>'
      + '<ShippingFirstName>' + this.loginUserDetails.UserFirstName + '</ShippingFirstName>'
      + '<ShippingLastName>' + this.loginUserDetails.UserLastName + '</ShippingLastName>'
      + '<DeliveryAddress>' + this.loginUserDetails.UserAddress + '</DeliveryAddress>'
      + '<City>' + this.loginUserDetails.UserCity + '</City>'
      + '<State>' + this.loginUserDetails.UserState + '</State>'
      + '<Zip>' + this.loginUserDetails.UserZip + '</Zip>'
      + '<Country>' + this.loginUserDetails.UserCountry + '</Country>'
      + '<PhoneNo>' + this.loginUserDetails.UserMobile + '</PhoneNo>'
      + '<BillingFirstName>' + this.loginUserDetails.UserFirstName + '</BillingFirstName>'
      + '<BillingLastName>' + this.loginUserDetails.UserLastName + '</BillingLastName>'
      + '<BillingCity>' + this.loginUserDetails.UserCity + '</BillingCity>'
      + '<BillingState>' + this.loginUserDetails.UserState + '</BillingState>'
      + '<BillingZip>' + this.loginUserDetails.UserZip + '</BillingZip>'
      + '<BillingCountry>' + this.loginUserDetails.UserCountry + '</BillingCountry>'
      + '<BillingAddress>' + this.loginUserDetails.UserAddress + '</BillingAddress>'
      + '<BillingMobile>' + this.loginUserDetails.UserMobile + '</BillingMobile>'
      + '<IsGuestUser>' + this.IsguestUser + '</IsGuestUser>'
      + '<DeliveryType>' + 'Deliver' + '</DeliveryType>'
      + '<PickupLocation>' + '' + '</PickupLocation>'
      + '<UserMobile>' + this.loginUserDetails.UserMobile + '</UserMobile>'
      + '<CouponCode></CouponCode>'
      + '<CouponDiscount>' + this.objCart.CouponDiscount + '</CouponDiscount>'
      + '<OrderDiscount>' + this.objCart.OrderDiscount + '</OrderDiscount>'
      + '<OrderTax>' + ((+this.objCart.CGSTAmt) + (+this.objCart.SGSTAmt)) + '</OrderTax>'
      + '<DeliveryCharges>' + ((+this.objCart.DeliveryCharges) - (+this.deliveryDiscount) <= 0 ? '0' : (+this.objCart.DeliveryCharges)) + '</DeliveryCharges>'
      + '<OrderNetTotal>' +
      (((+this.objCart.OrderNetTotal) - ((+this.objCart.CouponDiscount))) + (((+this.objCart.DeliveryCharges) - (+this.deliveryDiscount)) > 0 ? ((+this.objCart.DeliveryCharges) - (+this.deliveryDiscount)) : 0)).toFixed(0)
      + '</OrderNetTotal>'
      + '<OrderGrossAmount>' + this.objCart.OrderGrossAmount + '</OrderGrossAmount>'
      + '<Fld1></Fld1>'
      + '<Fld2></Fld2>'
      + '<EmailId>' + this.loginUserDetails.UserEmail + '</EmailId>'
      + '<OrderStatus>' + this.Orderstatus + '</OrderStatus>'
      + '<PaymentMode>' + (PaymentMode == 'COD' ? 'COD' : '') + '</PaymentMode>'
      + '<PaidStatus>' + (PaymentMode == 'COD' ? 2 : this.PaidStatus) + '</PaidStatus>'
      + '</Table1></NewDataSet>';
    var rows = "";

    var rows = "";

    if (typeof (this.lstCartList) != 'undefined' && this.lstCartList != null) {
      for (var i = 0; i < this.lstCartList.length; i++) {


        if (this.lstCartList[i].ProductID != "" && typeof (this.lstCartList[i].ProductID) != 'undefined')
          rows = rows + '<Table1>'
            + '<OrderID>' + (typeof (this.lstCartList[i].OrderID) == 'undefined' ? '0' : this.lstCartList[i].OrderID) + '</OrderID>'
            + ' <OrderDetailsID>0</OrderDetailsID>'
            + '<ProductOptionID>' + (typeof (this.lstCartList[i].ProductOptionID) == 'undefined' ? '0' : this.lstCartList[i].ProductOptionID) + '</ProductOptionID>'
            + '<ExpectedDeliveryDays>' + this.ExpectedDeliveryDays + '</ExpectedDeliveryDays>'
            + '<MerchantID>' + (typeof (this.lstCartList[i].MerchantID) == 'undefined' ? '0' : this.lstCartList[i].MerchantID) + '</MerchantID>'
            + '<ProductSKU>' + (typeof (this.lstCartList[i].ProductCode) == 'undefined' ? '' : this.lstCartList[i].ProductCode) + '</ProductSKU>'
            + '<ProductImage>' + (typeof (this.lstCartList[i].ProductImage) == 'undefined' ? '' : this.lstCartList[i].ProductImage) + '</ProductImage>'
            + '<ExtraPieces>' + 0 + '</ExtraPieces>'
            + '<ParentID>0</ParentID>'
            + '<IsWishListItem>' + (typeof (this.lstCartList[i].IsWishListItem) == 'undefined' ? '' : this.lstCartList[i].IsWishListItem) + '</IsWishListItem>'
            + '<ProductName>' + (typeof (this.lstCartList[i].ProductName) == 'undefined' ? '' : this.lstCartList[i].ProductName) + '</ProductName>'
            + '<ProductOptions>' + (typeof (this.lstCartList[i].ProductOptions) == 'undefined' ? '' : this.lstCartList[i].ProductOptions) + '</ProductOptions>'
            + '<Qty>' + (typeof (this.lstCartList[i].Qty) == 'undefined' ? '0' : this.lstCartList[i].Qty) + '</Qty>'
            + '<Price>' + (typeof (this.lstCartList[i].SalesPrice) == 'undefined' ? '0' : this.lstCartList[i].SalesPrice) + '</Price>'
            // +'<ProductTax>'+(typeof(this.lstCartList[i].ProductTax)=='undefined'?'0':this.lstCartList[i].ProductTax)+'</ProductTax>'
            // +'<ProductDiscount>'+(typeof(this.lstCartList[i].ProductDiscount)=='undefined'?'0':this.lstCartList[i].ProductDiscount)+'</ProductDiscount>'
            + '<ProductTax>' + (+((+this.objCart.CGSTAmt) + (+this.objCart.SGSTAmt)) / this.lstCartList.length) + '</ProductTax>'
            + '<ProductDiscount>' + ((+this.objCart.CouponDiscount) + (+this.objCart.OrderDiscount) / this.lstCartList.length) + '</ProductDiscount>'
            + '<ProductNetTotal>' + (typeof (this.lstCartList[i].ProductNetTotal) == 'undefined' ? '0' : this.lstCartList[i].ProductNetTotal) + '</ProductNetTotal>'
            + '<OrderDetailsStatus>Open</OrderDetailsStatus>'
            + '</Table1>'


        if ((typeof (this.lstCartList[i].ExtraPieces) == 'undefined' ? '' : this.lstCartList[i].ExtraPieces) != '') {
          var lstChild = this.lstCartList[i].lstSet;
          for (let c = 0; c < lstChild.length; c++) {
            rows = rows + '<Table1>'
              + '<OrderID>' + (typeof (this.lstCartList[i].OrderID) == 'undefined' ? '0' : this.lstCartList[i].OrderID) + '</OrderID>'
              + ' <OrderDetailsID>0</OrderDetailsID>'
              + '<ProductOptionID>' + (typeof (this.lstCartList[i].ProductOptionID) == 'undefined' ? '0' : lstChild[c].ProductOptionID) + '</ProductOptionID>'
              + '<ExpectedDeliveryDays>' + '' + '</ExpectedDeliveryDays>'
              + '<MerchantID>' + (typeof (lstChild[c].MerchantID) == 'undefined' ? '0' : lstChild[c].MerchantID) + '</MerchantID>'
              + '<ProductSKU>' + (typeof (lstChild[c].ProductCode) == 'undefined' ? '' : lstChild[c].ProductCode) + '</ProductSKU>'
              + '<ProductImage>' + (typeof (lstChild[c].ProductImage) == 'undefined' ? '' : lstChild[c].ProductImage) + '</ProductImage>'

              + '<ExtraPieces>' + (typeof (lstChild[c].ExtraPieces) == 'undefined' ? '' : lstChild[c].ExtraPieces) + '</ExtraPieces>'
              + '<ParentID>' + (typeof (lstChild[c].ParentID) == 'undefined' ? '0' : lstChild[c].ParentID) + '</ParentID>'

              + '<IsWishListItem>' + (typeof (lstChild[c].IsWishListItem) == 'undefined' ? '' : lstChild[c].IsWishListItem) + '</IsWishListItem>'
              + '<ProductName>' + (typeof (lstChild[c].ProductName) == 'undefined' ? '' : lstChild[c].ProductName) + '</ProductName>'
              + '<ProductOptions>' + (typeof (lstChild[c].ProductOptions) == 'undefined' ? '' : lstChild[c].ProductOptions) + '</ProductOptions>'

              + '<Qty>' + (typeof (lstChild[c].Qty) == 'undefined' ? '0' : lstChild[c].Qty) + '</Qty>'
              + '<Price>' + (typeof (lstChild[c].SalesPrice) == 'undefined' ? '0' : lstChild[c].SalesPrice) + '</Price>'
              + '<ProductTax>' + (typeof (lstChild[c].ProductTax) == 'undefined' ? '0' : lstChild[c].ProductTax) + '</ProductTax>'
              + '<ProductDiscount>' + (typeof (lstChild[c].ProductDiscount) == 'undefined' ? '0' : lstChild[c].ProductDiscount) + '</ProductDiscount>'
              + '<ProductNetTotal>' + (typeof (lstChild[c].ProductNetTotal) == 'undefined' ? '0' : lstChild[c].ProductNetTotal) + '</ProductNetTotal>'
              + '<OrderDetailsStatus>Open</OrderDetailsStatus>'
              + '</Table1>'
          }
        }
      }
    }
    if (rows == "") {
      xml2 = '<NewDataSet><Table1></Table1></NewDataSet>';
    } else {

      xml2 = '<NewDataSet>' + rows + '</NewDataSet>';
    }

    var xml3 = '<NewDataSet><Table1>'
      + '<UserEmail>' + this.loginUserDetails.UserEmail + '</UserEmail>'
      + '<UserPassword>' + this.loginUserDetails.UserPassword + '</UserPassword>'
      + '<UserFirstName>' + this.loginUserDetails.UserFirstName + '</UserFirstName>'
      + '<UserLastName>' + this.loginUserDetails.UserLastName + '</UserLastName>'
      + '<UserCity>' + this.loginUserDetails.UserCity + '</UserCity>'
      + '<UserState>' + this.loginUserDetails.UserState + '</UserState>'
      + '<UserZip>' + this.loginUserDetails.UserZip + '</UserZip>'
      + '<UserEmailVerified>' + this.loginUserDetails.UserEmail + '</UserEmailVerified>'
      + '<UserCountry>' + this.loginUserDetails.UserCountry + '</UserCountry>'
      + '<UserAddress>' + this.loginUserDetails.UserAddress + '</UserAddress>'
      + '<UserMobile>' + this.loginUserDetails.UserMobile + '</UserMobile>'
      + '<IsGuestUser>' + this.IsguestUser + '</IsGuestUser>'
      + '</Table1></NewDataSet>';

    //debugger
    this.apiCall.DBCalling('SaveOrder', xml1, xml2, xml3, '').subscribe(
      (res) => {
        //debugger
 
        $("#loaderParent").hide();
        
        this.DbResult = (Object.assign([], res));

        if (this.DbResult.tasks[0].length > 0) {
          //debugger
          var DbResult = this.DbResult;
          var lst = DbResult.tasks[0][0];
          this.OrderNo = DbResult.tasks[0][0].OrderTrackingNumber;
          this.OrderDate = DbResult.tasks[0][0].OrderDate;
          this.orderTotal = (+ (((+this.objCart.OrderNetTotal) - ((+this.objCart.CouponDiscount))
          ) + (((+this.objCart.DeliveryCharges) - (+this.deliveryDiscount)) > 0 ? ((+this.objCart.DeliveryCharges) - (+this.deliveryDiscount)) : 0)).toFixed(0));
          if (typeof sessionStorage !== 'undefined') {
          sessionStorage.removeItem('orderno');
          sessionStorage.setItem('OrderItemCount', this.lstCartList.length.toString());
          sessionStorage.setItem('orderno', this.OrderNo);
          sessionStorage.setItem('ordertTotal', this.orderTotal.toString());
          sessionStorage.setItem('successOrderNo',this.OrderNo );
          sessionStorage.setItem('successUserEmail', this.loginUserDetails.UserEmail);
          sessionStorage.setItem('successUserPhone', this.loginUserDetails.UserMobile);
          }

          this.detailsArray = { 'OrderNo': this.OrderNo, 'OrderDate': this.OrderDate, 'orderTotal': this.orderTotal, 

          'viewName': 'orderDetails' };
          this.store.dispatch(new PageStore.OpenPage(Object.assign(Object.assign([], this.detailsArray))));
          if (PaymentMode == 'CardPayment') {
            this.routerUrl.navigateByUrl('/eazypaypro');
            
            
            // .then((e)=>
            // {
            //   window.location.reload();
            // });
          } else {
            //debugger
            this.router.navigate(['/home'], { queryParams: { OrderStatus: 'Open' } });
          }
          // PAYPAL calling method  from itself own component
          // this.PaypalPaymentgateway(this)
          // this.objPaymentGatway.loadPaymentGatway(this);

          //  authorize.net payment calling from paymentgetwaymodel
          // this.paymentdisplay=PaymentgatwayModel.paymentdisplay;
          // this.objPaymentGatway.loadPaymentGatway(this);

          //  stripe payment gateway 
          // this.paymentdisplay=PaymentgatwayModel.paymentdisplay
          // this.objPaymentGatway.loadPaymentGatway(this);


          // razor pay 
          // this.paymentdisplay=PaymentgatwayModel.paymentdisplay;
          // this.objPaymentGatway.loadPaymentGatway(this);

        }
      });
  }

  cod() {
    //debugger
  }

  buttonText: string = "Apply"
  format(state) {
     
    var $state = $(
      '<span>' +
      state.val +
      '</span>'
    );
    return $state;
    
  };
lstCouponList=[];
 
  LoadCouponDropdown(sstring) {
     
    this.lstCouponList=[];
    $("#loaderParent").show();
    
    //  OrderPaymentDetailsUpdate
 
    this.apiCall.DBCalling('ViewCouponDiscounts', sstring, this.loginUserDetails.UserID, '', '').subscribe(
      (res) => {
        debugger;
        $("#loaderParent").hide();
        this.couponstatus=false;
        this.DbResult = (res);

        if (this.DbResult.tasks.length > 0) {

                  var ResultData = ((this.DbResult.tasks[0]));

          var data = $.map(ResultData, function (obj) {

             
            obj.id = obj.PromoCode;
            obj.label =obj.DiscountName;
            obj.value =  obj.PromoCode;
            obj.text =  obj.PromoCode;
            return obj;
          });

         this. lstCouponList=data;

          // return {


          //   results: data

          // };
           
        }
     //   return null;
      });
    //  debugger;
   
    // var jq = jQuery.noConflict();
    // var thats = this;
    // jq(document).ready(function() {
    //   debugger;
    //   var that = thats;
    //   jq(".drpCoupon").select2({
    //   allowClear: true,

    //   placeholder: "Search for Coupon",
    //   ajax: {
    //     url: that.storeSettings.apiCallingUrl + 'ValuePass.php',
    //     type: "POST",
    //     dataType: 'json',
    //     delay: 250, minimumInputLength: 4,
    //     data:
    //       function (params) {

    //         var sstring = "";
    //         if (params.term != undefined) {
    //           sstring = params.term;
    //         }

    //         return JSON.stringify({ "Operation": 'ViewCouponDiscounts', "Params": sstring, "Xml2": that.loginUserDetails.UserID, "Xml3": '', "Xml4": '' })

    //       }
    //     ,
    //     contentType: 'application/json; charset=utf-8',
    //     processResults: function (response) {

    //       var ResultData = ((response.tasks[0]));

    //       var data = $.map(ResultData, function (obj) {

    //         // obj.PromoCode = obj.PromoCode; 
    //         obj.id = 0;
    //         obj.text = obj.PromoCode;
    //         obj.val = obj.DiscountName;

    //         return obj;
    //       });



    //       return {


    //         results: data

    //       };
    //     },
    //     cache: false

    //   }
    //   , templateResult: this.format

    // });


    
    // jq('.drpCoupon').on('select2:open', function (e) {


    //   jq('.drpCoupon').empty();


    // });


     
    // jq('.drpCoupon').on('select2:select', function (e) {


    //   if (typeof ((<any>e).params.data.id) != 'undefined') {

    //     that.AppliedCouponCode = (<any>e).params.data.PromoCode;

    //   }


    // });

    // jq(".drpCoupon").on("select2:unselecting", function (e) {

    //   that.AppliedCouponCode = '';



    // });
    // })
  }

  CouponCodeChange(e) {
 
    $("#txtCouponCode").val(e.target.value);
    $("input[name='txtCouponCode']").val(e.target.value);
    
  }

  PaypalPaymentsuccess(data) {

    var orderid = this.DbResult.tasks[0][0].OrderID
    console.log(sessionStorage)
     
    $("#loaderParent").show();
    
    //  OrderPaymentDetailsUpdate
    this.apiCall.DBCalling('OrderPaymentDetailsUpdate', orderid, data.payerID, data.orderID, data.paymentSource).subscribe(
      (res) => {
        
        $("#loaderParent").hide();
        
        this.DbResult = (res);

        if (this.DbResult.tasks.length > 0) {

          // this.sweetalert()
          this.router.navigateByUrl('thankyoupage');
          this.paymentdisplay = 'none';
        }
      });
  }

  Razorpaymentsuccess(Paymentrefid) {

    var orderid = this.DbResult.tasks[0][0].OrderID;
 
    $("#preloader").show();
    
    //  OrderPaymentDetailsUpdate
    this.apiCall.DBCalling('OrderPaymentDetailsUpdate', orderid, Paymentrefid, Paymentrefid, Paymentrefid).subscribe(
      (res) => {

        this.DbResult = (res);
        if (this.DbResult.tasks.length > 0) {

          this.apiCall.SendEmail((this.loginUserDetails.UserFirstName + this.loginUserDetails.UserLastName), this.loginUserDetails.UserEmail, 'Order Summary', this.lstCartList);
          this.lstCartList = [];
          this.claculateDeliveryDiscount();
          this.objCart.CalcTotals(this.lstCartList)
          this.store.dispatch(new PageStore.OpenPage({ viewName: 'CartList', lstCartList:Object.assign([],  this.lstCartList) }));
          this.paymentdisplay = 'none';

          this.router.navigateByUrl('/thankyoupage')
         
          $('#preloader').hide();
          
        }
      });
  }

  sweetalert() {
    Swal.fire({
      icon: 'success',
      title: 'Thank you!...',
      text: 'Your order placed successfully!..',
    });
    this.router.navigateByUrl('thankyoupage');
  }
scroollToTop()
{
  
   
        window.scrollTo(0, 0)

    
  }
  ngAfterViewInit() {
    
   
    //this.LoadCouponDropdown()



    //   PaymentgatwayModel.pageComponent = this;
    if (this.NavigateFrom == 'BuyNow') {
      this.BuyDetails()
    } else {
      this.cartDetails();
    }
    this.scroollToTop();
  }

  home(){
    this.router.navigateByUrl('home');
  }


  paymentMode(d){
    if(this.selectedPaymentMode == 'Online Payment'){
      this.selectedPaymentMode = "COD";
    }else{
      this.selectedPaymentMode = "Online Payment";
    }
    this.claculateDeliveryDiscount();
  }
  getTotalAmount(){
    const orderNetTotal = (+this.objCart.OrderNetTotal);
    const couponDiscount = (+this.objCart.CouponDiscount);
    const deliveryCharges = (+this.objCart.DeliveryCharges);
    const deliveryDiscount = (+this.deliveryDiscount);
    const deliveryChargeAfterDiscount = deliveryCharges - deliveryDiscount > 0 ? deliveryCharges - deliveryDiscount : 0;
    const totalCost = orderNetTotal - couponDiscount + deliveryChargeAfterDiscount;
    const roundedTotalCost = parseFloat(totalCost.toFixed(0));
     return roundedTotalCost;
  }
  ngDoCheck(){
    let cartTotal = this.getTotalAmount();
    if(cartTotal >  2000){
      this.selectedPaymentMode = "Online Payment";
    }
  }
}