<h2 class="offer">TERMS & CONDITIONS POLICY</h2>

<div class="container">
    <p>Welcome to our <strong>BLACK DENIM Website</strong>. These Terms and Condition Policy outline the rules and regulations for
        the use of our website.</p>

<p>PLEASE READ THESE TERMS AND CONDITIONS (“TERMS OF USE”) CAREFULLY BEFORE USING <strong>BLACK DENIM INDUSTRIES PVT LTD</strong> WEBSITE. BY USING THIS WEBSITE, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THESE TERMS OF USE. THESE TERMS OF USE MAY BE AMENDED OR CHANGED BY US AT ANY TIME AT OUR DISCRETION. YOU AGREE THAT YOUR CONTINUED USE OF THIS WEBSITE AFTER ANY SUCH AMENDMENT OR CHANGE SHALL CONSTITUTE YOUR AGREEMENT TO ANY SUCH CHANGES.</p>
<p> PLEASE NOTE THAT THESE TERMS OF USE CONTAIN TERMS AND CONDITIONS APPLICABLE TO VARIOUS BRANDS AVAILABLE ON THIS WEBSITE. WE REQUEST YOU TO READ THOSE TERMS AND CONDITIONS BY CLICKING THE LINKS AVAILABLE HEREIN. YOUR ACCEPTANCE OF THESE TERMS OF USE WILL BE CONSIDERED DEEMED ACCEPTANCE OF THE TERMS AND CONDITIONS APPLICABLE TO VARIOUS BRANDS AVAILABLE ON THIS WEBSITE.</p>


<h1></h1>
<h2></h2>
<h3></h3>
<h4></h4>
</div>