<h2 class="offer">About us</h2>

<!-- <div class="container">
    <h2>MADE IN INDIA </h2>
    <p>OUR FACLITY <strong>ESTABLISHED 2016</strong></p>
    <h2>STORES Expanding & Evolving </h2>
    <p>Denim is a rugged, sturdy, twill-weave cotton fabric made of different coloured wefts and warps threads
        <br>(blue, Black and white colored threads) That’s <strong>BLACK DENIM</strong></p>

        <h2>INTRODUCTION AND OVERVIEW OF BLACK DENIM:</h2>
        <p>This policy applies to all the <strong>BLACK DENIM</strong> platforms, which is operated and owned by <strong>Black Denim Industries Pvt. Ltd.</strong>marketed and/or managed by Black Denim Industries Pvt. Ltd. It is <strong>BLACK DENIM</strong> policy to comply with general laws for protecting user information and bank details shared for the purpose of availing BLACK DENIM services. This regulates the processing of information relating to you and grants you various rights in respect of your personal data.
            Any Images, Data or Files Uploaded on the website must not be used without the consent of the authorized personnel of the brand.
            The Web Site contains links to other websites over which we have no control. 
            <br><strong>BLACK DENIM</strong> is not responsible for the privacy policies or practices of other web sites to which you choose to link from blackdenim.in. We encourage you to review the privacy policies of those other web sites so you can understand how they collect, use and share your information.</p>
</div> -->

<div class="container">
    <div class="row">
        <div class="col-md-12">
                <p class="mb-5">Welcome to Black Denim, your one stop fashion destination from the vibrant streets of Hyderabad. With 11 beloved offline brand stores scattered across the city, we've become synonymous with style, quality, and unbeatable service.</p>
                <p class="mb-5">Black Denim Industries Pvt. Ltd started its journey in 2016, with a simple thought in mind to share the joy of fashion in its true form. As we believe that every detail matters, every stitch counts, and every outfit tells its story. From picking quality fabrics to the latest designs, each item in our collection is a testimonial to dedication, craftsmanship and quality.</p>
                <p class="mb-5">Are you a fashion enthusiast or simply someone who loves to express yourself through fashion, Here is your Home. Black Denim is not just a brand – we're a family, a fashion community, united by the passion for style and commitment to authenticity. </p>
                <p class="mb-5">Looking for the perfect pair of jeans, or stylish shirts for men, or trending hoodies to elevate your everyday look, we've got you covered. We at Black Denim understand that fashion is personal, and offer a wide range of collections that cater to every taste and preference.</p>
                <p class="mb-5">Our commitment to you goes beyond just style. We're dedicated to making a positive impact on the world around us..</p>
                <p class="mb-5">So whether you're a long-time fan of Black Denim or recently get to know about us for the first time, we invite you to explore our collection. Just feel the fabric and designs and embrace our collection, and join us on this exciting journey. With Black Denim, the possibilities are endless, and the adventure is just beginning. Happy Shopping!</p>
        </div>
    </div>
</div>

<h1></h1>
        <h2></h2>
        <h3></h3>
        <h4></h4>