import { Component, OnInit } from '@angular/core';
import { APICallingService } from '../model/APICallingService';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contactdetails',
  templateUrl: './contactdetails.component.html',
  styleUrls: ['./contactdetails.component.css']
})
export class ContactdetailsComponent implements OnInit {

  submit  : boolean = false;
  contact = {
    name : '',
    email : '',
    phone : '',
    message : '',
    orderid : 0
  }

  active : string = "form";

  constructor( private apiCall: APICallingService,private router:Router) { }

  ngOnInit(): void {
    this.view();
  }


  send(){
    debugger;
    this.submit = true;
    if(this.contact.name!='' && this.contact.phone !='' && this.validateEmail(this.contact.email)){
      debugger;
      var xml1 = '<NewDataSet><Table1>'
      + '<Name>' + this.contact.name + '</Name>'
      + '<Email>' + this.contact.email + '</Email>'
      + '<Phone>' + this.contact.phone + '</Phone>'
      + '<OrderID>' + this.contact.orderid + '</OrderID>'
      + '<Message>' + this.contact.message + '</Message>'
      + '</Table1></NewDataSet>';
      debugger;
      this.apiCall.DBCalling('SaveContact', xml1, '', '', '').subscribe(
        (res:any) => {
          debugger;
          let result = res;
          if(result.tasks[0][0].DBresult){
            this.active='thanks';
            this.resetForm();
          }
        })
    }
  }

  validateEmail(input: any): boolean {
    var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    var res = regexp.test(input)
    return res;
  }


  view(){
    debugger;
    this.apiCall.DBCalling('ViewContacts', '', '', '', '').subscribe(
      (res) => {
        debugger;
        // this.resetForm();
      })
  }


  resetForm(){
    this.submit = false;
    this.contact =  {
      name : '',
      email : '',
      phone : '',
      message : '',
      orderid : 0
    }
  }


  home(){
    this.active='form';
    this.router.navigateByUrl('home');
  }
}
