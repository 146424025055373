  <main class="main__content_wrapper mb-5">
        <div class="container">
            <div class="row">
                <div class="col-md-7 mx-auto">
                    <main class="main__content_wrapper ">
                        <form action="#">
                            <div class="checkout__content--step section__shipping--address">
                                <div class="section__header">
                                    <h3 class="section__header--title mb-25">Address</h3>
                                </div>
                                <div class="section__shipping--address__content">
                                    <div class="row">
                                        <div class="col-12 mb-12">
                                            <div class="checkout__input--list ">
                                                <label>
                                                    <input class="checkout__input--field border-radius-5" placeholder="Name" type="text">
                                                </label>
                                            </div>
                                        </div>
                                        
                                        <div class="col-12 mb-12">
                                            <div class="checkout__input--list">
                                                <label>
                                                    <input class="checkout__input--field border-radius-5" placeholder="Email address" type="email">
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-12">
                                            <div class="checkout__input--list">
                                                <label>
                                                    <input class="checkout__input--field border-radius-5" placeholder="Mobile number" type="number">
                                                </label>
                                            </div>
                                        </div>
                                     
                                        <div class="col-12 mb-12">
                                            <div class="checkout__input--list">
                                                <label>
                                                    <input class="checkout__input--field border-radius-5" placeholder="House number" type="text">
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-12">
                                            <div class="checkout__input--list">
                                                <label>
                                                    <input class="checkout__input--field border-radius-5" placeholder="RoadName/AreaColony" type="text">
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 mb-12">
                                            <!-- <div class="checkout__input--list checkout__input--select select">
                                                <label class="checkout__select--label" for="country">Country/region</label>
                                                <select class="checkout__input--select__field border-radius-5" id="country">
                                                    <option value="1">India</option>
                                                    <option value="2">United States</option>
                                                    <option value="3">Netherlands</option>
                                                    <option value="4">Afghanistan</option>
                                                    <option value="5">Islands</option>
                                                    <option value="6">Albania</option>
                                                    <option value="7">Antigua Barbuda</option>
                                                </select>
                                            </div> -->
                                            <div class="checkout__input--list">
                                                <label>
                                                    <input class="checkout__input--field border-radius-5" placeholder="Country" type="text">
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-12">
                                            <div class="checkout__input--list">
                                                <label>
                                                    <input class="checkout__input--field border-radius-5" placeholder="State" type="text">
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-12">
                                            <div class="checkout__input--list">
                                                <label>
                                                    <input class="checkout__input--field border-radius-5" placeholder="City" type="text">
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-12">
                                            <div class="checkout__input--list">
                                                <label>
                                                    <input class="checkout__input--field border-radius-5" placeholder="Pin" type="number">
                                                </label>
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-6 mb-12">
                                            <div class="checkout__input--list">
                                                <label>
                                                    <input class="checkout__input--field border-radius-5" placeholder="Landmark" type="text">
                                                </label>
                                            </div>
                                        </div> -->
                                        <div class="col-12 mb-12">
                                            <div class="checkout__input--list">
                                                <label>
                                                    <input class="checkout__input--field border-radius-5" placeholder="Address" type="text">
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-12">
                                            <div class="checkout__input--list">
                                                <label>
                                                    <input class="checkout__input--field border-radius-5" placeholder="Landmark" type="text">
                                                </label>
                                            </div>
                                        </div>
                                      
                                        <div class="checkout__content--step__footer d-flex align-items-center mt-20">
                                            <a class="continue__shipping--btn primary__btn border-radius-5" href="thank-you.html"style="margin-right: 20px;">Save</a>
                                            <a class="continue__shipping--btn primary__btn border-radius-5" href="thank-you.html">Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </main>
                    </div>
            </div>
        </div>

        <h1></h1>
        <h2></h2>
        <h3></h3>
        <h4></h4>
     </main>