<section>

    <div class="container py-5">
        <div class="row">
        <div class="col-md-4">
            <div class="col-md-12">
                <h3 class="mb-2">Customer Reviews</h3>
                    <span [ngClass]="rate >= 1 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                    <span [ngClass]="rate >= 2 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                    <span [ngClass]="rate >= 3 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                    <span [ngClass]="rate >= 4 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                    <span [ngClass]="rate >=5 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                    <span data-hook="rating-out-of-text" class="a-size-medium a-color-base fs-17 ms-3">{{rate}} out of 5</span>
    
                    <p class="fs-13">{{totalRating}} global ratings</p>
                    <div class="row mt-4">
                        <!-- rating 5 -->
                        <div class="col-md-3 pe-0">
                            <!-- <p></p> -->
                            <span class="rt-num">5 Star</span>
                        </div>
                        <div class="col-md-7 my-auto p-0">
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" [style.width.%]="ratings.rate5" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <span class="rt-num">{{ratings.rate5}}%</span>
                        </div>
                        <!-- rating 5 -->


                        <!-- rating 4 -->
                        <div class="col-md-3 pe-0">
                            <!-- <p></p> -->
                            <span class="rt-num">4 Star</span>
                        </div>
                        <div class="col-md-7 my-auto p-0">
                             <div class="progress">
                                <div class="progress-bar" role="progressbar" [style.width.%]="ratings.rate4" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <span class="rt-num">{{ratings.rate4}}%</span>
    
                        </div>
                        <!-- rating 4 -->
    
                        <!-- rating 3 -->
                        <div class="col-md-3 pe-0">
                            <!-- <p></p> -->
                            <span class="rt-num">3 Star</span>
                        </div>
                        <div class="col-md-7 my-auto p-0">
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" [style.width.%]="ratings.rate3" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <span class="rt-num">{{ratings.rate3}}%</span>
    
                        </div>
                        <!-- rating 3 -->
    
                        <!-- rating 2 -->
                        <div class="col-md-3 pe-0">
                            <!-- <p></p> -->
                            <span class="rt-num">2 Star</span>
                        </div>
                        <div class="col-md-7 my-auto p-0">
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" [style.width.%]="ratings.rate2" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <span class="rt-num">{{ratings.rate2}}%</span>
    
                        </div>
                        <!-- rating 2 -->
    
                        <!-- rating 1 -->
                        <div class="col-md-3 pe-0">
                            <!-- <p></p> -->
                            <span class="rt-num">1 Star</span>
                        </div>
                        <div class="col-md-7 my-auto p-0">
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" [style.width.%]="ratings.rate1" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <span class="rt-num">{{ratings.rate1}}%</span>
                        </div>
                        <!-- rating 1 -->
                    </div>
                        
                    
            </div>
        </div>
        <div class="col-md-8">
            <!-- <div class="row bg">
                <div class="col-md-12">
                    <h3>Reviews with images</h3>
                </div>
            </div>
           
            <div class="row mx-auto mt-5">
                <div class="col-md-12 mt-3" >
                  


                    <ngx-slick-carousel [config]="SlideOptions2" class="owl-theme sliding carousel">
                        <ng-container *ngFor="let d of reviews">
                          <div class="item" *ngIf="d.Image != ''">
                            <div class="top-content">
                              <img [src]="ImagePath + d.Image" alt="Black denim review" id="Image" class="" onerror="this.onerror=null;this.src='./assets/img/other/noimagepreview.png'" />
                            </div>
                          </div>
                          <div class="item" *ngIf="d.Image1 != ''">
                            <div class="top-content">
                              <img [src]="ImagePath + d.Image1" alt="Black denim review" id="Image1" class="" onerror="this.onerror=null;this.src='./assets/img/other/noimagepreview.png'" />
                            </div>
                          </div>
                          <div class="item" *ngIf="d.Image2 != ''">
                            <div class="top-content">
                              <img [src]="ImagePath + d.Image2" alt="Black denim review" id="Image2" class="" onerror="this.onerror=null;this.src='./assets/img/other/noimagepreview.png'" />
                            </div>
                          </div>
                        </ng-container>
                      </ngx-slick-carousel>
                      

                </div>
    
            </div> -->
        <div class="row mt-5">
            
            <div class="col-md-12 mt-5 mb-4">
                <h3>Top Reviews from India</h3>
            </div>
    
            <div class="col-md-12 mb-3" *ngFor="let d of reviews">
                <div  class="card p-3">
                    <div  class="row">
                        <div  class="col-md-12 mb-3">
                            <p class="mb-3">
                                <span class="new_ratings mb-3"><i  class="fa fa-star" aria-hidden="true"></i>{{d.Rating}}</span>
                            </p>
                            <h4 >{{d.CustomerName}}</h4>
                            <p  class="review">{{d.Reason}}</p>
                            </div>
                        <div  class="col-md-2 col-3" *ngIf="d.Image!=''">
                            <img  alt="Black denim product review" class="w-100 mb-3 prd_review_img" src="{{ImagePath+d.Image}}" onerror="this.onerror=null;this.src='./assets/img/other/noimagepreview.png'">
                        </div>
                        <div  class="col-md-2 col-3" *ngIf="d.Image1!=''">
                            <img  alt="Black denim product review" class="w-100 mb-3 prd_review_img" src="{{ImagePath+d.Image1}}" onerror="this.onerror=null;this.src='./assets/img/other/noimagepreview.png'">
                        </div>
                        <div  class="col-md-2 col-3" *ngIf="d.Image2!=''">
                            <img  alt="Black denim product review" class="w-100 mb-3 prd_review_img" src="{{ImagePath+d.Image2}}" onerror="this.onerror=null;this.src='./assets/img/other/noimagepreview.png'">
                        </div>
                    </div>
                </div>
            </div>
    
        </div>
    
            
    </div>
        </div>
    </div>
    </section>