<main class="main__content_wrapper mb-5">
    <div class="container">
        <div class="row" *ngIf="successOrderNo !='' && successUserEmail !='' && successUserPhone !=''">
            <div class="col-md-12 text-center">
                <div class="col-md-12 pt-5"><b class="fs-32">Thank you For Your order</b> </div>
                <img src="./assets/img/success.gif" 
                alt="Black Denim - Thank you for your order">
                <h3>Order Placed Successfully</h3>
                <p ><b>Order ID</b> : {{successOrderNo}}</p>
                <p ><b>Email</b> : {{successUserEmail}}</p>
                <p  class="mb-4"><b>Phone</b> : {{successUserPhone}}</p>

                <button class="btn btn-light mx-3 back" type="button"><a (click)="home()">Back To Home</a></button>
                <button class="btn btn-danger" type="button"><a (click)="orderClick()">View Orders</a></button>
            </div>
        </div>
    </div>
    <h1></h1>
<h2></h2>
<h3></h3>
<h4></h4>
 </main>