<div class="row">
    <div class="container mw-1690">
        <div class="row">
            <div class="col-6 col-md-3 p-0" *ngFor="let d of lstCombos">
                <div class="product__items user-profile"
                    (mouseover)="hover=true;productMouseOverCall(d.ID)" (mouseleave)="hover=false">
                    <div class="product__items--thumbnail figure">
                        <a class="product__items--link">
                            <img class="product__items--img product__primary--img"
                               
                                src="{{ImagePath+d.Image}}" alt="Buy online - {{d.ComboName}}">
                            <img class="product__items--img product__secondary--img"
                                src="{{ImagePath+d.Image}}" alt="Buy online - {{d.ComboName}}">
                        </a>
                    </div>
                    <div class="css-popover bg__black d-none d-lg-block" style="z-index: 9!important;">
                        <div class="row">
                          
                            <span >
                                <div class="col-md-12">
                                    <ul class="text-white" style="display:grid ;">
                                        <li *ngFor="let o of selectedProductOptions">- {{o.Product}}</li>
                                    </ul>
                                </div>
                            </span>
                            <div class="col-md-12 mt-4">
                                <h4 class="text-white"
                                    (click)="ProductClick(d)">
                                    <a>QUICK VIEW</a></h4>
                            </div>
                        </div>
                    </div>
                    <div class="product__items--content text-center">
                        <strong>{{d.ComboName}}</strong>
                        <!-- <div class="product__items--price">
                                <span class="current__price mx-2"><b class="rupee">Rs
                                    </b>{{d.ProductOptionID[0].Price}}</span>
    
    
                            </div> -->
    
                        <div class="product__items--price">
                            <span  class="current__price mx-2"><b  class="rupee">{{storeSettings.currency}} </b>{{(+d.SalesPrice).toFixed(0)}}</span>
                            <span class="text-danger"
                                *ngIf="((+d.SalesPrice)<(+d.Price))">
                                (<b class="rupee"><del>{{storeSettings.currency}}{{(+d.Price).toFixed(0)}}</del></b> )
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
