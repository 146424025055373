import { Component, HostListener, OnInit } from '@angular/core';

import { APICallingService } from '../model/APICallingService';
import { AppSettings, IAppSettings } from '../model/AppSettings.js';
import { ActivatedRoute } from '@angular/router';
import { BrowserService } from '../browser.service.js';

@Component({
  selector: 'app-product-review',
  templateUrl: './product-review.component.html',
  styleUrls: ['./product-review.component.css']
})
export class ProductReviewComponent implements OnInit {
  DProductID : number = 0;

  storeSettings:IAppSettings=
  {
      faxUserId:'',
      faxPassword:'',
      companyName:'',
      theamColorCode:'#ea0029',
      headerColorCode: '#000',
      headerimage: '../../assets/img/trending1.png',
      loginBackgroundImage:'../../assets/bg-1.jpg',
      loginImage:'../../assets/img/logo_web.png',
      dangerColorCode: '#dc3545',
      successColorCode: '#28a745',
      logo: '../../assets/img/logo_web.png',
      paymentGateway:'',
      key:'',
      selectedBranch:'',
      apiLoginId:'',
      orderEmail:'',
      adminUrl:'',
      orderUrl:'',
      viewName:'AppSettings',
      apiCallingUrl:'',
      fontFamily:'prompt,sans-serif',
      domainName:'',
      fax:'',
      projectId:'',
      keyNo:'',
      noOfBranches:0,
      currency:'Rs ',
      transactionkey:'',
  };
  
  ImagePath: string = "https://blackdenim.in/E-commerceWebAPI/ApplicationLevelScripts/Images/";
  constructor(private browserService: BrowserService,
    private apiCall: APICallingService,public appSettings:AppSettings,
    private route: ActivatedRoute) {
      if (this.browserService.isBrowser()) {
    this.storeSettings = this.appSettings.loadSettings();
    this.ImagePath = this.storeSettings.apiCallingUrl;
      
    this.route.queryParamMap.subscribe((params:any) => {
      //debugger
     this.DProductID= params.params.ID;
    });
  }
  }

  ngOnInit() {
    this.ViewMainCategory();

    //  https://blackdenim.in/E-commerceWebAPI/ApplicationLevelScripts/Images/Categories_BannerJeansTueAug012023204435GMT0530IndiaStandardTime.webp
  }




  
  SlideOptions2 =
  {
    slidesToShow: 3,
    dots: false,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    prevArrow: '<div style="background-size: 170%; position: absolute; top: 50%; margin-top: -1.6em; z-index: 999; height: 3em; width: 2.6em; background-image: url(./assets/img/slide/arrows.png);background-position: 0 0; left:10px;"></div>',
    nextArrow: '<div style="background-size: 170%; position: absolute; top: 50%; margin-top: -1.6em; z-index: 60; height: 3em; width: 2.7em; background-image: url(./assets/img/slide/arrows.png);right:10px;"></div>'
  };
  // {
  //   items: 3, dots: false, nav: true, loop: false, autoplay: false,
  //   autoplayTimeout: 3000,
  //   autoplayHoverPause: true,
  //   navText: ['<div  style="background-size: 170%; position: absolute; top: 50%; margin-top: -1.6em; z-index: 999; height: 3em; width: 2.6em; background-image: url(./assets/img/slide/arrows.png);background-position: 0 0; left:10px;" ></div>', '<div style="background-size: 170%; position: absolute; top: 50%; margin-top: -1.6em; z-index: 60; height: 3em; width: 2.7em; background-image: url(./assets/img/slide/arrows.png);right:10px; background-position: 100% 0;" ></div>'],
  // };

  SlideOptions3 = {
    items: 2, dots: false, nav: true, loop: true, autoplay: false,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    navText: ['<div  style="background-size: 170%; position: absolute; top: 50%; margin-top: -1.6em; z-index: 999; height: 3em; width: 2.6em; background-image: url(./assets/img/slide/arrows.png);background-position: 0 0; left:10px;" ></div>', '<div style="background-size: 170%; position: absolute; top: 50%; margin-top: -1.6em; z-index: 60; height: 3em; width: 2.7em; background-image: url(./assets/img/slide/arrows.png);right:10px; background-position: 100% 0;" ></div>'],
  };

  lstCategoriesMainDet: any = []



  mobile: boolean = false
  @HostListener('window:resize', [])
  onOrientationChange(event) {
    if (this.browserService.isBrowser()) {
      const window:any = this.browserService.getWindow();
    var width = window.innerWidth;
    if (width <= 768) {

      this.mobile = true;
      // window.scrollTo(0,0)
    } else {
      this.mobile = false;
    }
  }

  }

  totalRating: number = 0;
  ratings: any = [];
  reviews: any = [];
  rate : number = 0;

  ViewMainCategory() {
    //debugger
    this.apiCall.DBCalling("ratingsByProductId",typeof(this.DProductID)!='undefined'?   this.DProductID.toString():'0', "", "", "").subscribe(
      (res: any) => {
        //debugger
        let dbResult = res;
        this.totalRating = (+dbResult.tasks[0][0].TotalRating);
        this.ratings = (dbResult.tasks[1][0]);
        this.reviews = (dbResult.tasks[2]);


        //debugger
        let rate1= (+this.ratings.rate1) * 1;
        let rate2= (+this.ratings.rate2) * 2;
        let rate3= (+this.ratings.rate3) * 3;
        let rate4= (+this.ratings.rate4) * 4;
        let rate5= (+this.ratings.rate5) * 5;
         
        if(this.ratings.rate5){
          this.ratings.rate5 = (+this.ratings.rate5) > 0 ? (((+this.totalRating)/(+this.ratings.rate5))*(100)) : 0;
        }
        if(this.ratings.rate4){
          this.ratings.rate4 = (+this.ratings.rate4) > 0 ? (((+this.totalRating)/(+this.ratings.rate4))*(100)) : 0;
        }
        if(this.ratings.rate3){
          this.ratings.rate3 = (+this.ratings.rate3) > 0 ? (((+this.totalRating)/(+this.ratings.rate3))*(100)) : 0;
        }
        if(this.ratings.rate2){
          this.ratings.rate2 = (+this.ratings.rate2) > 0 ? (((+this.totalRating)/(+this.ratings.rate2))*(100)) : 0;
        }
        if(this.ratings.rate1){
          this.ratings.rate1 = (+this.ratings.rate1) > 0 ? (((+this.totalRating)/(+this.ratings.rate1))*(100)) : 0;
        }


        //debugger
        this.rate = (+rate1 + rate2 + rate3 + rate4  + rate5) / (+this.totalRating);
      });
  }

}
