<div >

<!-- 
    <div id="carouselExampleControls" class="carousel slide"  data-bs-ride="carousel" data-bs-interval="1000">
        <div class="carousel-inner">
          <div   *ngFor="let d of lstBanners;let ind=index" [class]="ind==0? 'carousel-item active':'carousel-item'">
            <img class="d-block w-100"  [src]="ImagePath + d.Image" alt="First slide">
          </div>
         
        </div>
        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div> -->

      <ngx-slick-carousel [config]="SlideOptions" class="carousel"   #slickModal="slick-carousel" >
        <div  class="slide" *ngFor="let d of lstBanners" ngxSlickItem >
          <div>
            <img (click)="BannerClick(d.BannerType, d.BannerTypeId, d.Image, d.BannersName, d.IsParentCategory)"
                 [src]="ImagePath + d.Image"
                 alt="Buy online - {{ d.BannersName }}"
                 class="main-slider w-100 cursor-pointer" />
          </div>
        </div>
      </ngx-slick-carousel>
 
   <!-- <ngx-slick-carousel [config]="SlideOptions"  class="carousel">
        <div class="baner-item" *ngFor="let d of lstBanners">
          <div>
            <img (click)="BannerClick(d.BannerType, d.BannerTypeId, d.Image, d.BannersName, d.IsParentCategory)"
                 [src]="ImagePath + d.Image"
                 alt="Buy online - {{d.BannersName}}"
                 class="main-slider w-100 cursor-pointer" />
          </div>
        </div>
      </ngx-slick-carousel>  -->

    <!-- <owl-carousel [options]="SlideOptions" [items]="lstBanners" [carouselClasses]="['owl-theme', 'sliding','main-home-slider']"
        id="myOwlCarousel ">
        <div class="baner-item" *ngFor="let d of lstBanners">
            <div>
                <img (click)="BannerClick(d.BannerType,d.BannerTypeId,d.Image,d.BannersName,d.IsParentCategory)"
                     src="{{ImagePath+d.Image}}"
                    alt="Buy online - {{d.BannersName}}" class="main-slider w-100 cursor-pointer" />
            </div>
        </div>
    </owl-carousel> -->

    <div *ngFor="let d of lstHomeScreanSettings;" class="lazy-image mt-15">
        <div class="row p-0" *ngIf="d.Region=='Top Full'">
            <div [class]="'col-md-12 p-0 mb-04'"
                *ngIf="d.LayoutOrientation=='Multi View Sliding' && d.LayoutViewType!='FlashMassage'  && d.LayoutViewType!='Category'  ">
                <!-- <owl-carousel [options]="{items:d.ItemsToShow , dots: false, navigation: false,autoplay:true,loop:true}"
                    [items]="d['Child']" [carouselClasses]="['owl-theme', 'sliding']">
                    <div class="brand__logo--section bg__secondary section--padding color-scheme-1"
                        *ngFor="let subItem of d['Child'];let i = index">
                        <div class="brand__logo--section__inner d-flex justify-content-center align-items-center">
                            <div class="brand__logo--items">
                                <a (click)="ProductDetByType(d.LayoutViewType,subItem.LayoutViewID,subItem.Title,subItem.Image)"
                                    class="product-item-photo cursor-pointer">
                                    <img class="product-image-photo" src="{{ImagePath+subItem.Image}}"
                                        onerror="this.onerror=null;this.src='./assets/img/other/noimagepreview.png'"
                                        alt="Buy online - {{subItem.Title}}" />
                                </a>
                            </div>
                        </div>
                    </div>
                </owl-carousel> -->


                <ngx-slick-carousel  
                  [config]="{ slidesToShow: 6, dots: false, autoplay: true, loop: true }" class="owl-theme sliding carousel">
                    <div class="brand__logo--section bg__secondary section--padding color-scheme-1" ngxSlickItem  *ngFor="let subItem of d['Child']; let i = index">
                      <div class="brand__logo--section__inner d-flex justify-content-center align-items-center">
                        <div class="brand__logo--items">
                          <a (click)="ProductDetByType(d.LayoutViewType, subItem.LayoutViewID, subItem.Title, subItem.Image)" class="product-item-photo cursor-pointer">
                            <img class="product-image-photo" [src]="ImagePath + subItem.Image"  alt="Buy online - {{ subItem.Title }}" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </ngx-slick-carousel>


            </div>

            <div [class]="'col-md-12 text-center'"
                *ngIf="d.LayoutOrientation=='Multi View Sliding' && d.LayoutViewType!='FlashMassage'  && d.LayoutViewType=='Category'">
                <div *ngFor="let subItem of d['Child']" class="related-product-area">
                    <div class="page-header mb-25">
                        <h3 class="name">{{subItem.Title}}</h3>
                    </div>
                    <!-- <owl-carousel
                        [options]="{items: d.ItemsToShow, dots: false, navigation: false,autoplay:true,loop:true}"
                        [items]="subItem['ChildDetails']" [carouselClasses]="['owl-theme', 'sliding']">
                        <div class="item" *ngFor="let subItemDet of subItem['ChildDetails'];let i = index">
                            <div class="product-item">
                                <div class="item-inner">
                                    <div class="product-thumbnail">
                                        <a (click)="ProductClick(subItemDet.ProductOptionID,subItemDet.ProductID,subItemDet.ProductImage,subItemDet.ProductName)"
                                            class="product-item-photo cursor-pointer">
                                            <img class="product-image-photo w-408 h-252" src="{{ImagePath+subItemDet.ProductImage}}"
                                                alt="Buy online - {{subItemDet.ProductName}}"
                                                />
                                        </a>
                                    </div>
                                    <div class="pro-box-info">
                                        <div class="item-info">
                                            <div class="info-inner">
                                                <div class="item-title cursor-pointer">
                                                    <a (click)="ProductClick(subItemDet.ProductOptionID,subItemDet.ProductID,subItemDet.ProductImage,subItemDet.ProductName)">{{subItemDet.ProductName}} </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="box-hover">
                                            <div class="product-item-actions">
                                                <div class="pro-actions">
                                                    <button class="action add-to-cart cursor-pointer" type="button" title="Shop Now"
                                                        (click)="ProductClick(subItemDet.ProductOptionID,subItemDet.ProductID,subItemDet.ProductImage,subItemDet.ProductName)"
                                                        >
                                                        <span>Shop Now </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </owl-carousel>
                 -->
                 <ngx-slick-carousel [config]="{ slidesToShow: d.ItemsToShow || 1, dots: false, autoplay: true, loop: true }" class="owl-theme sliding">
                    <div class="item" *ngFor="let subItemDet of subItem['ChildDetails']; let i = index">
                      <div class="product-item">
                        <div class="item-inner">
                          <div class="product-thumbnail">
                            <a (click)="ProductClick(subItemDet.ProductOptionID, subItemDet.ProductID, subItemDet.ProductImage, subItemDet.ProductName)" class="product-item-photo cursor-pointer">
                              <img class="product-image-photo w-408 h-252" [src]="ImagePath + subItemDet.ProductImage" alt="Buy online - {{subItemDet.ProductName}}" />
                            </a>
                          </div>
                          <div class="pro-box-info">
                            <div class="item-info">
                              <div class="info-inner">
                                <div class="item-title cursor-pointer">
                                  <a (click)="ProductClick(subItemDet.ProductOptionID, subItemDet.ProductID, subItemDet.ProductImage, subItemDet.ProductName)">{{subItemDet.ProductName}}</a>
                                </div>
                              </div>
                            </div>
                            <div class="box-hover">
                              <div class="product-item-actions">
                                <div class="pro-actions">
                                  <button class="action add-to-cart cursor-pointer" type="button" title="Shop Now" (click)="ProductClick(subItemDet.ProductOptionID, subItemDet.ProductID, subItemDet.ProductImage, subItemDet.ProductName)">
                                    <span>Shop Now</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ngx-slick-carousel>
                  
                </div>
            </div>
            <div [class]="'col-md-12 '" *ngIf="d.LayoutViewType=='FlashMassage'">
                <p>
                    <marquee class="marquee-class">
                        {{d.SubTiltle}}</marquee>
                </p>
            </div>
            <div [class]="'col-md-12 mt-3'" *ngIf="d.LayoutOrientation=='Horizontal' && d['Child'].length>0">
                <section class="bg-color" *ngFor="let subItem of d['Child'];let i = index">
                    <div class="row">
                        <div class="col-md-12">
                            <a [href]="subItem.Subtitle" class="w-100"> <img src="{{ImagePath+subItem.Image}}"
                                    alt="Buy online - {{subItem.Title}}" class="w-100" /> </a>
                        </div>
                    </div>
                </section>
            </div>
            <div class="row"
                *ngIf="d.LayoutViewType!='Category' && d.LayoutViewType!='Product'   &&  d.LayoutViewType!='FlashMassage'  && d.LayoutOrientation!='Horizontal'    &&  d.LayoutOrientation!='Multi View Sliding' ">
                <div *ngFor="let subItem of d['Child']" [attr.class]="(d.LayoutOrientation=='Two Box Layout' ? 'col-md-6 mx-auto' : (d.LayoutOrientation=='Three Box Layout' ? 'col-md-4 mx-auto':(
                              (d.LayoutOrientation=='Four Box Layout' ? 'col-md-3 mx-auto':
                              (d.LayoutOrientation=='Six Box Layout' ? 'col-md-2 mx-auto':'col-md-12 mx-auto') ))))">
                    <div *ngIf="subItem.Title!='' ">
                        <a (click)="ProductDetByType(d.LayoutViewType,subItem.LayoutViewID,subItem.Title,subItem.Image)"
                            class="cursor-pointer">
                            <img class="img-respo class-img" alt="Buy online - {{subItem.Title}}"
                                src="{{ImagePath+subItem.Image}}"
                                 />
                        </a>

                        <div class="product__items--content text-center">
                            <div class="product__items--price" *ngIf="subItem.Title!=''"><a>{{subItem.Title}}</a></div>
                            <div class="product__items--content__title h4" *ngIf="subItem.Subtitle!=''">
                                <a>{{subItem.Subtitle}}</a>
                            </div>
                            <div class="variant__buy--now__btn primary__btn outline"><a
                                    (click)="ProductDetByType(d.LayoutViewType,subItem.LayoutViewID,subItem.Title,subItem.Image)"
                                   class="cursor-pointer">Shop Now</a></div>
                            <br />
                        </div>
                    </div>
                    <img *ngIf="subItem.Title==''" src="{{ImagePath+subItem.Image}}"
                        alt="Buy online - {{subItem.Title}}" class="img-responsive" width="100%" />
                </div>
            </div>
        </div>
    </div>

    <div class="mx-auto mt-5 p-3" style="display: flex;overflow-x: scroll;">
        <!-- <div class="col-md-12 mt-3" *ngIf="!mobile">


            <owl-carousel [options]="SlideOptions2" [items]="lstCategoriesMainDet"
                [carouselClasses]="['owl-theme', 'sliding']">
                <ng-container *ngFor="let d of lstCategoriesMainDet">
                    <div class="item">
                        <div class="top-content" (click)="CategoryClickByproduct(d.CategoryID,d.Categoryname)">
                            <img src="{{ImagePath+d.Image}}" alt="Buy online - {{d.Categoryname}}"
                                onerror="this.onerror=null;this.src='./assets/img/other/noimagepreview.png'"
                                class="top-image" />
                            <div class="text">{{d.Categoryname}}</div>
                        </div>
                    </div>

                </ng-container>
            </owl-carousel>
        </div> -->

        <div class="col-md-2 col-3" *ngFor="let d of lstCategoriesMainDet">
            <div class="top-content" (click)="CategoryClickByproduct(d.CategoryID,d.Categoryname)">
            <img src="{{ImagePath+d.Image}}" alt="Buy online - {{d.Categoryname}}" onerror="this.onerror=null;this.src='./assets/img/other/noimagepreview.png'"
            class="top-image" style="aspect-ratio: 1/1.4;height: auto!important;"/>

            <div class="text">{{d.Categoryname}}</div>
        </div>
        </div>

        <!-- <div class="col-md-12 mt-3" *ngIf="mobile">
            <owl-carousel [options]="SlideOptions3" [items]="lstCategoriesMainDet"
                [carouselClasses]="['owl-theme', 'sliding']">
                <ng-container *ngFor="let d of lstCategoriesMainDet">
                    <div class="item">
                        <div class="top-content" (click)="CategoryClickByproduct(d.CategoryID,d.Categoryname)">
                            <img src="{{ImagePath+d.Image}}" alt="Buy online - {{d.Categoryname}}"
                                onerror="this.onerror=null;this.src='./assets/img/other/noimagepreview.png'"
                                class="top-image" />
                            <div class="text">{{d.Categoryname}}</div>
                        </div>
                    </div>

                </ng-container>
            </owl-carousel>
        </div> -->



    </div>


        <div class="row">
            <div class="container mw-1690">
                <div class="row mx-auto mt-5">
                    <div class="col-md-12 text-center">
                        <h3 class="name mb-5">New Arrivals</h3>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-xl-12 col-lg-12">
                        <div class="row scroll-bar">
                            <div class="col-6 col-md-3 p-0" *ngFor="let d of lstNewArrivals">
                                <div class="product__items user-profile" (mouseover)="hover=true;productMouseOverCall(d.ProductID)"
                                    (mouseleave)="hover=false">
                                    <div class="product__items--thumbnail figure">
                                        <a class="product__items--link">
                                            <img class="product__items--img product__primary--img"
                                                (click)="ProductClick(d.ProductOptionID.ProductOptionID,d.ProductID,d.ProductImage,d.ProductName)"
                                                src="{{ImagePath+d.ProductImage}}" alt="Buy online - {{d.ProductName}}">
                                            <img class="product__items--img product__secondary--img"
                                                (click)="ProductClick(d.ProductOptionID.ProductOptionID,d.ProductID,d.ProductImage,d.ProductName)"
                                                src="{{ImagePath+d.ProductImage}}" alt="Buy online - {{d.ProductName}}">
                                        </a>
                                    </div>
                                    <div class="css-popover bg__black d-none d-lg-block z-9">
                                        <div class="row">
                                            <span *ngFor="let o of selectedProductOptions">
                                                <div class="col-md-12">
                                                    <ul class="text-white">
                                                        <li><strong class="text-white">{{o.Name}}</strong>:
                                                            {{o.Options}}</li>
                                                    </ul>
                                                </div>
                                            </span>
                                            <div class="col-md-12 mt-4">
                                                <h4 class="text-white" (click)="ProductClick(d.ProductOptionID.ProductOptionID,d.ProductID,d.ProductImage,d.ProductName)"><a>QUICK VIEW</a></h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product__items--content text-center">
                                        <strong>{{d.ProductName}}</strong>
                                        <!-- <div class="product__items--price">
                                            <span class="current__price mx-2"><b class="rupee">Rs
                                                </b>{{d.ProductOptionID[0].Price}}</span>
                
                
                                        </div> -->

                                        <div class="product__items--price">
                                            <span class="current__price mx-2"
                                                *ngIf="d.ProductOptionID.SalesPrice!=''"><b
                                                    class="rupee">{{getcurrency()}}</b>{{(+d.ProductOptionID.SalesPrice).toFixed(0)}}</span>
                                            <span class="text-danger"
                                                *ngIf="d.ProductOptionID.Price!='' && ((+d.ProductOptionID.SalesPrice)<(+d.ProductOptionID.Price))">
                                                (<b class="rupee"><del>{{storeSettings.currency}}{{(+d.ProductOptionID.Price).toFixed(0)}})</del></b>
                                                    <b class="mx-2">{{d.ProductOptionID.Discount}}</b>
                                            </span>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
        
                </div>
            </div>
        </div>


    <!-- Left Right Region -->
    <div class="row" *ngIf="(!isHomeScreenContainsLeftRegion && !isHomeScreenContainsRightRegion )">
        <div class="col-md-12 mx-auto">
            <div *ngFor="let d of lstHomeScreanSettings;" class="lazy-image">
                <div class="row" *ngIf="d.Region=='Center'">
                   

                    <div class="container mw-1690">
                        <div 
                            *ngIf="(d.LayoutViewType=='Category'  || d.LayoutViewType=='Product')     &&  d.LayoutOrientation!='Multi View Sliding'  ">
                            <div class="row" *ngFor="let subItem of d['Child']" class="mb_25">
                                <div class="col-md-12 text-center mt-10 mb-10" *ngIf="subItem['ChildDetails'].length>0">
                                    <h3 *ngIf="subItem.Title!='' && (+d.ShowTitle)==1" class="name">{{subItem.Title}} </h3>
                                    <button class="btn btn-danger" (click)="CategoryClickByproduct(subItem.ChildDetails[0].CategoryID,subItem.Title)" >View All</button>
                                    
                                </div>
                                <div class="row" *ngIf="subItem['ChildDetails'].length>0">
                                    <div class="col-xl-12 col-lg-12">
                                        <div class="shop__product--wrapper">
                                            <div class="tab_content">
                                                <div id="product_grid" class="tab_pane active show">
                                                    <div class="product__section--inner product__grid--inner">
                                                        <div
                                                            class="row row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-2 mb--n30 scroll-bar">
                                                            <!-- style="display: table-cell;" -->
                                                            <!-- {{subItem['ChildDetails'].length}} -->
                                                            <div *ngFor="let subItemDet of (subItem['ChildDetails'].slice(0, 8))"
                                                                class="col-md-3 mb-30 p-0 ">
                                                                <div (mouseover)="hover=true;productMouseOverCall(subItemDet.ProductID)"
                                                                    (mouseleave)="hover=false"
                                                                    class="product__items user-profile">
                                                                    <div class="product__items--thumbnail figure">
                                                                        <a class="product__items--link"
                                                                            (click)="ProductClick(subItemDet.ProductOptionID,subItemDet.ProductID,subItemDet.ProductImage,subItemDet.ProductName)">
                                                                            <img class="product__items--img product__primary--img"
                                                                                src="{{ImagePath+subItemDet.ProductImage}}"
                                                                                alt="Buy online - {{subItemDet.ProductName}}" />

                                                                            <img class="product__items--img product__secondary--img"
                                                                                src="{{ImagePath+subItemDet.ProductImage}}"
                                                                                alt="Buy online - {{subItemDet.ProductName}}" />
                                                                            <!-- <img class="product__items--img product__primary--img" src="https://cdn.shopify.com/s/files/1/0420/7073/7058/files/4MSD3615-0119_800x.jpg?v=1692433276?v=43624077394082" alt="product-img"> 
                                                                     <img class="product__items--img product__secondary--img" src=
                                                                     "https://cdn.shopify.com/s/files/1/0420/7073/7058/files/4MSD3615-0119_800x.jpg?v=1692433276?v=43624077394082" alt="product-img"> -->
                                                                        </a>
                                                                    </div>
                                                                    <div class="css-popover bg__black d-none d-lg-block"
                                                                        [style.z-index]="hover==true  ? 9 : 0">
                                                                        <div class="row">
                                                                            <!-- <div class="col-md-12 mb-4">
                                                                            <p class="product-desc text-white"> {{subItemDet.ProductShortDesc!=''?subItemDet.ProductShortDesc:subItemDet.ProductName}} </p>
                                                                        </div>  -->
                                                                            <span
                                                                                *ngFor="let o of selectedProductOptions">
                                                                                <div class="col-md-12">
                                                                                    <ul class="text-white">
                                                                                        <li><strong
                                                                                                class="text-white">{{o.Name}}</strong>:
                                                                                            {{o.Options}}</li>
                                                                                    </ul>
                                                                                </div>
                                                                            </span>
                                                                            <div class="col-md-12 mt-4">
                                                                                <h4 class="text-white">
                                                                                    <a
                                                                                        (click)="ProductClick(subItemDet.ProductOptionID,subItemDet.ProductID,subItemDet.ProductImage,subItemDet.ProductName)">QUICK
                                                                                        VIEW</a>
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="product__items--content text-center">
                                                                        <strong>{{subItemDet.ProductName}}</strong>
                                                                        <!-- <h3 class="product__items--content__title h4"><a (click)="ProductClick(subItemDet.ProductOptionID,subItemDet.ProductID,subItemDet.ProductImage,subItemDet.ProductName)">Checks Shirt</a></h3> -->
                                                                        <div class="product__items--price">
                                                                            <span class="current__price mx-2"
                                                                                *ngIf="subItemDet.SalesPrice!=''"><b
                                                                                    class="rupee">{{getcurrency()}}</b>{{(+subItemDet.SalesPrice).toFixed(0)}}</span>
                                                                            <span class="text-danger"
                                                                                *ngIf="subItemDet.Price!='' && ((+subItemDet.SalesPrice)<(+subItemDet.Price))">
                                                                                (<b class="rupee"><del>{{storeSettings.currency}}{{(+subItemDet.Price).toFixed(0)}})</del></b>
                                                                                    <b class="mx-2">{{subItemDet.Discount}}</b>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="row mt-5">
                                                            <div class="col-md-12 text-center">
                                                                
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="(isHomeScreenContainsLeftRegion && isHomeScreenContainsRightRegion )">
            <div class="col-md-2 mx-auto">
                <div *ngFor="let d of lstHomeScreanSettings;" class="lazy-image">
                    <div class="row" *ngIf="d.Region=='Left'">
                        <div class="col-md-12">
                            <!-- <owl-carousel [options]="{items: 1, dots: false, navigation: false,autoplay:true,loop:true}"
                                [items]="d['Child']" [carouselClasses]="['owl-theme', 'sliding']">
                                <div *ngFor="let subItem of d['Child'];">
                                    <img class="owl-class" src="{{ImagePath+subItem.Image}}" alt="Buy online - {{subItem.Title}}"
                                        (click)="ProductDetByType(d.LayoutViewType,subItem.LayoutViewID,subItem.Title,subItem.Image)" />
                                </div>
                            </owl-carousel> -->
                            <ngx-slick-carousel [config]="{ slidesToShow: 1, dots: false, autoplay: true, loop: true }" class="owl-theme sliding">
                                <div *ngFor="let subItem of d['Child'];">
                                  <img class="owl-class" [src]="ImagePath + subItem.Image" alt="Buy online - {{ subItem.Title }}" (click)="ProductDetByType(d.LayoutViewType, subItem.LayoutViewID, subItem.Title, subItem.Image)" />
                                </div>
                              </ngx-slick-carousel>
                              


                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8 mx-auto">
                <div *ngFor="let d of lstHomeScreanSettings;" class="lazy-image">
                    <div class="row" *ngIf="d.Region=='Center' ">
                        <div [class]="'col-md-12 '"
                            *ngIf="d.LayoutOrientation=='Multi View Sliding' && d.LayoutViewType!='FlashMassage'  && d.LayoutViewType!='Category'  "
                           class="mb-04">
                            <!-- <owl-carousel
                                [options]="{items:d.ItemsToShow , dots: false, navigation: false,autoplay:true,loop:true}"
                                [items]="d['Child']" [carouselClasses]="['owl-theme', 'sliding']">
                                <div class="brand__logo--section section--padding"
                                    *ngFor="let subItem of d['Child'];let i = index">
                                    <div
                                        class="brand__logo--section__inner d-flex justify-content-center align-items-center">
                                        <div class="brand__logo--items">
                                            <a (click)="ProductDetByType(d.LayoutViewType,subItem.LayoutViewID,subItem.Title,subItem.Image)"
                                                class="product-item-photo">
                                                <img class="product-image-photo cursor-pointer" src="{{ImagePath+subItem.Image}}"
                                                    alt="Buy online - {{subItem.Title}}"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </owl-carousel> -->

                            <ngx-slick-carousel [config]="{ slidesToShow:d.ItemsToShow || 1, dots: false, autoplay: true, loop: true }" class="owl-theme sliding">
                                <div class="brand__logo--section section--padding" *ngFor="let subItem of d['Child']; let i = index">
                                  <div class="brand__logo--section__inner d-flex justify-content-center align-items-center">
                                    <div class="brand__logo--items">
                                      <a (click)="ProductDetByType(d.LayoutViewType, subItem.LayoutViewID, subItem.Title, subItem.Image)" class="product-item-photo">
                                        <img class="product-image-photo cursor-pointer" [src]="ImagePath + subItem.Image" alt="Buy online - {{ subItem.Title }}" />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </ngx-slick-carousel>


                        </div>
                        <div [class]="'col-md-12 text-center'"
                            *ngIf="d.LayoutOrientation=='Multi View Sliding' && d.LayoutViewType!='FlashMassage'  && d.LayoutViewType=='Category'">
                            <div *ngFor="let subItem of d['Child']" class="related-product-area">
                                <div class="page-header mb-25">
                                    <h3 class="name">{{subItem.Title}}</h3>
                                </div>
                                <!-- <owl-carousel
                                    [options]="{items: d.ItemsToShow, dots: false, navigation: false,autoplay:true,loop:true}"
                                    [items]="subItem['ChildDetails']" [carouselClasses]="['owl-theme', 'sliding']">
                                    <div class="item" *ngFor="let subItemDet of subItem['ChildDetails'];let i = index">
                                        <div class="product-item">
                                            <div class="item-inner">
                                                <div class="product-thumbnail">
                                                    <a (click)="ProductClick(subItemDet.ProductOptionID,subItemDet.ProductID,subItemDet.ProductImage,subItemDet.ProductName)"
                                                        class="product-item-photo cursor-pointer">
                                                        <img class="product-image-photo w-408 h-252"
                                                            src="{{ImagePath+subItemDet.ProductImage}}"
                                                            alt="Buy online - {{subItemDet.ProductName}}"/>
                                                    </a>
                                                </div>
                                                <div class="pro-box-info">
                                                    <div class="item-info">
                                                        <div class="info-inner">
                                                            <div class="item-title">
                                                                <a (click)="ProductClick(subItemDet.ProductOptionID,subItemDet.ProductID,subItemDet.ProductImage,subItemDet.ProductName)"
                                                                   class="cursor-pointer">
                                                                    {{subItemDet.ProductName}}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="box-hover">
                                                        <div class="product-item-actions">
                                                            <div class="pro-actions">
                                                                <button class="action add-to-cart cursor-pointer" type="button"
                                                                    title="Shop Now"
                                                                    (click)="ProductClick(subItemDet.ProductOptionID,subItemDet.ProductID,subItemDet.ProductImage,subItemDet.ProductName)">
                                                                    <span>Shop Now </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </owl-carousel>
                            -->
                           
                            <ngx-slick-carousel [config]="{slidesToShow: d.ItemsToShow || 1, dots: false, autoplay: true, loop: true}" class="owl-theme sliding">
                                <div class="item" *ngFor="let subItemDet of subItem['ChildDetails']; let i = index">
                                  <div class="product-item">
                                    <div class="item-inner">
                                      <div class="product-thumbnail">
                                        <a (click)="ProductClick(subItemDet.ProductOptionID, subItemDet.ProductID, subItemDet.ProductImage, subItemDet.ProductName)" class="product-item-photo cursor-pointer">
                                          <img class="product-image-photo w-408 h-252" [src]="ImagePath + subItemDet.ProductImage" alt="Buy online - {{ subItemDet.ProductName }}" />
                                        </a>
                                      </div>
                                      <div class="pro-box-info">
                                        <div class="item-info">
                                          <div class="info-inner">
                                            <div class="item-title">
                                              <a (click)="ProductClick(subItemDet.ProductOptionID, subItemDet.ProductID, subItemDet.ProductImage, subItemDet.ProductName)" class="cursor-pointer">{{ subItemDet.ProductName }}</a>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="box-hover">
                                          <div class="product-item-actions">
                                            <div class="pro-actions">
                                              <button class="action add-to-cart cursor-pointer" type="button" title="Shop Now" (click)="ProductClick(subItemDet.ProductOptionID, subItemDet.ProductID, subItemDet.ProductImage, subItemDet.ProductName)">
                                                <span>Shop Now</span>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ngx-slick-carousel>
                              

                            </div>
                        </div>
                        <div [class]="'col-md-12 '" *ngIf="d.LayoutViewType=='FlashMassage'">
                            <p>
                                <marquee class="marquee-class">
                                    {{d.SubTiltle}}</marquee>
                            </p>
                        </div>
                        <div [class]="'col-md-12 mt-3'"
                            *ngIf="d.LayoutOrientation=='Horizontal' && d['Child'].length>0">
                            <section *ngFor="let subItem of d['Child'];let i = index" class="bg-color">
                                <div class="row">
                                    <div class="col-md-12">
                                        <a [href]="subItem.Subtitle" class="w-100"> <img
                                                src="{{ImagePath+subItem.Image}}" alt="Buy online - {{subItem.Title}}"
                                                class="w-100" /> </a>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="row"
                            *ngIf="d.LayoutViewType!='Category' && d.LayoutViewType!='Product'   &&  d.LayoutViewType!='FlashMassage'  && d.LayoutOrientation!='Horizontal'    &&  d.LayoutOrientation!='Multi View Sliding' ">
                            <div *ngFor="let subItem of d['Child']"
                                [attr.class]="(d.LayoutOrientation=='Two Box Layout' ? 'col-md-6 mx-auto' : (d.LayoutOrientation=='Three Box Layout' ? 'col-md-4 mx-auto':((d.LayoutOrientation=='Four Box Layout' ? 'col-md-3 mx-auto':(d.LayoutOrientation=='Six Box Layout' ? 'col-md-2 mx-auto':'col-md-12 mx-auto')))))">
                                <div *ngIf="subItem.Title!=''  ">
                                    <a (click)="ProductDetByType(d.LayoutViewType,subItem.LayoutViewID,subItem.Title,subItem.Image)" class="cursor-pointer">
                                        <img class="img-respo class-img" alt="Buy online - {{subItem.Title}}"
                                            src="{{ImagePath+subItem.Image}}"/>
                                    </a>

                                    <div class="product__items--content text-center">
                                        <div class="product__items--price" *ngIf="subItem.Title!=''">
                                            <a>{{subItem.Title}}</a>
                                        </div>
                                        <div class="product__items--content__title h4" *ngIf="subItem.Subtitle!=''">
                                            <a>{{subItem.Subtitle}}</a>
                                        </div>
                                        <div class="variant__buy--now__btn primary__btn outline"><a
                                                (click)="ProductDetByType(d.LayoutViewType,subItem.LayoutViewID,subItem.Title,subItem.Image)"
                                               class="cursor-pointer">Shop Now</a></div>
                                        <br />
                                    </div>
                                </div>
                                <img *ngIf="subItem.Title==''" src="{{ImagePath+subItem.Image}}"
                                    alt="Buy online - {{subItem.Title}}" class="img-responsive" width="100%" />
                            </div>
                        </div>
                        <div class="container mw-1690">
                            <div
                                *ngIf="(d.LayoutViewType=='Category'  || d.LayoutViewType=='Product')     &&  d.LayoutOrientation!='Multi View Sliding' ">
                                <div class="row" *ngFor="let subItem of d['Child']" class="mb_25">
                                    <div class="col-md-12 text-center mt-10 mb-10">
                                        <h3 *ngIf="subItem.Title!='' && (+d.ShowTitle)==1" class="name">
                                            {{subItem.Title}}
                                        </h3>
                                    </div>
                                    <div class="row">
                                        <div class="col-xl-12 col-lg-12">
                                            <div class="shop__product--wrapper">
                                                <div class="tab_content">
                                                    <div id="product_grid" class="tab_pane active show">
                                                        <div class="product__section--inner product__grid--inner">
                                                            <div
                                                                class="row row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-2 mb--n30">
                                                                <div *ngFor="let subItemDet of subItem['ChildDetails']"
                                                                    [attr.class]="(d.LayoutOrientation=='Two Box Layout' ? 'col-md-6  mx-auto ' : (d.LayoutOrientation=='Three Box Layout' ? 'col-md-4  mx-auto':((d.LayoutOrientation=='Four Box Layout' ? 'col-md-3  mx-auto':(d.LayoutOrientation=='Six Box Layout' ? 'col-md-2 mx-auto':'col-md-12 mx-auto')) )))"
                                                                    class="col mb-30 d-table_cell">
                                                                    <div (mouseover)="hover=true;productMouseOverCall(subItemDet.ProductID)"
                                                                        (mouseleave)="hover=false"
                                                                        class="product__items user-profile">
                                                                        <div class="product__items--thumbnail figure">
                                                                            <a class="product__items--link"
                                                                                (click)="ProductClick(subItemDet.ProductOptionID,subItemDet.ProductID,subItemDet.ProductImage,subItemDet.ProductName)">
                                                                                <img class="product__items--img product__primary--img"
                                                                                    src="{{ImagePath+subItemDet.ProductImage}}"
                                                                                    alt="Buy online - {{subItemDet.ProductName}}" />

                                                                                <img class="product__items--img product__secondary--img"
                                                                                    src="{{ImagePath+subItemDet.ProductImage}}"
                                                                                    alt="Buy online - {{subItemDet.ProductName}}" />
                                                                            </a>
                                                                        </div>

                                                                        <div class="css-popover bg__black d-none d-lg-block"
                                                                            [style.z-index]="hover==true  ? 9 : 0">
                                                                            <div class="row">
                                                                                <span
                                                                                    *ngFor="let o of selectedProductOptions">
                                                                                    <div class="col-md-12">
                                                                                        <ul class="text-white">
                                                                                            <li><strong
                                                                                                    class="text-white">{{o.Name}}</strong>:
                                                                                                {{o.Options}}</li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </span>
                                                                                <div class="col-md-12 mt-4">
                                                                                    <h4 class="text-white">
                                                                                        <a
                                                                                            (click)="ProductClick(subItemDet.ProductOptionID,subItemDet.ProductID,subItemDet.ProductImage,subItemDet.ProductName)">QUICK
                                                                                            VIEW</a>
                                                                                    </h4>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            class="product__items--content text-center">
                                                                            <strong>{{subItemDet.ProductName}}</strong>

                                                                            <div class="product__items--price">
                                                                                <span class="current__price mx-2"
                                                                                    *ngIf="subItemDet.SalesPrice!=''"><b
                                                                                        class="rupee">{{getcurrency()}}</b>{{(+subItemDet.SalesPrice).toFixed(0)}}</span>
                                                                                <span class="text-danger"
                                                                                    *ngIf="subItemDet.Price!='' && ((+subItemDet.SalesPrice)<(+subItemDet.Price))">
                                                                                    (<b class="rupee"><del>{{storeSettings.currency}}{{(+subItemDet.Price).toFixed(0) }})</del></b>
                                                                                    <!-- <b
                                                                                        class="mx-2">{{subItemDet.Discount}}</b> -->
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 mx-auto">
                <div *ngFor="let d of lstHomeScreanSettings;" class="lazy-image">
                    <div class="row" *ngIf="d.Region=='Right'">
                        <div class="col-md-12">
                            <!-- <owl-carousel [options]="{items: 1, dots: false, navigation: false,autoplay:true,loop:true}"
                                [items]="d['Child']" [carouselClasses]="['owl-theme', 'sliding']">
                                <div *ngFor="let subItem of d['Child'];">
                                    <img src="{{ImagePath+subItem.Image}}" alt="Buy online - {{subItem.Title}}"
                                        (click)="ProductDetByType(d.LayoutViewType,subItem.LayoutViewID,subItem.Title,subItem.Image)"
                                       class="owl-class"/>
                                </div>
                            </owl-carousel> -->

                            <ngx-slick-carousel [config]="{slidesToShow: 1, dots: false, autoplay: true, loop: true}" class="owl-theme sliding">
                                <div *ngFor="let subItem of d['Child'];">
                                  <img [src]="ImagePath + subItem.Image" alt="Buy online - {{ subItem.Title }}" (click)="ProductDetByType(d.LayoutViewType, subItem.LayoutViewID, subItem.Title, subItem.Image)" class="owl-class" />
                                </div>
                              </ngx-slick-carousel>
                              


                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="(isHomeScreenContainsLeftRegion && !isHomeScreenContainsRightRegion )">
            <div class="col-md-2 mx-auto">
                <div *ngFor="let d of lstHomeScreanSettings;" class="lazy-image">
                    <div class="row" *ngIf="d.Region=='Left'">
                        <div class="col-md-12">
                            <!-- <owl-carousel [options]="{items: 1, dots: false, navigation: false,autoplay:true,loop:true}"
                                [items]="d['Child']" [carouselClasses]="['owl-theme', 'sliding']">
                                <div *ngFor="let subItem of d['Child'];">
                                    <img src="{{ImagePath+subItem.Image}}" alt="Buy online - {{subItem.Title}}"
                                        (click)="ProductDetByType(d.LayoutViewType,subItem.LayoutViewID,subItem.Title,subItem.Image)"
                                       class="owl-class"/>
                                </div>
                            </owl-carousel> -->


                            <ngx-slick-carousel [config]="{slidesToShow: 1, dots: false, autoplay: true, loop: true}" class="owl-theme sliding">
                                <div *ngFor="let subItem of d['Child'];">
                                  <img [src]="ImagePath + subItem.Image" alt="Buy online - {{ subItem.Title }}" (click)="ProductDetByType(d.LayoutViewType, subItem.LayoutViewID, subItem.Title, subItem.Image)" class="owl-class" />
                                </div>
                              </ngx-slick-carousel>
                              


                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-10 mx-auto">
                <div *ngFor="let d of lstHomeScreanSettings;" class="lazy-image">
                    <div class="row" *ngIf="d.Region=='Center' ">
                        <div [class]="'col-md-12 '"
                            *ngIf="d.LayoutOrientation=='Multi View Sliding' && d.LayoutViewType!='FlashMassage'  && d.LayoutViewType!='Category'  "
                           class="mb-04">
                           
<!--                            
                           <owl-carousel
                                [options]="{items:d.ItemsToShow , dots: false, navigation: false,autoplay:true,loop:true}"
                                [items]="d['Child']" [carouselClasses]="['owl-theme', 'sliding']">
                                <div class="brand__logo--section section--padding"
                                    *ngFor="let subItem of d['Child'];let i = index">
                                    <div
                                        class="brand__logo--section__inner d-flex justify-content-center align-items-center">
                                        <div class="brand__logo--items">
                                            <a (click)="ProductDetByType(d.LayoutViewType,subItem.LayoutViewID,subItem.Title,subItem.Image)"
                                                class="product-item-photo">
                                                <img class="product-image-photo cursor-pointer" alt="Buy online - {{subItem.Title}}"
                                                    src="{{ImagePath+subItem.Image}}"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </owl-carousel>
                       -->
                      
                       <ngx-slick-carousel [config]="{slidesToShow: d.ItemsToShow  || 1, dots: false, autoplay: true, loop: true}" class="owl-theme sliding">
                        <div class="brand__logo--section section--padding" *ngFor="let subItem of d['Child']; let i = index">
                          <div class="brand__logo--section__inner d-flex justify-content-center align-items-center">
                            <div class="brand__logo--items">
                              <a (click)="ProductDetByType(d.LayoutViewType, subItem.LayoutViewID, subItem.Title, subItem.Image)" class="product-item-photo">
                                <img class="product-image-photo cursor-pointer" [alt]="'Buy online - ' + subItem.Title" [src]="ImagePath + subItem.Image" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </ngx-slick-carousel>
                      


                        </div>
                        <div [class]="'col-md-12 text-center'"
                            *ngIf="d.LayoutOrientation=='Multi View Sliding' && d.LayoutViewType!='FlashMassage'  && d.LayoutViewType=='Category'">
                            <div *ngFor="let subItem of d['Child']" class="related-product-area">
                                <div class="page-header mb-25">
                                    <h3 class="name">{{subItem.Title}}</h3>
                                </div>
                                <!-- <owl-carousel
                                    [options]="{items: d.ItemsToShow, dots: false, navigation: false,autoplay:true,loop:true}"
                                    [items]="subItem['ChildDetails']" [carouselClasses]="['owl-theme', 'sliding']">
                                    <div class="item" *ngFor="let subItemDet of subItem['ChildDetails'];let i = index">
                                        <div class="product-item">
                                            <div class="item-inner">
                                                <div class="product-thumbnail">
                                                    <a (click)="ProductClick(subItemDet.ProductOptionID,subItemDet.ProductID,subItemDet.ProductImage,subItemDet.ProductName)"
                                                        class="product-item-photo cursor-pointer">
                                                        <img class="product-image-photo w-408 h-252"
                                                            src="{{ImagePath+subItemDet.ProductImage}}"
                                                            alt="Buy online - {{subItemDet.ProductName}}"/>
                                                    </a>
                                                </div>
                                                <div class="pro-box-info">
                                                    <div class="item-info">
                                                        <div class="info-inner">
                                                            <div class="item-title">
                                                                <a (click)="ProductClick(subItemDet.ProductOptionID,subItemDet.ProductID,subItemDet.ProductImage,subItemDet.ProductName)" class="cursor-pointer">
                                                                    {{subItemDet.ProductName}}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="box-hover">
                                                        <div class="product-item-actions">
                                                            <div class="pro-actions">
                                                                <button class="action add-to-cart cursor-pointer" type="button"
                                                                    title="Shop Now"
                                                                    (click)="ProductClick(subItemDet.ProductOptionID,subItemDet.ProductID,subItemDet.ProductImage,subItemDet.ProductName)">
                                                                    <span>Shop Now </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </owl-carousel>
                            -->
                           
                            <ngx-slick-carousel [config]="{slidesToShow:d.ItemsToShow || 1, dots: false, autoplay: true, loop: true}" class="owl-theme sliding">
                                <div class="item" *ngFor="let subItemDet of subItem['ChildDetails']; let i = index">
                                  <div class="product-item">
                                    <div class="item-inner">
                                      <div class="product-thumbnail">
                                        <a (click)="ProductClick(subItemDet.ProductOptionID, subItemDet.ProductID, subItemDet.ProductImage, subItemDet.ProductName)" class="product-item-photo cursor-pointer">
                                          <img class="product-image-photo w-408 h-252" [src]="ImagePath + subItemDet.ProductImage" alt="Buy online - {{ subItemDet.ProductName }}" />
                                        </a>
                                      </div>
                                      <div class="pro-box-info">
                                        <div class="item-info">
                                          <div class="info-inner">
                                            <div class="item-title">
                                              <a (click)="ProductClick(subItemDet.ProductOptionID, subItemDet.ProductID, subItemDet.ProductImage, subItemDet.ProductName)" class="cursor-pointer">{{ subItemDet.ProductName }}</a>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="box-hover">
                                          <div class="product-item-actions">
                                            <div class="pro-actions">
                                              <button class="action add-to-cart cursor-pointer" type="button" title="Shop Now" (click)="ProductClick(subItemDet.ProductOptionID, subItemDet.ProductID, subItemDet.ProductImage, subItemDet.ProductName)">
                                                <span>Shop Now</span>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ngx-slick-carousel>
                              
                            </div>
                        </div>
                        <div [class]="'col-md-12 '" *ngIf="d.LayoutViewType=='FlashMassage'">
                            <p>
                                <marquee class="marquee-class">
                                    {{d.SubTiltle}}</marquee>
                            </p>
                        </div>
                        <div [class]="'col-md-12 mt-3'"
                            *ngIf="d.LayoutOrientation=='Horizontal' && d['Child'].length>0">
                            <section *ngFor="let subItem of d['Child'];let i = index" class="bg-color">
                                <div class="row">
                                    <div class="col-md-12">
                                        <a [href]="subItem.Subtitle" class="w-100"> <img
                                                src="{{ImagePath+subItem.Image}}" alt="Buy online - {{subItem.Title}}"
                                                class="w-100" /> </a>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="row"
                            *ngIf="d.LayoutViewType!='Category' && d.LayoutViewType!='Product'   &&  d.LayoutViewType!='FlashMassage'  && d.LayoutOrientation!='Horizontal'    &&  d.LayoutOrientation!='Multi View Sliding' ">
                            <div *ngFor="let subItem of d['Child']" [attr.class]="(d.LayoutOrientation=='Two Box Layout' ? 'col-md-6 mx-auto' : (d.LayoutOrientation=='Three Box Layout' ? 'col-md-4 mx-auto':(
                        
                        
                                      (d.LayoutOrientation=='Four Box Layout' ? 'col-md-3 mx-auto':
                                      (d.LayoutOrientation=='Six Box Layout' ? 'col-md-2 mx-auto':'col-md-12 mx-auto')
                                      
                                      )
                        
                                  )))">
                                <div *ngIf="subItem.Title!='' ">
                                    <a (click)="ProductDetByType(d.LayoutViewType,subItem.LayoutViewID,subItem.Title,subItem.Image)"
                                       class="cursor-pointer">
                                        <img class="img-respo class-img" alt="Buy online - {{subItem.Title}}"
                                            src="{{ImagePath+subItem.Image}}"/>
                                    </a>

                                    <div class="product__items--content text-center">
                                        <div class="product__items--price" *ngIf="subItem.Title!=''">
                                            <a>{{subItem.Title}}</a>
                                        </div>
                                        <div class="product__items--content__title h4" *ngIf="subItem.Subtitle!=''">
                                            <a>{{subItem.Subtitle}}</a>
                                        </div>
                                        <div class="variant__buy--now__btn primary__btn outline"><a
                                                (click)="ProductDetByType(d.LayoutViewType,subItem.LayoutViewID,subItem.Title,subItem.Image)"
                                               class="cursor-pointer">Shop Now</a></div>
                                        <br />
                                    </div>
                                </div>
                                <img *ngIf="subItem.Title==''" src="{{ImagePath+subItem.Image}}"
                                    alt="Buy online - {{subItem.Title}}" class="img-responsive" width="100%" />
                            </div>
                        </div>
                        <div class="container mw-1690">
                            <div
                                *ngIf="(d.LayoutViewType=='Category'  || d.LayoutViewType=='Product')     &&  d.LayoutOrientation!='Multi View Sliding' ">
                                <div class="row" *ngFor="let subItem of d['Child']" class="mb_25">
                                    <div class="col-md-12 text-center mt-10 mb-10">
                                        <h3 *ngIf="subItem.Title!='' && (+d.ShowTitle)==1" class="name">
                                            {{subItem.Title}}
                                        </h3>
                                    </div>
                                    <div class="row">
                                        <div class="col-xl-12 col-lg-12">
                                            <div class="shop__product--wrapper">
                                                <div class="tab_content">
                                                    <div id="product_grid" class="tab_pane active show">
                                                        <div class="product__section--inner product__grid--inner">
                                                            <div
                                                                class="row row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-2 mb--n30">
                                                                <div *ngFor="let subItemDet of subItem['ChildDetails']"
                                                                    [attr.class]="(d.LayoutOrientation=='Two Box Layout' ? 'col-md-6  mx-auto ' : (d.LayoutOrientation=='Three Box Layout' ? 'col-md-4  mx-auto':(
                                      (d.LayoutOrientation=='Four Box Layout' ? 'col-md-3  mx-auto':
                                      (d.LayoutOrientation=='Six Box Layout' ? 'col-md-2 mx-auto':'col-md-12 mx-auto')) )))"
                                                                    class="col mb-30 d-table_cell">
                                                                    <div (mouseover)="hover=true;productMouseOverCall(subItemDet.ProductID)"
                                                                        (mouseleave)="hover=false"
                                                                        class="product__items user-profile">
                                                                        <div class="product__items--thumbnail figure">
                                                                            <a class="product__items--link"
                                                                                (click)="ProductClick(subItemDet.ProductOptionID,subItemDet.ProductID,subItemDet.ProductImage,subItemDet.ProductName)">
                                                                                <img class="product__items--img product__primary--img"
                                                                                    src="{{ImagePath+subItemDet.ProductImage}}"
                                                                                    alt="Buy online - {{subItemDet.ProductName}}" />
                                                                                <img class="product__items--img product__secondary--img"
                                                                                    src="{{ImagePath+subItemDet.ProductImage}}"
                                                                                    alt="Buy online - {{subItemDet.ProductName}}" />
                                                                            </a>
                                                                        </div>
                                                                        <div class="css-popover bg__black d-none d-lg-block"
                                                                            [style.z-index]="hover==true  ? 9 : 0">
                                                                            <div class="row">
                                                                                <span
                                                                                    *ngFor="let o of selectedProductOptions">
                                                                                    <div class="col-md-12">
                                                                                        <ul class="text-white">
                                                                                            <li><strong
                                                                                                    class="text-white">{{o.Name}}</strong>:
                                                                                                {{o.Options}}</li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </span>
                                                                                <div class="col-md-12 mt-4">
                                                                                    <h4 class="text-white">
                                                                                        <a
                                                                                            (click)="ProductClick(subItemDet.ProductOptionID,subItemDet.ProductID,subItemDet.ProductImage,subItemDet.ProductName)">QUICK
                                                                                            VIEW</a>
                                                                                    </h4>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            class="product__items--content text-center">
                                                                            <strong>{{subItemDet.ProductName}}</strong>
                                                                            <!-- <h3 class="product__items--content__title h4"><a (click)="ProductClick(subItemDet.ProductOptionID,subItemDet.ProductID,subItemDet.ProductImage,subItemDet.ProductName)">Checks Shirt</a></h3> -->
                                                                            <div class="product__items--price">
                                                                                <span class="current__price mx-2"
                                                                                    *ngIf="subItemDet.SalesPrice!=''"><b
                                                                                        class="rupee">{{getcurrency()}}</b>{{(+subItemDet.SalesPrice).toFixed(0)}}</span>
                                                                                <span class="text-danger"
                                                                                    *ngIf="subItemDet.Price!='' && ((+subItemDet.SalesPrice)<(+subItemDet.Price))">
                                                                                    <!-- (<b class="rupee"><del>{{storeSettings.currency}}{{(+subItemDet.Price).toFixed(0)
                                                                                            }})</del></b> -->
                                                                                            <b
                                                                                        class="mx-2">{{subItemDet.Discount}}</b>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="(!isHomeScreenContainsLeftRegion && isHomeScreenContainsRightRegion )">
            <div class="col-md-10 mx-auto">
                <div *ngFor="let d of lstHomeScreanSettings;" class="lazy-image">
                    <div class="row" *ngIf="d.Region=='Center' ">
                        <div [class]="'col-md-12 mb-04 '"
                            *ngIf="d.LayoutOrientation=='Multi View Sliding' && d.LayoutViewType!='FlashMassage'  && d.LayoutViewType!='Category'  ">
<!--                          
                            <owl-carousel
                                [options]="{items:d.ItemsToShow , dots: false, navigation: false,autoplay:true,loop:true}"
                                [items]="d['Child']" [carouselClasses]="['owl-theme', 'sliding']">
                                <div class="brand__logo--section section--padding"
                                    *ngFor="let subItem of d['Child'];let i = index">
                                    <div
                                        class="brand__logo--section__inner d-flex justify-content-center align-items-center">
                                        <div class="brand__logo--items">
                                            <a (click)="ProductDetByType(d.LayoutViewType,subItem.LayoutViewID,subItem.Title,subItem.Image)"
                                                class="product-item-photo">
                                                <img class="product-image-photo cursor-pointer" src="{{ImagePath+subItem.Image}}"
                                                    alt="Buy online - {{subItem.Title}}"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </owl-carousel>
                        -->

                        <ngx-slick-carousel [config]="{slidesToShow: d.ItemsToShow || 1, dots: false, autoplay: true, loop: true}" class="owl-theme sliding">
                            <div class="brand__logo--section section--padding" *ngFor="let subItem of d['Child']; let i = index">
                              <div class="brand__logo--section__inner d-flex justify-content-center align-items-center">
                                <div class="brand__logo--items">
                                  <a (click)="ProductDetByType(d.LayoutViewType, subItem.LayoutViewID, subItem.Title, subItem.Image)" class="product-item-photo">
                                    <img class="product-image-photo cursor-pointer" [src]="ImagePath + subItem.Image" [alt]="'Buy online - ' + subItem.Title" />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </ngx-slick-carousel>
                          

                        </div>
                        <div [class]="'col-md-12 text-center'"
                            *ngIf="d.LayoutOrientation=='Multi View Sliding' && d.LayoutViewType!='FlashMassage'  && d.LayoutViewType=='Category'">
                            <div *ngFor="let subItem of d['Child']" class="related-product-area">
                                <div class="page-header mb-25">
                                    <h3 class="name">{{subItem.Title}}</h3>
                                </div>
                                <!-- <owl-carousel
                                    [options]="{items: d.ItemsToShow, dots: false, navigation: false,autoplay:true,loop:true}"
                                    [items]="subItem['ChildDetails']" [carouselClasses]="['owl-theme', 'sliding']">
                                    <div class="item" *ngFor="let subItemDet of subItem['ChildDetails'];let i = index">
                                        <div class="product-item">
                                            <div class="item-inner">
                                                <div class="product-thumbnail">
                                                    <a (click)="ProductClick(subItemDet.ProductOptionID,subItemDet.ProductID,subItemDet.ProductImage,subItemDet.ProductName)"
                                                        class="product-item-photo cursor-pointer">
                                                        <img class="product-image-photo w-408 h-252"
                                                            src="{{ImagePath+subItemDet.ProductImage}}"
                                                            alt="Buy online - {{subItemDet.ProductName}}"/>
                                                    </a>
                                                </div>

                                                <div class="pro-box-info">
                                                    <div class="item-info">
                                                        <div class="info-inner">
                                                            <div class="item-title">
                                                                <a (click)="ProductClick(subItemDet.ProductOptionID,subItemDet.ProductID,subItemDet.ProductImage,subItemDet.ProductName)"
                                                                  class="cursor-pointer">
                                                                    {{subItemDet.ProductName}}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="box-hover">
                                                        <div class="product-item-actions">
                                                            <div class="pro-actions">
                                                                <button class="action add-to-cart cursor-pointer" type="button"
                                                                    title="Shop Now"
                                                                    (click)="ProductClick(subItemDet.ProductOptionID,subItemDet.ProductID,subItemDet.ProductImage,subItemDet.ProductName)">
                                                                    <span>Shop Now </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </owl-carousel> -->
                          
                                <ngx-slick-carousel [config]="{slidesToShow: d.ItemsToShow || 1, dots: false, autoplay: true, loop: true}" class="owl-theme sliding">
                                    <div class="item" *ngFor="let subItemDet of subItem['ChildDetails']; let i = index">
                                      <div class="product-item">
                                        <div class="item-inner">
                                          <div class="product-thumbnail">
                                            <a (click)="ProductClick(subItemDet.ProductOptionID, subItemDet.ProductID, subItemDet.ProductImage, subItemDet.ProductName)" class="product-item-photo cursor-pointer">
                                              <img class="product-image-photo w-408 h-252" [src]="ImagePath + subItemDet.ProductImage" [alt]="'Buy online - ' + subItemDet.ProductName" />
                                            </a>
                                          </div>
                                          <div class="pro-box-info">
                                            <div class="item-info">
                                              <div class="info-inner">
                                                <div class="item-title">
                                                  <a (click)="ProductClick(subItemDet.ProductOptionID, subItemDet.ProductID, subItemDet.ProductImage, subItemDet.ProductName)" class="cursor-pointer">{{ subItemDet.ProductName }}</a>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="box-hover">
                                              <div class="product-item-actions">
                                                <div class="pro-actions">
                                                  <button class="action add-to-cart cursor-pointer" type="button" title="Shop Now" (click)="ProductClick(subItemDet.ProductOptionID, subItemDet.ProductID, subItemDet.ProductImage, subItemDet.ProductName)">
                                                    <span>Shop Now</span>
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </ngx-slick-carousel>
                                  
                          
                            </div>
                        </div>
                        <div [class]="'col-md-12 '" *ngIf="d.LayoutViewType=='FlashMassage'">
                            <p>
                                <marquee class="maquee-cstm">
                                    {{d.SubTiltle}}</marquee>
                            </p>
                        </div>
                        <div [class]="'col-md-12 mt-3'"
                            *ngIf="d.LayoutOrientation=='Horizontal' && d['Child'].length>0">
                            <section *ngFor="let subItem of d['Child'];let i = index" class="bg-color">
                                <div class="row">
                                    <div class="col-md-12">
                                        <a [href]="subItem.Subtitle" class="w-100"> <img
                                                src="{{ImagePath+subItem.Image}}" alt="Buy online - {{subItem.Title}}"
                                                class="w-100" /> </a>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="row"
                            *ngIf="d.LayoutViewType!='Category' && d.LayoutViewType!='Product'   &&  d.LayoutViewType!='FlashMassage'  && d.LayoutOrientation!='Horizontal'    &&  d.LayoutOrientation!='Multi View Sliding' ">
                            <div *ngFor="let subItem of d['Child']" [attr.class]="(d.LayoutOrientation=='Two Box Layout' ? 'col-md-6 mx-auto' : (d.LayoutOrientation=='Three Box Layout' ? 'col-md-4 mx-auto':(
                            
                            
                                          (d.LayoutOrientation=='Four Box Layout' ? 'col-md-3 mx-auto':
                                          
                                          (d.LayoutOrientation=='Six Box Layout' ? 'col-md-2 mx-auto':'col-md-12 mx-auto')
                                          
                                          )
                            
                                      )))">
                                <div *ngIf="subItem.Title!=''  ">
                                    <a (click)="ProductDetByType(d.LayoutViewType,subItem.LayoutViewID,subItem.Title,subItem.Image)"
                                      class="cursor-pointer">
                                        <img class="img-respo class-img" alt="Buy online - {{subItem.Title}}"
                                            src="{{ImagePath+subItem.Image}}"/>
                                    </a>

                                    <div class="product__items--content text-center">
                                        <div class="product__items--price" *ngIf="subItem.Title!=''">
                                            <a>{{subItem.Title}}</a>
                                        </div>
                                        <div class="product__items--content__title h4" *ngIf="subItem.Subtitle!=''">
                                            <a>{{subItem.Subtitle}}</a>
                                        </div>
                                        <div class="variant__buy--now__btn primary__btn outline cursor-pointer"><a
                                                (click)="ProductDetByType(d.LayoutViewType,subItem.LayoutViewID,subItem.Title,subItem.Image)">Shop Now</a></div>
                                        <br />
                                    </div>
                                </div>
                                <img *ngIf="subItem.Title==''" alt="Buy online - {{subItem.Title}}"
                                    src="{{ImagePath+subItem.Image}}" class="img-responsive" width="100%" />
                            </div>
                        </div>
                        <div
                            *ngIf="(d.LayoutViewType=='Category'  || d.LayoutViewType=='Product')     &&  d.LayoutOrientation!='Multi View Sliding' ">
                            <div class="row" *ngFor="let subItem of d['Child']" class="mb_25">
                                <div class="col-md-12 text-center mt-10 mb-10">
                                    <h3 *ngIf="subItem.Title!='' && (+d.ShowTitle)==1" class="name">{{subItem.Title}}
                                    </h3>
                                </div>
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12">
                                        <div class="shop__product--wrapper">
                                            <div class="tab_content">
                                                <div id="product_grid" class="tab_pane active show">
                                                    <div class="product__section--inner product__grid--inner">
                                                        <div
                                                            [attr.class]="(d.LayoutOrientation=='Two Box Layout' ? 'row col-md-6  mx-auto ' : (d.LayoutOrientation=='Three Box Layout' ? 'row col-md-4  mx-auto':(
                                          (d.LayoutOrientation=='Four Box Layout' ? 'row col-md-3  mx-auto':
                                          (d.LayoutOrientation=='Six Box Layout' ? 'row col-md-2 mx-auto':'row col-md-12 mx-auto') ))))">
                                                            <div *ngFor="let subItemDet of subItem['ChildDetails']"
                                                                class="col mb-30 d-table_cell">
                                                                <div (mouseover)="hover=true;productMouseOverCall(subItemDet.ProductID)"
                                                                    (mouseleave)="hover=false"
                                                                    class="product__items user-profile">
                                                                    <div class="product__items--thumbnail figure">
                                                                        <a class="product__items--link"
                                                                            (click)="ProductClick(subItemDet.ProductOptionID,subItemDet.ProductID,subItemDet.ProductImage,subItemDet.ProductName)">
                                                                            <img class="product__items--img product__primary--img"
                                                                                src="{{ImagePath+subItemDet.ProductImage}}"
                                                                                alt="Buy online - {{subItemDet.ProductName}}" />
                                                                            <img class="product__items--img product__secondary--img"
                                                                                src="{{ImagePath+subItemDet.ProductImage}}"
                                                                                alt="Buy online - {{subItemDet.ProductName}}" />
                                                                        </a>
                                                                    </div>
                                                                    <div class="css-popover bg__black d-none d-lg-block"
                                                                        [style.z-index]="hover==true  ? 9 : 0">
                                                                        <div class="row">
                                                                            <span
                                                                                *ngFor="let o of selectedProductOptions">
                                                                                <div class="col-md-12">
                                                                                    <ul class="text-white">
                                                                                        <li><strong
                                                                                                class="text-white">{{o.Name}}</strong>:
                                                                                            {{o.Options}}</li>
                                                                                    </ul>
                                                                                </div>
                                                                            </span>
                                                                            <div class="col-md-12 mt-4">
                                                                                <h4 class="text-white">
                                                                                    <a
                                                                                        (click)="ProductClick(subItemDet.ProductOptionID,subItemDet.ProductID,subItemDet.ProductImage,subItemDet.ProductName)">QUICK
                                                                                        VIEW</a>
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="product__items--content text-center">
                                                                        <strong>{{subItemDet.ProductName}}</strong>
                                                                        <!-- <h3 class="product__items--content__title h4"><a (click)="ProductClick(subItemDet.ProductOptionID,subItemDet.ProductID,subItemDet.ProductImage,subItemDet.ProductName)">Checks Shirt</a></h3> -->
                                                                        <div class="product__items--price">
                                                                            <span class="current__price mx-2"
                                                                                *ngIf="subItemDet.SalesPrice!=''"><b
                                                                                    class="rupee">{{getcurrency()}}</b>{{(+subItemDet.SalesPrice).toFixed(0)}}</span>
                                                                            <span class="text-danger"
                                                                                *ngIf="subItemDet.Price!='' && ((+subItemDet.SalesPrice)<(+subItemDet.Price))">
                                                                                <!-- (<b class="rupee">
                                                                                    <del>{{storeSettings.currency}}{{(+subItemDet.Price).toFixed(0)
                                                                                        }})</del></b> -->
                                                                                        
                                                                                        <b
                                                                                    class="mx-2">{{subItemDet.Discount}}</b>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 mx-auto">
                <div *ngFor="let d of lstHomeScreanSettings;" class="lazy-image">
                    <div class="row" *ngIf="d.Region=='Right'">
                        <div class="col-md-12">
                            <!-- <owl-carousel [options]="{items: 1, dots: false, navigation: false,autoplay:true,loop:true}"
                                [items]="d['Child']" [carouselClasses]="['owl-theme', 'sliding']">
                                <div *ngFor="let subItem of d['Child'];">
                                    <img src="{{ImagePath+subItem.Image}}" alt="Buy online - {{subItem.Title}}"
                                        (click)="ProductDetByType(d.LayoutViewType,subItem.LayoutViewID,subItem.Title,subItem.Image)"
                                       class="owl-class"/>
                                </div>
                            </owl-carousel> -->

                            <ngx-slick-carousel [config]="{slidesToShow: 1, dots: false, autoplay: true, loop: true}" class="owl-theme sliding">
                                <div class="item" *ngFor="let subItem of d['Child'];">
                                  <img [src]="ImagePath + subItem.Image" [alt]="'Buy online - ' + subItem.Title" (click)="ProductDetByType(d.LayoutViewType, subItem.LayoutViewID, subItem.Title, subItem.Image)" class="owl-class" />
                                </div>
                              </ngx-slick-carousel>
                              
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngFor="let d of lstHomeScreanSettings;" class="lazy-image">
            <div class="row" *ngIf="d.Region=='Bottom Full'">
                <div [class]="'col-md-12 '"
                    *ngIf="d.LayoutOrientation=='Multi View Sliding' && d.LayoutViewType!='FlashMassage'  && d.LayoutViewType!='Category'  "
                    class="mb-04">
                    <!-- <owl-carousel
                        [options]="{items:d.ItemsToShow , dots: false, navigation: false,autoplay:true,loop:true}"
                        [items]="d['Child']" [carouselClasses]="['owl-theme', 'sliding']">
                        <div class="brand__logo--section bg__secondary section--padding color-scheme-1"
                            *ngFor="let subItem of d['Child'];let i = index">
                            <div class="brand__logo--section__inner d-flex justify-content-center align-items-center">
                                <div class="brand__logo--items">
                                    <a (click)="ProductDetByType(d.LayoutViewType,subItem.LayoutViewID,subItem.Title,subItem.Image)"
                                        class="product-item-photo">
                                        <img class="product-image-photo cursor-pointer" src="{{ImagePath+subItem.Image}}"
                                            alt="Buy online - {{subItem.Title}}"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </owl-carousel> -->
              
                    <ngx-slick-carousel [config]="{slidesToShow: d.ItemsToShow  || 1, dots: false, autoplay: true, loop: true}" class="owl-theme sliding">
                        <div class="brand__logo--section bg__secondary section--padding color-scheme-1" *ngFor="let subItem of d['Child']; let i = index">
                          <div class="brand__logo--section__inner d-flex justify-content-center align-items-center">
                            <div class="brand__logo--items">
                              <a (click)="ProductDetByType(d.LayoutViewType, subItem.LayoutViewID, subItem.Title, subItem.Image)" class="product-item-photo">
                                <img class="product-image-photo cursor-pointer" [src]="ImagePath + subItem.Image" [alt]="'Buy online - ' + subItem.Title" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </ngx-slick-carousel>
                      
              
                </div>
                <div [class]="'col-md-12 text-center'"
                    *ngIf="d.LayoutOrientation=='Multi View Sliding' && d.LayoutViewType!='FlashMassage'  && d.LayoutViewType=='Category'">
                    <div *ngFor="let subItem of d['Child']" class="related-product-area">
                        <div class="page-header mb-25">
                            <h3 class="name">{{subItem.Title}}</h3>
                        </div>
                        <!-- <owl-carousel
                            [options]="{items: d.ItemsToShow, dots: false, navigation: false,autoplay:true,loop:true}"
                            [items]="subItem['ChildDetails']" [carouselClasses]="['owl-theme', 'sliding']">
                            <div class="item" *ngFor="let subItemDet of subItem['ChildDetails'];let i = index">
                                <div class="product-item">
                                    <div class="item-inner">
                                        <div class="product-thumbnail">
                                            <a (click)="ProductClick(subItemDet.ProductOptionID,subItemDet.ProductID,subItemDet.ProductImage,subItemDet.ProductName)"
                                                class="product-item-photo cursor-pointer">
                                                <img class="product-image-photo w-408 h-252"
                                                    src="{{ImagePath+subItemDet.ProductImage}}"
                                                    alt="Buy online - {{subItemDet.ProductName}}"
                                                   />
                                            </a>
                                        </div>
                                        <div class="pro-box-info">
                                            <div class="item-info">
                                                <div class="info-inner">
                                                    <div class="item-title">
                                                        <a (click)="ProductClick(subItemDet.ProductOptionID,subItemDet.ProductID,subItemDet.ProductImage,subItemDet.ProductName)"
                                                          class="cursor-pointer">{{subItemDet.ProductName}} </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="box-hover">
                                                <div class="product-item-actions">
                                                    <div class="pro-actions">
                                                        <button class="action add-to-cart cursor-pointer" type="button"
                                                            title="Shop Now"
                                                            (click)="ProductClick(subItemDet.ProductOptionID,subItemDet.ProductID,subItemDet.ProductImage,subItemDet.ProductName)">
                                                            <span>Shop Now </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </owl-carousel>
                    
                     -->

                     <ngx-slick-carousel [config]="{slidesToShow:  d.ItemsToShow || 1, dots: false, autoplay: true, loop: true}" class="owl-theme sliding">
                        <div class="item" *ngFor="let subItemDet of subItem['ChildDetails']; let i = index">
                          <div class="product-item">
                            <div class="item-inner">
                              <div class="product-thumbnail">
                                <a (click)="ProductClick(subItemDet.ProductOptionID, subItemDet.ProductID, subItemDet.ProductImage, subItemDet.ProductName)" class="product-item-photo cursor-pointer">
                                  <img class="product-image-photo w-408 h-252" [src]="ImagePath + subItemDet.ProductImage" [alt]="'Buy online - ' + subItemDet.ProductName" />
                                </a>
                              </div>
                              <div class="pro-box-info">
                                <div class="item-info">
                                  <div class="info-inner">
                                    <div class="item-title">
                                      <a (click)="ProductClick(subItemDet.ProductOptionID, subItemDet.ProductID, subItemDet.ProductImage, subItemDet.ProductName)" class="cursor-pointer">{{ subItemDet.ProductName }}</a>
                                    </div>
                                  </div>
                                </div>
                                <div class="box-hover">
                                  <div class="product-item-actions">
                                    <div class="pro-actions">
                                      <button class="action add-to-cart cursor-pointer" type="button" title="Shop Now" (click)="ProductClick(subItemDet.ProductOptionID, subItemDet.ProductID, subItemDet.ProductImage, subItemDet.ProductName)">
                                        <span>Shop Now</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ngx-slick-carousel>
                      


                    </div>
                </div>
                <div [class]="'col-md-12 '" *ngIf="d.LayoutViewType=='FlashMassage'">
                    <p>
                        <marquee class="marquee-class">
                            {{d.SubTiltle}}</marquee>
                    </p>
                </div>
                <div [class]="'col-md-12 mt-3'" *ngIf="d.LayoutOrientation=='Horizontal' && d['Child'].length>0">
                    <section *ngFor="let subItem of d['Child'];let i = index" class="bg-color">
                        <div class="row">
                            <div class="col-md-12">
                                <a [href]="subItem.Subtitle" class="w-100"> <img src="{{ImagePath+subItem.Image}}"
                                        alt="Buy online - {{subItem.Title}}" class="w-100" /> </a>
                            </div>
                        </div>
                    </section>
                </div>
                <div class="row"
                    *ngIf="d.LayoutViewType!='Category' && d.LayoutViewType!='Product'   &&  d.LayoutViewType!='FlashMassage'  && d.LayoutOrientation!='Horizontal'    &&  d.LayoutOrientation!='Multi View Sliding' ">
                    <div *ngFor="let subItem of d['Child']" [attr.class]="(d.LayoutOrientation=='Two Box Layout' ? 'col-md-6 mx-auto' : (d.LayoutOrientation=='Three Box Layout' ? 'col-md-4 mx-auto':(
                        
                        
                                      (d.LayoutOrientation=='Four Box Layout' ? 'col-md-3 mx-auto':
                                      
                                      (d.LayoutOrientation=='Six Box Layout' ? 'col-md-2 mx-auto':'col-md-12 mx-auto')
                                      
                                      
                                      
                                      )
                        
                                  )))">
                        <div *ngIf="subItem.Title!='' ">
                            <a (click)="ProductDetByType(d.LayoutViewType,subItem.LayoutViewID,subItem.Title,subItem.Image)"
                               class="cursor-pointer">
                                <img class="img-respo class-img" alt="Buy online - {{subItem.Title}}"
                                    src="{{ImagePath+subItem.Image}}"/>
                            </a>

                            <div class="product__items--content text-center">
                                <div class="product__items--price" *ngIf="subItem.Title!=''"><a>{{subItem.Title}}</a>
                                </div>
                                <div class="product__items--content__title h4" *ngIf="subItem.Subtitle!=''">
                                    <a>{{subItem.Subtitle}}</a>
                                </div>
                                <div class="variant__buy--now__btn primary__btn outline"><a
                                        (click)="ProductDetByType(d.LayoutViewType,subItem.LayoutViewID,subItem.Title,subItem.Image)"
                                      class="cursor-pointer">Shop Now</a></div>
                                <br />
                            </div>
                        </div>
                        <img *ngIf="subItem.Title==''" src="{{ImagePath+subItem.Image}}" class="img-responsive"
                            width="100%" />
                    </div>
                </div>
                <div class="container mw-1690">
                    <div
                        *ngIf="(d.LayoutViewType=='Category'  || d.LayoutViewType=='Product')     &&  d.LayoutOrientation!='Multi View Sliding' ">
                        <div class="row mb_25" *ngFor="let subItem of d['Child']">
                            <div class="col-md-12 text-center mt-10 mb-10">
                                <h3 *ngIf="subItem.Title!='' && (+d.ShowTitle)==1" class="name">{{subItem.Title}}</h3>
                            </div>
                            <div class="row">
                                <div class="col-xl-12 col-lg-12">
                                    <div class="shop__product--wrapper">
                                        <div class="tab_content">
                                            <div id="product_grid" class="tab_pane active show">
                                                <div class="product__section--inner product__grid--inner">
                                                    <div
                                                        class="row row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-2 mb--n30">
                                                        <div *ngFor="let subItemDet of subItem['ChildDetails']"
                                                            [attr.class]="(d.LayoutOrientation=='Two Box Layout' ? 'col-md-6  mx-auto ' : (d.LayoutOrientation=='Three Box Layout' ? 'col-md-4  mx-auto':(
                        
                        
                                      (d.LayoutOrientation=='Four Box Layout' ? 'col-md-3  mx-auto':
                                      
                                      (d.LayoutOrientation=='Six Box Layout' ? 'col-md-2 mx-auto':'col-md-12 mx-auto')
                                      
                                      
                                      )
                        
                                  )))" class="col mb-30 d-table_cell">
                                                            <div (mouseover)="hover=true;productMouseOverCall(subItemDet.ProductID)"
                                                                (mouseleave)="hover=false"
                                                                class="product__items user-profile">
                                                                <div class="product__items--thumbnail figure">
                                                                    <a class="product__items--link"
                                                                        (click)="ProductClick(subItemDet.ProductOptionID,subItemDet.ProductID,subItemDet.ProductImage,subItemDet.ProductName)">
                                                                        <img alt="Buy online - {{subItemDet.ProductName}}"
                                                                            class="product__items--img product__primary--img"
                                                                            onerror="this.onerror=null;this.src='./assets/img/other/noimagepreview.png'"
                                                                            src="{{ImagePath+subItemDet.ProductImage}}" />
                                                                        <img alt="Buy online - {{subItemDet.ProductName}}"
                                                                            class="product__items--img product__secondary--img"
                                                                            src="{{ImagePath+subItemDet.ProductImage}}"
                                                                            onerror="this.onerror=null;this.src='./assets/img/other/noimagepreview.png'" />
                                                                    </a>
                                                                </div>
                                                                <div class="css-popover bg__black d-none d-lg-block"
                                                                    [style.z-index]="hover==true  ? 9 : 0">
                                                                    <div class="row">
                                                                        <span *ngFor="let o of selectedProductOptions">
                                                                            <div class="col-md-12">
                                                                                <ul class="text-white">
                                                                                    <li><strong
                                                                                            class="text-white">{{o.Name}}</strong>:
                                                                                        {{o.Options}}</li>
                                                                                </ul>
                                                                            </div>
                                                                        </span>
                                                                        <div class="col-md-12 mt-4">
                                                                            <h4 class="text-white"><a
                                                                                    (click)="ProductClick(subItemDet.ProductOptionID,subItemDet.ProductID,subItemDet.ProductImage,subItemDet.ProductName)">QUICK
                                                                                    VIEW</a></h4>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="product__items--content text-center">
                                                                    <strong>{{subItemDet.ProductName}}</strong>

                                                                    <div class="product__items--price">
                                                                        <span class="current__price mx-2"
                                                                            *ngIf="subItemDet.SalesPrice!=''"><b
                                                                                class="rupee">{{getcurrency()}}</b>{{(+subItemDet.SalesPrice).toFixed(0)}}</span>
                                                                        <span class="text-danger"
                                                                            *ngIf="subItemDet.Price!='' && ((+subItemDet.SalesPrice)<(+subItemDet.Price))">
                                                                            <!-- (<b class="rupee"><del>{{storeSettings.currency}}{{(+subItemDet.Price).toFixed(0)
                                                                                    }})</del></b> -->
                                                                                    <b
                                                                                class="mx-2">{{subItemDet.Discount}}</b>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section class="product__section section--padding mb-5 mt-5">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row p-3">
                                    <div class="col-md-12">
                                        <div class="section__heading text-left">
                                            <h2>Shop Products By Brand</h2>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="row">
                                            <div class="col-md-12 col-12">
                                                <label class="mb-3"> Brand </label>
                                                <select name="ctrBrand" id="ctrBrand" class="form-control"
                                                    (change)="BrandchngeEvent($event)">
                                                    <option [value]="0" selected>Select Brand</option>
                                                    <option *ngFor="let d of lstBrands" [value]="d.BrandID">
                                                        {{d.BrandName}} </option>
                                                </select>
                                            </div>
                                            <div class="col-md-12 col-12" *ngIf="validationerror!=''"><label
                                                    class="mb-3 red">{{validationerror}}</label></div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="row">
                                            <div class="col-md-12 col-12">
                                                <label class="mb-3"> Category </label>
                                                <select name="ctrCategories" id="ctrCategories" class="form-control"
                                                    (change)="CategorychngeEvent($event)">
                                                    <option [value]="0" selected>Select category</option>
                                                    <option *ngFor="let d of lstCategories" [value]="d.CategoryID">
                                                        {{d.Categoryname}}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-12 col-12 mt-2" *ngIf="validationerror1!=''"><label
                                                    class="mb-3 red">{{validationerror1}}</label></div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label class="mb-3 invisible">Select Category </label>
                                        <button class="btn btn-danger w-100"
                                            (click)="SearchByProduct()">Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      
    </div>
</div>

<h1></h1>
<h2></h2>
<h3></h3>
<h4></h4>