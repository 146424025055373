import { Component, Injectable, Input, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { APICallingService } from '../model/APICallingService';
import { loginDetails } from '../UserDetails';
import { BrowserService } from '../browser.service';
import { Navigate } from '../navigate';
import { FacebookPixelService } from '../../facebook-pixel.service';
@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-thankyou-page',
  templateUrl: './thankyou-page.component.html',
  styleUrls: ['./thankyou-page.component.css']
})
export class ThankyouPageComponent implements OnInit {


  loginUserDetails: any;
  orderNo: string = "";
orderTotal:any='0';
  successOrderNo : string = "";
  successUserEmail : string = "";
  successUserPhone : string = "";

  constructor(private fbPixelService: FacebookPixelService,private browserService: BrowserService,private router: Navigate, private store: Store<any>, private APICALL: APICallingService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.loginUserDetails = new loginDetails();
    //debugger
    try {
      if (this.browserService.isBrowser()) {
     
      var result = (this.store as any).source['value']['MSECOM'].filter((x: any) => { return x.viewName == "loginDetails"; });
      if (result.length > 0) {

        this.loginUserDetails = (Object.assign({}, result[0]));
      }
    }
      //this.orderNo=this.GetOrderNo();
      this.ViewOrdersByuserId();
    } catch (e) {
      console.log(e)
    }
    if (typeof sessionStorage !== 'undefined') {
    this.successOrderNo = sessionStorage.getItem('successOrderNo')
    this.successUserEmail = sessionStorage.getItem('successUserEmail')
    this.orderTotal = sessionStorage.getItem('ordertTotal')
    
    this.successUserPhone = sessionStorage.getItem('successUserPhone')
    }
  }

  GetOrderNo() {
    //debugger
    return  (typeof sessionStorage !== 'undefined')? sessionStorage.getItem('orderno'):'';
  }

  lstofOrders: any = [];
  DbResult: any = [];
  ViewOrdersByuserId() {
    //debugger

 
    this.APICALL.DBCalling('OrderDetailsByUserID', this.loginUserDetails.UserID, '', '', '').subscribe((res) => {
      //debugger
      if (this.browserService.isBrowser()) {
      $("#preloader").hide();
      $("#loaderParent").hide();
      }
      this.DbResult = (res);

      if (this.DbResult.tasks.length > 0) {


          this.lstofOrders = this.DbResult.tasks[0];

        //  alert( (+this.lstofOrders[0].OrderNetTotal).toFixed(0));
      //  alert(((+this.lstofOrders[0].OrderNetTotal).toFixed(0)).toString());
          this.fbPixelService.track( 'Purchase', { order_id: this.successOrderNo,value: (+this.orderTotal), currency: 'INR'});

          this.orderNo = this.lstofOrders[0].OrderTrackingNumber;
          let len = this.lstofOrders.length - 1;
          console.log('lstOrdersLength', this.lstofOrders);
          for (var i = 0; i < this.lstofOrders.length; i++) {
            if (this.lstofOrders[i].Details != null && typeof (this.lstofOrders[i].Details) != undefined) {
              //debugger
              try {
                if (len == i) {
                  var Array = ((this.lstofOrders[i].Details).replace(/\n/g, "")).replace(/'/g, "\"");
                  this.lstofOrders[i].Details = JSON.parse(Array);
                  break;
                } else {
                  var Array = ((this.lstofOrders[i].Details).replace(/\n/g, "")).replace(/'/g, "\"");
                  this.lstofOrders[i].Details = JSON.parse(Array);
                }
                if (this.browserService.isBrowser()) {
                $("#preloader").hide();
                }
              }
              catch (error) { console.log(error) }
            }
          }
          //debugger
          // this.SendMailTouser(this.loginUserDetails.UserFirstName, this.loginUserDetails.UserEmail, this.lstofOrders[0])
          this.SendMailTouser(this.lstofOrders[0])
        }
    });
  }
  // OrderTrackingNumber
  // SendMailTouser(CustomerName, CustomerEmail, orderHistory) {
    SendMailTouser(orderHistory) {
    //debugger
    // this.APICALL.SendEmail(CustomerName, CustomerEmail, 'order Confirmation' + orderHistory.OrderTrackingNumber, orderHistory).subscribe((res) => {
      this.APICALL.SendEmailForCOD('order Confirmation' + orderHistory.OrderTrackingNumber,orderHistory.OrderTrackingNumber).subscribe((res) => {
      //debugger
      console.log('emailRes', res);
    })
  }

  orderClick() {
    this.router.navigateByUrl('orders');
  }

  ngAfterViewInit() {
    this.orderNo = this.GetOrderNo()
  }

  home(){
    //debugger
    this.router.navigateByUrl('home');
  }

  ngOnDestroy():void{
    //debugger
    if (typeof sessionStorage !== 'undefined') {
    sessionStorage.setItem('successOrderNo','')
    sessionStorage.setItem('successUserEmail','')
    sessionStorage.setItem('successUserPhone','')
    }
  }
}