<div class="container">
    <div class="checkout__page--inner d-flex">
        <p id="ctrCheckoutContainer"></p>
        <div class="row">



            <div class="main checkout__mian">
                <!-- mobile header starts  -->
                <aside class="checkout__sidebar sidebar none2 mobile">
                    <div class="cart__table checkout__product--table" *ngIf="lstCartList.length!=0">

                        <div class="row">
                            <div class="col-md-12 payment-options px-5">
                                <div class="form-check form-check-inline" (click)="paymentMode('Online Payment')" style="font-size: 15px;">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" [checked]="selectedPaymentMode == 'Online Payment' ? true:false">
                                    <label class="form-check-label" for="inlineRadio2"><b>Online Payment</b></label>
                                  </div>
    
                                <div class="form-check form-check-inline" (click)="paymentMode('COD')"  *ngIf="getTotalAmount() < 2000">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" [checked]="selectedPaymentMode == 'COD' ? true:false">
                                    <label class="form-check-label" for="inlineRadio1">COD</label>
                                    <!-- {{selectedPaymentMode}} -->
                                  </div>
                            </div>
                        </div>

                        <table class="cart__table--inner">
                            <tbody class="cart__table--body">



                                <tr class="cart__table--body__items" *ngFor="let crt of lstCartList;let ind =index">
                                    <td class="cart__table--body__list">
                                        <div class="product__image two  d-flex align-items-center">
                                            <div class="product__thumbnail border-radius-5">
                                                <a href="#"><img class="border-radius-5" src="{{crt.ProductImage}}"
                                                        alt="Buy online - {{crt.ProductName}}"></a>
                                                <span class="product__thumbnail--quantity">{{crt.Qty}}</span>
                                            </div>
                                            <div class="product__description">
                                                <h3 class="product__description--name h4"><a
                                                        href="#">{{crt.ProductName}}</a></h3>
                                                <span class="color__variant "
                                                    *ngIf="crt.OPGName1!=''"><b>{{crt.OPGName1}}: </b> {{crt.OPName1}}
                                                </span>
                                                <span class="color__variant"
                                                    *ngIf="crt.OPGName2!=''"><b>,{{crt.OPGName2}}: </b> {{crt.OPName2}}
                                                </span>
                                                <span class="color__variant"
                                                    *ngIf="crt.OPGName3!=''"><b>,{{crt.OPGName3}}: </b> {{crt.OPName3}}
                                                </span>
                                                <span class="color__variant"
                                                    *ngIf="crt.OPGName4!=''"><b>,{{crt.OPGName4}}: </b> {{crt.OPName4}}
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <!-- <td class="cart__table--body__list mobileaside">
                                            <span class="cart__price "><b class="rupee">{{storeSettings.currency}}</b>{{(+crt.SalesPrice).toFixed(0)}}</span>
                                        </td> -->
                                </tr>

                                <tr class="cart__table--body__items">
                                    <td class="cart__table--body__list justify-content-between pe-3 ps-3"><span
                                            class="font-weight-normal none">Value Of Product(s) </span>

                                        <span class="font-weight-normal rupee">{{storeSettings.currency}}
                                            {{(+objCart.OrderBaseGrossAmount).toFixed(0)}}</span>
                                    </td>

                                    <td class="cart__table--body__list justify-content-between pe-3 ps-3 text-success">
                                        <span class="font-weight-normal none">Discount(-) </span>
                                        <span class="font-weight-normal rupee text-success">{{storeSettings.currency}}
                                            {{((+objCart.OrderBaseGrossAmount)-(+objCart.OrderGrossAmount)).toFixed(0)}}</span>
                                    </td>
                                <tr class="cart__table--body__items">
                                    <td class="cart__table--body__list justify-content-between pe-3 ps-3"><span
                                            class="font-weight-normal none">Sub Total </span>
                                        <span class="font-weight-normal rupee">{{storeSettings.currency}}
                                            {{(+objCart.OrderGrossAmount).toFixed(0)}}</span>
                                    </td>




                                </tr>

                                <tr class="cart__table--body__items">

                                    <td class="cart__table--body__list">

                                        <div class="input-group mb-3">

                                            <!-- <select class="drpCoupon w-82" name="dropdown"></select> -->
                                            <select2 class="drpCoupon w-82 " style="    width: 78%;"
                                                (update)="couponUpdate($event)" [disabled]="couponstatus"
                                                (change)="CouponCodeChange($event)" placeholder="Select Coupon"
                                                [value]="AppliedCouponCode" [data]="lstCouponList"></select2>
                                            <!-- <input type="text" id="txtCouponCode"
                                                    name="txtCouponCode" (change)="CouponCodeChange($event)"
                                                    placeholder="Enter Coupon" aria-label="Enter Coupon"
                                                    aria-describedby="basic-addon2" [disabled]="couponstatus"
                                                    class="form-control bg__secondary"> -->

                                            <div class="input-group-append">
                                                <span id="basic-addon2" class="input-group-text bg__secondary apply_btn"
                                                    (click)="ApplyCoupon()">{{buttonText}}</span>
                                                <!-- <span *ngIf="couponstatus" id="basic-addon2"
                                                        class="input-group-text bg__secondary" (click)="ClearCoupon()"
                                                        style="background: #ea0029; color: white;place-content: center;width: 40px;">X</span> -->
                                            </div>
                                            <p *ngIf="couponError!=''" class="cpn_error"> <i
                                                    class="fa fa-warning coupoun_error"></i>{{couponError}}</p>
                                            <p *ngIf="couponaplymsg!=''" class="text-success"> <b>{{couponaplymsg}}</b>
                                            </p>

                                        </div>




                                    </td>
                                </tr>

                                <tr class="cart__table--body__items">
                                    <td class="cart__table--body__list justify-content-between pe-3 ps-3"><span
                                            class="font-weight-normal none text-success text-success">Coupon Discount(-)
                                        </span>
                                        <span class="font-weight-normal rupee text-success">{{storeSettings.currency}}
                                            {{(+objCart.CouponDiscount).toFixed(0)}}</span>

                                    </td>

                                </tr>

                                <tr class="cart__table--body__items">
                                    <td class="cart__table--body__list justify-content-between pe-3 ps-3"><span
                                            class="font-weight-normal none">CGST({{(+objCart.CGSTPer).toFixed(2)}}%)
                                        </span>
                                        <span class="font-weight-normal rupee">{{storeSettings.currency}}
                                            {{(+objCart.CGSTAmt).toFixed(2)}}</span>

                                    </td>

                                </tr>
                                <tr class="cart__table--body__items">
                                    <td class="cart__table--body__list justify-content-between pe-3 ps-3"><span
                                            class="font-weight-normal none">SGST ({{(+objCart.SGSTPer).toFixed(2)}}%)
                                        </span>
                                        <span class="font-weight-normal rupee">{{storeSettings.currency}}
                                            {{(+objCart.SGSTAmt).toFixed(2)}}</span>

                                    </td>

                                </tr>
                                <tr class="cart__table--body__items">
                                    <td class="cart__table--body__list justify-content-between pe-3 ps-3"><span
                                            class="font-weight-normal none">Delivery Charges </span>
                                        <span class="font-weight-normal rupee">


                                            <span *ngIf="((+objCart.DeliveryCharges)-(+deliveryDiscount))<=0"
                                                class="text-success"><b class="rupee"> </b>Free</span>

                                            <span class="cart__price"
                                                *ngIf="((+objCart.DeliveryCharges)-(+deliveryDiscount))>0">
                                                <b class="rupee">{{storeSettings.currency}}</b>
                                                <span>
                                                    {{((+objCart.DeliveryCharges)-((+deliveryDiscount)))}}</span>
                                                <span class="delvy_chgs" *ngIf="(+deliveryDiscount)>0">(
                                                    {{((+objCart.DeliveryCharges))
                                                    }})
                                                </span>

                                            </span>

                                            <!-- <span *ngIf="((+objCart.DeliveryCharges)-(+deliveryDiscount))<=0" class="font-weight-normal rupee" 
                                            style="text-decoration: line-through; color: red;">
                                            {{storeSettings.currency}}{{(+objCart.DeliveryCharges)
                                            }}</span>
                                            <span class="font-weight-normal rupee" *ngIf="((+objCart.DeliveryCharges)-(+deliveryDiscount))>0">
                                                {{storeSettings.currency}}
                                               

                                                <span  >
                                                    {{((+objCart.DeliveryCharges)-((+deliveryDiscount)))
                                                    }}
                                                </span>

                                               ( <span style="text-decoration: line-through; color: red"  *ngIf="(+deliveryDiscount)>0">
                                                    {{((+objCart.DeliveryCharges))
                                                    }}
                                                </span>)
                                                 
                                               </span> -->

                                        </span>

                                    </td>
                                    <!-- <td class="cart__table--body__list" colspan="2">
                                        <label class="text-left">Delivery Charges
                                            <span class="cart__price" style="float: right;">
                                                <i *ngIf="objCart.DeliveryCharges==0" aria-hidden="true"
                                                    class="fa fa-truck" style="margin-right: 5px;"></i>
                                                <span *ngIf="objCart.DeliveryCharges==0"
                                                    style="text-decoration: line-through; color: red;"><b
                                                        class="rupee">{{storeSettings.currency}}</b>{{(+objCart.DeliveryCharges)
                                                    }}</span>
                                                <span class="cart__price" *ngIf="+objCart.DeliveryCharges>0"><b
                                                        class="rupee">{{storeSettings.currency}}</b>{{((+objCart.DeliveryCharges))
                                                    }}</span>
                                            </span>
                                        </label>
                                    </td> -->

                                </tr>

                                <tr class="cart__table--body__items">
                                    <td class="cart__table--body__list justify-content-between pe-3 ps-3"><span
                                            class="font-weight-normal none"><b>Final Payment</b></span>
                                        <span class="font-weight-normal rupee"><b>{{storeSettings.currency}}

                                            {{getTotalAmount()}}
                                                <!-- {{(((+objCart.OrderNetTotal)-((+objCart.CouponDiscount)))+
                                                (((+objCart.DeliveryCharges)-(+deliveryDiscount))>0 ?
                                                ((+objCart.DeliveryCharges)-(+deliveryDiscount)):0)).toFixed(0)}} -->
                                            </b></span>

                                    </td>

                                </tr>
                            </tbody>
                        </table>


                    </div>
                    <div id="popup1" class="overlay" [ngStyle]="{'display':paymentdisplay}">
                        <div class="popup">
                            <h3 class="wlcm-pymt">Welcome to Payment gateway
                            </h3>

                            <a class="close" (click)="closeLoginModal()">&times;</a>
                            <div class="content">
                                <div class="row">
                                    <div class="col-md-10 mx-auto">

                                        <!-- <ngx-paypal [config]="payPalConfig"></ngx-paypal> -->

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="popup2" class="overlay" *ngIf="StockValidationdisplay">

                        <div class="popup12">
                            <h3 class="textt">Invalid stock details <a class="float-right" (click)="closeModalPopup()"
                                    class="close">×</a></h3>


                            <div class="content">
                                <div class="row">
                                    <div class="col-lg-12 mt-3">
                                        <div class="cart__table">
                                            <table class="cart__table--inner">
                                                <thead class="cart__table--header">
                                                    <tr class="cart__table--header__items">
                                                        <th class="cart__table--header__list text-center">Product Name
                                                        </th>

                                                        <th class="cart__table--header__list text-center">Available
                                                            Quantity</th>

                                                    </tr>
                                                </thead>
                                                <tbody class="cart__table--body">
                                                    <tr class="cart__table--body__items"
                                                        *ngFor="let crt of lstInvalidstock;let ind =index">
                                                        <td class="cart__table--body__list">
                                                            <div class="cart__product d-flex align-items-center">

                                                                <div class="cart__thumbnail">
                                                                    <a href="#"><img class="border-radius-5"
                                                                            src={{ImagePath+crt.Image1}}
                                                                            alt="Buy online - {{crt.ProductName}}"></a>
                                                                </div>
                                                                <div class="cart__content">
                                                                    <h4 class="cart__content--title">
                                                                        <a>{{crt.ProductName}}</a>
                                                                    </h4>
                                                                    <span class="color__variant"
                                                                        *ngIf="crt.OPGName1!=''"><b>{{crt.OPGName1}}:
                                                                        </b> {{crt.OPName1}} </span>
                                                                    <span class="color__variant"
                                                                        *ngIf="crt.OPGName2!=''"><b>,{{crt.OPGName2}}:
                                                                        </b> {{crt.OPName2}} </span>
                                                                    <span class="color__variant"
                                                                        *ngIf="crt.OPGName3!=''"><b>,{{crt.OPGName3}}:
                                                                        </b> {{crt.OPName3}} </span>
                                                                    <span class="color__variant"
                                                                        *ngIf="crt.OPGName4!=''"><b>,{{crt.OPGName4}}:
                                                                        </b> {{crt.OPName4}} </span>

                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="cart__table--body__list">
                                                            <h4 class="cart__content--title"><a>{{crt.AvailableQty}}</a>
                                                            </h4>
                                                        </td>

                                                    </tr>

                                                </tbody>
                                            </table>

                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </aside>
                <!-- mobile header ends  -->




                <main class="main__content_wrapper">

                    <div class="container">
                        <div class="row">

                            <h2 class="cart__title mb-3 p-0">Contact information</h2>
                            <div class="col-md-12 mb-0 p-0">
                                <p class="site-map text-end">
                                    <span (click)="home()" role="button">Home</span> <i class="fa fa-chevron-right mx-3"
                                        aria-hidden="true"></i>
                                    <span role="button">Checkout</span>
                                </p>
                            </div>


                        </div>
                    </div>

                    <form action="#">
                        <div class="checkout__content--step section__contact--information">
                            <div
                                class="section__header checkout__section--header d-flex align-items-center justify-content-between mb-25">
                                <!-- <h2 class="section__header--title h3">Contact information</h2> -->
                                <p *ngIf="formValidation" class="text-danger">Please Fill all the neccessary Details!..
                                </p>
                                <p class="layout__flex--item" *ngIf="loginUserDetails.UserID == 0">
                                    Already have an account?
                                    <a class="layout__flex--item__link" (click)="loginClick()">Log in</a>
                                </p>
                            </div>
                            <div class="customer__information">
                                <div class="checkout__email--phone mb-12">
                                    <label>
                                        <input class="checkout__input--field border-radius-5" autocomplete="off"
                                            placeholder="Email or mobile phone number" name="UserEmail" type="text"
                                            [(ngModel)]="loginUserDetails.UserEmail"
                                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" required>
                                    </label>

                                </div>

                            </div>
                        </div>
                        <div class="checkout__content--step section__shipping--address">
                            <div class="section__header mb-25">
                                <h3 class="section__header--title">Shipping address</h3>
                            </div>
                            <div class="section__shipping--address__content">
                                <div class="row">
                                    <div class="col-lg-6 mb-12">
                                        <div class="checkout__input--list ">
                                            <label>
                                                <input class="checkout__input--field border-radius-5"
                                                    placeholder="First name (optional)" autocomplete="off" type="text"
                                                    name="firstname" [(ngModel)]="loginUserDetails.UserFirstName">

                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 mb-12">
                                        <div class="checkout__input--list">
                                            <label>
                                                <input class="checkout__input--field border-radius-5"
                                                    placeholder="Last name" type="text" autocomplete="off"
                                                    name="lastname" [(ngModel)]="loginUserDetails.UserLastName">
                                            </label>
                                        </div>
                                    </div>

                                    <div class="col-12 mb-12">
                                        <div class="checkout__input--list">
                                            <label>
                                                <input class="checkout__input--field border-radius-5"
                                                    placeholder="Address1" type="text" autocomplete="off" name="address"
                                                    [(ngModel)]="loginUserDetails.UserAddress">
                                            </label>
                                        </div>
                                    </div>

                                    <div class="col-12 mb-12">
                                        <div class="checkout__input--list">
                                            <label>
                                                <input class="checkout__input--field border-radius-5" placeholder="City"
                                                    autocomplete="off" type="text" name="city"
                                                    [(ngModel)]="loginUserDetails.UserCity">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 mb-12">
                                        <div class="checkout__input--list">

                                            <label>
                                                <input class="checkout__input--field border-radius-5"
                                                    placeholder="Country" type="text"
                                                    [(ngModel)]="loginUserDetails.UserCountry" name="UserCountry"
                                                    autocomplete="off">

                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 mb-12">
                                        <div class="checkout__input--list">
                                            <label>
                                                <input class="checkout__input--field border-radius-5"
                                                    placeholder="Postal code" autocomplete="off"
                                                    (input)="Zipcode($event)" type="text" name="zip"
                                                    [(ngModel)]="loginUserDetails.UserZip">

                                                    
                                            </label>
                                            <label *ngIf="DeliveryError!=''"
                                                class="text-danger">{{DeliveryError}}</label>


                                        </div>
                                    </div>
                                </div>
                                <div class="checkout__checkbox">
                                    <input class="checkout__checkbox--input" id="check2" type="checkbox">
                                    <span class="checkout__checkbox--checkmark"></span>
                                    <label class="checkout__checkbox--label" for="check2">
                                        Save this information for next time</label>


                                </div>
                                <!-- <div class="minicart__conditions justify-content-start">
                                    <input class="minicart__conditions--input" id="accept" type="checkbox"
                                        (change)="PrivacyPolicy(PrivacyPolicycheck? true:false)">
                                    <label class="minicart__conditions--label" for="accept">I agree with the <a
                                            class="minicart__conditions--link" href="#privacy&policy">Privacy and Policy
                                        </a><sup class="text-danger">{{CheckMarkerrror}}</sup></label>
                                </div> -->
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="checkout__content--step__footer d-flex align-items-center mb-5" *ngIf="DeliveryError==''">
                                
                                    <a title="Cash On Delivery" class="continue__shipping--btn primary__btn border-radius-5 custom-tooltip text-wrap w-100 text-center"
                                (click)="confirmCOD('COD')"
                                    *ngIf="lstCartList.length!=0 && (+(((+objCart.OrderNetTotal)-((+objCart.CouponDiscount))) + 
                                    (((+objCart.DeliveryCharges)-(+deliveryDiscount))>0 ? ((+objCart.DeliveryCharges)-(+deliveryDiscount)):0)).toFixed(0)) <=2000 && selectedPaymentMode == 'COD'">Cash
                                    on Delivery</a>
                                    
                                    &nbsp;&nbsp;
                                    <br>
                              
                                <a class="continue__shipping--btn primary__btn border-radius-5 text-wrap w-100 text-center" title="Pay With Card"  (click)="CheckStockByProducts('CardPayment')"
                                 *ngIf="lstCartList.length!=0 && selectedPaymentMode == 'Online Payment'">Pay With Card</a>
                            </div>
                             <p class="m-0"><b>Note :</b> * No Cash-On-Delivery above <span class="text-danger">₹ 2000/-</span></p>
                            </div>
                        </div>

                        <!-- (click)="CheckStockByProducts()"  -->
                    </form>
                </main>

            </div>


            <aside class="checkout__sidebar sidebar none3">
                <div class="cart__table checkout__product--table" *ngIf="lstCartList.length!=0">
                    <div class="row">
                        <div class="col-md-12 payment-options">
                            <div class="form-check form-check-inline" (click)="paymentMode('Online Payment')">
                                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" [checked]="selectedPaymentMode == 'Online Payment' ? true:false">
                                <label class="form-check-label" for="inlineRadio2">Online Payment</label>
                              </div>


                              <div class="form-check form-check-inline" (click)="paymentMode('COD')"  *ngIf="getTotalAmount() < 2000">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" [checked]="selectedPaymentMode == 'COD' ? true:false">
                                    <label class="form-check-label" for="inlineRadio1">COD</label>
                              </div>
                        </div>
                    </div>
                    <table class="cart__table--inner">
                        <tbody class="cart__table--body">
                            <tr class="cart__table--body__items" *ngFor="let crt of lstCartList;let ind =index">
                                <td class="cart__table--body__list">
                                    <div class="product__image two  d-flex align-items-center">
                                        <div class="product__thumbnail border-radius-5">
                                            <a href="#"><img class="border-radius-5" src="{{crt.ProductImage}}"
                                                    alt="Buy online - {{crt.ProductName}}"></a>
                                            <span class="product__thumbnail--quantity">{{crt.Qty}}</span>
                                        </div>
                                        <div class="product__description">
                                            <h3 class="product__description--name h4"><a
                                                    href="#">{{crt.ProductName}}</a></h3>
                                            <span class="color__variant" *ngIf="crt.OPGName1!=''"><b>{{crt.OPGName1}}:
                                                </b> {{crt.OPName1}} </span>
                                            <span class="color__variant" *ngIf="crt.OPGName2!=''"><b>,{{crt.OPGName2}}:
                                                </b> {{crt.OPName2}} </span>
                                            <span class="color__variant" *ngIf="crt.OPGName3!=''"><b>,{{crt.OPGName3}}:
                                                </b> {{crt.OPName3}} </span>
                                            <span class="color__variant" *ngIf="crt.OPGName4!=''"><b>,{{crt.OPGName4}}:
                                                </b> {{crt.OPName4}} </span>
                                        </div>
                                    </div>
                                </td>
                                <td class="cart__table--body__list">
                                    <span class="cart__price"><b class="rupee">{{storeSettings.currency}}</b>{{(+crt.Price).toFixed(0)}}</span>
                                </td>
                            </tr>

                            <tr class="cart__table--body__items">
                                <td class="cart__table--body__list">
                                    <label class="text-left">Value Of Product(s) </label>
                                </td>
                                <td class="cart__table--body__list">
                                    <span class="cart__price"><b
                                            class="rupee">{{storeSettings.currency}}</b>{{(+objCart.OrderBaseGrossAmount).toFixed(0)}}</span>
                                </td>
                            </tr>


                            <tr class="cart__table--body__items">
                                <td class="cart__table--body__list">
                                    <label class="text-left text-success">Discount(-) </label>
                                </td>
                                <td class="cart__table--body__list">
                                    <span class="cart__price text-success"><b
                                            class="rupee">{{storeSettings.currency}}</b>{{((+objCart.OrderBaseGrossAmount)-(+objCart.OrderGrossAmount)).toFixed(0)}}</span>
                                </td>
                            </tr>


                            <tr class="cart__table--body__items">
                                <td class="cart__table--body__list">
                                    <label class="text-left">Sub Total </label>
                                </td>
                                <td class="cart__table--body__list">
                                    <span class="cart__price"><b
                                            class="rupee">{{storeSettings.currency}}</b>{{(+objCart.OrderGrossAmount).toFixed(0)}}</span>
                                </td>
                            </tr>

                            <tr class="cart__table--body__items">
                                <td class="cart__table--body__list" colspan="2">
                                    <div class="input-group mb-3">
                                        <!-- <input type="text" id="txtCouponCode"
                                            name="txtCouponCode" (change)="CouponCodeChange($event)"
                                            placeholder="Enter Coupon" aria-label="Enter Coupon"
                                            aria-describedby="basic-addon2" [disabled]="couponstatus"
                                            class="form-control bg__secondary"> -->
                                        <!-- web dropdown -->
                                        <!-- <select class="drpCoupon w-85" name="dropdown"> </select> -->
                                        <select2 class="drpCoupon w-85" [disabled]="couponstatus"
                                            (change)="CouponCodeChange($event)" placeholder="Select Coupon"
                                            [data]="lstCouponList" (update)="couponUpdate($event)"
                                            [value]="AppliedCouponCode"></select2>
                                        <div class="input-group-append">
                                            <!-- -->
                                            <span id="basic-addon2" class="input-group-text bg__secondary apply_btn"
                                                (click)="ApplyCoupon()">{{buttonText}}</span>
                                            <!-- <span *ngIf="couponstatus" id="basic-addon2"
                                                class="input-group-text bg__secondary" (click)="ClearCoupon()"
                                                style="background: #ea0029; color: white;place-content: center;width: 40px;">X</span> -->
                                        </div>
                                    </div>
                                    <label *ngIf="couponError!=''" class="text-danger"> <i
                                            class="fa fa-warning cpn-error"></i>{{couponError}}</label>
                                    <label *ngIf="couponaplymsg!=''" class="text-success">
                                        <b>{{couponaplymsg}}</b></label>

                                </td>
                            </tr>

                            <tr class="cart__table--body__items">
                                <td class="cart__table--body__list">
                                    <label class="text-left text-success">Coupon Discount(-) </label>
                                </td>
                                <td class="cart__table--body__list">
                                    <span class="cart__price text-success"><b
                                            class="rupee">{{storeSettings.currency}}</b>{{(+objCart.CouponDiscount).toFixed(0)}}</span>
                                </td>
                            </tr>
                            <tr class="cart__table--body__items">
                                <td class="cart__table--body__list">
                                    <label class="text-left">CGST({{(+objCart.CGSTPer).toFixed(2)}}%)</label>
                                </td>
                                <td class="cart__table--body__list">
                                    <span class="cart__price fw-nrml"><span
                                            class="mx-2">{{storeSettings.currency}}</span>{{(+objCart.CGSTAmt).toFixed(2)}}</span>
                                </td>
                            </tr>
                            <tr class="cart__table--body__items">
                                <td class="cart__table--body__list">
                                    <label class="text-left">SGST ({{(+objCart.SGSTPer).toFixed(2)}}%)</label>
                                </td>
                                <td class="cart__table--body__list">
                                    <span class="cart__price fw-nrml"><span
                                            class="mx-2">{{storeSettings.currency}}</span>{{(+objCart.SGSTAmt).toFixed(2)}}</span>
                                </td>
                            </tr>
                            <tr class="cart__table--body__items">
                                <td class="cart__table--body__list" colspan="2">
                                    <label class="text-left">Delivery Charges
                                        <span class="cart__price float-right">
                                            <i *ngIf="((+objCart.DeliveryCharges)-(+deliveryDiscount))<=0" aria-hidden="true" class="mr-05"></i>
                                            <span *ngIf="((+objCart.DeliveryCharges)-(+deliveryDiscount))<=0 && selectedPaymentMode == 'Online Payment'"
                                                class="text-success"><b class="rupee"> </b>Free</span>
                                            <span class="cart__price"
                                                *ngIf="((+objCart.DeliveryCharges)-(+deliveryDiscount))>0 || selectedPaymentMode != 'Online Payment'">
                                                <b class="rupee">{{storeSettings.currency}}</b>
                                                <span>
                                                    {{((+objCart.DeliveryCharges)-((+deliveryDiscount)))}}
                                                </span>

                                                <span class="dvry-chrgs" *ngIf="(+deliveryDiscount)>0">(
                                                    {{((+objCart.DeliveryCharges))}})
                                                </span>
                                            </span>
                                        </span>
                                    </label>
                                </td>

                            </tr>

                            <tr class="cart__table--body__items">
                                <td class="cart__table--body__list">
                                    <label class="text-left"><b>Final Payment</b></label>
                                </td>
                                <td class="cart__table--body__list">
                                    <span class="cart__price"><b class="rupee">{{storeSettings.currency}}</b>
                                        
                                        <!-- {{(((+objCart.OrderNetTotal)-((+objCart.CouponDiscount))
                                        )+ (((+objCart.DeliveryCharges)-(+deliveryDiscount))>0 ?
                                        ((+objCart.DeliveryCharges)-(+deliveryDiscount)):0)).toFixed(0)}} -->
                                            
                                        {{getTotalAmount()}}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    
                </div>
                <div id="popup1" class="overlay" [ngStyle]="{'display':paymentdisplay}">
                    <div class="popup">
                        <h3 class="wlcm-pymt">Welcome to Payment gateway</h3>

                        <a class="close" (click)="closeLoginModal()">&times;</a>
                        <div class="content">
                            <div class="row">
                                <div class="col-md-10 mx-auto">
                                    <!-- <ngx-paypal [config]="payPalConfig"></ngx-paypal> -->

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="popup2" class="overlay" *ngIf="StockValidationdisplay">

                    <div class="popup12">
                        <h3 class="textt">Invalid stock details <a class="float-right" (click)="closeModalPopup()"
                                class="close">×</a></h3>


                        <div class="content">
                            <div class="row">
                                <div class="col-lg-12 mt-3">
                                    <div class="cart__table">
                                        <table class="cart__table--inner">
                                            <thead class="cart__table--header">
                                                <tr class="cart__table--header__items">
                                                    <th class="cart__table--header__list text-center">Product Name</th>

                                                    <th class="cart__table--header__list text-center">Available Quantity
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody class="cart__table--body">
                                                <tr class="cart__table--body__items"
                                                    *ngFor="let crt of lstInvalidstock;let ind =index">
                                                    <td class="cart__table--body__list">
                                                        <div class="cart__product d-flex align-items-center">

                                                            <div class="cart__thumbnail">
                                                                <a href="#"><img class="border-radius-5"
                                                                        src={{ImagePath+crt.Image1}}
                                                                        alt="Buy online - {{crt.ProductName}}"></a>
                                                            </div>
                                                            <div class="cart__content">
                                                                <h4 class="cart__content--title">
                                                                    <a>{{crt.ProductName}}</a>
                                                                </h4>
                                                                <span class="color__variant"
                                                                    *ngIf="crt.OPGName1!=''"><b>{{crt.OPGName1}}: </b>
                                                                    {{crt.OPName1}} </span>
                                                                <span class="color__variant"
                                                                    *ngIf="crt.OPGName2!=''"><b>,{{crt.OPGName2}}: </b>
                                                                    {{crt.OPName2}} </span>
                                                                <span class="color__variant"
                                                                    *ngIf="crt.OPGName3!=''"><b>,{{crt.OPGName3}}: </b>
                                                                    {{crt.OPName3}} </span>
                                                                <span class="color__variant"
                                                                    *ngIf="crt.OPGName4!=''"><b>,{{crt.OPGName4}}: </b>
                                                                    {{crt.OPName4}} </span>

                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td class="cart__table--body__list">
                                                        <h4 class="cart__content--title"><a>{{crt.AvailableQty}}</a>
                                                        </h4>
                                                    </td>

                                                </tr>

                                            </tbody>
                                        </table>

                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </aside>

        </div>
    </div>
    <h1></h1>
    <h2></h2>
    <h3></h3>
    <h4></h4>
</div>

<!-- modal popup start of Eazy Pay Pro -->
<!-- <div id="id01" class="w3-modal" [ngStyle]="{'display':paymentmodaldisplay}">
    <div class="w3-modal-content">
      <div class="w3-container" style="padding: 20px;">
        <h4 class="section__header--title">Eazy Pay Pro</h4>
        <hr>
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <iframe [src]="objPaymentGatway.productionUrl" id="paymentFrame" width="482" height="450" frameborder="0" scrolling="No" style="margin:0px !important;width:100%;"></iframe>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div> -->