<div class="container">
    <div class="row">
        <div class="col-md-12 text-center mt-5 mb-5">
            <h1 class="text-danger">FAQ's</h1>
        </div>
    </div>

    <div class="row mb-5" *ngFor="let d of lstFaq">
        <div class="col-md-12 mb-4">
            <h3>{{d.GroupType}}</h3>
        </div>
        <div class="col-md-12 faq mb-4" *ngFor="let e of d.childFaqs;let i=index">
            <h3 class="heading" (click)="viewdetails(e.ID)"><i class="fa fa-chevron-down"></i> {{e.Heading}}</h3>
            <div [ngClass]="active == e.ID ? 'description d-block' : 'd-none'">
                <span [innerHtml]="e.Description"></span>
            </div>
        </div>
    </div>
</div>