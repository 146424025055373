<h2 class="offer">REFUNDS & RETURNS</h2>

<div class="container">
    <h2>No Questions asked policy?</h2>
    <p>We have a flexible 7 days no questions asked returns policy which is absolutely customer friendly. If you do not find the product(s) satisfying, you can return it as long as the following conditions are met:</p>
    <p>Product is unused, unwashed and in original condition. You are welcome to try on a product but please take adequate measure to preserve its condition</p>
    <p>The price tags, brand tags, shoe-box and all original packaging must be present</p>
    <p>The product must be returned within 7 days</p>

    <h1>How does the return process work?</h1>
    <p>In case you wish to return the product, please follow these steps:</p>
    <p>Go the ‘My Order’ and select the order from which you wish to return the items</p>
    <p>Click ‘Return Product’ option</p>
    <p>Please select the reason for return</p>
    <p>Select where you want your refund back at <br> a) Original Mode of Payment <br> b) Credit Points</p>
    <p>Select the address where you want the pickup to be done from</p>
    <p>A return id will be generated against that product</p>
    <p>A pickup agent will come and do the Quality Check on the product you are returning to ensure return conditions are met</p>
    <p>Post the successful pickup, your refund will be initiated in next 2-3 working days</p>
    <p>Once the refund is initiated, the amount will be reflecting in your statement within 7 working days</p>

    <h1>When can I not return my product?</h1>
    <p>You will not be able to return the product in the following conditions:</p>
    <p> The return window of 7 days is over </p>
    <p>The quality check of the product is not successful</p>
    <p>The product is non-returnable (Masks and Intimates)</p>

    <h1>Can I return the product to the delivery person in case I don't like it ?</h1>
    <p>No. In case you wish to return the product, please create a return for the product and hand over to the pickup agent assigned to pick up your product.</p>
    <h1>Can I exchange my product with another ?</h1>
    <p>Unfortunately, we do not have an exchange policy right now. We only accept returns and we do have an easy 7 days return policy available.</p>
    <h1>When will I get my refund?</h1>
    <p>Your refund is initiated within 2-3 business days post successful pickup of your product. <br>
        It would take 3-5 working days post initiation from our end for the refund to reflect in your bank account/credit card statement.</p>

        <h1>Which are the products that can not be returned?</h1>
        <p>To maintain the hygiene standards of the products, we do not offer returns on masks and intimate products. <br>However, if you've received a non-returnable product in a damaged/defective condition, please drop us a mail with pictures attached immediately to initiate a request for refund.</p>

        <h1>Do I have to return the whole oder?</h1>
        <p>No. You can select the products in the order which you wish to return from our ‘My Orders’ page in your account</p>

        <h1>Can I return the product at a nearby store?</h1>
        <p>No. You can not return the product to any offline stores. Please follow the steps mentioned in FAQs to initiate the return from your online account and hand it over to the pickup agent assigned.</p>
        <h1>When will the product by picked up?</h1>
        <p>Usually, the products will be pickup up from your address within 2-3 days after the return request is raised. In case if any delays or issues, please drop us a mail and we’ll be happy to help</p>

        <h1>Have not received my refund amount? What should I do ?</h1>
        <p>In an unlikely scenario you have not received your refund within 7 business days post the pickup of the product, please drop us a mail and we’ll be happy to assist you with regards to it.</p>

        <h1 class="ln-height">The product is received is different than what I ordered. But the pickup agent is not picking it up by not clearing the Quality Check. What should I do?</h1>
        <p>In case you receive a product different than what you ordered, please create a return and share the return details along with the picture to your customer service mail id.<br> We’ll ensure you do not face any issues while the quality check during the pickup of the product..</p>
        <h1>Where will I get my refund?</h1>
        <p>While creating the return, you’ll get a choice of getting the refund back to the original source of payment, or in your account wallet as credit points. 
             Once selected, we would not be able to reverse the refund to a different source. </p>

             <h1>Will I get Credit points and Loyalty points refunds post return?</h1>
             <p>Your credit points or loyalty points will be credited back to your account within 2 business days after the successful pickup of the product or the cancellation of the product.</p>

             <h1></h1>
        <h2></h2>
        <h3></h3>
        <h4></h4>

            </div>