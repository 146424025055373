import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptoServiceService {
  private initVector: string = '000102030405060708090a0b0c0d0e0f';
  constructor() { }

  encrypt(plainText: string, key: string): string {
    let md5Key = CryptoJS.MD5(key);
    let cipherText = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(plainText), CryptoJS.enc.Hex.parse(md5Key.toString()), {
      iv: CryptoJS.enc.Hex.parse(this.initVector)
    });
    return cipherText.ciphertext.toString(CryptoJS.enc.Hex);
  }


  encryptResult(plainText: string, key: string): string {
    //debugger
    const encrypted = CryptoJS.AES.encrypt(plainText, key).toString();
    const encRequest = encrypted;

return encRequest;
    // let md5Key = CryptoJS.MD5(key);
    // let cipherText = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(plainText), CryptoJS.enc.Hex.parse(md5Key.toString()), {
    //   iv: CryptoJS.enc.Hex.parse(this.initVector)
    // });
    // return cipherText.ciphertext.toString(CryptoJS.enc.Hex);
  }

  decrypt(encryptedText: string, key: string): string {
    let md5Key = CryptoJS.MD5(key);
    let cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Hex.parse(encryptedText)
    });
    let decryptedText = CryptoJS.AES.decrypt(cipherParams, CryptoJS.enc.Hex.parse(md5Key.toString()), {
      iv: CryptoJS.enc.Hex.parse(this.initVector)
    });
    return decryptedText.toString(CryptoJS.enc.Utf8);
  }
}
