import { Component, OnInit } from '@angular/core';
import $ from 'jquery';
import * as PageStore from "../../../src/app/Store/PageStore/Page.Actions";
import { ActivatedRoute, Router } from '@angular/router';
import { Cart } from '../Cart';
import { Store } from '@ngrx/store';
import { AppSettings, IAppSettings } from '../model/AppSettings';
import { APICallingService } from '../model/APICallingService';
import { loginDetails } from '../UserDetails';
import { AppComponent } from '../app.component';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { BrowserService } from '../browser.service';
import { Navigate } from '../navigate';
@Component({
  selector: 'app-returnsmyorders',
  templateUrl: './returnsmyorders.component.html',
  styleUrls: ['./returnsmyorders.component.css']
})
export class ReturnsmyordersComponent implements OnInit {
  hideTable: boolean = true;
  returnlstProducts: any = [];
  modal: string = "none";
  ImagePath: string = "";
  replaceQty: number = 0;

  constructor(private browserService: BrowserService,private route: ActivatedRoute, private store: Store<any>, private App: AppComponent, public objCart: Cart, public appSettings: AppSettings, public router:Navigate, private apiCall: APICallingService) {
    if (this.browserService.isBrowser()) {
    this.storeSettings = this.appSettings.loadSettings();
    this.ImagePath = this.storeSettings.apiCallingUrl;
    }
  }

  storeSettings: IAppSettings =
    {

      faxUserId: '',
      faxPassword: '',
      companyName: '',
      theamColorCode: '#ea0029',
      headerColorCode: '#000',
      headerimage: '../../assets/img/trending1.png',
      loginBackgroundImage: '../../assets/bg-1.jpg',
      loginImage: '../../assets/img/logo_web.png',
      dangerColorCode: '#dc3545',
      successColorCode: '#28a745',
      logo: '../../assets/img/logo_web.png',
      paymentGateway: '',
      key: '',
      selectedBranch: '',
      apiLoginId: '',
      orderEmail: '',
      adminUrl: '',
      orderUrl: '',
      viewName: 'AppSettings',
      apiCallingUrl: '',
      fontFamily: 'prompt,sans-serif',
      domainName: '',
      fax: '',
      projectId: '',

      keyNo: '',
      noOfBranches: 0,

      currency: 'Rs ',
      transactionkey: '',
    };

  ReturnId: any = [];
  ngOnInit(): void {
    if (this.browserService.isBrowser()) {
    $("#preloader").hide();
    }
    //debugger

    if (this.browserService.isBrowser()) {
    var result = (this.store as any).source['value']['MSECOM'].filter((x: any) => { return x.viewName == "DeliveredList"; });
    if (result.length > 0) {
      this.returnlstProducts = (Object.assign({}, result[0].lstproductlist));
      // console.log(this.returnlstProducts)
    }
  }
  }

  Reasontext: string = "";
  Reasonselect(event) {
    //debugger
    if (event != '') {
      //debugger
      this.errormsge = false;
      this.Reasontext = event.target.value;
      if (this.Reasontext == 'Wrong size / color') {
        //debugger


      } else if (this.Reasontext == 'Damaged or defective') {
        //debugger
      }
    } else {
      this.errormsge = true
    }


  }
  DbResult: any = [];
  errormsge = false;
  xml: string = "";
  returnType: string = "";

  ReturnProduct(d) {
    //debugger
    this.returnType = d;
    if (this.Reasontext == '') {
      //debugger
      this.errormsge = true
    } else {
      //debugger
      if (this.Reasontext == 'Wrong size / color' || this.Reasontext == "Damaged or defective" && d == 'Replace' || d == 'Return') {
        //debugger
        this.modal = 'block';
        this.viewProductDetail();
      } else {

      }
    }
  }

  saveReplace() {
    //debugger
    if (this.replaceQty > this.returnlstProducts.Qty) {

    } else {
     
      $("#preloader").show();
      
      this.errormsge = false;
      if (this.returnlstProducts.Qty == 1) {
        this.replaceQty = 1;
      }
      let replaceOption = "Color : " + this.color + ", " + "Size : " + this.size;

      var xml1 = '<NewDataSet><Table1>'
        + '<replaceOption>' + replaceOption + '</replaceOption>'
        + '<OrderDetailsStatus>' + 'Replace' + '</OrderDetailsStatus>'
        + '<ReturnProductOptionID>' + this.ProductOptionID + '</ReturnProductOptionID>'
        + '<ReplaceQty>' + this.replaceQty + '</ReplaceQty>'
        + '</Table1></NewDataSet>';


      this.apiCall.DBCalling('SaveReturnsProducts', this.returnlstProducts.OrderDetailsID, this.Reasontext, this.returnlstProducts.OrderID, xml1).subscribe(
        (res) => {
          //debugger

          $("#preloader").hide();
          
          this.DbResult = (Object.assign([], res));
          if (this.DbResult.tasks[0].length > 0) {
            this.modal = 'none';
            var that = this;

            Swal.fire({
              position: 'center',
              icon: 'success',
              text: 'Your Request has been submitted sucessfully.'
            }).then(function () {
              that.ViewOrders()
            });
          }
        })
    }

  }

  saveReturn() {
    //debugger
    var xml1 = '<NewDataSet><Table1>'
      + '<replaceOption>' + '' + '</replaceOption>'
      + '<OrderDetailsStatus>' + 'Return' + '</OrderDetailsStatus>'
      + '<ReplaceQty>' + this.replaceQty + '</ReplaceQty>'
      + '</Table1></NewDataSet>';

    this.apiCall.DBCalling('SaveReturnsProducts', this.returnlstProducts.OrderDetailsID, this.Reasontext, this.returnlstProducts.OrderID, xml1).subscribe(
      (res) => {
        //debugger
       
        $("#preloader").hide();
        
        this.DbResult = (Object.assign([], res));
        if (this.DbResult.tasks[0].length > 0) {
          var that = this;
          Swal.fire({
            position: 'center',
            icon: 'success',
            text: 'Your Request has been submitted sucessfully.'
          }).then(function () {
            that.ViewOrders()
          });
        }
      })
  }

  product: any = [];
  productDetails: any = [];

  viewProductDetail() {
    //debugger
    this.apiCall.DBCalling("OnlineOrderProductOptionDetailsByProductID", this.returnlstProducts.ProductID, '', "", "").subscribe(
      (res: any) => {
        //debugger
        let dbResult = res;
        this.product = dbResult.tasks[0];
        this.productDetails = dbResult.tasks[0][0];

        var lst1Options = (((this.productDetails.lst1Options).replace(/\n/g, "")).replace(/'/g, "\""));
        let lst1Optionsdata = JSON.parse(lst1Options);
        // this.productDetails.lst1Options = JSON.parse(lst1Options);

        const isUnique = (value: any, index: number, self: any[]) => {
          //debugger
          return (
            self.findIndex(
              (item) =>
                item.OptionGroup === value.OptionGroup &&
                item.OptionName === value.OptionName &&
                item.OptionID === value.OptionID
            ) === index
          );
        };
        
        // Create a new array with unique objects
        this.productDetails.lst1Options = lst1Optionsdata.filter(isUnique);

        var lst2Options = (((this.productDetails.lst2Options).replace(/\n/g, "")).replace(/'/g, "\""));
        this.productDetails.lst2Options = JSON.parse(lst2Options);
      })
  }

  mymodal() {
    //debugger
    if (this.modal == "block") {
      this.modal = "none"
    } else {
      this.modal = "block"
    }
  }
  ViewOrders() {
    this.router.navigateByUrl('orders');
  }

  color: string = "";
  size: string = "";
  availableQty: number = 0;
  submit: boolean = false;
  ProductOptionID: number = 0;

  colorChange(e) {
    //debugger
    this.color = e.target.value;
    if (this.color != '' && this.size != '') {
      this.checkQty()
    }
  }

  sizeChange(e) {
    //debugger
    this.size = e.target.value;
    if (this.color != '' && this.size != '') {
      this.checkQty()
    }
  }

  checkQty() {
    //debugger
    this.submit = true;
    for (let i = 0; i < this.product.length; i++) {
      if (this.product[i].OPName1 == this.color && this.product[i].OPName2 == this.size) {
        this.availableQty = (+this.product[i].Availableqty);
        this.ProductOptionID = (+this.product[i].ProductOptionID);
      }
    }
  }



  loginUserDetails: any = [];
}
