<div class="container-fluid p-0">
    <img src={{Imagesrc}} alt="Buy online - {{ParentName}}" class="img-class"/>
    <h1>{{ParentName}}</h1>
  </div>


  

<div class="container-fluid text-center">
  <h1 class="m-30">CATEGORIES</h1>

  <div class="row">
    <div class="col-xl-2 col-md-2 col-sm-2" *ngFor="let d of lstChildCategories" (click)="CategoryClick(d)">
      <img src={{ImagePath+d.Image}} alt="Buy online - {{d.Categoryname}}" class="rounded-circle">
      <h4 class="p-3">{{d.Categoryname}}</h4>
    </div>
   
  </div>
  
  <h1></h1>
        <h2></h2>
        <h3></h3>
        <h4></h4>
        
</div>