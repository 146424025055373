        <main class="main__content_wrapper mb-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-7 mx-auto">
                        <main class="main__content_wrapper ">
                            <form action="#">
                               
                                <div class="checkout__content--step section__shipping--address">
                                    <div class="section__header">
                                        <h3 class="section__header--title mb-25">User Login</h3>
                                        <!-- <p class="layout__flex--item">
                                            Already have an account?
                                            <a class="layout__flex--item__link" href="login.html">Log in</a>  
                                        </p> -->
                                    </div>
                                    <div class="section__shipping--address__content">
                                        <div class="row">
                                            <div class="col-lg-6 mb-12">
                                                
                                            <div class="col-12 mb-12">
                                                <div class="checkout__input--list">
                                                    <label>
                                                        <input class="checkout__input--field border-radius-5" placeholder="Email Id" type="email" name="email" [(ngModel)]="email" autocomplete="off">
                                                        <div *ngIf="submited && email==''"><label class="validate-error">Email required*</label></div>
                                                        <div *ngIf="submited && email!='' && !validateEmail(email)"><label class="validate-error">Invalid  Email</label></div>
                                                    </label>
                                                </div>
                                            </div>
                                           
                                            <div class="col-12 mb-12">
                                                <div class="checkout__input--list">
                                                    <label>
                                                        <input class="checkout__input--field border-radius-5" placeholder="Password" type="password" name="password" [(ngModel)]="password" autocomplete="off">
                                                        <div *ngIf="submited && password==''"><label class="validate-error"> password required</label></div>
                                                    </label>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div class="checkout__checkbox">
                                    <input class="checkout__checkbox--input" id="check2" type="checkbox">
                                    <span class="checkout__checkbox--checkmark"></span>
                                    <label class="checkout__checkbox--label mb-20" for="check2">
                                        Save this information for next time</label>
                                </div>
                            </div>
                                <div class="checkout__content--step__footer d-flex align-items-center">
                                    <a class="continue__shipping--btn primary__btn border-radius-5">Login</a>
                                    <!-- <a class="continue__shipping--btn primary__btn border-radius-5" (click)="Login()">Login</a> -->
                                 
                                </div>
                            </form>
                        </main>
                        </div>
                </div>
            </div>
            <h1></h1>
<h2></h2>
<h3></h3>
<h4></h4>
         </main>