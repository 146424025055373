{
    "meta":[
        {
          "name": "daron-beige-cotton-chinos-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "black-cotton-chinos",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "jacob-olive-cotton-chinos",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "navy-cotton-chinos",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "-jacob-beige-cotton-chinos",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "blue-indigo-ankle-length-jeans",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "blue-shaded-ankle-length-jeans",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "buzzer-blue-ankle-length-jeans",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "cloud-grey-ankle-length-jeans",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "edwin-sky-blue-ankle-length-jeans",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "ice-blue-ankle-length-jeans",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "shade-grey-ankle-length-jeans",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "zack-ash-black-ankle-length-jeans",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "black-ankle-length-tone-jeans",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "grey-ankle-length-tone-jeans",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "indigo-blue-ankle-length-tone-jeans",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "light-blue-ankle-length-tone-jeans",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "light-grey-ankle-length-tone-jeans",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "black-track-pants-grip",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "blue-track-pants-grip",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "forest-green-track-pants-grip",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "green-&-blue-track-pants-grip",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "grey-track-pants-grip",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "khake-&-grey-track-pants-grip",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "grey-&-black-track-pants-non-grip",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "print-leaf-forestgreen-round-neck-t-shirts-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "goldenrod-round-neck-t-shirts-designer",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "indigo-blue-round-neck-t-shirts-designer",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "pale-blue-round-neck-t-shirts-designer",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "pink-round-neck-t-shirts-designer",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "red-round-neck-t-shirts-designer",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "white-round-neck-t-shirts-designer",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "black-&-grey-multicolor-round-neck-t-shirts-stripe",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "black-&-grey-round-neck-t-shirts-stripe",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "black-&-white-small-round-neck-t-shirts-stripe",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "black-&-white-round-neck-t-shirts-stripe",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "blue-chest-white-round-neck-t-shirts-stripe",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "pale-blue-round-neck-t-shirts-stripe",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "white-black-round-neck-t-shirts-stripe",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "white-blue-round-neck-t-shirts-stripe",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "white-multicolor-round-neck-t-shirts-stripe",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "white-yellow-round-neck-t-shirts-stripe",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "black-round-neck-dry-fit-t-shirts",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "blue-round-neck-dry-fit-t-shirts",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "cerise-round-neck-dry-fit-t-shirts",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "cerulean-round-neck-dry-fit-t-shirts",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "forestgreen-round-neck-dry-fit-t-shirts",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "grey-round-neck-dry-fit-t-shirts-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "sage-round-neck-dry-fit-t-shirts-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "silver-round-neck-dry-fit-t-shirts",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "turquoise-round-neck-dry-fit-t-shirts",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "white-round-neck-dry-fit-t-shirts",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "black-&-blue-round-neck-dry-fit-t-shirts-stripe",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "blue-&-grey-round-neck-dry-fit-t-shirts-stripe",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "green-&-grey-round-neck-dry-fit-t-shirts-stripe",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "turquoise-&-grey-round-neck-dry-fit-t-shirts-stripe",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "white-&-pink-round-neck-dry-fit-t-shirts-stripe",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "blue-and-white-checks-casual-shirt-for-men",
          "title": "Buy Blue And White Checks Casual Shirt For Men | Black Denim",
          "description": "Shop Blue And White Checks Casual Shirt For Men at best prices Online from Black Denim. Explore Black Denim latest mens casual shirts, trending jeans and avail great discounts on it\n",
          "tags": "Black Denim Shirts, Back Denim, Causal Shirts, Shirts for men, Blue checkered shirt, blue check shirt, shirts for men"
        },
        {
          "name": "cubix-brown-check-shirt-for-men",
          "title": "Buy Cubix Brown Check Shirt for Men Online | Black Denim",
          "description": "Shop Cubix Brown Check Shirt for Men from the brand American Trends. Explore our extensive range of men's check shirts at best prices online.",
          "tags": "Cubix Brown Slim Fit Checked Casual Shirt,  Black Denim, Check Shirts, Topwear, Apparel, Apparel for Men, Buy Cubix Brown Check Shirt for Men Online in India, Buy brown Shirts at best price in india"
        },
        {
          "name": "men-forest-green-checked-casual-shirt",
          "title": "Buy Men Forest Green Checked Casual Shirt Online | Black Denim",
          "description": "Get Men Forest Green Checked Casual Shirt at lowest prices from Black Denim. Avail latest offer and great deals on men's check shirts collection, with stylish jeans and cargo pants\n",
          "tags": "Black Denim Men Checkered Casual green Shirt, Clothing, Men Green and White Slim Fit Checked Shirt, green casual check shirt for sale, Black Denim Shirts, Buy green plaid check shirt online in india"
        },
        {
          "name": "green-tartan-check-slim-fit-casual-shirt-for-men",
          "title": "Buy Green Tartan Check Slim fit Casual Shirt for Men Online",
          "description": "Buy Green Tartan Check Slim fit Casual Shirt for Men at best prices in india. Style this Black Denim offers  Long Sleeves flannel Check shirt with blue jeans\n",
          "tags": "Black Denim, Black Denim shirts, Black Denim check shirts, Men's check shirt for sale, Checkered shirt for men, buy tartan check shirt "
        },
        {
          "name": "slim-fit-red-checked-shirt-for-men",
          "title": "Buy Slim Fit red checked shirt for Men Online in India | Black Denim",
          "description": "Buy Slim Fit red checked shirt for Men @ BlackDenim at best prices Online. Grab latest deals and discounts on branded Men's shirt and jeans\n",
          "tags": "Black Denim Men Red Slim Fit Checked Pure Cotton Casual Shirt,  Black Denim, Shirts, Apparel, Apparel for Men, Buy Black Denim Slim Fit red checked shirt for Men Online in India, Buy Cotton Shirts at best price"
        },
        {
          "name": "casual-rose-brown-checked-shirt-for-men",
          "title": "Buy Casual Rose Brown Checked Shirt for Men | Black Denim ",
          "description": "Buy Casual Rose Brown Checked Shirt for Men from Black Denim. Shop for more stylish new shirt designs and avail great offers and discounts.\n",
          "tags": "Clothing, buy Men's shirt online, Black Denim, Black denim Shirts, Black Denim Cotton shirts, buy Check shirts, Checked shirts for men"
        },
        {
          "name": "dark-blue-checkered-cotton-shirt-for-men",
          "title": "Buy Dark Blue Checkered Cotton Shirt for Men for Men Online | Black Denim",
          "description": "Get Dark Blue Checkered Cotton Shirt for Men at Best price from Black Denim. Explore the latest offers and grab discounts on the best shirts for men collection",
          "tags": "Black Denim Checks Cotton Casual Shirt,  Black Denim, Shirts, Topwear, Apparel, Apparel for Men, Buy Dark Blue Checkered Cotton Shirt for Men for Men Online  in India, Buy Shirts at best price"
        },
        {
          "name": "baby-blue-cargo-double-pocket-shirt-for-men",
          "title": " Buy Baby Blue Cargo Double Pocket Shirt for Men in India Online",
          "description": "Baby Blue Cargo Double Pocket Shirt for Men is on sale in Black Denim at best prices. Check out latest discount and deals on double pocket shirts \n",
          "tags": "cotton shirt for men, Dual pocket shirt, Men's Shirts, buy sky blue shirt online, blue shirt matching pant, Cargo Shirt for sale, Casual Shirt, sky blue shirt"
        },
        {
          "name": "blue-cargo-double-pocket-shirt-for-men",
          "title": "Buy Blue Cargo Double Pocket Shirt for Men Online | Black Denim",
          "description": "Buy Blue Cargo Double Pocket Shirt for Men from the Brand American Trends, Buy latest deals and flat discounts on Trending shirts Online at best price in India\n",
          "tags": "best formal pant shirt combination, cotton shirt for men, double pocket shirt, shirt for men, buy blue shirt online, shirt matching pant, Cargo Shirt, Casual Shirt"
        },
        {
          "name": "green-cargo-double-pocket-shirt-for-men",
          "title": "Buy Green Cargo Double Pocket Shirt for Men | Black Denim",
          "description": "Green Cargo Double Pocket Shirt for Men is on sale now, Explore more deals and discounts on trending double pocket shirts combination pant from Black Denim\n",
          "tags": "men shirt, summer shirts men, casual shirts for men, cotton shirt, men's full sleeve t shirts, green shirt "
        },
        {
          "name": "grey-double-pocket-shirt-for-men",
          "title": "Buy Grey Double Pocket Shirt For Men Online | Black Denim",
          "description": "Buy Grey Double Pocket Shirt For Men by Black Denim at best prices in India. Shop for latest and trending dual pocket shirts from Men\n",
          "tags": "Solid Slim Fit Shirt, Clothing, Black Denim Beige Dual Pocket Shirt,  Black Denim, trending Shirts, Topwear, Apparel, Apparel for Men, Buy Black Denim Men’s Double pocket Shirt Online in India, Buy Shirts at best price\n"
        },
        {
          "name": "maroon-cargo-double-pocket-shirt-for-men",
          "title": "Buy Maroon Cargo Double Pocket Shirt for Men | Black Denim",
          "description": "Maroon Cargo Double Pocket Shirt for Men is on Sale at lowest prices in India, avail flat discounts and offers on latest men’s wear collection \n",
          "tags": "Maroon Cargo Double Pocket Shirt for Men,  Black Denim Shirts, Topwear, Apparel, Apparel for Men, Buy Black Denim Maroon Slim Fit Cotton Casual Shirt Online in India, Buy Shirts at best price"
        },
        {
          "name": "pale-blue-cargo-double-pocket-shirt-for-men",
          "title": "Buy Pale Blue Cargo Double Pocket Shirt for Men | Black Denim",
          "description": "Shop Pale Blue cargo double pocket shirt for men in India at lowest prices, and get latest offers on fashion-forward Black Denim Men Blue Slim Fit Casual Shirt collection\n",
          "tags": "Black Denim Men Blue Slim Fit Casual Shirt, Dual Pocket Shirt, BLACK DENIM, Shirts, Topwear, Apparel, Apparel for Men, Buy Black Denim Pale Blue Men double pocket shirt Online, Buy Shirts at best prices in India\n"
        },
        {
          "name": "steel-blue-double-pocket-shirt-for-men",
          "title": "Buy Steel Blue Double Pocket Shirt for Men Online in India",
          "description": "Get Steel Blue Double Pocket Shirt for Men at best prices from black denim, explore latest offers and discounts on trending Double pocket shirts online\n",
          "tags": "men shirt, summer shirts men, casual shirts for men, grey pant matching shirt, men's shirts, online shirts for men"
        },
        {
          "name": "white-cargo-double-pocket-shirt-for-men",
          "title": "Buy White Cargo Double Pocket Shirt For Men | Black Denim",
          "description": "Shop White Cargo Double Pocket Shirt For Men at best prices in India, Get latest deals and discounts on double pocket shirts from Black Denim\n",
          "tags": "double pocket shirts for men, double pocket cargo shirts, cargo shirts, White cargo shirt, men's appreal, men's fashion, Men's shirt, white cargo shirt for men"
        },
        {
          "name": "sea-green-jute-linen-cotton-shirt-for-men",
          "title": "Buy Sea Green Jute Linen Cotton Shirt For Men Online",
          "description": "Shop for Sea Green Jute Linen Cotton Shirt for Men at best prices online and explore our best formal pant shirt combinations and choose one for you. Buy Best Linen shirts online",
          "tags": "jute shirts for men, buy men's linen shirts, buy best cotton shirts online, men's wear, topwear, appereal"
        },
        {
          "name": "azure-jute-linen-cotton-shirt-for-men",
          "title": "Buy Azure Jute Linen Cotton Shirt For Men Online in India",
          "description": "Azure Jute Linen Cotton Shirt For Men is on sale from Black Denim, this linen teal blue plain shirt comes with buttoned down collar and full sleeves at best prices in India\n",
          "tags": "Men Slim Fit Opaque Casual Shirt, Spread Collar Casual Shirt,  Jute Shirts, Topwear, Apparel, Apparel for Men, Buy Jute linen Cotton Shirt Online in India, Buy Linen Shirts at best price\n"
        },
        {
          "name": "black-jute-linen-cotton-shirt-for-men",
          "title": "Buy  Black Jute linen Cotton Shirt For Men Online in India",
          "description": "Shop Black Jute linen Cotton Shirt For Men at black Denim at best prices in India. Get latest offers and flat discounts on Men’s Black shirts Online \n",
          "tags": "Men Slim Fit Casual Shirt, Spread Collar Shirt,  Jute Shirts, Topwear, Apparel, Apparel for Men, Black shirt for men, Buy Jute linen Cotton Shirt Online in India, Buy Linen Shirts at best price\n"
        },
        {
          "name": "dark-blue-jute-linen-cotton-shirt-for-men",
          "title": "Buy Dark Blue Jute linen Cotton Shirt For Men Online | Black Denim",
          "description": "Buy Dark Blue Jute linen Cotton Shirt For Men at best prices from Black Denim. Get Latest discounts and offers on latest men’s blue plain shirt with trending trousers \n\n",
          "tags": "Clothing, men's wear, appereal, casual shirts, blue casual shirt, Black Denim Men Navy Blue Pure Cotton Shirt,  Black Denim, Buy Shirts at best price"
        },
        {
          "name": "black-solid-rayon-regular-fit-casual-shirt-for-men",
          "title": "Buy Black Solid Rayon Regular Fit Casual Shirt for Men Online | Black Denim",
          "description": "Buy Solid Rayon Regular Fit Casual Shirt for Men at best prices in India. Black Denim offers trending men’s shirts, jeans and latest t shirts collections for you\n",
          "tags": "buy rayon shirt Online, rayon fabric shirt for sale, Black rayon shirt mens, rayon material shirt black, black shirt combination"
        },
        {
          "name": "grey-jute-linen-cotton-shirt-for-men",
          "title": "Buy Grey Jute Linen Cotton Shirt For Men Online | BlackDenim.in",
          "description": "Add this Grey Jute Linen Cotton Shirt For Men to cart to get flat discounts and offers on Latest Men’s shirts collection from Black Denim India\n",
          "tags": "Black Denim Cotton Shirts,  Black Denim, Jute Shirts, Topwear, Apparel, Apparel for Men, Buy Black Denim Grey Jute Linen Cotton Shirt For Men Online in India, Buy Shirts at lowest price"
        },
        {
          "name": "ivory-jute-linen-cotton-shirt-for-men",
          "title": "Buy Ivory Jute Linen Cotton Shirt for Men | Black Denim",
          "description": "Shop for Ivory Jute Linen Cotton Shirt for Men at best prices online. Avail latest offers and discounts on Jute cotton casual shirts online. \n",
          "tags": "Casual Shirts, Cream Casual Shirts, Ivory Mens Self Design Casual Shirt, Cream Cotton Casual Shirts, Black Denim Casual Shirts, Black Denim Shirts"
        },
        {
          "name": "maroon-jute-linen-cotton-shirt-for-men",
          "title": " Buy Maroon Jute Linen Cotton Shirt for Men | Black Deni",
          "description": " Shop Maroon Jute Linen Cotton Shirt for Men at best prices online. Explore more discounts and offers on best casual shirts for men.\n",
          "tags": "Buy Burgundy Color Shirts online in India, Buy maroon Color Shirts, Jute shirt, shirt for men, buy Jute linen shirt online, Maroon shirt matching pant"
        },
        {
          "name": "turquoise-jute-linen-cotton-shirt-for-men",
          "title": "Buy Turquoise Jute Linen Cotton Shirt For Men Online | BlackDenim.in",
          "description": " Add Turquoise Jute Linen Cotton Shirt For Men to your cat and get exclusive discounts and offers on best shirt brands in India, Buy men's Sky blue linen shirt at lowest prices",
          "tags": "Dennis Lingo Men Blue Slim Fit Pure Cotton Solid Casual Shirt,  Dennis Lingo, Topwear, Apparel for Men, Buy Dennis Lingo Men Blue Slim Fit Pure Cotton Solid Casual Shirt Online in India, Buy Shirts at best price"
        },
        {
          "name": "solid-jute-white-linen-shirt-for-men",
          "title": "Buy Solid Jute White Linen Shirt for Men Online | Black Denim",
          "description": "Buy Solid Jute White Linen Shirt for Men at best prices online in India, Avail latest offers and great discounts on pure linen shirts from Black Denim Jeans, t shirts at best prices\n",
          "tags": "Buy linen shirts for men, jute shirt for sale, buy white cotton shirts online, Black denim, Black denim shirts online"
        },
        {
          "name": "mint-green-jute-linen-cotton-shirt-for-men",
          "title": "Buy Mint Green Jute Linen Cotton Shirt For Men | BlackDenim.in",
          "description": "Mint Green Jute Linen Cotton Shirt for men is on sale at best prices in India, Get more stylish and comfortable men’s shirt and jeans with latest deals and discounts",
          "tags": "Black Denim Slim Fit Casual Shir, Linen Shirt for sale, cotton shirt for sale, Black Denim, buy Men's cotton shirt online"
        },
        {
          "name": "turquoise-blue-formal-satin-shirt-for-men",
          "title": "Buy Turquoise Blue Formal Satin Shirt for Men | Black Denim",
          "description": "Shop for Turquoise Blue Formal Satin Shirt for Men in India at best prices from Black Denim. Explore latest deals on modern satin shirts online and style with trending jeans\n",
          "tags": "Black Denim, Black Denim Shirt, Satin Shirts, Blue formal shirt, buy casual shirt online, blue shirt in india"
        },
        {
          "name": "black-formal-60s-satin-shirt-for-men",
          "title": "Buy Black Formal 60s Satin Shirt for Men Online | Black Denim",
          "description": "Shop for Black Formal 60s Satin Shirt for Men at best prices in India. Explore latest modern stylish formal pant shirt combinations with great discounts and offers\n",
          "tags": "Black Denim Shirts, Black Denim, Buy Black Satin shirt for men, Buy Satin shirt online"
        },
        {
          "name": "blue-formal-plain-satin-shirt-for-men",
          "title": "Buy Blue Formal Plain Satin Shirt for Men | Black Denim",
          "description": "Get Blue Formal Plain Satin Shirt for Men at best prices in India. Explore latest best formal pant shirt combination for blue satin shirt online from Black Denim",
          "tags": "Black denim, Black denim Shirts, Best satin Shirts for sale, buy Formal Shirts, Blue satin shirt for sale"
        },
        {
          "name": "slim-fit-mens-casual-bottle-green-satin-shirt",
          "title": "Buy Slim Fit Mens Casual Bottle Green Satin Shirt | Black Denim",
          "description": "Slim Fit Mens Casual Bottle Green Satin Shirt is on sale Online at lowest prices, make sure to check best shirt for men with combination in India\n",
          "tags": "Slim Fit satin Casual Shirt, buy satin shirt online, Black Denim, Black Denim shirts, Buy Shirts at best price\n"
        },
        {
          "name": "solid-slim-fit-grey-satin-shirt-for-men",
          "title": "Buy Solid Slim Fit Grey Satin Shirt for Men | Black Denim",
          "description": "Get Solid Slim Fit Grey Satin Shirt for Men at best prices in India, Explore more about grey pant matching shirt combinations with great discounts\n",
          "tags": "Black Denim, Black denim Shirts, Buy Black Denim Grey shirt online, Best satin shirts at lowest prices in India"
        },
        {
          "name": "yellow-slim-fit-full-sleeves-satin-shirt-for-men",
          "title": "Buy Yellow Slim fit full Sleeves Satin Shirt for Men Online",
          "description": "Get Yellow Slim fit full Sleeves Satin Shirt for Men at best prices from Black denim. Avail latest offers and flat discount on modern formal shirts \n",
          "tags": "satin shirt mens, yellow shirt, Topwear, Apparel for Men, Buy Shirts at best price, men's shirt, online shirts for men, latest shirts for men, casual shirts for men\n"
        },
        {
          "name": "maroon-solid-slim-fit-satin-casual-shirt-for-men",
          "title": "Buy Maroon Solid Slim Fit Satin Casual Shirt for Men | Black Denim",
          "description": "Buy Maroon Solid Slim Fit Satin Casual Shirt for Men at lowest prices in India on Black Denim and avail exclusive offers. Shop from our wide range of collection\n",
          "tags": "Black Denim, Black Denim Shirts, Buy Black denim Satin shirts at best price, Satin shirts, Men’s wear, Burgundy casual shirt\n\n"
        },
        {
          "name": "men’s-casual-dark-grey-satin-shirt",
          "title": "Buy Men’s Casual Dark Grey Satin Shirt | Black Denim",
          "description": "Men’s Casual dark grey satin shirt is on sale at lowest prices in India, buy now. Get great discounts and best deals on men’s satin shirts with Black Denim\n",
          "tags": "Black Denim Slim Shirt, Black Denim Shirts, Men Satin Shirt, Men’s Satin Shirt , Casual Slim Shirt, Buy Black Denim Dark Grey Satin Shirt"
        },
        {
          "name": "classic-fit-cotton-white-satin-shirt-for-men",
          "title": "Buy Classic Fit Cotton White satin Shirt for men | Black Denim",
          "description": "Buy Classic Fit Cotton White satin Shirt for men Online at best prices in India, explore trending white satin shirts with best offers and deals online from Black Denim\n",
          "tags": "Black Denim, Black Denim Shirts, Satin Shirts, Buy Satin shirts for Sale,"
        },
        {
          "name": "black-rayon-tropical-print-shirt-for-men",
          "title": "Buy Black Rayon Tropical Print Shirt for Men | Black Denim",
          "description": "Black Rayon Tropical Print Shirt for Men is on sale now, explore more trending shirts for men from Black Denim at lowest price in India and get great discounts \n",
          "tags": "Black Denim, Black Denim Shirt, Buy rayon shirt at best prices, Black Denim Tropical Print Casual Shirt, Apparel for Men, Buy Black Rayon Tropical Print Shirt for Men Online in India"
        },
        {
          "name": "black-and-grey-mandala-rayon-printed-shirt-for-men",
          "title": "Buy Black and Grey Mandala Rayon Printed Shirt for Men",
          "description": "Black and Grey Mandala Rayon Printed Shirt for Men is on sale at lowest prices in India, avail great discounts and offers on best men shirts and pants online\n",
          "tags": "Black Denim, Black Denim Shirts, Mandala print shirts, Slim Fit Ethnic Motifs rayon printed Shirt, Buy Black and Grey Mandala printed rayon shirt at Black Denim\n"
        },
        {
          "name": "rajasthani-blue-printed-rayon-shirt-for-men",
          "title": "Buy Rajasthani Blue Printed rayon shirt for Men | Black Denim",
          "description": "Buy Rajasthani Blue Printed rayon shirt for Men Online at best prices in India. Explore latest men apparels includes trendy shirt collections, latest trousers\n",
          "tags": "Black Denim Printed Shirt, Rajasthani print Casual Shirt, Black Denim, Black Denim Shirts, Men's partywear shirts"
        },
        {
          "name": "blue-self-design-rayon-printed-casual-shirt-for-men",
          "title": "Buy Blue Self-design Rayon Printed Casual Shirt for Men ",
          "description": "Shop Blue Self-design Rayon Printed Casual Shirt for Men at best prices in Black Denim, avail latest offers on summer shirts men collection",
          "tags": "Buy Black Denim Shirts, Black Denim, Apparel, Men's wear, Shirts for Men, Rayon shirts, Printed shirts for men"
        },
        {
          "name": "classic-geometric-rayon-green-printed-shirt-for-men",
          "title": "Buy Classic Geometric Rayon Green Printed Shirt for Men ",
          "description": "Buy Classic Geometric Rayon green printed shirt for men at best prices from Black Denim. Explore latest men's printed shirt with flat discounts in India",
          "tags": "Black Denim, Black Denim Shirts, Self Printed Shirts, Satin Shirts for Men, rayon shirt mens, printed shirt for men, green printed shirt"
        },
        {
          "name": "white-leaf-printed-rayon-shirt-for-men",
          "title": "Buy White Leaf Printed rayon shirt for men | Black Denim",
          "description": "White leaf printed rayon shirt for men is a unique add on to your day to day wardrobe. Choose from a wide range of Lates printed Shirt for Men in India\n",
          "tags": "Black Denim, Black Denim shirts, Buy leaf printed shirt online in india, Shop latest white shirts for men, Men's collection, best jeans and shirts for men"
        },
        {
          "name": "black-corduroy-shirts-cum-jacket",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "bronze-corduroy-shirts-cum-jacket",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "jade-green-corduroy-shirts-cum-jacket",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "maroon-corduroy-shirts-cum-jacket",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "veludo-ash-grey-corduroy-shirts-cum-jacket",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "black-cargo",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "blue-cargo",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "brown-cargo",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "grey-cargo",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "ivory-cargo",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "lemon-cargo",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "mistyrose-cargo",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "silver-cargo",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "blue-cotton-chinos",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "dark-beige-cotton-chinos",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "grey-cotton-chinos",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "casual-blue-and-white-check-shirt-for-men",
          "title": "Buy Casual Blue and White Check Shirt for Men | Black Denim",
          "description": "Buy Casual Blue and White Check Shirt for men online in Inida @ Black Denim, This slim fit Blue Checked shirt available in all sizes, add to your cart now\n",
          "tags": "Black Denim, Black Denim Shirts, Buy Blue and white Checked Collar-Neck Shirt, Cotton Checkered Blue Casual Full Sleeves Shirt, Navy Blue Checkered Casual Shirt\n\n\n"
        },
        {
          "name": "pink-slim-fit-check-shirt-for-men",
          "title": "Buy Pink Slim Fit Check Shirt for Men | Black Denim",
          "description": "Buy Pink Slim Fit Check Shirt for Men online at best prices in India. Get the latest deals on stylish men’s casual checked shirts from Black Denim \n",
          "tags": "Black Denim, Black Denim Shirts, Black Denim Checkered Slim Fit Shirt for Men, Checkered Shirts, Pink check shirt for Men, Spread Collar Casual Shirt\n"
        },
        {
          "name": "white-and-purple-checked-shirt-for-men",
          "title": "Buy White and Purple Checked shirt for Men | Black Denim",
          "description": "Get White and Purple Checked shirt for Men at best prices online today. Explore latest Men checks Slim Fit Shirt with Patch Pocket\n",
          "tags": "Violet and off-white checked casual shirt, Black denim Shirts, Black Denim, Shirts for Men, Buy Black denim White and Purple Checked shirt for Men"
        },
        {
          "name": "cream-with-designer-round-neck-t-shirts",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "cloud-grey-super-premium-jeans-for-men",
          "title": "Buy Cloud Grey Super Premium Jeans for Men | Black Denim",
          "description": "Cloud Grey Super Premium Jeans for Men is on Sale at Black Denim. Add this Men’s Skinny jeans to your collection now, explore Grey Jeans Matching Shirt options\n",
          "tags": "Grey Colour Jeans,  best jeans for men, mid waist jeans for men, branded jeans for men, cotton jeans for men, stylish jeans for men\n"
        },
        {
          "name": "grey-super-premium-jeans",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "indigo-super-premium-skinny-jeans-for-men",
          "title": "Buy Indigo Super Premium Jeans for Men in India | BlackDenim",
          "description": "American Trends Indigo Super Premium Jeans for Men on sale on Black Denim for an affordable price. Add this Men’s Indigo Skinny Jeans to your wardrobe now\n",
          "tags": " best jeans for men, mid waist jeans for men, blue jeans for men, branded jeans for men, cotton jeans for men, stylish jeans for men, black jeans for men"
        },
        {
          "name": "light-grey-super-premium-jeans",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "shade-blue-super-premium-jeans-for-men",
          "title": "Buy Shade Blue Super Premium Jeans for Men Online in India",
          "description": "Shade Blue Super Premium Jeans for Men is on sale, Buy trending sky blue skinny fit stretchable jeans from Black Denim and pair it with a stylish t shirt",
          "tags": "black denim, best jeans for men, mid waist jeans for men, branded jeans for men, cotton jeans for men, stylish jeans for men, blue jeans for men\n"
        },
        {
          "name": "black-full-hands-chinese-collar-t-shirts",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "blue-full-hand-chinese-collar-t-shirts",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "brown-full-hand-chinese-collar-t-shirts",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "dark-brown-full-hand-chinese-collar-t-shirts",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "red-full-hand-chinese-collar-t-shirts",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "white-full-hand-chinese-collar-t-shirts",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "light-blue-with-designer-round-neck-t-shirts",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "joggers-cargo-grip-apricot",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "black-joggers-cargo-grip",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "blue-joggers-cargo-grip",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "bronze-joggers-cargo-grip",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "copper-joggers-cargo-grip",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "sepia-joggers-cargo-grip",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "slim-fit-mid-rise-black-jeans-for-men",
          "title": "Buy Slim Fit Mid Rise Black Jeans for Men Online | BlackDenim",
          "description": "Buy Black Denim Slim Fit Mid Rise Black Jeans for Men at best prices in India. Shop from a wide range of Fashion at Black Denim and avail great discounts Today\n",
          "tags": "Black Denim, straight fit jeans, black jeans for men, denim jeans for men, branded jeans for men, stylish jeans for men"
        },
        {
          "name": "aqua-hoodie-with-drawstring",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "blue-frenchman-hoodie-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "aqua--frenchman-hoodie-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "brown-premium-hoodie-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "red-premium-hoodie-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "rose-brown-frenchman-hoodie",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "sky-blue-premium-hoodie-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "stylish-blue-sweatshirt",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "men-full-sleeve-red-sweatshirt",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "full-sleeve-solid-black-sweatshirt",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "men-full-sleeve-printed-blue-sweatshirt",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "men-full-sleeve-dark-green-sweatshirt",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "stylish-crew-neck-sweatshirt",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "men-full-sleeve-solid-sweatshirt-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "brand-print-crew-neck-sweatshirt-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "white-satin-stretchable-plain-shirts-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "red-slim-fit-self-printed-shirt-for-men",
          "title": "Buy Red Slim Fit Self Printed Shirt For Men | Black Denim",
          "description": "Shop Black Denim Red Slim Fit Self Printed Shirt For Men online at best prices. Add this twill cotton red printed shirt to your wardrobe collection today.\n",
          "tags": "Black Denim Shirts, Buy Red Slim Fit Self Printed Shirt For Men, Men's Twill cotton shirt, Black Denim Slim fit shirt for Sale"
        },
        {
          "name": "maroon-slim-fit-self-printed-shirt-for-men",
          "title": "Buy Maroon Slim Fit Self Printed Shirt for Men | Black Denim",
          "description": "Buy Black Denim Maroon Slim Fit Self Printed Shirt for Men Online in India at best price. Know more about our stylish shirt for men collection and grab one\n",
          "tags": "Black Denim, Black Denim Shirts, Buy Maroon Slim Fit Self Printed Shirt for Men, Printed Shirts for Men, Men's stylish shirts for Sale"
        },
        {
          "name": "blue-slim-fit-self-printed-shirt-for-men",
          "title": "Buy Blue Slim Fit Self Printed Shirt for Men | Black Denim",
          "description": "Buy Black Denim Blue Slim Fit Self Printed Shirt for Men Online at best prices. Explore our trending shirt for men collection and get perfect for you.",
          "tags": "Black Denim, Black Denim shirts, Buy Blue Slim Fit Self Printed Shirt for Men, Printed shirt for sale"
        },
        {
          "name": "olive-green-self-printed-shirt-for-men",
          "title": "Buy Olive Green Self Printed Shirt for Men | Black Denim",
          "description": "American Trends Olive Green Self Printed Shirt for sale on BlackDenim. Buy Olive Green Printed Twill Cotton Slim Fit Mens Casual Shirt at best prices\n  ",
          "tags": "Black denim Shirts, Buy Olive Green Self Printed Shirt for Men, Twill cotton shirt for Men, Buy Green Print Casual Shirts for Men\n"
        },
        {
          "name": "white-and-blue-abstract-printed-shirt-for-men",
          "title": "Buy White and Blue Abstract Printed Shirt for Men Online",
          "description": "Buy White And Blue Abstract Printed Shirt for Men is on sale @ Black Denim. Get the latest trending shirts for men, Jeans, Track pants with affordable prices ",
          "tags": "Black Denim, Black Denim Shirts online, Buy White and Blue Abstract Printed Shirt for Men Online, Wavy printed color shirt for Sale, T shirt for Men"
        },
        {
          "name": "blue-cotton-leaf-printed-shirt-for-men",
          "title": "Buy Blue Cotton Leaf Printed Shirt for Men | Black Denim",
          "description": "Blue Cotton Leaf Printed Shirt for Men is on sale at best price online in India. Latest deals on men printed shirts and cargo pants, baggy pants from BlackDenim\n",
          "tags": "Black Denim Shirts, Black Denim Printed Shirts, Blue Shirt for Men, Printed shirt for men"
        },
        {
          "name": "light-blue-floral-printed-shirt-for-men",
          "title": "Buy Light Blue Floral Printed Shirt For Men Online in India",
          "description": "Buy Light Blue Floral Printed Shirt For Men from Black Denim. Explore our stylish shirts for men, best selling cargo pants in budget avail latest offers\n",
          "tags": "Black Denim Shirts, Black Denim, Blue Printed shirt for Men, Blue shirt for Men, Mens Wear, Tshirt for Men, Buy Light Blue Floral Printed Shirt For Men Online in Black Denim"
        },
        {
          "name": "pink-cotton-leaf-printed-shirt-for-men",
          "title": "Buy Pink Cotton Leaf Printed Shirt for Men Online in India",
          "description": "Shop Pink Cotton Leaf Printed Shirt for Men at best price from Black Denim. Explore more Tropical Print Spread-Collar Shirt, Latest T Shirt collection\n",
          "tags": "Black Denim Shirts, Printed Shirt for men, Leaf Printed Shirts online, Cotton Shirt for men, Pink Cotton Shirt for men"
        },
        {
          "name": "light-yellow-floral-printed-shirt-for-men",
          "title": "Buy Light Yellow Floral Printed Shirt for Men | Black Denim",
          "description": "Buy Light Yellow Floral Printed Shirt for Men online at best prices. Check our latest  men printed shirts, trendy T-Shirt collection to upgrade your wardrobe",
          "tags": "Black Denim Shirts, Black Denim Shirt for men, Cotton Shirts for Men, Printed Shirt for men, Leaf Printed Shirt for men"
        },
        {
          "name": "grey-paisley-printed-casual-shirt-for-men",
          "title": "Buy Grey Paisley Printed Casual Shirt For Men | Black Denim",
          "description": "Grey Paisley Printed Casual Shirt For Men is a must have pick for your wardrobe collection. Buy latest cotton printed shirts for men at best price online",
          "tags": "Black Denim Shirts, Cotton Shirts, Printed cotton Shirt for men, Men Print Regular Fit Shirt, Grey Paisley Printed Casual Shirt\n"
        },
        {
          "name": "white-and-pink-wave-digital-print-shirt-for-men",
          "title": "Buy White and Pink Wave Digital Print Shirt for Men Online",
          "description": "Buy White and Pink Wave Digital Print Shirt for Men in India from BlackDenim at the best price. Get More stylish men printed shirt collections with us.\n\n",
          "tags": "Buy Shirts at best price, Buy Black Denim Shirts, Black denim, Printed Shirts for Men, Digital Print Shirt"
        },
        {
          "name": "green-paisley-casual-printed-shirt-for-men",
          "title": "Buy Green Paisley Casual Printed Shirt For Men | Black Denim",
          "description": "Buy Green Paisley Casual Printed Shirt For Men at a budget pick @ Black Denim. Buy Green printed casual shirt at best price. Explore more latest Apparel for Men\n",
          "tags": "Black Denim Shirts, Printed Shirt for Men, Green printed casual shirt for Men, Buy Green Paisley Casual Printed Shirt For Men, top appreal for men"
        },
        {
          "name": "slim-fit-stretchable-white-shirt-for-men",
          "title": "Slim Fit Stretchable White Shirt for Men @ Black Denim",
          "description": "Slim Fit stretchable white shirt for Men is on Sale at an affordable budget. Looking for the best stretchable shirts online, check out our collection. \n",
          "tags": "cotton stretch white plain casual shirt, slim fit shirt, White shirt, plain white shirt, formal shirts for men, branded shirts for men\n"
        },
        {
          "name": "gray-vertical-striped-shirt-for-men",
          "title": "Buy Gray Vertical Striped Shirt For Men Online - Black Denim",
          "description": "Gray Vertical Striped Shirt For Men is a must have pick for your wardrobe. Looking for the trending shirts for men, here is one from Black Denim",
          "tags": "Men Slim Fit Striped Casual Shirt, Buy Gray Vertical Striped Shirt For Men, Black Denim Shirts, Black Denim Stripes Shirt for Men\n\n"
        },
        {
          "name": "pink-vertical-striped-shirt-for-men",
          "title": "Buy Pink Vertical Striped Shirt for Men Online @ Black Denim",
          "description": "Buy Pink Vertical Striped Shirt for Men from Black Denim at an affordable price. Get the latest collection shirt for men including crochet & knitted shirts etc",
          "tags": "Black Denim Shirts, Striped Shirt for men, Buy"
        },
        {
          "name": "green-vertical-striped-shirt-for-men",
          "title": "Buy Green Vertical Striped Shirt for Men Online | BlackDenim",
          "description": "Green Vertical Striped Shirt for Men is on sale @ Black Denim. Explore our casual yet trendy shirts for men collection and get one for you.",
          "tags": "Black Denim, Black Denim Shirts, Buy Green Vertical Striped Shirt for Men Online, Green shirt for Men"
        },
        {
          "name": "yellow-vertical-striped-shirt-for-men",
          "title": "Buy Yellow Vertical Striped Shirt for Men - Black Denim",
          "description": "Yellow Vertical Striped Shirt for Men Shop Online @ BlackDenim. Looking for trending shirts for men, check Solid Cotton Shirt collection now and get one\n",
          "tags": "Black Denim, Black Denim Shirts, Buy Yellow Vertical Striped Shirt for Men, Slim Fit Striped Casual Shirt, Summer Yellow Shirt\n\n"
        },
        {
          "name": "blue-printed-design-kurta-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "white-combo-printed-kurta",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "aqua-printed-kurta-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "grey-printed-kurta-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "green-printed-kurta",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "white-&-blue-checks-kurta-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "pink-combo-printed-kurta",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "yellow-checks-kurta-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "sky-blue-polo-fancy-t-shirt-for-men",
          "title": "Buy Sky Blue Polo Fancy T-Shirt Online in India | Black Denim",
          "description": "Buy Sky Blue Polo Fancy T-Shirt at best prices in india. Explore our latest stylish t-shirt, polo t shirts for Men from the premium brand Black Denim\n\n",
          "tags": "Black Denim T Shirts, T Shirt for Sale, mens t-shirt with collar, t-shirt for men, Blue T shirt for Men, Trending T Shirts for sale"
        },
        {
          "name": "red-polo-fancy-t-shirt-for-men",
          "title": "Buy Red Polo Fancy T-Shirt for Men Online | Black Denim",
          "description": "Add Red Polo Fancy T-Shirt for Men to cart. Grab latest offers and great discounts from Black Denim on trending T shirt for men, Branded Jeans, Cargo Pants etc\n",
          "tags": "t shirts for men, printed t shirts for men, red colour t shirt, Black denim, Black Denim T Shirts"
        },
        {
          "name": "light-blue-polo-printed-t-shirt-for-men",
          "title": "Buy Light Blue Polo Printed T-Shirt for Men Online in India",
          "description": "Buy Light Blue Polo Printed T-Shirt for Men from the Black Denim and with lowest prices. Grab latest offers and discounts on best T Shirts, Stylish shirts etc",
          "tags": "polo t shirts for men, t shirts for men, branded t shirts for men, printed t shirts for men, stylish t shirts for men, blue colour t shirt"
        },
        {
          "name": "multiple-color-polo-fancy-t-shirt-for-men",
          "title": "Buy Multiple Color Polo Fancy T-Shirt Online | Black Denim",
          "description": "Shop Multiple Color Polo Fancy T-Shirt from the Brand Black Denim at best prices in India, avail discounts on stylish t shirts for men, Branded Shirts and Jeans",
          "tags": "multicolor t shirt for men, mens multi color t shirt, polo t shirts for men, mens t shirt with collar, branded t shirts for men"
        },
        {
          "name": "red-color-blue-stripe-collar-polo-fancy-t-shirt-for-men",
          "title": "Buy Red Color Blue Stripe Collar Polo Fancy T-Shirt for Men",
          "description": "Shop Red Color Blue Stripe Collar Polo Fancy T-Shirt for men in India at lowest prices from Black Denim, Explore latest Men’s t shirt collection with discounts",
          "tags": "polo t shirts for men, t shirts for men, t shirt design, branded t shirts for men, best t shirts for men"
        },
        {
          "name": "white-polo-fancy-t-shirt-for-men",
          "title": "Buy White Polo Fancy T-Shirt for Men Online | Black Denim",
          "description": "Get White Polo Fancy T-Shirt for Me at lowest prices in India, Avail latest offers and discounts on stylish half sleeve t shirt for men \n",
          "tags": "mens t-shirt with collar, t-shirt for men, white t-shirt, t-shirt design, Printed T Shirt for Men"
        },
        {
          "name": "red-chest-printed-round-neck-t-shirt-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "black-round-neck-t-shirt-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "green-round-neck-t-shirt-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "light-sky-blue-round-neck-t-shirt-for-men-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "light-blue-round-neck-t-shirt-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "blue-round-neck-t-shirt-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "black-color-white-cross-stripe-round-neck-t-shirt-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "red-color-stripe-round-neck-t-shirt-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "dark-blue-round-neck-t-shirt-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "white-&-green-round-neck-t-shirt-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "grey-polo-fancy-plain-collar-t-shirt-for-men-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "men-purple-solid-polo-neck-polo-t-shirts-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "black-polo-solid-t-shirts-for-men-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "light-brown-polo-cotton-solid-t-shirts-for-men-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "jadeblue-men's-half-sleeve-cotton-polo-t-shirts-for-men-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "men-blue-solid-polo-t-shirt-for-men-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "yellow-polo-solid-half-sleeve-t-shirt-for-men-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "light-blue-polo-plain-t-shirt-for-men-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "white-polo-plain-t-shirts-for-men-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "cream-polo-plain-half-sleeve-t--shirt-for-men-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "mens-light-blue-slim-fit-track-pant",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "joggers-stone-green-track-pant-for-men-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "cream-track-pant-for-men-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "mens-light-purple-solid-track-pant-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "solid-men-beige-casual-track-pant-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "pink-classic-slim-fit-track-pant-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "blue-solid-slim-fit-track-pant-for-men-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "black-solid-slim-fit-track-pant-for-men-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "maroon-slim-fit-track-pant-for-men-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "blue-solid-regular-fit-track-pant-for-men-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "green-cargo-pant-for-men-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "black-cargo-pant-for-men-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "dark-brown-cargo-pant-for-men-",
          "title": "Buy Dark Brown Cargo Pant for Men Online India | Black Denim",
          "description": "Shop for Black Denim Dark Brown Cargo Pant for Men Online and pair it with your favorite shirt. Explore for more best cargo pants for men from Black denim\n\n",
          "tags": "Bottomwear, Apparel for Men, best cargo pants for men, brown cargo pants, cotton cargo pants for men, 6 pocket cargo pants, best cargo pants brand in india"
        },
        {
          "name": "grey-cargo-pant-for-men-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "sky-blue-cargo-pant-for-men-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "premium-cargo-pant-for-men-",
          "title": "",
          "description": "",
          "tags": ""
        },
        {
          "name": "olive-green-colorblock-sports-jacket-for-men",
          "title": "Buy Olive Green Colorblock Sports Jacket for Men Online",
          "description": "Shop Black Denim's Olive Green Colorblock Sports Jacket for Men at best prices in India, explore latest offers and great deals on Black Denim Sports jackets ",
          "tags": "Sports jackets, Slim fit sports jacket, sports upper jacket for men, Best sports jacker under 1500, men sports jacket"
        },
        {
          "name": "navy-blue-color-block-sports-jacket-for-men",
          "title": "Buy Navy Blue Color Block Sports jacket for Men | BlackDenim",
          "description": "Shop Black Denim' Navy Blue Color Block Sports jacket for Men online at best prices in India, Get latest offers and great deals on Navy Blue Color Block Sports jacket for Men",
          "tags": "stylish jacket for men, Sports jacket for men, casual jacket for men, colour blocked jacket, best sports jacket under 1500"
        },
        {
          "name": "light-grey-color-block-sports-jacket-for-men",
          "title": "Buy Light Grey Color Block Sports Jacket for Men Online",
          "description": "Shop Black Denim Light Grey Color Block Sports Jacket for Men Online in India at best prices. Grab latest and Best Jackets for Men from Black Denim under 1500",
          "tags": "sports jacket for men, sports upper jacket, sports jacket under 1500, Black Denim sports jacket, Best sports jacket for gym, men sports jacket"
        },
        {
          "name": "black-solid-slim-fit-sports-jacket-for-men",
          "title": "Buy Black Solid Slim Fit Sports Jacket for Men | Black Denim",
          "description": "Shop Black Denim's Black Solid Slim Fit Sports Jacket for Men online at best prices in India, get discounts on Lycra Full Sleeve Zipper Men's Fit Sports Jacket ",
          "tags": "Black Denim sports jacket for men, best sports jacket for gym, men's lightweight sports jacket, buy sports jacket mens"
        },
        {
          "name": "grey-solid-color-blocked-sports-jacket-for-men",
          "title": "Buy Grey Solid Color Blocked Sports Jacket for Men Online",
          "description": "Shop Black Denim's Grey Solid Color Blocked Sports Jacket for Men at best prices in India. Avail great discounts on Dry fit stretchable Men's Sports jacket Now!",
          "tags": "Black Denim Sports Jacket for Men, Best sports jacket under 1500, gym jacket for men, buy grey sports jacket for men"
        },
        {
          "name": "black-color-block-slim-fit-sports-jacket-for-men",
          "title": "Buy Black Color Block Slim Fit Sports Jacket for Men Online",
          "description": "Shop Black Denim's Black Color Block Slim Fit Sports Jacket for Men at affordable Prices in India, Get great discounts on Men's Dry fit sports jackets online",
          "tags": "Black Denim Sports Jackets for Men, Buy black sports jacket for men, best sports jacket under 1500, gym jacket for men"
        },
        {
          "name": "black-slim-fit-printed-t-shirt-for-men",
          "title": "Buy Black Slim Fit Printed T Shirt for Men | Black Denim",
          "description": "Shop Black Slim Fit Printed T Shirt for Men from Black Denim at affordable prices in India. Get Flat 40% off on Black Casual Crew Neck Men T-Shirt Now online",
          "tags": "Black Denim T shirts, Designer T shirts for men, Printed T Shirts for Men, black round neck t shirt, Denim Printed Slim Fit T-Shirt for Men\n\n\n"
        },
        {
          "name": "black-denim-slim-fit-stretchable-jeans-for-men",
          "title": "Buy Black Denim Slim fit Stretchable jeans for Men Online",
          "description": "Shop Black Denim Slim fit Stretchable jeans for men at best prices in India, Black denim offers flat 60% Off on this black Ankle Length Jeans now! Get it today",
          "tags": "black jeans combination shirt, black baggy jeans, black denim jeans, Black Slim Fit Jeans, Black Jeans for Men, black skinny jeans, Black Ankle Length Jeans, buy Black Denim Slim fit Stretchable jeans for Men\n"
        },
        {
          "name": "light-blue-baggy-fit-cargo-6-pocket-jeans",
          "title": "Buy Light Blue Baggy Fit Cargo 6 Pocket Jeans For Men Online",
          "description": "Shop light blue baggy fit cargo 6 pocket jeans for men from Black Denim. Enjoy the latest seasonal discounts on men’s baggy fit cargo 6 pocket jeans collections\n",
          "tags": "oversized jeans, denim loose jeans, baggy pants jeans, loose baggy jeans, baggy fit jeans, Cargo fit jeans, straight fit loose jeans, Straight Fit Cargo Jeans,6 pocket jeans,side pocket jeans, six pocket jeans\n"
        },
        {
          "name": "black-baggy-fit-cargo-6-pocket-jeans-for-men",
          "title": "Buy Black Baggy Fit Cargo 6 Pocket Jeans for Men |BlackDenim",
          "description": "Shop Black Baggy Fit Cargo 6 Pocket Jeans for Men from Black Denim. Discover trendy cargo jeans collection & grab offers on best baggy fit jeans at best prices",
          "tags": "black baggy jeans, black cargo jeans, black jeans matching shirt, black relaxed fit jeans, oversized jeans, denim loose jeans, baggy pants jeans\n"
        },
        {
          "name": "dark-blue-baggy-fit-cargo-6-pocket-jeans-",
          "title": "Buy Dark Blue Baggy Fit Cargo 6 Pocket Jeans Online in India",
          "description": "Shop dark blue baggy fit cargo 6 pocket jeans for men from Black Denim. Grab the great deals and limited time offers by adding best baggy fit jeans to the cart",
          "tags": "Black Denim, Buy Baggy Jeans online in India, Baggy Jeans For Men, Dark Blue Jeans for men, Dark Blue Baggy Fit Jeans For Men, Black denim Baggy Jeans for men"
        },
        {
          "name": "blue-baggy-fit-6-pocket-jeans-for-men-",
          "title": "Buy Blue Baggy Fit 6 Pocket Jeans for Men Online | Black Denim",
          "description": "Buy Blue Baggy Fit 6 Pocket Jeans for men online in India with best price from Black Denim. Embrace style and comfort, Shop now & get free shipping",
          "tags": "Black Denim Baggy Jeans for Men, oversized jeans, denim loose jeans, baggy pants jeans, loose baggy jeans, baggy fit jeans, 6 pocket jeans \n"
        }
      ]
}