import { ApplicationRef, ChangeDetectionStrategy, ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, HostListener, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { loginDetails } from '../UserDetails';
import { Cart, CartList } from '../Cart';
import { APICallingService } from '../model/APICallingService';
import { AppSettings, IAppSettings } from '../model/AppSettings';
import * as PageStore from "../../../src/app/Store/PageStore/Page.Actions";
import { AppComponent } from '../app.component';
import { MyWishList } from '../Mywishlist';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation, NgxGalleryLayout, NgxGalleryImageSize, NgxGalleryComponent } from '@kolkov/ngx-gallery';
import Swal from 'sweetalert2';
import $ from 'jquery';
import { BrowserService } from '../browser.service';
import * as _ from 'lodash';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import * as data from '../../assets/seo/meta-tags.json';
import { MetaService } from './meta-service';



@Component({
  selector: 'app-combo',
  templateUrl: './combo.component.html',
  styleUrl: './combo.component.css',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComboComponent {
    @ViewChild('ctrCart') ctrCartButton: ElementRef;
    @ViewChild('ctrGallery') ctrGallery: NgxGalleryComponent;
    galleryOptions: NgxGalleryOptions[];
    galleryImages: NgxGalleryImage[];
    hover = false;
    metaDetails: any[] = (data as any).meta;
  
    lstReviews: any = [];
    uniqueProducts : any = [];
    recommendedProducts : any = [];
    storeSettings: IAppSettings =
      {
        faxUserId: '',
        faxPassword: '',
        companyName: '',
        theamColorCode: '#ea0029',
        headerColorCode: '#000',
        headerimage: '../../assets/img/trending1.png',
        loginBackgroundImage: '../../assets/bg-1.jpg',
        loginImage: '../../assets/img/logo_web.png',
        dangerColorCode: '#dc3545',
        successColorCode: '#28a745',
        logo: '../../assets/img/logo_web.png',
        paymentGateway: '',
        key: '',
        selectedBranch: '',
        apiLoginId: '',
        orderEmail: '',
        adminUrl: '',
        orderUrl: '',
        viewName: 'AppSettings',
        apiCallingUrl: '',
        fontFamily: 'prompt,sans-serif',
        domainName: '',
        fax: '',
        projectId: '',
        keyNo: '',
        noOfBranches: 0,
        currency: 'Rs ',
        transactionkey: '',
    };
  
    ImagePath: string;
    //private appRef: ApplicationRef,
    constructor(private titleService: Title, private metaService: MetaService, private meta: Meta, 
      private browserService: BrowserService, private cdr: ChangeDetectorRef, private el: ElementRef, private renderer: Renderer2, private route: ActivatedRoute, private apiCall: APICallingService, private WishList: MyWishList, private App: AppComponent, private objCart: Cart, private store: Store<any>, public router: Router, 
      public appSettings: AppSettings) {
      var that = this;
      if (this.browserService.isBrowser()) {
        this.storeSettings = this.appSettings.loadSettings();
        this.ImagePath = this.storeSettings.apiCallingUrl;
        let storeDeliverySettings = (this.store as any).source['value']['MSECOM'].filter((x: any) => {
          return x.viewName == 'deliveryZipCode';
        });
        if (storeDeliverySettings.length > 0) {
          let deliveryZipCode = Object.assign({}, storeDeliverySettings[0]);
          $('#selectedZipCode').val(deliveryZipCode.deliveryZipCode);
          if (this.browserService.isBrowser()) {
            let cartDetails = (this.store as any).source['value']['MSECOM'].filter((x: any) => {
              return x.viewName == 'CartList';
            });
            if (cartDetails.length > 0) {
              let lstCartList = Object.assign([], cartDetails[0].lstCartList);
              this.objCart.CalcTotals(lstCartList);
            }
          }
          this.checkDeliveryAvailability((+this.objCart.OrderGrossAmount));
        }
      }
    }
  
    getcurrency() {
      return this.storeSettings.currency.toString();
    }
  
    sizepopdisplay = 'none';
    ImageClick(Image) {
      this.Imagesrc = Image;
    }
  
    closeLoginModal() {
      this.sizepopdisplay = 'none';
    }
  
    sizeView(description) {
      debugger;
      this.sizepopdisplay = 'block';
      this.SizeChartDescription = description;
    }
  
    loginUserDetails: any = [];
    RawImagePath = "";
    Imagesrc = "";
    getdescription(ProductShortDesc) {
  
      if (typeof (ProductShortDesc) != "undefined") {
        var res = ProductShortDesc.replaceAll("<p>", "<p class='white-font-color'>");
        return res;
      }
    }
    productName: string = "";
    comboId: number = 0;
    lstComboDetails : any = [];
    Description : string = "";
    ngOnInit(): void {
      debugger;
      
      //this.metaService.setMetaTags();
      //this.meta.updateTag({ name: 'description', content:'dsfsdfdsfsdf'});
  
      this.route.queryParamMap.subscribe((params: any) => {
        debugger
  
        // this.DProductID = params.params.ID;
        // this.DOptionID = params.params.OptionID;
        this.comboId = params.params.ID;
  
        if (this.comboId > 0) {
            this.viewComboDetails(this.comboId)
        }

        // try {
        //   let index = this.metaDetails.findIndex(x => x.id == this.DProductID);
        //   let description = this.metaDetails[index].description.trim();
        //   let title = this.metaDetails[index].title.trim();
        //   let tags = this.metaDetails[index].tags.trim();
         
        // } catch (e) {
  
        // }
  
        this.loginUserDetails = new loginDetails();
        var result = (this.store as any).source['value']['MSECOM'].filter((x: any) => { return x.viewName == "loginDetails"; });
        if (result.length > 0) {
          this.loginUserDetails = (Object.assign({}, result[0]));
        }
        
        this.ratingsReviews(this.DProductID);
      }
      );
  
      this.galleryOptions = [
        {
          width: "497px",
          height: "1100px",
          previewCloseOnClick: true,
          previewCloseOnEsc: true,
          thumbnailsColumns: 5,
          previewZoom: true,
          imageInfinityMove: true,
  
          imageBullets: true,
  
          previewRotate: true,
          fullWidth: false,
          arrowPrevIcon: 'fa fa-chevron-left',
          arrowNextIcon: 'fa fa-chevron-right',
          closeIcon: "fa fa-window-close",
          fullscreenIcon: "fa fa-arrows",
          spinnerIcon: "fa fa-refresh fa-spin fa-3x fa-fw",
          previewFullscreen: true,
          thumbnailSize: NgxGalleryImageSize.Contain,
  
          imageAutoPlayInterval: 3000,
          layout: NgxGalleryLayout.ThumbnailsBottom,
          // auto play section
          imageAutoPlay: false,
          imageAutoPlayPauseOnHover: true,
          previewAutoPlay: true,
          previewAutoPlayPauseOnHover: true,
          // auto play section ends
          imageAnimation: NgxGalleryAnimation.Slide,
          imageSwipe: true,
          thumbnailsSwipe: true,
          previewSwipe: true,
          imageSize: NgxGalleryImageSize.Cover,
          thumbnailsMoveSize: 1
        },
  
        // max-width 800
        {
          breakpoint: 800,
          width: '100%',
          height: '800px',
          imagePercent: 100,
          thumbnailsPercent: 20,
          previewFullscreen: true,
          thumbnailsMargin: 0,
          thumbnailMargin: 0,
          imageSwipe: true,
          thumbnailsSwipe: true,
          previewSwipe: true,
  
        },
  
        // max-width 400
        {
          breakpoint: 400,
          preview: true,
          previewFullscreen: true,
          imageSwipe: true,
          thumbnailsSwipe: true,
          previewSwipe: true,
        },
  
  
        { "breakpoint": 500, "width": "300px", "height": "800px", "previewFullscreen": true, "thumbnailsColumns": 5 },
        { "breakpoint": 300, "width": "100%", "height": "800px", "previewFullscreen": true, "thumbnailsColumns": 5 }
  
      ];
      this.galleryImages = [];
    }

    viewComboDetails(comboId) {
        debugger;
        $("#loaderParent").prepend("<div class='theme-loader' id='loaderDet'> <div class='preloader3 loader-block'> <div class='circ1'></div> <div class='circ2'></div> <div class='circ3'></div> <div class='circ4'></div> </div> </div>");
        $("#loaderParent").show();
        let that = this;
        that.apiCall.DBCalling('ViewCombos', comboId, '', '', "").subscribe(
          (res: any) => {
            debugger;
            $("#loaderParent").hide();
            let DbResult = (res);
            that.lstComboDetails = [];
            if (DbResult.tasks[0].length > 0) {
              debugger;
              that.lstComboDetails = Object.assign([], DbResult.tasks[0]);
              that.Description =  DbResult.tasks[1][0].Description
              debugger;
              var resproducts = (((that.lstComboDetails[0].Products).replace(/\n/g, "")).replace(/'/g, "\""));
              var res = JSON.parse(resproducts);
              debugger;
              for(let i=0;i<res.length;i++){
                debugger;
                that.getProductOptionDetailsByProductID(res[i].ProductID);
              }
            }
        })
      }

    applyStyles() {
      // const element = this.el.nativeElement.querySelector('.ngx-gallery-layout');
  
      // if (element) {
      //   this.renderer.setStyle(element, 'width', '100%');
      //   this.renderer.setStyle(element, 'height', '100%');
      //   this.renderer.setStyle(element, 'display', '-webkit-box');
      //   this.renderer.setStyle(element, 'display', 'block');
      //   this.renderer.setStyle(element, '-webkit-box-orient', 'vertical');
      //   this.renderer.setStyle(element, '-webkit-box-direction', 'normal');
      //   this.renderer.setStyle(element, 'flex-direction', 'column');
      // }
  
      // const element = this.el.nativeElement.querySelector('ngx-gallery-thumbnails');
      // if (element) {
      //       this.renderer.setStyle(element, 'height', 'calc(15% + 20px)!important');
      // } 
  
      // const element1 = this.el.nativeElement.querySelector('[ngx-gallery-image]');
      // if(element1){
      //   this.renderer.setStyle(element1, 'margin-bottom', '-10px');
      //  }
    }

    ratingsReviews(e) {
      //debugger
      // alert(e);
      this.apiCall.DBCalling("Ratings_Reviews", e, '', '', '').subscribe((res: any) => {
        //debugger
        this.lstReviews = [];
        let data = (res.tasks[0]);
        try {
          if (data.length > 0) {
            this.lstReviews = data;
          }
        } catch (e) {
  
        }
      })
    }
  
    zindexval: string = '-1';
    click(event) {
  
  
  
    }
  
  
  
    lstProducts = [];
    lstRelatedProducts = [];
    IsWishListItem = [];
    lstset: any = [];
    lstOp2: any = [];
    lstOp3: any = [];
    lstOp4: any = [];
    MerchantID = '0';
  
    SelectedOption1 = '';
    SelectedOption2 = '';
    SelectedOption3 = '';
    SelectedOption4 = '';
    DOptionID = '0';
    DProductID = '0';
    lstCurrentDet:any=[];
  
    CheckOptionB(lstData): [] {
  
      var lstFilterDet: any = [];
      if (lstData != null && typeof (lstData) != "undefined") {
  
  
        for (let j = 0; j < this.lstProducts.length; j++) {
  
          if (this.lstProducts[j].OPName1 == this.SelectedOption1) {
            for (var i = 0; i < lstData.length; i++) {
  
              if (lstData[i].OptionName == this.lstProducts[j].OPName2 && lstData[i].SalesPrice == this.lstProducts[j].SalesPrice) {
                if (lstFilterDet.length > 0) {
                  lstFilterDet.push(lstData[i]);
                } else {
                  lstFilterDet = [lstData[i]];
                }
  
              }
            }
          }
        }
      }
  
      return lstFilterDet;
    }
  
  
  
    CheckOptionC(lstData): [] {
  
  
      var lstFilterDet: any = [];
  
      if (lstData != null && typeof (lstData) != "undefined") {
  
        for (let j = 0; j < this.lstProducts.length; j++) {
  
          if (this.lstProducts[j].OPName1 == this.SelectedOption1 && this.lstProducts[j].OPName2 == this.SelectedOption2) {
            for (var i = 0; i < lstData.length; i++) {
  
              if (lstData[i].OptionName == this.lstProducts[j].OPName3 && lstData[i].SalesPrice == this.lstProducts[j].SalesPrice) {
                if (lstFilterDet.length > 0) {
                  lstFilterDet.push(lstData[i]);
                } else {
                  lstFilterDet = [lstData[i]];
                }
  
              }
            }
          }
        }
      }
  
      return lstFilterDet;
    }
  
  
  
  
  
    CheckOptionD(lstData): [] {
  
      var lstFilterDet: any = [];
  
      try {
  
  
  
        if (lstData != null && typeof (lstData) != "undefined") {
  
          for (let j = 0; j < this.lstProducts.length; j++) {
  
            if (this.lstProducts[j].OPName1 == this.SelectedOption1 && this.lstProducts[j].OPName2 == this.SelectedOption2 && this.lstProducts[j].OPName3 == this.SelectedOption3) {
              for (var i = 0; i < lstData.length; i++) {
  
                if (lstData[i].OptionName == this.lstProducts[j].OPName4 && lstData[i].SalesPrice == this.lstProducts[j].SalesPrice) {
                  if (lstFilterDet.length > 0) {
                    lstFilterDet.push(lstData[i]);
                  } else {
                    lstFilterDet = [lstData[i]];
                  }
  
                }
              }
            }
          }
  
        }
  
      } catch (e) {
  
      }
      return lstFilterDet;
    }
  
  
    CheckOption2(lstData, SValue): [] {
  
  
      var lstFilterDet: any = [];
      for (var i = 0; i < lstData.length; i++) {
  
        if (lstData[i].OPName2 == SValue) {
          if (lstFilterDet.length > 0) {
            lstFilterDet.push(lstData[i]);
          } else {
            lstFilterDet = [lstData[i]];
          }
  
        }
      }
  
      return lstFilterDet;
    }
  
  
  
    CheckOption3(lstData, SValue): [] {
  
      var lstFilterDet: any = [];
      for (var i = 0; i < lstData.length; i++) {
  
        if (lstData[i].OPName3 == SValue) {
          if (lstFilterDet.length > 0) {
            lstFilterDet.push(lstData[i]);
          } else {
            lstFilterDet = [lstData[i]];
          }
  
  
        }
      }
  
      return lstFilterDet;
    }
  
  
  
    CheckOption4(lstData, SValue): [] {
  
      var lstFilterDet: any = [];
      for (var i = 0; i < lstData.length; i++) {
  
        if (lstData[i].OPName4 == SValue) {
          if (lstFilterDet.length > 0) {
            lstFilterDet.push(lstData[i]);
          } else {
            lstFilterDet = [lstData[i]];
          }
  
  
        }
      }
  
      return lstFilterDet;
    }
  
    CheckOption1(lstData, SValue): [] {
  
      var lstFilterDet: any = [];
      for (var i = 0; i < lstData.length; i++) {
  
        if (lstData[i].OPName1 == SValue) {
          if (lstFilterDet.length > 0) {
            lstFilterDet.push(lstData[i]);
          } else {
            lstFilterDet = [lstData[i]];
          }
  
        }
      }
  
      return lstFilterDet;
    }
    addDays = (date: Date, days: number): Date => {
      let result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    };
    ExpectedDeliveryDays = "";
    DeliveryCharges: number = 0;
    mDeliveryCharges = 0;
    EnterPincode: string = "";
    showDeliveryStatusmsg: boolean = false;
    Deliveryerror: boolean = false;
  
  
    checkDeliveryAvailability(price) {
  
      let deliveryZipCode = $("#deliveryZipCode").val();
      this.EnterPincode = deliveryZipCode.toString()
      if (typeof (deliveryZipCode) != 'undefined' && deliveryZipCode != '') {
        debugger;
        $("#preloader").show();
  
        this.apiCall.DBCalling("CheckDeliveryAvailability", deliveryZipCode, price, "", "").subscribe(
          (res: any) => {
            debugger
            $("#preloader").hide();
            this.showDeliveryStatusmsg = true;
            if (res.tasks[1][0].Message != '') {
              this.DeliveryCharges = -1;
              this.Deliveryerror = true
              debugger;
  
            } else {
              let dbResult: any = (res);
              if (dbResult.tasks.length > 0) {
                debugger
                this.Deliveryerror = false;
                this.store.dispatch(new PageStore.OpenPage({ viewName: 'selectedZipCode', deliveryZipCode }));
                let ExpectedDeliveryDays = typeof (dbResult.tasks[0][0]['ExpectedDeliveryDays']) == 'undefined' ? '' : dbResult.tasks[0][0]['ExpectedDeliveryDays'];
                this.DeliveryCharges = (typeof (dbResult.tasks[0][0]['DeliveryCharges']) == 'undefined' ? -1 : (+dbResult.tasks[0][0]['DeliveryCharges']));
                this.mDeliveryCharges = (+dbResult.tasks[0][0]['DeliveryCharges']);
                this.showDeliveryStatusmsg = this.DeliveryCharges == -1 ? false : true;
                // this.showDeliveryStatusmsg = this.DeliveryCharges > 0 ? true : false;
                this.cdr.detectChanges();
                try {
                  debugger
                  if (ExpectedDeliveryDays != '') {
                    const date: Date = new Date();
                    const dateResult: Date = this.addDays(date, (+ExpectedDeliveryDays));
                    debugger;
                    this.ExpectedDeliveryDays = dateResult.toLocaleDateString('en-US', { day: 'numeric', month: 'short' });
                  } else {
                    this.ExpectedDeliveryDays = null;
                  }
                  debugger;
                } catch (e) {
                }
                debugger;
                try {
                  debugger;
                  let maxDiscountAmount = (+dbResult.tasks[1][0]['MaxDiscountAmount'])
                  let fixedAmount = (+dbResult.tasks[1][0]['FixedAmount'])
                  let percentage = (+dbResult.tasks[1][0]['Percentage'])
                  debugger;
                  let perDiscount = percentage > 0 ? ((price * percentage) / 100) : 0;
                  debugger;
                  let disc = perDiscount > 0 ? ((perDiscount > fixedAmount) ? fixedAmount : perDiscount) : (fixedAmount > 0 ? ((fixedAmount > maxDiscountAmount) ? maxDiscountAmount : fixedAmount) : maxDiscountAmount);
                  debugger;
                  this.DeliveryCharges = this.DeliveryCharges - (disc > maxDiscountAmount ? maxDiscountAmount : disc);
                  this.DeliveryCharges = this.DeliveryCharges > 0 ? this.DeliveryCharges : 0;
                } catch (e) {
                }
              } else {
                debugger;
                this.Deliveryerror = true;
              }
            }
            this.cdr.detectChanges();
          }
        );
      } else {
        this.ExpectedDeliveryDays = '';
        this.showDeliveryStatusmsg = false;
      }
  
    }
  
  
    validatePinCode(e) {
      debugger
  
      let pincode = e.target.value
      if (pincode.length > 6) {
        debugger;
        // pincode.splice(0,6)
        pincode = pincode.slice(0, 6)
        // document.getElementById('deliveryZipCode').value =pincode;
        const inputElement = document.getElementById('deliveryZipCode') as HTMLInputElement;
        inputElement.value = pincode;
      }
  
  
      //   if (this.value.length > 4) {
      //     this.value = this.value.slice(0,4); 
      // }
  
    }
    getstockstatus(OPName2) {
  
      var filter = this.lstProducts.filter((x) => x.OPName2 == OPName2);
      return ((filter[0].Available) != 'out of stock' ? false : true)
    }
  
    OptionsChange(event, target, index) {
  
  
      var lstFilterDet: any[];
      var lstFilterDet1: any[];
      var ReslstFilterDet: any[];
  
      if (index == 1) {
  
        this.SelectedOption1 = target;
  
  
        this.SelectedOption2 = "";
        this.SelectedOption3 = "";
        this.SelectedOption4 = "";
      }
  
      if (index == 2) {
  
        this.SelectedOption2 = target;
  
        this.SelectedOption3 = "";
        this.SelectedOption4 = "";
      }
  
      if (index == 3) {
        this.SelectedOption3 = target;
  
        this.SelectedOption4 = "";
      }
  
      if (index == 4) {
        this.SelectedOption4 = target;
  
      }
      lstFilterDet = this.CheckOption1(this.lstProducts, this.SelectedOption1);
  
  
  
  
      if (this.SelectedOption2 != "" && (lstFilterDet.length > 1)) {
  
        lstFilterDet1 = this.CheckOption2(lstFilterDet, this.SelectedOption2);
        lstFilterDet = lstFilterDet1;
  
        //this.SelectedOption3=lstFilterDet.length==1 ? lstFilterDet[0].OPName3 : '';
      }
  
      if (this.SelectedOption3 != "" && (lstFilterDet.length > 1)) {
  
        lstFilterDet1 = this.CheckOption3(lstFilterDet, this.SelectedOption3);
        lstFilterDet = lstFilterDet1;
      }
  
      if (this.SelectedOption4 = "" && (lstFilterDet.length > 1)) {
  
        lstFilterDet1 = this.CheckOption4(lstFilterDet, this.SelectedOption4);
        lstFilterDet = lstFilterDet1;
      }
  
  
  
  
  
      if (lstFilterDet.length > 0) {
        var Sindex = 0;
        var MaxOptions = 0;
  
        for (var i = 0; i < lstFilterDet.length; i++) {
          if (lstFilterDet[i].OPName1 != "" && lstFilterDet[i].OPName2 != "" && lstFilterDet[i].OPName3 != "" && lstFilterDet[i].OPName4 != "") {
            Sindex = i;
            MaxOptions = 4;
  
  
          }
  
          if (lstFilterDet[i].OPName1 != "" && lstFilterDet[i].OPName2 != "" && lstFilterDet[i].OPName3 != "" && MaxOptions < 4) {
            Sindex = i;
            MaxOptions = 3;
          }
  
  
          if (lstFilterDet[i].OPName1 != "" && lstFilterDet[i].OPName2 != "" && MaxOptions < 3) {
            Sindex = i;
            MaxOptions = 2;
          }
  
  
          if (lstFilterDet[i].OPName1 != "" && MaxOptions < 2) {
            Sindex = i;
            MaxOptions = 2;
          }
  
        }
  
  
  
  
        var resD1 = (((lstFilterDet[Sindex].SetDetails).replace(/\n/g, "")).replace(/'/g, "\""));
        var resChild1 = JSON.parse(resD1);
        this.lstset = resChild1 == '' ? [] : resChild1;
  
        this.lstCurrentDet = lstFilterDet[Sindex];
  
        this.SelectedOption1 = lstFilterDet[Sindex].OPName1;
  
        this.SelectedOption2 = lstFilterDet[Sindex].OPName2;
  
  
        this.SelectedOption3 = lstFilterDet[Sindex].OPName3;
        this.SelectedOption4 = lstFilterDet[Sindex].OPName4;
        try {
  
          var resDF = (((this.lstCurrentDet['Features']).replace(/\n/g, "")).replace(/'/g, "\""));
          var resChildF = JSON.parse(resDF);
          this.lstCurrentDet['Features'] = resChildF;
        } catch (e) {
  
        }
  
        try {
  
  
          var resD2 = (((this.lstCurrentDet['lst1Options']).replace(/\n/g, "")).replace(/'/g, "\""));
          var resChild2 = JSON.parse(resD2);
          this.lstCurrentDet['lst1Options'] = resChild2;
  
        } catch (e) {
  
        }
  
  
        {
          try {
  
            var resD = (((this.lstCurrentDet['lst2Options']).replace(/\n/g, "")).replace(/'/g, "\""));
            var resChild = JSON.parse(resD);
            this.lstCurrentDet['lst2Options'] = resChild;
  
          } catch (e) {
  
          }
          this.lstOp2 = this.CheckOptionB(this.lstCurrentDet['lst2Options']);
        }
        {
          try {
            var resD = (((this.lstCurrentDet['lst3Options']).replace(/\n/g, "")).replace(/'/g, "\""));
            var resChild = JSON.parse(resD);
            this.lstCurrentDet['lst3Options'] = resChild;
          } catch (e) {
  
          }
          this.lstOp3 = this.CheckOptionC(this.lstCurrentDet['lst3Options']);
        }
  
        {
          try {
            var resD = (((this.lstCurrentDet['lst4Options']).replace(/\n/g, "")).replace(/'/g, "\""));
            var resChild = JSON.parse(resD);
            this.lstCurrentDet['lst4Options'] = resChild;
          } catch (e) {
  
          }
          this.lstOp4 = this.CheckOptionD(this.lstCurrentDet['lst4Options']);
        }
  
  
  
      }
  
      this.Imagesrc = this.lstCurrentDet.Image1;
  
      this.lstCurrentDet.Price = Math.trunc(this.lstCurrentDet.Price)
  
  
      this.galleryImages = [];
  
      if (this.lstCurrentDet.Image1 != '' && this.lstCurrentDet.Image1 != '0') {
        this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image1, medium: this.ImagePath + this.lstCurrentDet.Image1, big: this.ImagePath + this.lstCurrentDet.Image1, description: 'Buy online -' + this.productName, label: 'Buy online -' + this.productName })
      }
      if (this.lstCurrentDet.Image2 != '' && this.lstCurrentDet.Image2 != '0') {
        this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image2, medium: this.ImagePath + this.lstCurrentDet.Image2, big: this.ImagePath + this.lstCurrentDet.Image2, description: 'Buy online -' + this.productName, label: 'Buy online -' + this.productName })
      }
  
      if (this.lstCurrentDet.Image3 != '' && this.lstCurrentDet.Image3 != '0') {
        this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image3, medium: this.ImagePath + this.lstCurrentDet.Image3, big: this.ImagePath + this.lstCurrentDet.Image3, description: 'Buy online -' + this.productName, label: 'Buy online -' + this.productName })
      }
      if (this.lstCurrentDet.Image4 != '' && this.lstCurrentDet.Image4 != '0') {
        this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image4, medium: this.ImagePath + this.lstCurrentDet.Image4, big: this.ImagePath + this.lstCurrentDet.Image4, description: 'Buy online -' + this.productName, label: 'Buy online -' + this.productName })
      }
      if (this.lstCurrentDet.Image5 != '' && this.lstCurrentDet.Image5 != '0') {
        this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image5, medium: this.ImagePath + this.lstCurrentDet.Image5, big: this.ImagePath + this.lstCurrentDet.Image5, description: 'Buy online -' + this.productName, label: 'Buy online -' + this.productName })
      }
      if (this.lstCurrentDet.Image6 != '' && this.lstCurrentDet.Image6 != '0') {
        this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image6, medium: this.ImagePath + this.lstCurrentDet.Image6, big: this.ImagePath + this.lstCurrentDet.Image6, description: 'Buy online -' + this.productName, label: 'Buy online -' + this.productName })
      }
  
      this.galleryOptions[0].thumbnailsColumns = this.galleryImages.length;
  
      var keyNamecolor = "";
      let NotslectedProdcutslst = [];
  
      function getKeyByValue(object, value) {
        return Object.keys(object).find(key =>
          object[key] === value);
      }
      keyNamecolor = getKeyByValue(this.lstProducts[0], 'Color');
      if (keyNamecolor == 'undefined' || keyNamecolor == undefined) {
        keyNamecolor = getKeyByValue(this.lstProducts[0], 'color');
      }
      if (keyNamecolor == 'OPGName1') {
        NotslectedProdcutslst = this.lstProducts.filter((x) => {
          return x.OPName1 !== this.SelectedOption1
        })
  
      } else if (keyNamecolor == 'OPGName2') {
        NotslectedProdcutslst = this.lstProducts.filter((x) => {
          return x.OPName2 !== this.SelectedOption2
        })
  
      } else if (keyNamecolor == 'OPGName3') {
        NotslectedProdcutslst = this.lstProducts.filter((x) => {
          return x.OPName3 !== this.SelectedOption3
        })
  
      } else if (keyNamecolor == 'OPGName4') {
        NotslectedProdcutslst = this.lstProducts.filter((x) => {
          return x.OPName4 !== this.SelectedOption4
        })
  
      } else {
  
      }
  
  
      if (NotslectedProdcutslst.length > 0) {
  
        for (let d of NotslectedProdcutslst) {
  
          this.lstRelatedProducts.unshift(Object.assign({}, d))
        }
      }
    }
    SizeChartDescription: string = "";
  
  
    DProductName = "";
    someHtmlCode = "<p>new <strong class='ql-font-monospace'>china assadadasdas<strong></p>"
    AddtoCartClick(Qty, check) {
      debugger;
  
      let lstCart = (this.store as any).source['value']['MSECOM'].filter((x: any) => {
        return x.viewName == 'CartList';
      });
      let product;
  
      if (lstCart.length > 0) {
        product = lstCart[0].lstCartList.filter((x: any) => x.ProductID == this.lstCurrentDet.ProductID && x.ProductOptionID == this.lstCurrentDet.ProductOptionID)
      } debugger;
  
  
  
      if (product != undefined && product.length > 0 && product[0].Qty >= (+product[0].ProductQty)) {
        debugger;
        // alert('Out of Stock')
        Swal.fire({
          title: `Out Of Stock`,
          timer: 2000,
        });
  
      } else {
  
  
  
        this.store.dispatch(new PageStore.OpenPage({ viewName: 'Buylst', lstCartList: [] }));
        debugger;
        var keyNamecolor, KeyNamesize, ValueColorKey, ValuesizeKey, ValidationMsge;
        debugger;
        function getKeyByValue(object, value) {
          return Object.keys(object).find(key =>
            object[key] === value);
        }
        keyNamecolor = getKeyByValue(this.lstCurrentDet, 'Color');
        KeyNamesize = getKeyByValue(this.lstCurrentDet, 'Size');
  
        if (keyNamecolor == 'undefined' || keyNamecolor == undefined) {
          keyNamecolor = getKeyByValue(this.lstCurrentDet, 'color');
        }
        if (KeyNamesize == 'undefined' || KeyNamesize == undefined) {
          KeyNamesize = getKeyByValue(this.lstCurrentDet, 'size');
        }
  
        debugger;
  
        ValueColorKey = keyNamecolor === 'OPGName1' ? this.lstCurrentDet.OPName1 : keyNamecolor === 'OPGName2' ? this.lstCurrentDet.OPName2 : keyNamecolor === 'OPGName3' ? this.lstCurrentDet.OPName3 : keyNamecolor === 'OPGName4' ? this.lstCurrentDet.OPName4 : '';
        ValuesizeKey = KeyNamesize === 'OPGName1' ? this.lstCurrentDet.OPName1 : KeyNamesize === 'OPGName2' ? this.lstCurrentDet.OPName2 : KeyNamesize === 'OPGName3' ? this.lstCurrentDet.OPName3 : KeyNamesize === 'OPGName4' ? this.lstCurrentDet.OPName4 : '';
        if (ValueColorKey != '' && typeof (ValueColorKey) != 'undefined' && ValuesizeKey !== '' && typeof (ValuesizeKey) != 'undefined' && ValuesizeKey != null && ValueColorKey != null) {
          debugger;
          var lstofProduct = this.lstCurrentDet;
          var valid = true;
          try {
            valid = true;
            let obj = new CartList();
            obj.MerchantID = this.MerchantID;
            obj.ProductName = lstofProduct.ProductName;
            obj.ProductCode = lstofProduct.ProductCode;
            obj.ProductQty = this.getproductAvailableqty();
            obj.ProductLongDesc = lstofProduct.ProductLongDesc;
            obj.ProductImage = this.ImagePath + this.Imagesrc;
            obj.Price = lstofProduct.Price;
            obj.SalesPrice = lstofProduct.SalesPrice;
            obj.IsWishListItem = this.IsWishListItem.toString();
            obj.OPGName1 = lstofProduct.OPGName1;
            obj.OPGName2 = lstofProduct.OPGName2;
            obj.OPGName3 = lstofProduct.OPGName3;
            //   obj.AvailableQty=lstofProduct.AvailableQty;
            obj.OPGName4 = lstofProduct.OPGName4;
            obj.ProductID = lstofProduct.ProductID;
            obj.ProductOptionID = lstofProduct.ProductOptionID;
            obj.OPName1 = lstofProduct.OPName1;
            obj.OPName2 = lstofProduct.OPName2;
            obj.OPName3 = lstofProduct.OPName3;
            obj.OPName4 = lstofProduct.OPName4;
            debugger;
            obj.ParentID = '0';
  
            obj.Qty = Qty;
  
            obj.OPName3 = lstofProduct.OPName3;
            obj.OPName4 = lstofProduct.OPName4;
  
            var strExtraPieces = "";
            if (this.lstSetItemList.length > 0) {
              debugger
              for (let s = 0; s < this.lstSetItemList.length; s++) {
                debugger;
                let obj1 = new CartList();
                obj1.MerchantID = this.MerchantID;
                obj1.ProductName = this.lstSetItemList[s].ProductName;
                obj1.ProductCode = this.lstSetItemList[s].ProductCode;
                obj1.ProductLongDesc = this.lstSetItemList[s].ProductLongDesc;
                obj1.ProductImage = this.ImagePath + this.lstSetItemList[s].OptionItem.Image;
                obj1.Price = this.lstSetItemList[s].Price;
                obj1.SalesPrice = this.lstSetItemList[s].SalesPrice;
                obj1.OPGName1 = this.lstSetItemList[s].OPGName1;
                obj.Price = (+obj.Price) + (+this.lstSetItemList[s].Price);
                obj.SalesPrice = (+obj.SalesPrice) + (+this.lstSetItemList[s].SalesPrice);
                obj1.OPGName2 = this.lstSetItemList[s].OPGName2;
                obj.IsWishListItem = this.lstSetItemList[s].IsWishListItem.toString();
                obj1.OPGName3 = this.lstSetItemList[s].OPGName3;
                obj1.OPGName4 = this.lstSetItemList[s].OPGName4;
                obj1.ProductID = this.lstSetItemList[s].ProductID;
                obj1.ProductOptionID = this.lstSetItemList[s].ProductOptionID;
                obj1.OPName1 = this.lstSetItemList[s].OPName1;
                obj1.OPName2 = this.lstSetItemList[s].OPName2;
                obj1.OPName3 = this.lstSetItemList[s].OPName3;
                obj1.OPName4 = this.lstSetItemList[s].OPName4;
                obj1.ParentID = this.lstCurrentDet.ProductOptionID;
                obj1.Qty = Qty;
                obj1.OPName3 = this.lstSetItemList[s].OPName3;
                obj1.OPName4 = this.lstSetItemList[s].OPName4;
                obj1.CalcTotal();
                obj.lstSet.push(obj1)
                if (s == 0) {
                  strExtraPieces = this.lstSetItemList[s].ProductName;
  
                } else {
                  strExtraPieces = strExtraPieces + ',' + this.lstSetItemList[s].ProductName;
                  debugger;
                }
  
              }
  
              obj.ExtraPieces = strExtraPieces;
            }
            obj.CalcTotal();
            debugger;
  
  
            this.lstSetItemList = [];
            this.objCart.AddToCart(obj);
            this.store.dispatch(new PageStore.OpenPage({ viewName: 'CartList', lstCartList: Object.assign([], this.objCart.lstCartList) }));
            if (check == 'cart') {
              //debugger
  
              debugger;
              //  const buttonElement: HTMLElement = this.ctrCartButton.nativeElement;
  
              // Perform a click on the element
              // this.renderer.selectRootElement(buttonElement).click();
              //  $("#ctrCart").click();
              //  $('#ctrCart').click();
  
              //$(".minicart__open--btn").click();
  
              ($('#ctrCart') as any).click();
              // Swal.fire({
              //   title: "Product has been added to your cart",
              //   timer: 2000,
              // });
            }
  
            debugger;
            this.DProductName = obj.ProductName;
  
  
  
            this.CQty = 1;
          } catch (e) {
            console.log(e)
          }
        } else {
          debugger
          if (ValueColorKey == '' || typeof (ValueColorKey) == 'undefined' || typeof (ValueColorKey) == undefined || ValueColorKey == null && ValuesizeKey == '' || typeof (ValuesizeKey) == 'undefined' || typeof (ValuesizeKey) == undefined || ValuesizeKey == null) {
            ValidationMsge = 'Color && Size'
          } else if (ValueColorKey == '' || typeof (ValueColorKey) == 'undefined' || typeof (ValueColorKey) == undefined || ValueColorKey == null) {
            ValidationMsge = 'Color'
          } else if (ValuesizeKey == '' || typeof (ValuesizeKey) == 'undefined' || typeof (ValuesizeKey) == undefined || ValuesizeKey == null) {
            ValidationMsge = 'Size'
          }
  
  
          debugger;
  
          Swal.fire({
            title: `Please select ${ValidationMsge} to your cart`,
            timer: 2000,
          });
        }
  
  
      }
    }
  
    BuyNowProduct() {
  
  
      var keyNamecolor, KeyNamesize, ValueColorKey, ValuesizeKey, ValidationMsge;
  
      function getKeyByValue(object, value) {
        return Object.keys(object).find(key =>
          object[key] === value);
      }
      keyNamecolor = getKeyByValue(this.lstCurrentDet, 'Color');
      KeyNamesize = getKeyByValue(this.lstCurrentDet, 'Size');
  
      if (keyNamecolor == 'undefined' || keyNamecolor == undefined) {
        keyNamecolor = getKeyByValue(this.lstCurrentDet, 'color');
      }
      if (KeyNamesize == 'undefined' || KeyNamesize == undefined) {
        KeyNamesize = getKeyByValue(this.lstCurrentDet, 'size');
      }
  
      ValueColorKey = keyNamecolor === 'OPGName1' ? this.lstCurrentDet.OPName1 : keyNamecolor === 'OPGName2' ? this.lstCurrentDet.OPName2 : keyNamecolor === 'OPGName3' ? this.lstCurrentDet.OPName3 : keyNamecolor === 'OPGName4' ? this.lstCurrentDet.OPName4 : '';
      ValuesizeKey = KeyNamesize === 'OPGName1' ? this.lstCurrentDet.OPName1 : KeyNamesize === 'OPGName2' ? this.lstCurrentDet.OPName2 : KeyNamesize === 'OPGName3' ? this.lstCurrentDet.OPName3 : KeyNamesize === 'OPGName4' ? this.lstCurrentDet.OPName4 : '';
  
      if (ValueColorKey != '' && typeof (ValueColorKey) != 'undefined' && ValuesizeKey !== '' && typeof (ValuesizeKey) != 'undefined' && ValuesizeKey != null && ValueColorKey != null) {
        var lstofProduct = this.lstCurrentDet;
        var lst: any = [];
  
        let obj = new CartList();
        obj.MerchantID = this.MerchantID;
        obj.ProductName = lstofProduct.ProductName;
        obj.ProductCode = lstofProduct.ProductCode;
        obj.ProductQty = this.getproductAvailableqty();
        obj.ProductLongDesc = lstofProduct.ProductLongDesc;
        obj.ProductImage = this.ImagePath + this.Imagesrc;
        obj.Price = lstofProduct.Price;
  
        obj.SalesPrice = lstofProduct.SalesPrice;
        obj.OPGName1 = lstofProduct.OPGName1;
        obj.IsWishListItem = this.IsWishListItem.toString();
        obj.OPGName2 = lstofProduct.OPGName2;
  
        obj.OPGName3 = lstofProduct.OPGName3;
        obj.OPGName4 = lstofProduct.OPGName4;
        obj.ProductID = lstofProduct.ProductID;
        obj.ProductOptionID = lstofProduct.ProductOptionID;
        obj.OPName1 = lstofProduct.OPName1;
        obj.OPName2 = lstofProduct.OPName2;
        obj.OPName3 = lstofProduct.OPName3;
        obj.OPName4 = lstofProduct.OPName4;
  
        obj.ParentID = '0';
  
        obj.Qty = 1;
  
        obj.OPName3 = lstofProduct.OPName3;
        obj.OPName4 = lstofProduct.OPName4;
        obj.CalcTotal();
        lst.push(Object.assign({}, obj))
        this.store.dispatch(new PageStore.OpenPage({ viewName: 'Buylst', lstCartList: Object.assign([], lst) }));
  
  
  
        if (this.loginUserDetails.UserID == 0) {
  
  
          this.App.loginClick('checkout')
        } else {
  
  
          this.router.navigate(['/checkout'], { queryParams: { navigateFrom: 'BuyNow' } });
  
  
        }
      } else {
        // //debugger
        if (ValueColorKey == '' || typeof (ValueColorKey) == 'undefined' || typeof (ValueColorKey) == undefined || ValueColorKey == null && ValuesizeKey == '' || typeof (ValuesizeKey) == 'undefined' || typeof (ValuesizeKey) == undefined || ValuesizeKey == null) {
          ValidationMsge = 'Color && Size'
        } else if (ValueColorKey == '' || typeof (ValueColorKey) == 'undefined' || typeof (ValueColorKey) == undefined || ValueColorKey == null) {
          ValidationMsge = 'Color'
        } else if (ValuesizeKey == '' || typeof (ValuesizeKey) == 'undefined' || typeof (ValuesizeKey) == undefined || ValuesizeKey == null) {
          ValidationMsge = 'Size'
        }
  
  
        Swal.fire({
          title: `Please select ${ValidationMsge} to your cart`,
          timer: 2000,
        });
      }
  
  
    }
  
    getproductAvailableqty() {
  
      return this.lstCurrentDet.Availableqty;
    }
  
    GetPurchaseQty(PurchaseProductList) {
  
  
      return typeof (this.objCart.getPurchaseqtyfromcart(PurchaseProductList)) == 'undefined' ? '' : this.objCart.getPurchaseqtyfromcart(PurchaseProductList);
    }
    CQty = 1;
    UserID = '0';
    lstSetItemList: any = [];
    Addedcart() {
  
  
    }
  
  
    AddWishlist() {
  
  
      this.UserID = this.loginUserDetails.UserID == 0 ? this.UserID : this.loginUserDetails.UserID;
  
  
      var ExItems = "";
      var strExtraPieces = "";
      if (this.lstSetItemList.length > 0) {
        for (let s = 0; s < this.lstSetItemList.length; s++) {
  
          ExItems = ExItems + "<Table1>"
  
            + '<UserID>' + this.UserID + '</UserID>'
            + '<ProductOptionID>' + this.lstSetItemList[s].ProductOptionID + '</ProductOptionID>'
            + '<ParentID>' + this.lstCurrentDet.ProductOptionID + '</ParentID>'
            + '<ExtraPieces></ExtraPieces>'
            + "</Table1>"
          if (s == 0) {
            strExtraPieces = this.lstSetItemList[s].ProductName;
  
          } else {
            strExtraPieces = strExtraPieces + ',' + this.lstSetItemList[s].ProductName;
  
          }
  
        }
      }
  
      var rows = "<Table1>"
  
        + '<UserID>' + this.UserID + '</UserID>'
        + '<ProductOptionID>' + this.lstCurrentDet.ProductOptionID + '</ProductOptionID>'
        + '<ParentID>' + 0 + '</ParentID>'
        + '<ExtraPieces>' + strExtraPieces + '</ExtraPieces>'
        + "</Table1>"
        ;
      rows = '<NewDataSet>' + rows + ExItems + '</NewDataSet>';
  
      this.apiCall.DBCalling("SaveWishList", rows, "", "", "").subscribe(
        (res) => {
  
          let DbResult: any = (res);
  
          if (DbResult.tasks.length > 0) {
  
            for (let i = 0; i < DbResult.tasks.length; i++) {
  
              if (DbResult.tasks[i].length > 0) {
  
                if (DbResult.tasks[i][0].DBresult > 0) {
  
                  $("#ctrlWishList").css("background-position", "-2800px 0");
                  $("#ctrlWishList").css("transition", "background 1s steps(28)");
  
                }
              }
            }
            Swal.fire({
              title: "Product has been added to your Wishlist",
              timer: 2000,
            });
            this.WishList.GetDetailsfromWishList(this.UserID)
          }
  
        });
  
    }
    async productMouseOverCall(productId) {
  
  
      await this.apiCall.DBCalling("GetProductOptionsShortDetByProductId", productId, "", "", "").subscribe(
        async (res) => {
  
          let opResult: any = (res);
          var resD = (((opResult.tasks[0][0].Result).replace(/\n/g, "")).replace(/'/g, "\""));
          var resChild = JSON.parse(resD)
          if (resChild.length > 0) {
            await this.prepareOptionsString(resChild);
  
          }
  
          if (this.browserService.isBrowser()) {
            const document: any = this.browserService.getDocument();
            document.getElementById("prodshortdesc").style.color = '#d00'
          }
        }
      );
    }
  
    ProductClick(ProductOptionID, ProductID, ProductImage, data, option2, option3) {
      // //debugger
      var keyNamecolor = "";
      function getKeyByValue(object, value) {
        return Object.keys(object).find(key =>
          object[key] === value);
      }
      if (data.ProductName == this.lstCurrentDet.ProductName && data.ProductID == this.lstCurrentDet.ProductID) {
        keyNamecolor = getKeyByValue(data, 'Color');
        if (keyNamecolor == 'OPGName1') {
          this.SelectedOption1 = data.OPName1;
          this.OptionsChange('', '', 0)
        } else if (keyNamecolor == 'OPGName2') {
          this.SelectedOption2 = data.OPName2
          this.OptionsChange('', '', 0)
        } else if (keyNamecolor == 'OPGName3') {
          this.SelectedOption3 = data.OPName3
          this.OptionsChange('', '', 0)
        } else if (keyNamecolor == 'OPGName4') {
          this.SelectedOption4 = data.OPName4
          this.OptionsChange('', '', 0)
        } else {
  
        }
      }
      if (this.browserService.isBrowser()) {
        const window: any = this.browserService.getWindow();
        window.scrollTo(0, 0)
      }
  
      const urlFriendlyProduct = _.kebabCase(data.ProductName);
      this.router.navigate(['/product', urlFriendlyProduct], { queryParams: { ID: ProductID } });
    }
    selectedProductOptions = []
    prepareOptionsString(selectedProductOptions) {
      this.selectedProductOptions = [];
      //
      let Options = [];
  
  
      for (let d = 0; d < selectedProductOptions.length; d++) {
  
        let extind = Options.findIndex(x => x == selectedProductOptions[d].OptionGroupName);
        if (extind == -1) {
          Options.push(selectedProductOptions[d].OptionGroupName);
  
        }
      }
  
      for (let i = 0; i < Options.length; i++) {
        //
        const array = selectedProductOptions.map(item => (item.OptionGroupName == Options[i] ? item.OptionName : null));
        const filteredArray = array.filter(value => value !== null && value !== undefined);
        const result = filteredArray.join(',');
        if (result != '') {
          this.selectedProductOptions.push({ 'Name': Options[i], 'Options': result });
        }
      }
  
  
  
  
    }
  
    ArryLimit = 4;
    RelatedProductslength = 0;
    getRelatedProducts1() {
      var keyNamecolor = "";
      let relatedProdlst = [];
      function getKeyByValue(object, value) {
        return Object.keys(object).find(key =>
          object[key] === value);
      }
  
      keyNamecolor = getKeyByValue(this.lstCurrentDet, 'Color');
      if (keyNamecolor == 'undefined' || keyNamecolor == undefined) {
        keyNamecolor = getKeyByValue(this.lstProducts[0], 'color');
      }
      if (keyNamecolor == 'OPGName1') {
        var filterprod = this.lstRelatedProducts.filter(x => x.OPName1 != this.SelectedOption1);
        relatedProdlst = this.getFilterByProducts(filterprod, 'OPName1', this.SelectedOption1)
      } else if (keyNamecolor == 'OPGName2') {
  
  
      } else if (keyNamecolor == 'OPGName3') {
  
  
      } else if (keyNamecolor == 'OPGName4') {
  
  
      } else {
  
      }
      //  let mymap = new Map();
  
      //     let unique = this.lstRelatedProducts.filter(el => {
      //         const val = mymap.get(el[keyNamecolor]);
      //         if (val) {
      //             if (el.id < val) {
      //                 mymap.delete(el.name);
      //                 mymap.set(el.name, el.id);
      //                 return true;
      //             } else {
      //                 return false;
      //             }
      //         }
      //         mymap.set(el.name, el.id);
      //         return true;
      //     });
  
      return relatedProdlst;
    }
    getFilterByProducts(Products, OptionName, selectedoption) {
  
      var existproducts = [];
  
      for (let d of Products) {
        var fillen = existproducts.filter(x => x[OptionName] == selectedoption);
        if (fillen.length == 0) {
          existproducts.unshift(Object.assign({}, d))
        }
      }
  
      return existproducts
    }
    getRelatedProducts() {
      debugger;
      try {
        debugger;
  
        var res = this.lstRelatedProducts.filter(x => x.ProductID != this.DProductID);
        var uniqueProducts = this.lstRelatedProducts.reduce((result, product) => {
          // Use a Set to keep track of unique product IDs
          var resty = result.map(p => p.ProductID && p.OPName1);
          const uniquePairs = [];
          result.forEach(p => {
            const pair = { ProductID: p.ProductID, OPName1: p.OPName1 };
            const existingPair = uniquePairs.find(item => item.ProductID === pair.ProductID && item.OPName1 === pair.OPName1);
  
            if (!existingPair) {
              uniquePairs.push(pair);
            }
          });
  
  
          // var idsSet = new Set(result.map(p => p.ProductID && p.OPName1));
  
          // If the product's ID is not in the Set, add it to the result array
  
          var uindex = uniquePairs.findIndex(x => x.ProductID == product.ProductID && x.OPName1 == product.OPName1);
  
          if (uindex == -1) {
            result.push(product);
          }
  
          //  if (!idsSet.has(product.ProductID )) {
  
          //  }
  
          // Return the intermediate result for the next iteration
          return result;
        }, []);
        var keyNamecolor = "";
        function getKeyByValue(object, value) {
          return Object.keys(object).find(key =>
            object[key] === value);
        }
  
        keyNamecolor = getKeyByValue(this.lstCurrentDet, 'Color');
        if (keyNamecolor == 'undefined' || keyNamecolor == undefined) {
          keyNamecolor = getKeyByValue(this.lstProducts[0], 'color');
        }
        if (keyNamecolor == 'OPGName1') {
          var index = uniqueProducts.findIndex((x) => x.OPName1 == this.SelectedOption1);
          uniqueProducts.splice(index, 1)
          var filterprod = this.lstProducts.filter(x => x.OPName1 != this.SelectedOption1);
          for (let d of filterprod) {
            var existrecord = uniqueProducts.filter(x => x.OPName1 != this.SelectedOption1);
            if (existrecord.length == 0) {
              uniqueProducts.unshift(Object.assign({}, d))
            } else {
  
            }
          }
  
  
        } else if (keyNamecolor == 'OPGName2') {
          var index = uniqueProducts.findIndex((x) => x.OPName2 == this.SelectedOption2);
          uniqueProducts.splice(index, 1)
  
        } else if (keyNamecolor == 'OPGName3') {
          var index = uniqueProducts.findIndex((x) => x.OPName3 == this.SelectedOption3);
          uniqueProducts.splice(index, 1)
  
        } else if (keyNamecolor == 'OPGName4') {
          var index = uniqueProducts.findIndex((x) => x.OPName4 == this.SelectedOption4);
          uniqueProducts.splice(index, 1)
  
        } else {
  
        }
  
  
  
  
  
  
        //  var res= this.lstRelatedProducts;
        //   const uniqueProducts = this.lstRelatedProducts.reduce((result, product) => {
        //     // Use a Set to keep track of unique product IDs
        //    const idsSet = new Set(result.map(p => p.OPName2!=this.SelectedOption2));
  
        //     // If the product's ID is not in the Set, add it to the result array
        //  //  if (!idsSet.has(product.OPName2)) {
        //      result.push(product);
        //  //  }
  
        //     // Return the intermediate result for the next iteration
        //    return result;
        //  }, []);
        this.RelatedProductslength = res.length;
        debugger;
        this.uniqueProducts = uniqueProducts;
        let productDetails = this.lstRelatedProducts.filter(x => x.ProductID == this.DProductID);
        debugger;
  
        this.trainAndRecommend(productDetails[0]);
  
        return uniqueProducts;
      } catch (e) {
  
      }
  
  
  
  
    }
    ServerPath = "";
    SharePath = "";
    mobile: boolean = false;
    @HostListener('window:resize', [])
    onOrientationChange(event) {
  
      var width = window.innerWidth;
      if (width <= 768) {
  
        this.mobile = true;
        // window.scrollTo(0,0)
      } else {
        this.mobile = false;
      }
    }
    share(type) {
  
      if (this.browserService.isBrowser()) {
        const window: any = this.browserService.getWindow();
  
        const document: any = this.browserService.getDocument();
        switch (type) {
          case 'Twitter':
            {
              window.open("https://twitter.com/share?url=" + encodeURIComponent(this.SharePath), "_blank", "");
  
              break;
            }
          case 'Whatsapp':
            {
              //  sending url to be like this to whatsapp 
  
              // https://www.blackdenim.in/#/ProductDetails?OptionID=1065&ID=145&Img=Images%2FProductID_Image145WedAug022023150321GMT0530IndiaStandardTime.JPG&option2=M&option3=
  
              window.open("https://web.whatsapp.com/send?text=" + encodeURIComponent(window.location.href), " _blank", "");
  
              break;
            }
  
          case 'Facebook':
            {
              window.open("https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(this.SharePath), "_blank", "");
  
  
              break;
            }
  
          case 'mbWhatsapp':
            {
  
              // window.open("whatsapp://send?text="+encodeURIComponent("https://www.blackdenim.in/#/ProductDetails?OptionID=1065&ID=145&Img=Images%2FProductID_Image145WedAug022023150321GMT0530IndiaStandardTime.JPG&option2=M&option3=") ,"_blank" ,"");
              window.open("whatsapp://send?text=" + encodeURIComponent(window.location.href), "_blank", "");
  
  
              break;
            }
  
          case 'Instagram':
            {
              window.open("https://www.instagram.com/Blackdenim", "_blank", "");
  
  
              break;
            }
          case 'Copy link':
            {
              let url = document.location.href
              navigator.clipboard.writeText(url).then(function () {
  
              }, function () {
  
              });
  
  
              break;
            }
        }
      }
    }
    clickonView() {
  
    }
    ngDoCheck() {
      try {
        const galleryThumbnails = this.el.nativeElement.querySelector('ngx-gallery-thumbnails');
        this.renderer.setStyle(galleryThumbnails, 'height', '12%');
        this.renderer.setStyle(galleryThumbnails, 'background-size', 'cover');
  
  
        const galleryThumbnails1 = this.el.nativeElement.querySelector('ngx-gallery-image');
        this.renderer.setStyle(galleryThumbnails1, 'margin-bottom', '10px');
  
  
        const imgScroll = this.el.nativeElement.querySelector('.ngx-gallery-image-wrapper');
        this.renderer.setStyle(imgScroll, 'overflow-x', 'scroll');
  
        const imgScroll1 = this.el.nativeElement.querySelector('.ngx-gallery-thumbnails-wrapper');
        this.renderer.setStyle(imgScroll1, 'overflow-x', 'scroll');
        // debugger;
        // const galleryThumbnail =$('.ngx-gallery-thumbnail');
  
        // for(let i=0;i<galleryThumbnail.length;i++)
        // {
        //   galleryThumbnail[i].setStyle(galleryThumbnail, 'border', 'none');
  
  
        // }
  
        $('.ngx-gallery-thumbnail').each(function () {
          // Use $(this) to refer to the current element in the loop
          $(this).css('border', 'none');
        });
  
      } catch (e) {
  
      }
    }
  
    ngAfterViewInit() {
      debugger
  
      // /$("#ctpcontainer").scrollTop(100);
  
  
      // // window.scrollTo(0,0);
      //  const galleryThumbnails = this.el.nativeElement.querySelector('ngx-gallery-thumbnails');
      //   this.renderer.setStyle(galleryThumbnails, 'height', 'calc(35% + 20px)');
  
      //   var that=this;
  
      //  $(document).ready(function() {
      //   //debugger
      //   // Find ngx-gallery-thumbnails by tag name
      //   // var $galleryThumbnails = $('ngx-gallery-thumbnails');
  
      //   // $galleryThumbnails[0].style.height="calc(35% + 20px) !important"
      //   const galleryThumbnails = that.el.nativeElement.querySelector('ngx-gallery-thumbnails');
      //   that.renderer.setStyle(galleryThumbnails, 'height', 'calc(35% + 20px)');
  
      // });
  
      this.applyStyles();
    }
    //   updateMetaTags(productTags)
  
    // {
    //   debugger;
    // // this.meta.updateTag({ property: 'og:description', content: 'sdaasdsad' }, "property='og:description'");
    //   try{
    // //alert( productTags.Fld2.toString());
  
  
    //     this.meta.updateTag({ name: 'description', content: productTags.Fld2.toString() });
    //   this.meta.updateTag({ name: 'keywords', content: productTags.toString()});
    //   //  if(productTags[0].Fld2!='')
    //   //  {
    //       debugger;
    //     // this.meta.addTags([
    //     //   { name: 'description', content:this.lstProducts[0].Fld2 },
    //     //   { name: 'keywords', content: this.lstProducts[0].Fld3}
    //     // ]);
  
    //         // this.meta.updateTag( { name: 'description', content:productTags[0].Fld2 });
    //         //  this.meta.updateTag({ property: 'og:image', content:productTags[0].Fld2 }, "property='og:image'");
    //         //  this.meta.updateTag({ property: 'og:title', content: productTags[0].Fld2 }, "property='og:title'");
    //         //  this.meta.updateTag({ property: 'og:description', content: productTags[0].Fld2}, "property='og:description'");
    //   //  }
    //   //  if(productTags[0].Fld1!='')
    //    // {
    //       this.titleService.setTitle(productTags.Fld1);
  
    //     //}
  
  
    //   }catch(e)
    //   {
  
    //   }
  
    // }
    GetstocksizefromProduct() {
      // //debugger
      var filter = this.lstProducts.filter((x) => x.Available == 'In stock');
      return filter[0].OPName2;
    }
    dataLoaded = false;
  
  
  
    getProductOptionDetailsByProductID(DProductID) {
      //  this.apiCall.DBCalling("OnlineOrderProductOptionDetailsByProductID",ID,UserID,"","")
      debugger
      if (this.browserService.isBrowser()) {
        $("#preloader").show();
      }
      this.dataLoaded = false;
      debugger;
  
      this.apiCall.DBCalling("OnlineOrderProductOptionDetailsByProductID", DProductID, 0, "", "").subscribe(
        (res) => {
          debugger
          let dbResult: any = (res);
          this.lstProducts = [];
  
          if (dbResult.tasks.length > 0 && dbResult.tasks[0].length > 0) {
  
            this.lstProducts = dbResult.tasks[0];
  
            this.lstRelatedProducts = dbResult.tasks[1];
            this.MerchantID = this.lstProducts[0]["MerchantID"];
           
  
            for (var i = 0; i < this.lstProducts.length; i++) {
              try {
                var resD = (((this.lstProducts[i]["lst1Options"]).replace(/\n/g, "")).replace(/'/g, "\""));
                var resChild = JSON.parse(resD);
  
  
  
                this.lstProducts[i]["lst1Options"] = resChild
  
                this.lstset = resChild[0].SetDetails == '' ? [] : resChild[0].SetDetails;
  
                this.SelectedOption1 = resChild[0].OptionName
  
  
              } catch (e) {
  
              }
              try {
  
  
  
                var resD = (((this.lstProducts[i]["lst2Options"]).replace(/\n/g, "")).replace(/'/g, "\""));
                var resChild = JSON.parse(resD);
  
  
  
                this.lstProducts[i]["lst2Options"] = resChild
  
  
  
                this.SelectedOption2 = this.SelectedOption2 == '' ? resChild[0].OptionName : this.GetstocksizefromProduct();
  
                this.OptionsChange('', this.SelectedOption2, 2)
  
              } catch (e) {
  
              }
  
  
              try {
                var resD = (((this.lstProducts[i]["lst3Options"]).replace(/\n/g, "")).replace(/'/g, "\""));
                var resChild = JSON.parse(resD);
  
  
  
                this.lstProducts[i]["lst3Options"] = resChild
                this.SelectedOption3 = this.SelectedOption3 == '' ? resChild[0].OptionName : this.SelectedOption3;
  
  
              } catch (e) {
  
              }
  
              try {
  
                var resD = (((this.lstProducts[i]["lst4Options"]).replace(/\n/g, "")).replace(/'/g, "\""));
                var resChild = JSON.parse(resD);
  
                this.lstProducts[i]["lst4Options"] = resChild
                this.SelectedOption4 = resChild[0].OptionName;
  
              } catch (e) {
  
              }
  
  
  
              try {
  
                var resDF = (((this.lstProducts['Features']).replace(/\n/g, "")).replace(/'/g, "\""));
                var resChildF = JSON.parse(resDF);
                this.lstProducts['Features'] = resChildF;
              } catch (e) {
  
              }
  
  
  
  
  
  
              if (this.lstProducts[i].ProductOptionID == this.DOptionID) {
                this.IsWishListItem = this.lstProducts[i].IsWishListItem;
                this.lstCurrentDet.push( this.lstProducts[i]);
                debugger;
               // this.Imagesrc = this.lstCurrentDet.Image1;
                this.lstOp2 = this.CheckOptionB(this.lstCurrentDet['lst2Options'])
                this.lstOp3 = this.CheckOptionC(this.lstCurrentDet['lst3Options'])
                this.lstOp4 = this.CheckOptionD(this.lstCurrentDet['lst4Options'])
              }
  
              if (this.galleryImages.length == 0) {
  
  
                if (this.lstCurrentDet.Image1 != '' && this.lstCurrentDet.Image1 != '0') {
                  this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image1, medium: this.ImagePath + this.lstCurrentDet.Image1, big: this.ImagePath + this.lstCurrentDet.Image1 })
                }
                if (this.lstCurrentDet.Image2 != '' && this.lstCurrentDet.Image2 != '0') {
                  this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image2, medium: this.ImagePath + this.lstCurrentDet.Image2, big: this.ImagePath + this.lstCurrentDet.Image2 })
                }
  
                if (this.lstCurrentDet.Image3 != '' && this.lstCurrentDet.Image3 != '0') {
                  this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image3, medium: this.ImagePath + this.lstCurrentDet.Image3, big: this.ImagePath + this.lstCurrentDet.Image3 })
                }
                if (this.lstCurrentDet.Image4 != '' && this.lstCurrentDet.Image4 != '0') {
                  this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image4, medium: this.ImagePath + this.lstCurrentDet.Image4, big: this.ImagePath + this.lstCurrentDet.Image4 })
                }
                if (this.lstCurrentDet.Image5 != '' && this.lstCurrentDet.Image5 != '0') {
                  this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image5, medium: this.ImagePath + this.lstCurrentDet.Image5, big: this.ImagePath + this.lstCurrentDet.Image5 })
                }
                if (this.lstCurrentDet.Image6 != '' && this.lstCurrentDet.Image6 != '0') {
                  this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image6, medium: this.ImagePath + this.lstCurrentDet.Image6, big: this.ImagePath + this.lstCurrentDet.Image6 })
                }
              }
            }
            if (this.browserService.isBrowser()) {
              $("#preloader").hide();
            }
            // //debugger
            if (typeof (this.lstRelatedProducts) != undefined && typeof (this.lstRelatedProducts) != 'undefined' && this.lstRelatedProducts.length > 0) {
              for (let i = 0; i < this.lstRelatedProducts.length; i++) {
                if (this.lstRelatedProducts[i].ProductRating === "5.0000") {
                  this.lstRelatedProducts[i].ProductRating = "5";
                }
              }
            }
            // //debugger
            let NotslectedProdcutslst;
            if (this.SelectedOption2 != '' && this.SelectedOption1 != '') {
              NotslectedProdcutslst = this.lstProducts.filter((x) => {
                return x.OPName1 != this.SelectedOption1 && x.OPName2 != this.SelectedOption2
              })
            } else {
              NotslectedProdcutslst = this.lstProducts.filter((x) => {
                return x.OPName1 != this.SelectedOption1
              })
            }
  
            // //debugger
            if (NotslectedProdcutslst.length > 0) {
              this.lstRelatedProducts.unshift(Object.assign({}, NotslectedProdcutslst[0]))
            }
            // //debugger
  
          }
          if (this.browserService.isBrowser()) {
            const document: any = this.browserService.getDocument();
            this.ServerPath = document.location.origin;
  
            const urlFriendlyProduct = _.kebabCase(this.productName);
            if (document.location.hostname == 'localhost') {
              this.SharePath = this.ServerPath + "/Product/" + urlFriendlyProduct + "?ID=" + this.DProductID + "&OptionID=" + this.DOptionID + "&Img" + this.RawImagePath;
            } else {
              this.SharePath = this.ServerPath + "Blackdenim/web/Product/" + urlFriendlyProduct + "?ID=" + this.DProductID + "&OptionID=" + this.DOptionID + "&Img" + this.RawImagePath;
            }
          }
  
  
  
          // // window.scrollTo(0,0);
  
          debugger;
          //  this.appRef.tick();
          //   this.dataLoaded=true;
  
        });
  
    }
  
  
    home() {
      this.router.navigateByUrl('home')
    }
  
    products() {
      //this.router.navigateByUrl('ProductSearchAndFilterByCategory')
  
      this.router.navigate(
        ['/collection', '']
        //,Img:''
      );
  
    }
  
  
    viewAll() {
      //debugger
  
      if (this.browserService.isBrowser()) {
        const window: any = this.browserService.getWindow();
  
        var NewUrl = (window.location.href.split('#')[0])
        NewUrl = this.getBaseUrl(NewUrl);
  
        // var url =
         this.router.navigate(['/product-review'], {
          queryParams: {
            ID: this.DProductID, Name: this.productName
          }
        })
        // window.open(NewUrl + url, "_blank", ""); //'#'+
      }
    }
  
  
    getBaseUrl(url: string): string {
      //debugger
      const parsedUrl = new URL(url);
      return `${parsedUrl.origin}`;
    }
  
    async trainAndRecommend(d:any){
      debugger;
      const selectedProduct = d;
      const recommendedProducts = this.recommendProducts(selectedProduct).slice(0,6);
      debugger;
      
  
      this.recommendedProducts = recommendedProducts
    }
  
    recommendProducts(selectedProduct:any) {
      debugger;
      const recommendations = this.uniqueProducts.map(product => ({
        product,
        score: this.calculateSimilarity(selectedProduct, product)
      }))
      // .map(item => item.product.ProductID)
      .filter(item => item.product.ProductID != selectedProduct.ProductID)
      .sort((a, b) => b.score - a.score); 
      debugger;
      return recommendations.map(item => item.product);
    }
  
    calculateSimilarity(productA:any, productB:any) {
      // Simple similarity based on category and price
      let score = 0;
      if (productA.BrandName != productB.BrandName) score += 1;
      if (productA.Categoryname != productB.Categoryname) score += 1;
      return score;
    }

}

