import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-returnscancellationpolicy',
  templateUrl: './returnscancellationpolicy.component.html',
  styleUrls: ['./returnscancellationpolicy.component.css']
})
export class ReturnscancellationpolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // window.scrollTo(0,0);
  }

}
