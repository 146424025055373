import { Component, NgZone, OnInit, Pipe } from '@angular/core';
import { APICallingService } from '../model/APICallingService';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
 
import { BrowserService } from '../browser.service';
import { AppSettings, IAppSettings } from '../model/AppSettings';

@Component({
  selector: 'app-storelocators',
  templateUrl: './storelocators.component.html',
  styleUrls: ['./storelocators.component.css']
})
export class StorelocatorsComponent implements OnInit {
  lstStores: any = [];
  currentMap: any;
  activeLocation : string = "";
  ImagePath: string;

  
  storeSettings: IAppSettings =
  {
    faxUserId: '',
    faxPassword: '',
    companyName: '',
    theamColorCode: '#ea0029',
    headerColorCode: '#000',
    headerimage: '../../assets/img/trending1.png',
    loginBackgroundImage: '../../assets/bg-1.jpg',
    loginImage: '../../assets/img/logo_web.png',
    dangerColorCode: '#dc3545',
    successColorCode: '#28a745',
    logo: '../../assets/img/logo_web.png',
    paymentGateway: '',
    key: '',
    selectedBranch: '',
    apiLoginId: '',
    orderEmail: '',
    adminUrl: '',
    orderUrl: '',
    viewName: 'AppSettings',
    apiCallingUrl: '',
    fontFamily: 'prompt,sans-serif',
    domainName: '',
    fax: '',
    projectId: '',
    keyNo: '',
    noOfBranches: 0,
    currency: 'Rs ',
    transactionkey: '',
  };


  constructor(private apiCall: APICallingService, private router: Router,private ngZone: NgZone,
    private sanitizer: DomSanitizer,private browserService: BrowserService, public appSettings: AppSettings
  ) { 
    

    if (this.browserService.isBrowser()) {
      this.storeSettings = this.appSettings.loadSettings();
      this.ImagePath = this.storeSettings.apiCallingUrl;
    }
  }

  ngOnInit(): void {
   
    this.viewStores();
  }



  viewStores() {
    debugger;
    this.apiCall.DBCalling('ViewStores', '', '', '', '').subscribe(
      (res: any) => {
        debugger;
        let result = res;
        this.lstStores = result.tasks[0];
        console.log(this.lstStores)
        for (let i = 0; i < this.lstStores.length; i++) {
          if (this.lstStores[i].Iframe != '') {
            debugger;
            this.updateMap(this.lstStores[i].Iframe)
            // this.activeLocation = this.lstStores[i].Name;

           
            // const srcRegex = /src="([^"]*)"/;
            // const match = currentMap.match(srcRegex);
            // const srcValue = match ? match[1] : '';
            // this.currentMap = srcValue;
            break;
          }
        }
      })
  }

  updateMap(map:any) {
    // this.ngZone.run(() => {
      debugger; // This will help you inspect the value during development
      this.selectLocation(map);
    // });
  }


  selectLocation(map:any){
    debugger;
    this.currentMap = decodeURIComponent(map);
  }


  getUrl(d:any){
    return decodeURIComponent(d);
  }

  getMap(map){

    let mapIframe = decodeURIComponent(map)
    return mapIframe;
  }
}
