import { Component ,} from '@angular/core';
import { BrowserService } from '../browser.service';
import { ActivatedRoute, Router } from '@angular/router';
import { APICallingService } from '../model/APICallingService';
import { Cart } from '../Cart';
import { Store } from '@ngrx/store';
import { AppSettings, IAppSettings } from '../model/AppSettings';
import { Location } from '@angular/common';
import * as _ from 'lodash';

@Component({
  selector: 'app-combos',
  templateUrl: './combos.component.html',
  styleUrl: './combos.component.css'
})
export class CombosComponent {
  lstCombos : any = [];
  selectedProductOptions : any = [];
  storeSettings: IAppSettings =
  {

    faxUserId: '',
    faxPassword: '',
    companyName: '',
    theamColorCode: '#ea0029',
    headerColorCode: '#000',
    headerimage: '../../assets/img/trending1.png',
    loginBackgroundImage: '../../assets/bg-1.jpg',
    loginImage: '../../assets/img/logo_web.png',
    dangerColorCode: '#dc3545',
    successColorCode: '#28a745',
    logo: '../../assets/img/logo_web.png',
    paymentGateway: '',
    key: '',
    selectedBranch: '',
    apiLoginId: '',
    orderEmail: '',
    adminUrl: '',
    orderUrl: '',
    viewName: 'AppSettings',
    apiCallingUrl: '',
    fontFamily: 'prompt,sans-serif',
    domainName: '',
    fax: '',
    projectId: '',

    keyNo: '',
    noOfBranches: 0,

    currency: 'Rs ',
    transactionkey: '',
  };
  ImagePath = this.storeSettings.apiCallingUrl;
  hover: boolean = false;
  constructor(private browserService: BrowserService, 
    private route: ActivatedRoute, private apiCall: APICallingService, public objCart: Cart, 
    private store: Store<any>, public router: Router, public appSettings: AppSettings,private location: Location) {
    if (this.browserService.isBrowser()) {
      this.storeSettings = this.appSettings.loadSettings();
      this.ImagePath = this.storeSettings.apiCallingUrl;
    }
  }

  ngOnInit(){
    this.ViewCombos();
  }

  ViewCombos() {
    debugger;
    $("#loaderParent").prepend("<div class='theme-loader' id='loaderDet'> <div class='preloader3 loader-block'> <div class='circ1'></div> <div class='circ2'></div> <div class='circ3'></div> <div class='circ4'></div> </div> </div>");
    $("#loaderParent").show();
    let that = this;
    that.apiCall.DBCalling('ViewCombos', '', '', '',"").subscribe(
      (res:any) => {
        debugger;
        $("#loaderParent").hide();
        let DbResult = (res);
        that.lstCombos = [];
        if (DbResult.tasks[0].length > 0) {
          debugger;
          that.lstCombos = Object.assign([], DbResult.tasks[0]);
          console.log(that.lstCombos);
        }
      })
  }

  async productMouseOverCall(productId) {

    debugger;
    let comboDetails = this.lstCombos.filter((x:any)=>x.ID == productId)
    var resD = (((comboDetails[0].ComboOptionsDetails).replace(/\n/g, "")).replace(/'/g, "\""));
    debugger;
    this.selectedProductOptions = JSON.parse(resD);

  }

  
  getcurrency() {
    return this.storeSettings.currency;
  }

  ProductClick(details:any) {
    debugger;
    this.router.navigateByUrl('ProductClick');

    const urlFriendlyProduct = _.kebabCase(details.ComboName);
    this.router.navigate(
      ['/combo', urlFriendlyProduct],
      { queryParams: { ID: details.ID } }
    );
  }

}
