<h2 class="offer">Contact Us</h2>

<div class="container mx-auto">
  <div class="row">

    <div class="col-md-12 p-5 pb-0">
      <h4 class="text-dark text-center m-0">We are always here to help, and love to hear from you. <br>Whether it is regarding our collection or any assistance with your order, Our team is here for you.  </h4>
    </div>
    <div class="col-md-5 mx-auto text-left p-5 contact-details">
      <h1 class="text-left">Get in Touch With Us</h1>

      <p class="mb-3">Have any Questions regarding Purchase, exchange, return, please check our  <a href="faq.html" class="text-danger">FAQ’s</a>.  If your query is not listed there you can write to us here.</p>

      <p class="mb-3">
        For Return or Exchange Please visit our   <a href="https://blackdenim.in/returns" class="text-danger">Return Policy Page</a>
      </p>
      
      <p class="text-left mb-5"><i class="fa fa-phone" aria-hidden="true"></i> Customer Care <a
          href="tel:+918977500750">+91 897-750-0750 </a></p>

      <p class="text-left mb-5">Timings : 11 AM -7 PM (Monday -Saturday)</p>

      
      <p class="text-left mb-5">
        <!-- <img class="header__shipping--text__icon" src="./assets/img/icon/email.png" alt="Black Denim - Email"> -->
        For any Queries and Feedback reach us : 
       
        <a class="header__shipping--text__link" href="mailto:info&#64;blackdenim.in"><i class="fa fa-envelope"></i> info&#64;blackdenim.in</a>
      </p>

      <p  class="mb-3"><a href="https://api.whatsapp.com/send/?phone=918977500750"><i class="fa fa-whatsapp"></i> WhatsApp Support</a></p>
      
      <h1>Head Office Address</h1>
      <p>Black Denim Industries Pvt Ltd
        # 8-1-297/2, Building, E/1, Second Floor
        Falcon Valley Rd, Ambedkar Nagar
        Shaikpet, Hyderabad, Telangana 500008 
        </p>
    </div>

    <div class="col-md-7 my-auto">
      
      <div class="row" *ngIf="active == 'form'">
        <div class="col-md-6">
          <label for="">Name</label>
          <input type="text" [ngClass]="submit && contact.name=='' ? 'required form-control' : 'form-control'" 
          [(ngModel)]="contact.name">
        </div>

        <div class="col-md-6">
          <label for="">Phone</label>
          <input type="number" [ngClass]="submit && contact.phone=='' ? 'required form-control' : 'form-control'" [(ngModel)]="contact.phone" >
        </div>

        <div class="col-md-6">
          <label for="">Email</label>
          <input type="text" [ngClass]="submit && contact.email=='' && !validateEmail(contact.email) ? 'required form-control' : 'form-control'"
           [(ngModel)]="contact.email" >
           <div *ngIf="submit && contact.email!='' && !validateEmail(contact.email)">
            <label class="validate-error">Invalid Email</label>
          </div>

        </div>

        <div class="col-md-6">
          <label for="">Order Id</label>
          <input type="text" class="form-control" [(ngModel)]="contact.orderid">
        </div>
 
        <div class="col-md-12 mb-5">
          <label for="">Message</label>
          <textarea class="form-control" cols="30" rows="5" [(ngModel)]="contact.message" ></textarea>
        </div>

        <div class="col-md-12">
          <button class="btn btn-dark" (click)="send()">Send</button>
        </div>
      </div>

      <div class="row" *ngIf="active != 'form'">
       <div class="col-md-9 text-center mx-auto">
        <div class="card">
          <div class="card-body">
            <h1>Thank you for contacting us!</h1>

            <p class="text-center">We Have received your message</p>
            <p class="text-center">We will reach you out ASAP!</p>
  
            <a title="Back to home" class="continue__shipping--btn primary__btn border-radius-5 custom-tooltip text-wrap" (click)="home()">Back to Home</a>
          </div>
        </div>
       </div>
      </div>

    </div>
  </div>

  <h1></h1>
  <h2></h2>
  <h3></h3>
  <h4></h4>
</div>