import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { APICallingService } from '../model/APICallingService';
import { Observable, tap } from 'rxjs';
import { MetaService } from './meta-service';
@Injectable({
    providedIn: 'root'
  })
  
export class ProductResolver implements Resolve<any> {
  constructor(  private apiCall: APICallingService,private metaservice: MetaService) {


    
  }
 

//   resolve(route: ActivatedRouteSnapshot): Observable<any> {
//     debugger;
//     let  DProductID = route.params['ID'];
//     return this.apiCall.DBCalling("OnlineOrderProductOptionDetailsByProductID", DProductID, 0, "", "").pipe(
//       tap(data => {
// debugger;
        
// this.metaservice.setMetaTags(data.tasks[0][0].Fld1.toString().trim(),data.tasks[0][0].Fld2.toString().trim(),data.tasks[0][0].Fld3.toString().trim())
//        // const dataKey = makeStateKey<any>('productData');
//        // this.transferState.set(dataKey, data);
//       })
//     );
//     }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    debugger; 
    let  DProductID =route.queryParams['ID'];
     return   this.apiCall.DBCalling("OnlineOrderProductOptionDetailsByProductID", DProductID, 0, "", "");
  }
}
