<div class="container mt-5">
    <div class="row">
        <div class="col-md-4 mb-5 " *ngFor="let d of lstBlogs">
            <div class="card" (click)="view(d)">
                <div class="row p-3">
                    <div class="col-md-12">
                        <img src="{{ImagePath+d.Image}}" alt="" class="w-100 blog-image">
                    </div>

                    <div class="col-md-12 mt-3">
                        <b class="text-gray">{{d.Title}}</b>
                    </div>
                    <div class="col-md-12">
                        <h3 class="mb-3">{{d.Heading}}</h3>
                    </div>

                    <div class="col-md-12 text-right">
                        <p><a (click)="view(d)">View details</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>