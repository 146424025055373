import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { APICallingService } from '../model/APICallingService';
import * as PageStore from "../../../src/app/Store/PageStore/Page.Actions";
import $ from 'jquery';
import { AppSettings, IAppSettings } from '../model/AppSettings';
// import 'slick-carousel';
// import 'slick-carousel/slick/slick.min.js';
// import '../../../src/assets/js/vendor/popper.js'
//  import '../../../src/assets/js/vendor/bootstrap.min.js'
import * as _ from 'lodash';
import { loginDetails } from '../UserDetails';
import { Cart } from '../Cart';
import { BrowserService } from '../browser.service';
import { Navigate } from '../navigate';
//import "../../../node_modules/slick-carousel/slick/slick.min.js";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  errorImage:string="./assets/img/other/noimagepreview.png";
  lstNewArrivals : any =[];
  hover: boolean = false;
  storeSettings:IAppSettings=
{
    
    faxUserId:'',
    faxPassword:'',
    companyName:'',
    theamColorCode:'#ea0029',
    headerColorCode: '#000',
    headerimage: '../../assets/img/trending1.png',
    loginBackgroundImage:'../../assets/bg-1.jpg',
    loginImage:'../../assets/img/logo_web.png',
    dangerColorCode: '#dc3545',
    successColorCode: '#28a745',
    logo: '../../assets/img/logo_web.png',
    paymentGateway:'',
    key:'',
    selectedBranch:'',
    apiLoginId:'',
    orderEmail:'',
    adminUrl:'',
    orderUrl:'',
    viewName:'AppSettings',
    apiCallingUrl:'',
    fontFamily:'prompt,sans-serif',
    domainName:'',
    fax:'',
    projectId:'',
    
    keyNo:'',
    noOfBranches:0,
   
    currency:'Rs ',
    transactionkey:'',
};

//hover=false;
 // constructor(private browserService: BrowserService,private route: ActivatedRoute,private apiCall:APICallingService,public objCart: Cart,private store: Store<any>,public router:Router,public appSettings:AppSettings) {
    

    constructor(private browserService: BrowserService,private route: ActivatedRoute,private apiCall:APICallingService,public objCart: Cart,private store: Store<any>,public routerUtl:Router,public router:Navigate,public appSettings:AppSettings) {
    


     if (this.browserService.isBrowser()) {
    this.storeSettings=   this.appSettings.loadSettings();
    this.ImagePath=this.storeSettings.apiCallingUrl;
     }
    //debugger
    this.getHomescreenSettings();
    this.newArrivals();
     
   }

   getcurrency(){

    return this.storeSettings.currency;
  }
  SelectedBrandId:string="0";
  SelectedBrandName:string="";
  SelectedCategoryId:string="0";
  SelectedCategoryname:string="";
  lstCategories=[];
  BrandchngeEvent(e){

let Id=e.target.value;

    debugger
    if(Id==0){
     this.SelectedBrandId=Id;
    }else{
      var data=this.lstBrands.filter(x=>x.BrandID==Id)[0]
      this.SelectedBrandId=data.BrandID;
     this.SelectedBrandName=data.BrandName;
     this.validationerror='';
    //  this.lstCategoriesMainDet=[];
     //debugger
     this.lstCategories=this.getcategory(data.BrandID)
      
    }
    
  }
  getUniqueProducts(filter,BrandId,Categoryname){
    //debugger
  
    const indexes = filter.reduce((r, n, i) => {
      n.Categoryname === Categoryname && n.BrandID === BrandId && r.push(i);
      
      return r;
    }, []);

   if(indexes.length>1){
    for(let i=1;i<indexes.length;i++){
     //debugger
      filter.splice(indexes[i],1)
    }
   }

   return filter;
  }
  lstBrandsandcategories:any=[];
  
  ViewCategoryAndBrand(){

    this.apiCall.DBCalling("ViewProductsBycategoryandbrand",'ViewcategoryByMaincategory',0,0,"").subscribe(
      async (res:any) => {
       //debugger
         this.lstBrandsandcategories= (res.tasks);
      });
  }
  getcategory(brandId){
    
    var filter=this.lstBrandsandcategories[1].filter(x=> x.BrandID==brandId);
    return filter;
  }

  newArrivals(){
    //debugger
    this.apiCall.DBCalling("newArrivals",'','','','').subscribe((res:any)=>{
      //debugger
      // this.lstNewArrivals = (res.tasks[0]);

      let data = (res.tasks[0]);

      for(let i = 0;i<data.length;i++){
        //debugger
        data[i].ProductOptionID = JSON.parse(data[i].ProductOptionID);
      }

      this.lstNewArrivals = data;
    })
  }
 

  
  CategorychngeEvent(e){
    let Id=e.target.value;
    debugger
   // if(Id==0){
      this.SelectedCategoryId=Id;
   // }else{
  //  var data=this.lstCategoriesMainDet.filter(x=>x.CategoryID==Id)[0]
  //  this.SelectedCategoryId=data.CategoryID;
  // this.SelectedCategoryname=data.Categoryname;
   //this.validationerror1='';
   // }
  }
  //  rtl:true,
   ImagePath=this.storeSettings.apiCallingUrl;


  //  slideOptions = {
  //   slidesToShow: 1,
  //   dots: false,
  //   arrows: true,
  //   autoplay: true,
  //   autoplayTimeout: 3000,
  //   autoplayHoverPause: true,
  //   infinite: true
  //  }
   
   SlideOptions = {
    slidesToShow: 1,
    dots: false,
    arrows: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          adaptiveHeight: true
        }
      }
    ],
    prevArrow: '<div style="background-size: 170%; position: absolute; top: 50%; margin-top: -1.6em; z-index: 60; height: 3em; width: 2.6em; background-image: url(./assets/img/slide/arrows.png);background-position: 0 0; left:10px;"></div>',
    nextArrow: '<div style="background-size: 170%; position: absolute; top: 50%; margin-top: -1.6em; z-index: 60; height: 3em; width: 2.7em; background-image: url(./assets/img/slide/arrows.png);right:10px; background-position: 100% 0;"></div>'
  };

  //  SlideOptions = { items: 1, dots: false, nav: true ,loop:true,autoplay:true,
  //   autoplayTimeout:3000,
  //   autoplayHoverPause:true,
  //   responsive: {
  //     0: {
  //       items: 1,
  //       autoHeight: 850
  //     },
  //   },
  //   navText: ['<div  style="background-size: 170%; position: absolute; top: 50%; margin-top: -1.6em; z-index: 60; height: 3em; width: 2.6em; background-image: url(./assets/img/slide/arrows.png);background-position: 0 0; left:10px;" ></div>','<div style="background-size: 170%; position: absolute; top: 50%; margin-top: -1.6em; z-index: 60; height: 3em; width: 2.7em; background-image: url(./assets/img/slide/arrows.png);right:10px; background-position: 100% 0;" ></div>'],
    
  // };
  @HostListener('window:resize', [])
  onOrientationChange(event) {
    
    var width = window.innerWidth;
  if(width<=768){
   
   this.mobile=true;
    // window.scrollTo(0,0)
  }else{
    this.mobile=false;
  }
  
  
  }
  CategoryClickByproduct(CategoryId,e){
    //debugger
    const urlFriendlyName = _.kebabCase(e);


    this.router.navigate(
      ['/collection',urlFriendlyName],
      { queryParams: { Type: 'Category', ID:CategoryId} } //Img:''
    );
    }

  // SlideOptions2 = { items:   6, dots: false, nav: false ,loop:true,autoplay:false,
  //   autoplayTimeout:3000,
  //   autoplayHoverPause:true,
  //   navText: ['<div  style="background-size: 170%; position: absolute; top: 50%; margin-top: -1.6em; z-index: 60; height: 3em; width: 2.6em; background-image: url(../../assets/img/slide/arrows.png);background-position: 0 0; left:10px;" ></div>','<div style="background-size: 170%; position: absolute; top: 50%; margin-top: -1.6em; z-index: 60; height: 3em; width: 2.7em; background-image: url(../../assets/img/slide/arrows.png);right:10px; background-position: 100% 0;" ></div>']
  // };
  // SlideOptions3 = { items: 3, dots: false, nav: false ,loop:true,autoplay:false,
  //   autoplayTimeout:3000,
  //   autoplayHoverPause:true,
  //   navText: ['<div  style="background-size: 170%; position: absolute; top: 50%; margin-top: -1.6em; z-index: 60; height: 3em; width: 2.6em; background-image: url(../../assets/img/slide/arrows.png);background-position: 0 0; left:10px;" ></div>','<div style="background-size: 170%; position: absolute; top: 50%; margin-top: -1.6em; z-index: 60; height: 3em; width: 2.7em; background-image: url(../../assets/img/slide/arrows.png);right:10px; background-position: 100% 0;" ></div>']
  // };
   CarouselOptions = { items: 3, dots: true, nav: true };  
   SearchStr="";
   lstHomeScreanSettings=[];
   lstRegion=[];
   FlashString="";
   lstBanners=[];
   isHomeScreenContainsLeftRegion=false;
   isHomeScreenContainsRightRegion=false;

selectedProductOptions=[];

prepareOptionsString(selectedProductOptions)
{
  this.selectedProductOptions=[];
  //
  let Options=[];
  //const uniqueArray = selectedProductOptions.filter((item, index) => selectedProductOptions.indexOf(item.OptionGroupName) === index);

  for(let d=0;d<selectedProductOptions.length;d++)
  {

   let extind=Options.findIndex(x=>x==selectedProductOptions[d].OptionGroupName);
    if(extind==-1)
    {
      Options.push(selectedProductOptions[d].OptionGroupName);

    }
  }

for(let i=0;i<Options.length;i++)
{
  //
  const array = selectedProductOptions.map(item => ( item.OptionGroupName ==Options[i]?item.OptionName:null));
  const filteredArray = array.filter(value => value !== null && value !== undefined);
  const result = filteredArray.join(',');
if(result!='')
{
  this.selectedProductOptions.push({'Name':Options[i],'Options':result});
}
}
  
  


}



lstBrands=[];
viewBrands()
{
   this.apiCall.DBCalling("ViewBrand","","","","").subscribe(
    async (res) => {
    
      let brnadResult:any= (res);
      this.lstBrands=brnadResult.tasks[0];
      
    }
  );
}


lstCategoriesMainDet:any=[];
// viewCategories()
// {
//    this.apiCall.DBCalling("ViewCategoryByProducts",'',"","","").subscribe(
//     async (res) => {
//       //
//       let catResult:any= (res);
//       const uniqueProducts = catResult.tasks[0].reduce((result, product) => {
       
//         const idsSet = new Set(result.map(p => p.CategoryID));
//         if (!idsSet.has(product.CategoryID)) {
//           result.push(product);
//         }
//         return result;
//       }, []);
//       this.lstCategories=catResult.tasks[0];

//       this.lstCategoriesMainDet=uniqueProducts.filter(x=>x.ParentCategoryId==='0');
     
//     }
//   );
// }
lstParentCategory:any=[];
ViewMainCategory(){
 //debugger
 this.lstParentCategory=[];
  this.apiCall.DBCalling("ViewParentCategoriesForMenu","","","","").subscribe(
    async (res) => {
      //debugger
      
      let lstMncategory:any= (res);
      this.lstParentCategory = lstMncategory.tasks[0];
      this.lstCategoriesMainDet= lstMncategory.tasks[0];

    });
}

async productMouseOverCall(productId)
{
//

await this.apiCall.DBCalling("GetProductOptionsShortDetByProductId",productId,"","","").subscribe(
  async (res) => {
    //
    let opResult:any= (res);
    var resD=(((opResult.tasks[0][0].Result).replace(/\n/g, "")).replace(/'/g,"\""));
    var resChild=JSON.parse(resD)
if(resChild.length>0)
{
  await this.prepareOptionsString( resChild);

}
  }
);
}
currentIndex:number=0;


getHomescreenSettings()
{
//debugger
if (this.browserService.isBrowser()) {
  $("#preloader").show();
}

 this.apiCall.DBCalling("GetHomeScreenSettingDetails","","","","").subscribe(
  (res) => {
    //debugger
    
    let HResult:any= (res);

    
    this.lstHomeScreanSettings=[];
    if(HResult.tasks.length>0 && HResult.tasks[0].length>0 )
    {

      //debugger
 
      this.lstHomeScreanSettings=HResult.tasks[0];
      
    this.lstRegion=HResult.tasks[1];
    this.isHomeScreenContainsLeftRegion=(this.lstHomeScreanSettings.filter(x=> x.Region=='Left')).length>0?true:false;
    this.isHomeScreenContainsRightRegion=(this.lstHomeScreanSettings.filter(x=> x.Region=='Right')).length>0?true:false;

 
 for(var i=0;i<this.lstHomeScreanSettings.length;i++)
      {
      this.currentIndex=i;
        if(this.lstHomeScreanSettings[i].LayoutViewType=='FlashMassage')
        {
        this.FlashString=this.FlashString+this.lstHomeScreanSettings[i].SubTiltle;
        }
        try
        {
          //debugger
          var resD=(((this.lstHomeScreanSettings[i]["Child"]).replace(/\n/g, "")).replace(/'/g,"\""));
          
               var resChild:any=JSON.parse(resD);
            
               this.lstHomeScreanSettings[i]["Child"]=resChild;
              
            
           if(resChild.length>0){
            //debugger
              for(let j=0;j< resChild.length;j++){
                //debugger
                if(this.lstHomeScreanSettings[i]["Child"][j].ChildDetails.length>0 && this.lstHomeScreanSettings[i]["Child"][j].ChildDetails!=''){
                  //debugger
                 
                  var keyNamecolor,ValueNameKey="";
                  function getKeyByValue(object, value) {
                    return Object.keys(object).find(key => 
                            object[key] === value);
                  }
                  keyNamecolor=getKeyByValue(this.lstHomeScreanSettings[i]["Child"][j].ChildDetails[0],'Color');
            
                  if(keyNamecolor=='undefined' || keyNamecolor==undefined ){
                    keyNamecolor=getKeyByValue(this.lstHomeScreanSettings[i]["Child"][j].ChildDetails[0],'color');
                  }
                  if(keyNamecolor=='OPGName1'){
                    ValueNameKey='OPName1'
                  }else if(keyNamecolor=='OPGName2'){
                    ValueNameKey='OPName2'
                  }else if(keyNamecolor=='OPGName3'){
                    ValueNameKey='OPName3'
                  }else if(keyNamecolor=='OPGName4'){
                    ValueNameKey='OPName4'
                  }
                  var uniqueProducts = this.lstHomeScreanSettings[i]["Child"][j].ChildDetails.reduce((result, product) => {
                    const uniquePairs = [];
                    result.forEach(p => {
                     const pair = { ProductID: p.ProductID, [ValueNameKey]: p[ValueNameKey] };
                     const existingPair = uniquePairs.find(item => item.ProductID === pair.ProductID && item[ValueNameKey] === pair[ValueNameKey]);
                   
                     if (!existingPair) {
                       uniquePairs.push(pair);
                     }
                   });
                   
                 
                   // var idsSet = new Set(result.map(p => p.ProductID && p.OPName1));
                   
                    // If the product's ID is not in the Set, add it to the result array
                 
                   var uindex= uniquePairs.findIndex(x=> x.ProductID== product.ProductID && x[ValueNameKey]==product[ValueNameKey]);
                 
                 if(uindex==-1)
                 {
                   result.push(product);
                   
                 }
                 return result;
                     
                }, [])
                this.lstHomeScreanSettings[this.currentIndex]["Child"][j].ChildDetails=uniqueProducts;  
                }
              }
           }
          
            }catch(e)
        {
        }
      }
     
      
      this.store.dispatch(new  PageStore.OpenPage(Object.assign([], this.lstHomeScreanSettings)));
      console.log(this.lstHomeScreanSettings);
    }
   
  });


  
this.apiCall.DBCalling("ViewBanners",(this.SearchStr==null?"":this.SearchStr),"","","").subscribe(
(res) => {
  
  //
 let Result:any= (res);
  this.lstBanners=null;
  if(Result.tasks.length>0){

 
  if(Result.tasks[0].length>0 )
  {
    this.lstBanners=Result.tasks[0];
  

    this.CarouselOptions = { items: this.lstBanners.length, dots: true, nav: true };  
    // console.log(this.lstBanners);
  }
  else{
    Result=[];
  }
}
if (this.browserService.isBrowser()) {
    $("#preloader").hide();
}
});
this.viewBrands();
//this.viewCategories();
}


ProductClick(ProductOptionID,ProductID,ProductImage,productName)
{
  //debugger
//this.GetProductOptionIdByProduct(ProductOptionID,ProductID,ProductImage,productName)
const urlFriendlyProduct = _.kebabCase(productName);
this.router.navigate(
  ['/product',urlFriendlyProduct],
  { queryParams: {ID:ProductID} }
);
}
GetProductOptionIdByProduct(ProductOptionID,ProductId,ProductImage,productName){

  this.apiCall.DBCalling("ProductOptionsByProductID",ProductId,"","","").subscribe(
    async (res:any) => {
      debugger
      let listofProducts:any= (res.tasks[0]);
      window.scrollTo(0,0)

      const urlFriendlyProduct = _.kebabCase(productName);
      this.router.navigate(
        ['/product',urlFriendlyProduct],
        { queryParams: {ID:ProductId} }
      );


      
     
    })
    
}
ProductDetByType(Type,ID,DName,Image)
{

debugger
const urlFriendlyName = _.kebabCase(DName);


this.router.navigate(
  ['/collection',urlFriendlyName],
  { queryParams: { Type:Type , ID:ID } } //,Img:Image
);

}
//  CategoryByProduct(CategoryID,data){
//   //debugger
// if(CategoryID>0 && data.ParentCategoryId!="0"){
//   // only for  categories  
//   this.router.navigate(
//     ['/ProductSearchAndFilterByCategory'],
//     { queryParams: { Type: 'Category', ID:CategoryID,Name:'',Img:''} }
//   );
// }else{
//   // only for  main categories 
//   this.router.navigate(
//     ['/ProductSearchAndFilter'],
//     { queryParams: { Type: 'Category', ID:CategoryID,Name:'',Img:''} }
//   );
// }
 
// }


BannerClick(BannerType,BannerTypeId,Image,DName,IsParentCategory)
{

//debugger
if(IsParentCategory!="0")
{
//this.router.navigateByUrl('/CategoryDetByParentCategory', { state: { ID:BannerTypeId,Name:DName,Img:Image} });

this.router.navigate(
  ['/CategoryDetByParentCategory'],
  { queryParams:  { ID:BannerTypeId,Name:DName}} // ,Img:Image
);


}else{
  //this.router.navigateByUrl('/ProductDetailsByType', { state: { Type:BannerType , ID:BannerTypeId,Name:DName,Img:Image} });

  const urlFriendlyName = _.kebabCase(DName);

  this.router.navigate(
    ['/collection',urlFriendlyName],
    { queryParams: { Type:BannerType , ID:BannerTypeId } } //,Img:Image
  );

}
}
validationerror:string="";
validationerror1:string="";
SearchByProduct()
{

  
  debugger
if(this.SelectedCategoryId!='0' && this.SelectedBrandId!='0' ){
  this.validationerror='';
  this.validationerror1='';
  window.scrollTo(0,0)
  const urlFriendlyName = _.kebabCase(this.SelectedBrandName);
  this.router.navigate(
    ['/collection',urlFriendlyName],
    { queryParams: { Type: 'Brand', ID:this.SelectedBrandId,CategoryId:this.SelectedCategoryId } } //,Img:''
  );

  // this.router.navigate(
  //   ['/ProductSearchAndFilterByCategory'],
  //   { queryParams: { Type: 'Brand', ID:this.SelectedBrandId,Name:'',CategoryId:this.SelectedCategoryId}} //Img:'',
  // );
}else if(this.SelectedCategoryId!='0'){
  this.validationerror1='';
 window.scrollTo(0,0)

 const urlFriendlyName = _.kebabCase(this.SelectedCategoryname);
 this.router.navigate(
   ['/collection',urlFriendlyName],
   { queryParams: { Type: 'Category', ID:this.SelectedCategoryId } } //,Img:''
 );

  // this.router.navigate(
  //   ['/ProductSearchAndFilterByCategory'],
  //   { queryParams: { Type: 'Category', ID:this.SelectedCategoryId,Name:'',} } //Img:''
  // );
}else if(this.SelectedBrandId!='0'){
  this.validationerror='';
  window.scrollTo(0,0)
  const urlFriendlyName = _.kebabCase(this.SelectedBrandName);
  
  this.router.navigate(
    ['/collection',urlFriendlyName],
    { queryParams: { Type: 'Brand', ID:this.SelectedBrandId } } //,Img:''
  );
}else{
 
    this.validationerror1='Please Select Category**';
    this.validationerror='Please Select Brand**';
  
}

}
TransactionStatus:string="";
  ngAfterViewInit()
  {
   //debugger
 
   
   if (this.browserService.isBrowser()) {
//get the user into current scroll position on div 
   $(document).ready(function() {
 
 //  window.scroll(0,Number(localStorage.getItem("scrollPosition")));
    // window.scrollTo(0,0);
    })
   }
     this.ViewMainCategory()

     this.ViewCategoryAndBrand()
  }
  mobile:boolean=false;
  loginUserDetails:any;
  ngOnInit(): void {

    if (this.browserService.isBrowser()) {
      const window:any = this.browserService.getWindow();

    
 
    
    $('span').removeProp('display')
      
      this.loginUserDetails= new loginDetails();
      var result = (this.store as any).source['value']['MSECOM'].filter((x:any) => { return x.viewName == "loginDetails"; });
      if (result.length > 0) {
        this.loginUserDetails=( Object.assign({},result[0] ));
      
      }
      
      this.route.queryParamMap.subscribe((params:any) => {
        debugger
        this.TransactionStatus= params.params.OrderStatus;
        if(typeof(this.TransactionStatus)!='undefined' && this.TransactionStatus!=''){
          this.ClearCheckoutDetails();
          
        }
      
       }
     );
      
     
     
     
     
     window.onscroll = function (e) 
{
    if(this.scrollY > 1000)
    {
     
    var scrolly=window.scrollY;
    if (typeof localStorage !== 'undefined') {
    localStorage.setItem("scrollPosition",scrolly.toString());
    }
    }
}
this.observer = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      // Load the image when it comes into view
      const img:any = entry.target.querySelector('img');
      if (img) {
        img.src = img.dataset.src;
        this.observer.unobserve(entry.target);
      }
    }

  });
});
if (this.browserService.isBrowser()) {
  const document:any = this.browserService.getDocument();
// Observe each lazy-image container
document.querySelectorAll('.lazy-image').forEach(item => {
  this.observer.observe(item);
});
}
  }
  }
  observer: IntersectionObserver;
  ClearCheckoutDetails(){
    
    //debugger

    //if (typeof sessionStorage !== 'undefined') {
    var OrderItemCount:number=(typeof sessionStorage !== 'undefined')?parseInt(sessionStorage.getItem('OrderItemCount')):0;
    console.log('OrderItemCount',OrderItemCount);
    if(OrderItemCount>1){
      // removing cart details from the cart section 
      this.store.dispatch(new PageStore.OpenPage({ viewName: 'CartList', lstCartList: [] }));
      let value=[];
      this.objCart.getCartQty(value)
      
    }else{
      //debugger
    // removing buy now  details from the buy section 
    let BuyProduct = (this.store as any).source['value']['MSECOM'].filter((x: any) => {
      return x.viewName == 'Buylst';
    });
    if(BuyProduct[0].lstCartList.length>0){
      //debugger
      this.store.dispatch(new PageStore.OpenPage({ viewName: 'Buylst', lstCartList: [] }));
    }else{
      //debugger
      this.store.dispatch(new PageStore.OpenPage({ viewName: 'CartList', lstCartList: [] }));
      let value=[];
      this.objCart.getCartQty(value)
    }
    
    }
    if (typeof sessionStorage !== 'undefined') {
    sessionStorage.removeItem('OrderItemCount');
    }


    this.routerUtl.navigateByUrl('thankyoupage');
  }
  // console.log('clearcheckoutDetailscalled');
 

}
