<main class="main__content_wrapper">
    <section class="product__details--section section--padding">
       <div class="container">
          <div class="row row-cols-lg-2 row-cols-md-2">
             <div class="col z-1">
                <div class="heart" (click)="AddWishlist()" *ngIf="loginUserDetails.UserID!='0'" >           
                <i class="fa fa-heart-o p-8 fs-25 float-right" (click)="AddWishlist()" aria-hidden="true"></i>
             </div>
             <div class="gallery-wrapper">
                <ngx-gallery [options]="galleryOptions" [images]="galleryImages" (previewChange)="click($event)" class="ngx-gallery" loading="eager" fetchPriority="high"></ngx-gallery>
             </div>
          </div>
          <div class="col">
             <div class="product__details--info">
                <p class="m-0 fs-20 fw-bold text-capitalize">
                   {{lstCurrentDet.BrandName}} 
                   <!-- <a href="javascript:void(0);"  style="float:right;">
                      <div class="dropdown" style="max-width: fit-content;padding: 3rem">
                          <button class="btnshare"><i class="fa fa-share-alt dropdown-toggle"
                                  aria-hidden="true"></i> Share</button>
                          <div class="dropdown-content">
                              <ul class="mt-2">
                                  <li (click)="share('Facebook')"><a  class="facebook"><i class="fa fa-facebook"></i>&nbsp;&nbsp;FaceBook</a></li>
                                  <li (click)="share('Twitter')"><a  class="twitter"><i class="fa fa-twitter"></i>&nbsp;&nbsp;Twitter</a></li>
                                  <li (click)="share('mbWhatsapp')" *ngIf="mobile"><a  class="whatsapp" ><i class="fa fa-whatsapp"></i>&nbsp;&nbsp;whatsapp</a></li>
                                  <li (click)="share('Whatsapp')" *ngIf="!mobile"><a  class="whatsapp" ><i class="fa fa-whatsapp"></i>&nbsp;&nbsp;whatsapp</a></li>
                                  <li (click)="share('Instagram')"><a  class="instagram"><i class="fa fa-instagram">&nbsp;&nbsp;Instagram</i></a>
                                  <li (click)="share('Copy link')"><a  class="instagram"><i class="fa fa-clipboard">&nbsp;&nbsp;Copy Link</i></a>
                                  </li>
                              </ul>
                          </div>
                      </div>
                      </a> -->
                </p>
                <h2 class="product__details--info__title mb-15">{{lstCurrentDet.ProductName}}</h2>
                <p class="product__details--info__title mb-15 text"
                   *ngIf="lstCurrentDet.Available=='out of stock' || lstCurrentDet.Availableqty==0">This Product is <b class="eroor">out of
                   stock!..</b>
                </p>
                <div class="product__details--info__price mb-10">
                   <span class="current__price mx-2"
                      *ngIf="lstCurrentDet.SalesPrice!=''"><b class="rupee">{{getcurrency()}}</b> {{((+lstCurrentDet.SalesPrice).toFixed(0))}}</span>
                   <!-- <span class="price__divided"
                      *ngIf="lstCurrentDet.Price!='' && ((+lstCurrentDet.SalesPrice)<(+lstCurrentDet.Price))"></span> -->
                   <span class="text-danger"
                      *ngIf="lstCurrentDet.Price!='' && ((+lstCurrentDet.SalesPrice)<(+lstCurrentDet.Price))">(<b class="rupee"><del>{{getcurrency()}}{{(+lstCurrentDet.Price).toFixed(0)}})</del></b><b class="mx-2">{{lstCurrentDet.Discount}}</b></span>
                   <!-- <b class="rupee">{{getcurrency()}}</b>{{((+lstCurrentDet.Price).toFixed(0))}} -->  
                </div>
                <div class="product__details--info__rating d-flex align-items-center mb-15">
                   <div *ngIf="lstCurrentDet.ProductRating>0" class="rating">
                      <input type="radio" id="star5" name="rating" value="5" [attr.checked]="(lstCurrentDet.ProductRating==5 ? 'checked' : null    )" disabled/><label for="star5"></label>
                      <input type="radio" id="star4" name="rating" value="4" [attr.checked]="(lstCurrentDet.ProductRating==4 ? 'checked' : null    )" disabled/><label for="star4"></label>
                      <input type="radio" id="star3" name="rating" value="3" [attr.checked]="(lstCurrentDet.ProductRating==3 ? 'checked' : null    )"  disabled/><label for="star3"></label>
                      <input type="radio" id="star2" name="rating" value="2" [attr.checked]="(lstCurrentDet.ProductRating==2 ? 'checked' : null    )"  disabled/><label for="star2"></label>
                      <input type="radio" id="star1" name="rating" value="1" [attr.checked]="(lstCurrentDet.ProductRating==1 ? 'checked' : null    )" disabled/><label for="star1"></label>
                   </div>
                </div>
                <div class="product__variant">
                   <div class="product__variant--list mb-10" *ngIf="(lstCurrentDet.OPGName1!='')">
                      <fieldset class="variant__input--fieldset"
                         *ngIf="(lstCurrentDet.OPGName1=='Color' ||  lstCurrentDet.OPGName1=='color' ||  lstCurrentDet.OPGName1=='COLOR')">
                         <legend class="product__variant--title mb-8">
                            {{lstCurrentDet.OPGName1}} :  
                            <ng-container *ngFor="let OptionItem of  lstCurrentDet['lst1Options']| UniqueOptions;let ind=index" >
                               <span class="w-40" *ngIf="OptionItem.Image!='0'">
                               <input [id]="'color-'+ind" name="color" type="radio"  
                               [attr.checked]="(SelectedOption1== OptionItem.OptionName ? 'checked' : null    )"
                               (click)="OptionsChange($event,OptionItem.OptionName,1);">
                               <label class="variant__color--value red" [for]="'color-'+ind"   *ngIf="OptionItem.Image!='0'"
                               title={{OptionItem.OptionName}} class="w-35 h-35">
                               <img class="variant__color--value__img w-29 h-29"   
                               alt="Buy online - {{lstCurrentDet.ProductName}}"   
                                  src={{ImagePath+OptionItem.Image}} >
                               </label>
                               </span>
                            </ng-container>
                         </legend>
                      </fieldset>
                      <fieldset class="variant__input--fieldset weight"
                         *ngIf="!(lstCurrentDet.OPGName1=='Color' || lstCurrentDet.OPGName1=='color' || lstCurrentDet.OPGName1=='COLOR' )">
                         <div class="row">
                            <div class="col-md-8 col-12">
                               <legend class="product__variant--title mb-8">
                                  {{lstCurrentDet.OPGName1}}
                               </legend>
                               <span
                                  *ngFor="let OptionItem of  lstCurrentDet['lst1Options']| UniqueOptions;let ind=index">
                               <input [id]="'lstCurrentDet.OPGName1_'+ind"
                               [name]="'lstCurrentDet.OPGName1_'+ind" type="radio"
                               [attr.checked]="(SelectedOption1== OptionItem.OptionName ? 'checked' : null    )"
                               (click)="OptionsChange($event,OptionItem.OptionName,1);">
                               <label class="variant__size--value red d-inline p-04"
                                  [for]="'lstCurrentDet.OPGName1_'+ind">{{OptionItem.OptionName}}</label>
                               </span>
                            </div>
                            <div class="col-md-4 col-12"
                               *ngIf="(lstCurrentDet.OPGName1=='Size' ||  lstCurrentDet.OPGName1=='size' ||  lstCurrentDet.OPGName1=='SIZE')">
                               <a href="#popup1" *ngIf="lstCurrentDet.ProductCartDesc!=''"
                               (click)="sizeView(lstCurrentDet.ProductCartDesc)"><img
                                  src="assets/img/size-icon.png" alt="Size Chart"> Size Chart</a>
                            </div>
                         </div>
                      </fieldset>
                   </div>
                   <div class="product__variant--list mb-10" *ngIf="(lstCurrentDet.OPGName2!='')">
                      <fieldset class="variant__input--fieldset"
                         *ngIf="(lstCurrentDet.OPGName2=='Color' ||  lstCurrentDet.OPGName2=='color' ||  lstCurrentDet.OPGName2=='COLOR')">
                         <legend class="product__variant--title mb-8">
                            {{lstCurrentDet.OPGName2}}:
                            <ng-container *ngFor="let OptionItem of  lstCurrentDet['lst2Options']| UniqueOptions;let ind=index" >
                               <span class="w-40">
                                  <input [id]="'color-'+ind" name="color" type="radio"
                                  value="OptionItem.OptionName"
                                  [attr.checked]="(SelectedOption2== OptionItem.OptionName ? 'checked' : null    )"
                                  (click)="OptionsChange($event,OptionItem.OptionName,2);">
                                  <label class="variant__color--value red w-35 h-35" [for]="'color-'+ind"
                                     title={{OptionItem.OptionName}}> 
                                  <img class="variant__color--value__img w-29 h-29"
                                    alt="Buy online - {{lstCurrentDet.ProductName}}"
                                     src={{ImagePath+OptionItem.Image}} />
                                  </label>
                                  <!-- [attr.checked]="(SelectedOption2== OptionItem.OptionName ? 'checked' : null    )" -->
                               </span>
                            </ng-container>
                         </legend>
                      </fieldset>
                      <!-- Sizes binding -->
                      <fieldset class="variant__input--fieldset weight"
                         *ngIf="( lstCurrentDet.OPGName2=='Size' || lstCurrentDet.OPGName2=='size' || lstCurrentDet.OPGName2=='SIZE' )">
                         <div class="row">
                            <div class="col-md-8 col-12">
                               <legend class="product__variant--title mb-8">
                                  {{lstCurrentDet.OPGName2}} : 
                                  <span class="w-47"
                                     *ngFor="let OptionItem of  lstCurrentDet['lst2Options']| UniqueOptions;let ind=index" >
                                  <input [id]="'lstCurrentDet.OPGName2_'+ind"
                                  [name]="'lstCurrentDet.OPGName2_'+0" type="radio"
                                  [attr.checked]="(SelectedOption2== OptionItem.OptionName ? 'checked' : null    )"
                                  title="{{'lstCurrentDet.OPGName2_'+ind}}"
                                  (click)="OptionsChange($event,OptionItem.OptionName,2);">
                                  <label class="variant__size--value red p-02 h-34 w-34 br-rds_50 p-relative"
                                     [for]="'lstCurrentDet.OPGName2_'+ind">{{OptionItem.OptionName}}
                                  <span *ngIf="getstockstatus(OptionItem.OptionName)" class="crossmark"></span>
                                  </label>
                                  </span>
                               </legend>
                            </div>
                            <div class="col-md-4 col-12"
                               *ngIf="(lstCurrentDet.OPGName2=='Size' ||  lstCurrentDet.OPGName2=='size' ||  lstCurrentDet.OPGName2=='SIZE')">
                               <a href="#popup1" *ngIf="lstCurrentDet.ProductCartDesc!=''"
                               (click)="sizeView(lstCurrentDet.ProductCartDesc)"><img
                                  src="assets/img/size-icon.png" alt="Size Chart"> Size Chart</a>
                            </div>
                         </div>
                      </fieldset>
                   </div>
                   <div class="product__variant--list mb-10" *ngIf="(lstCurrentDet.OPGName3!='')">
                      <fieldset class="variant__input--fieldset" *ngIf="(lstCurrentDet.OPGName3=='Color' ||  lstCurrentDet.OPGName3=='color' ||  lstCurrentDet.OPGName3=='COLOR')">
                         <legend class="product__variant--title mb-8">{{lstCurrentDet.OPGName3}} :
                            <span
                               *ngFor="let OptionItem of  lstCurrentDet['lst3Options']| UniqueOptions;let ind=index">
                            <input [id]="'color-'+ind" name="color" type="radio"
                            [attr.checked]="(SelectedOption3== OptionItem.OptionName ? 'checked' : null    )"
                            (click)="OptionsChange($event,OptionItem.OptionName,3);">
                            <label class="variant__color--value red" [for]="'color-'+ind"
                               title={{OptionItem.OptionName}}>
                            <img class="variant__color--value__img w-19 h-19"
                            alt="Buy online - {{lstCurrentDet.ProductName}}"
                               src={{ImagePath+OptionItem.Image}} /></label>
                            </span>
                         </legend>
                      </fieldset>
                      <fieldset class="variant__input--fieldset" *ngIf="( lstCurrentDet.OPGName3=='Size' || lstCurrentDet.OPGName3=='size' || lstCurrentDet.OPGName3=='SIZE' )">
                         <legend class="product__variant--title mb-8">  {{lstCurrentDet.OPGName3}}  :
                            <span *ngFor="let OptionItem of  lstCurrentDet['lst3Options']| UniqueOptions;let ind=index" class="w-47">
                            <input [id]="'lstCurrentDet.OPGName3'+ind"
                            [name]="'lstCurrentDet.OPGName3'+0" type="radio"
                            [attr.checked]="(SelectedOption3== OptionItem.OptionName ? 'checked' : null    )"
                            title="{{'lstCurrentDet.OPGName3'+ind}}"
                            (click)="OptionsChange($event,OptionItem.OptionName,3);">
                            <label class="variant__size--value red p-02 h-34 br-rds_50"
                               [for]="'lstCurrentDet.OPGName3'+ind">{{OptionItem.OptionName}}</label>
                            </span>
                         </legend>
                      </fieldset>
                      <fieldset class="variant__input--fieldset weight"
                         *ngIf="!(lstCurrentDet.OPGName3=='Color'|| lstCurrentDet.OPGName3=='color' || lstCurrentDet.OPGName3=='size' || lstCurrentDet.OPGName3=='Size' ||lstCurrentDet.OPGName3=='size' )">
                         <div class="row">
                            <div class="col-md-8 col-12">
                               <legend class="product__variant--title mb-8">
                                  {{lstCurrentDet.OPGName3}} :
                                  <ng-container *ngFor="let OptionItem of  lstCurrentDet['lst3Options']| UniqueOptions; let ind=index">
                                     <span class="w-auto margin_cstm">
                                     <input [id]="'lstCurrentDet.OPGName3_'+ind"
                                     [name]="'lstCurrentDet.OPGName3_0'" type="radio"
                                     [attr.checked]="(SelectedOption3==OptionItem.OptionName ? 'checked' : null)"
                                     (click)="OptionsChange($event,OptionItem.OptionName,3);" >
                                     <label class="variant__size--value red p-02 h-34 w-34 w-auto"
                                        [for]="'lstCurrentDet.OPGName3_'+ind">{{OptionItem.OptionName}}</label>    
                                     </span>
                                  </ng-container>
                               </legend>
                            </div>
                            <div class="col-md-4 col-12"
                               *ngIf="(lstCurrentDet.OPGName3=='Size' ||  lstCurrentDet.OPGName3=='size' ||  lstCurrentDet.OPGName3=='SIZE')">
                               <a href="#popup1" *ngIf="lstCurrentDet.ProductCartDesc!=''"
                               (click)="sizeView(lstCurrentDet.ProductCartDesc)"><img
                                  src="assets/img/size-icon.png" alt="Size Chart"> Size Chart</a>
                            </div>
                         </div>
                      </fieldset>
                   </div>
                   <div class="product__variant--list mb-10" *ngIf="(lstCurrentDet.OPGName4!='')">
                      <fieldset class="variant__input--fieldset"
                         *ngIf="(lstCurrentDet.OPGName4=='Color' ||  lstCurrentDet.OPGName4=='color' ||  lstCurrentDet.OPGName4=='COLOR')">
                         <legend class="product__variant--title mb-8">{{lstCurrentDet.OPGName4}} :
                         </legend>
                         <span
                            *ngFor="let OptionItem of  lstCurrentDet['lst4Options']| UniqueOptions;let ind=index">
                         <input [id]="'color-'+ind" name="color" type="radio"
                         [attr.checked]="(SelectedOption4== OptionItem.OptionName ? 'checked' : null    )"
                         (click)="OptionsChange($event,OptionItem.OptionName,4);">
                         <label class="variant__color--value red" [for]="'color-'+ind"
                            title={{OptionItem.OptionName}}>
                         <img class="variant__color--value__img w-19 h-19"
                         alt="Buy online - {{lstCurrentDet.ProductName}}"
                            src={{ImagePath+OptionItem.Image}} />
                         </label>
                         </span>
                      </fieldset>
                      <fieldset class="variant__input--fieldset weight"
                         *ngIf="!(lstCurrentDet.OPGName4=='Color' ||  lstCurrentDet.OPGName4=='color' ||  lstCurrentDet.OPGName4=='COLOR')">
                         <div class="row">
                            <div class="col-md-8 col-12">
                               <legend class="product__variant--title mb-8">
                                  {{lstCurrentDet.OPGName4}}
                               </legend>
                               <span
                                  *ngFor="let OptionItem of  lstCurrentDet['lst4Options']| UniqueOptions;let ind=index">
                               <input [id]="'lstCurrentDet.OPGName4_'+ind"
                               [name]="'lstCurrentDet.OPGName4_'+ind" type="radio"
                               [attr.checked]="(SelectedOption4== OptionItem.OptionName ? 'checked' : null )"
                               (click)="OptionsChange($event,OptionItem.OptionName,4);">
                               <label class="variant__size--value red d-inline p-04"
                                  [for]="'lstCurrentDet.OPGName4_'+ind">{{OptionItem.OptionName}}</label>
                               </span>
                            </div>
                            <div class="col-md-4 col-12"
                               *ngIf="(lstCurrentDet.OPGName4=='Size' ||  lstCurrentDet.OPGName4=='size' ||  lstCurrentDet.OPGName4=='SIZE')">
                               <a href="#popup1" *ngIf="lstCurrentDet.ProductCartDesc!=''"
                               (click)="sizeView(lstCurrentDet.ProductCartDesc)"><img
                                  src="assets/img/size-icon.png" alt="Size Chart"> Size Chart</a>
                            </div>
                         </div>
                      </fieldset>
                   </div>
                   <div class="product__variant--list mb-10">
                      <div class="row ">
                         <h4 for="" >Product Details</h4>
                         <div class="col-md-12 mb-2">
                            <p>{{lstCurrentDet.ProductLongDesc}}</p>
                         </div>
                         <div class="col-md-6 mb-2">
                            <!-- <h4 class="layout__flex--item product-desc-title">Code:</h4> -->
                            <p class="p-0 fw-600">Code : {{lstCurrentDet.ProductCode}}</p>
                         </div>
                         <div class="col-md-6 mb-2" *ngIf="lstCurrentDet.OPGName1!=''">
                            <p class="p-0 fw-600">{{lstCurrentDet.OPGName1}} : {{SelectedOption1}}</p>
                         </div>
                         <div class="col-md-6 mb-2" *ngIf="lstCurrentDet.OPGName2!=''">
                            <p class="p-0 fw-600">{{lstCurrentDet.OPGName2}} : {{SelectedOption2}}</p>
                         </div>
                         <div class="col-md-6 mb-2" *ngIf="lstCurrentDet.OPGName3!=''">
                            <p class="p-0 fw-600">{{lstCurrentDet.OPGName3}} : {{SelectedOption3}}</p>
                         </div>
                         <div class="col-md-6 mb-2" *ngIf="lstCurrentDet.OPGName4!=''">
                            <p class="p-0 fw-600">{{lstCurrentDet.OPGName3}} : {{SelectedOption4}}</p>
                         </div>
                      </div>
                   </div>
                   <div class="product__variant--list mb-10 " *ngIf="(lstCurrentDet.ProductShortDesc!='')">
                      <fieldset class="variant__input--fieldset weight ">
                         <div class="row">
                            <div class="col-md-12 col-12" >
                               <legend class="product__variant--title mb-8"> Description </legend>
                               <div  class="myclass" [innerHtml]="lstCurrentDet.ProductShortDesc"
                                  ></div>
                            </div>
                         </div>
                      </fieldset>
                   </div>
                   <div class="product__variant--list mb-15">
                      <div class="row" *ngIf="GetPurchaseQty(lstCurrentDet)<=lstCurrentDet.Availableqty || GetPurchaseQty(lstCurrentDet)=='' || lstCurrentDet.Availableqty==(GetPurchaseQty(lstCurrentDet))">
                         <!-- <div class="col-6 col-md-6 mb-3"><button (click)="AddtoCartClick(1,'cart')"
                            *ngIf="lstCurrentDet.Available!='out of stock'"
                            class="variant__buy--now__btn primary__btn outline" type="submit" ><a>Add To Cart </a></button></div> -->
                         <!-- <div class="col-6  col-md-6 mb-3"><button (click)="BuyNowProduct()" -->
                           <div class="col-6  col-md-6 mb-3"><button (click)="buyProduct()"
                            *ngIf="lstCurrentDet.Available!='out of stock'"
                            class="variant__buy--now__btn primary__btn" type="submit"><a>Replace</a></button>
                         </div>
                      </div>
                      <div class="row">
                         <div class="col-md-7 mb-1">
                            <h4 for="" class="mb-3">Delivery options</h4>
                            <div class="input-group mb-3">
                               <input type="text" name="deliveryZipCode" id="deliveryZipCode"
                                  class="form-control" placeholder="Enter pincode" autocomplete="off"
                                  aria-label="Recipient's username" aria-describedby="basic-addon2">
                               <div class="input-group-append cursor-pointer">
                                  <span class="input-group-text" id="basic-addon2"><a
                                     (click)="checkDeliveryAvailability(lstCurrentDet.SalesPrice)">Check</a></span>
                               </div>
                            </div>
                         </div>
                         <div class="col-md-12 mb-2" *ngIf="DeliveryCharges==-1 && EnterPincode!=''">
                            <label *ngIf="Deliveryerror" class="text-danger fs-13">Delivery not available at pincode -{{EnterPincode}}</label>
                         </div>
                         <div class="col-md-12 mb-2"  *ngIf="ExpectedDeliveryDays==''">
                            <label for=""><i class="fa fa-calendar me-05"
                               aria-hidden="true"></i> Enter Pincode for Delivery Date</label>
                         </div>
                         <div class="col-md-12 mb-2" *ngIf="ExpectedDeliveryDays!='' && ExpectedDeliveryDays!=null">
                            <label for=""><i class="fa fa-calendar me-05"
                               aria-hidden="true"></i> Expected Delivery Date
                            {{ExpectedDeliveryDays}}</label>
                         </div>
                         <div *ngIf="!Deliveryerror && showDeliveryStatusmsg">
                            <label for="" class="text-success fs-13 fw-400">Delivery available at pincode -{{EnterPincode}}</label>
                         </div>
                         <div class="col-md-12 mb-2" *ngIf="showDeliveryStatusmsg && DeliveryCharges==0">
                            <!-- Shipping Charges -->
                            <!-- <label for=""><i class="fa fa-truck" style="margin-right:5px;"
                               aria-hidden="true"></i>{{DeliveryCharges==0?'FREE shipping':('Shipping Charges:'+(getcurrency()+DeliveryCharges ))}}
                               <span *ngIf="DeliveryCharges==0"
                               style="text-decoration:line-through; color:red;"><b class="rupee">{{getcurrency()}}</b>{{(mDeliveryCharges)}}</span>
                               </label> -->
                            <label for=""><i class="fa fa-truck me-05"
                               aria-hidden="true"></i>{{DeliveryCharges==0?'FREE shipping':''}}
                            <span *ngIf="DeliveryCharges==0" class="text_dec"><b class="rupee">{{getcurrency()}}</b>{{(mDeliveryCharges)}}</span>
                            </label>
                         </div>
                         <div class="col-md-12">
                            <label for=""><i class="fa fa-reply me-05 text-decoration"
                               aria-hidden="true"></i><a href="#returns">View Return Policy</a></label>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
       </div>
    </section>

    <h1></h1>
        <h2></h2>
        <h3></h3>
        <h4></h4>
 </main>