import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-personalinfodetails',
  templateUrl: './personalinfodetails.component.html',
  styleUrls: ['./personalinfodetails.component.css']
})
export class PersonalinfodetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  //  $(document).ready(function() {
 
      //  window.scroll(0,Number(localStorage.getItem("scrollPosition")));
      // window.scrollTo(0,0);
     //    })
  }

}
