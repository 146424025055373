
    <section class="portfolio__section section--padding">
        <div class="container-fluid offset">
            <div class="section__heading text-center mb-40">
                <h2 class="section__heading--maintitle">Men</h2>
            </div>
            <div class="portfolio__section--inner p-5">
                <div class="row row-cols-lg-6 row-cols-md-6 row-cols-sm-2 row-cols-2 mb--n30">
                    <div class="col mb-30">
                        <div class="portfolio__items">
                            <div class="portfolio__items--thumbnail position__relative">
                                <a class="portfolio__items--thumbnail__link" href="product.html"><img class="portfolio__items--thumbnail__img" src="assets/img/other/1.jpg" alt="portfolio-img">
                                    <span class="portfolio__view--icon visible">
                                        Shirts
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col mb-30">
                        <div class="portfolio__items">
                            <div class="portfolio__items--thumbnail position__relative">
                                <a class="portfolio__items--thumbnail__link"><img class="portfolio__items--thumbnail__img" src="assets/img/other/2.jpg" alt="portfolio-img">
                                    <span class="portfolio__view--icon visible">
                                        T Shirts
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col mb-30">
                        <div class="portfolio__items">
                            <div class="portfolio__items--thumbnail position__relative">
                                <a class="portfolio__items--thumbnail__link"><img class="portfolio__items--thumbnail__img" src="assets/img/other/3.jpg" alt="portfolio-img">
                                    <span class="portfolio__view--icon visible">
                                       Trousers &amp; Chinos
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col mb-30">
                        <div class="portfolio__items">
                            <div class="portfolio__items--thumbnail position__relative">
                                <a class="portfolio__items--thumbnail__link"><img class="portfolio__items--thumbnail__img" src="assets/img/other/4.jpg" alt="portfolio-img">
                                    <span class="portfolio__view--icon visible">
                                        Jeans
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col mb-30">
                        <div class="portfolio__items">
                            <div class="portfolio__items--thumbnail position__relative">
                                <a class="portfolio__items--thumbnail__link" alt="portfolio-img"> <img class="portfolio__items--thumbnail__img" src="assets/img/other/5.jpg" alt="portfolio-img">
                                    <span class="portfolio__view--icon visible">
                                       Shorts
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col mb-30">
                        <div class="portfolio__items">
                            <div class="portfolio__items--thumbnail position__relative">
                                <a class="portfolio__items--thumbnail__link" alt="portfolio-img"> <img class="portfolio__items--thumbnail__img" src="assets/img/other/6.jpg" alt="portfolio-img">
                                    <span class="portfolio__view--icon visible">
                                        Suits
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col mb-30">
                        <div class="portfolio__items">
                            <div class="portfolio__items--thumbnail position__relative">
                                <a class="portfolio__items--thumbnail__link"><img class="portfolio__items--thumbnail__img" src="assets/img/other/2.jpg" alt="portfolio-img">
                                    <span class="portfolio__view--icon visible">
                                        T Shirts
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col mb-30">
                        <div class="portfolio__items">
                            <div class="portfolio__items--thumbnail position__relative">
                                <a class="portfolio__items--thumbnail__link"><img class="portfolio__items--thumbnail__img" src="assets/img/other/3.jpg" alt="portfolio-img">
                                    <span class="portfolio__view--icon visible">
                                        Trousers &amp; chinos
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col mb-30">
                        <div class="portfolio__items">
                            <div class="portfolio__items--thumbnail position__relative">
                                <a class="portfolio__items--thumbnail__link "><img class="portfolio__items--thumbnail__img" src="assets/img/other/5.jpg" alt="portfolio-img">
                                    <span class="portfolio__view--icon visible">
                                       Shorts
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col mb-30">
                        <div class="portfolio__items">
                            <div class="portfolio__items--thumbnail position__relative">
                                <a class="portfolio__items--thumbnail__link">
                                    <img class="portfolio__items--thumbnail__img" src="assets/img/other/6.jpg" alt="portfolio-img">
                                    <span class="portfolio__view--icon visible">
                                        Shuits
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col mb-30">
                        <div class="portfolio__items">
                            <div class="portfolio__items--thumbnail position__relative">
                                <a class="portfolio__items--thumbnail__link"><img class="portfolio__items--thumbnail__img" src="assets/img/other/1.jpg" alt="portfolio-img">
                                    <span class="portfolio__view--icon visible">
                                        Shirts
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col mb-30">
                        <div class="portfolio__items">
                            <div class="portfolio__items--thumbnail position__relative">
                                <a class="portfolio__items--thumbnail__link"><img class="portfolio__items--thumbnail__img" src="assets/img/other/4.jpg" alt="portfolio-img">
                                    <span class="portfolio__view--icon visible">
                                        Jeans
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    

                </div>
            </div>
        </div>
        <h1></h1>
        <h2></h2>
        <h3></h3>
        <h4></h4>
    </section>
 